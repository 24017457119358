import { ApiResponse } from "apisauce";
import { ApiResponseAction, IDocument } from "app-types";
import { DocumentDetailDto } from "features/documents/documents-api-types";
import Apis from "store/api";
import { createAppAsyncThunk, haveItem, parseResponse } from "store/slices/slice-helpers";
import { APPROVE_TYPES } from "utils/checklist-helpers";

export const DOC_THUNKS = {
  fetch: "document/fetch",
  respond: "document/checklist/item/respond",
  approve: "document/checklist/approve",
};

export const fetchDocThunk = createAppAsyncThunk(DOC_THUNKS.fetch, async (params: any, thunkAPI) => {
  const { docId, forceRefresh = false } = params;
  if(!docId) return;

  const docState = thunkAPI.getState().doc;

  // let item: IDocument | null = null;
  let response: ApiResponseAction<DocumentDetailDto | null> | null = null;

  if(forceRefresh || haveItem(docId, docState)){
    // item = docState.item;
    response = { type: DOC_THUNKS.fetch, ok: true, data: null, params };
  }
  else {
    const getResult = await Apis.Documents.get<IDocument, any>(`/${docId}`);
    response = parseResponse(DOC_THUNKS.fetch, getResult, { docId });
  }

  return response;
});

export const respondToItemThunk = createAppAsyncThunk(DOC_THUNKS.respond, async (params: any, thunkAPI) => {
  const { response } = params;
  const { id, isDelete = false, isUpdate = false } = response;
  let apiResult: ApiResponse<any> | null = null;

  if(id){
    if(isDelete) apiResult = await Apis.CheckListResponses.delete(`/${id}`);
    else if(isUpdate) apiResult = await Apis.CheckListResponses.put(`/${id}`, response);
  }
  else {
    //this is a new response, so post it
    apiResult = await Apis.CheckListResponses.post<any, any>("", response);
  }

  const result = parseResponse(DOC_THUNKS.respond, apiResult!, { response });
  return result;
});

export const approveCheckListThunk = createAppAsyncThunk(DOC_THUNKS.approve, async (params: any, thunkAPI) => {
  const { model } = params;
  const { status } = model;
  const endpoint = status === APPROVE_TYPES.unApprove ? "disapprove" : "approve";
  const path = `/${model.reviewRecordId}/checklists/${model.checkListId}/${endpoint}`;

  const apiResult = await Apis.ReviewRecords.put(path, model);
  const result = parseResponse(DOC_THUNKS.approve, apiResult, { model });
  return result;
});


// //== NOTE: this action is not dispatched as it is a transient request, and
// // the results should not be stored in the repository.
export const getDocReviewRecords = async (docId: string) => {
  const result = await Apis.Documents.get(`/${docId}/review-records`);
  if(result.ok){
    return {
      ok: true,
      data: result.data
    }
  }  
  else{
    return result;
  }
}