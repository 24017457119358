import React, { useState, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Fade, Grid, TextField, Tooltip, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { useAppDispatch } from 'store';
import { insertBuilderItem, insertBuilderSection, moveBuilderItem, toggleBuilderSection, updateBuilderSection } from 'store/actions';
import { selectWorkingSection } from 'store/selectors/builder-selectors';
import { keyDownCommand } from 'utils/general-helpers';
import Validation from 'utils/validation-helper';
import { useInputHandler } from 'hooks/general-hooks';
import AssertionEditor from './checklist-editor-assertion';

const ChecklistEditorSection = ({section, checklist, status, isEditable}) => {
  const { classes }   = buildStyles();
  const dispatch  = useAppDispatch();
  const working   = useSelector(state => selectWorkingSection(state, section.id));
  
  const original  = useMemo(() => _.pick(working, ["sectionName"]), [working]);
  const [values, onChange] = useInputHandler(original, null, onValidate);
  const [validation, setValidation]	= useState({canSave: false});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isAutoFocus = useMemo(() => working.isNew && checklist.name?.length > 0, []);
  
  if(!working) return null;

  function onValidate(toValidate){
    //Validate that there is a name
    const overall   = Validation.validatePropertyCondition(validation, "sectionName", toValidate, val => val.length > 3, "Name is required (and must be at least 3 characters).");
    const hasErrors = _.some(overall, v => v.hasError);
    setValidation({
      ...overall,
      canSave   : !hasErrors,
    });
  }

  function onToggleExpanded(){
    dispatch(toggleBuilderSection({ id: section.id, isExpanded: !working.isExpanded }));
  }

  function onTextBlur(e){
    if(!shallowEqual(values, original)){
      dispatch(updateBuilderSection({ id: section.id, changes: values }));
    }
  }
  
  function onTextFocus(e){
    e.stopPropagation();
  }

  function onCommandExecuted(command){
    switch(command){
      case "moveUp"     : dispatch(moveBuilderItem({ id: section.id, offset: -1 })); break;
      case "moveDown"   : dispatch(moveBuilderItem({ id: section.id, offset: 1 })); break;
      case "insertAssertionAfter"   : {
        const refId  = working.items.length > 0 ? working.items[working.items.length - 1].id : undefined;
        dispatch(insertBuilderItem({ parentId: section.id, refId, offset: 1 }));
        break;
      }
      case "insertSectionBefore"    : dispatch(insertBuilderSection({ id: section.id, offset: -1 })); break;
      case "insertSectionAfter"     : dispatch(insertBuilderSection({ id: section.id, offset: 1 }));break;
      case "deleteItem" : toggleDelete(); break;
      default : console.log("unknown command: ", command); break;
    }
  }

  function onActionClicked(e, action){
    e.stopPropagation();
    onCommandExecuted(action);
  }

  function onKeyDown(e){
    const command   = keyDownCommand(e);
    if(!command) return;
    onCommandExecuted(command);
  }

  function toggleDelete(){
    const changes   = { isDeleted: !Boolean(working.isDeleted) };
    dispatch(updateBuilderSection({ id: section.id, changes }));
  }

  return (
    <>
      <DeletedSection item={working} values={values} onUndelete={toggleDelete} />
      {!working.isDeleted && 
        <Accordion className={classes.sectionPanel} variant="outlined" expanded={working.isExpanded} onChange={onToggleExpanded}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{content: classes.panelSummary}}>
            {!working.isExpanded && 
              <Grid container alignItems="center">
                <Typography className={classes.title}>{working.sectionName}</Typography>
                <Typography className={classes.subTitle}>({working.items.length} assertions)</Typography>
              </Grid>
            }
            {working.isExpanded && 
              <Grid container alignItems="center">
                <TextField id="sectionName" autoFocus={isAutoFocus} value={values.sectionName} onChange={onChange} onClick={onTextFocus} onFocus={onTextFocus} onKeyDown={onKeyDown} onBlur={onTextBlur}
                disabled={!isEditable || status.isWorking} fullWidth className={classes.titleTextField} size="small" placeholder="Section Name" {...validation.sectionName?.fieldProps} autoComplete="off"/>
              </Grid>
            }
          </AccordionSummary>
          <AccordionDetails className={classes.panelBody}>
            <Grid container direction="column">
              {
                _.map(working.items, (item, index) => <AssertionEditor key={item.id} rowIndex={index} assertion={item} section={section} checklist={checklist} status={status} isEditable={isEditable}/>)
              }
            </Grid>
          </AccordionDetails>
          {isEditable && 
            <Fade in={working.isExpanded}>
              <Grid container justifyContent="center">
                <EditorTools onAction={onActionClicked}/>        
              </Grid>
            </Fade>
          }
        </Accordion>
      }
    </>
  );
}

export default ChecklistEditorSection;

const buildStyles   = makeStyles()(theme => ({
  sectionPanel  : {
    width     : "100%",
  },
  panelSummary  : {
    "&.Mui-expanded" : {
      margin      : `${theme.spacing(1.5)} ${theme.spacing(0)} !important`, //`
    }
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 400,
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    marginLeft  : theme.spacing(2),
    color     : theme.palette.grey[600],
  },
  titleTextField  : {
    "& input" : {
      fontSize  : 22,
    },
  },
  toolsGrid   : {
    // border      : `1px solid ${theme.palette.grey[300]}`, //`
    // borderRadius: 4,
    // padding     : `${theme.spacing(0)} ${theme.spacing(1)}`, //`
    position    : "absolute",
    bottom      : "-16px",
    background  : "white",
    maxWidth    : 200,
    // marginBottom  : theme.spacing(2),
  },
  toolsButton   : {
    padding   : theme.spacing(0.5),
    "&:hover"   : {
      "& svg"   : {
        color     : theme.palette.secondary.main,
      }
    },
  },
  deletedSection      : {
    background  : theme.statuses.rejected.background,
    padding     : `${theme.spacing(0.5)} ${theme.spacing(1)}`, //`,
    marginTop     : theme.spacing(1),
    marginBottom  : theme.spacing(1),
    border      : `1px solid ${theme.palette.grey[300]}`, //`
  },
  sectionIcon   : {
    marginRight   : theme.spacing(1),
    marginBottom  : -6,
  },
  deletedText  : {
    fontSize    : 14,
    display     : "inline-block",
  },
  deleteButton  : {
    // marginRight : theme.spacing(1),
  }
}));

export function EditorTools({onAction}){
  const { classes }   = buildStyles();

  return (
    <Grid container justifyContent="space-evenly" className={classes.toolsGrid}>
      <Tooltip title="Add Section (below)">
        <IconButton
          className={classes.toolsButton}
          onClick={(e) => onAction(e, "insertSectionAfter")}
          size="large"><AddIcon fontSize="small" /></IconButton>
      </Tooltip>
      <Tooltip title="Add Assertion (at bottom)">
        <IconButton
          className={classes.toolsButton}
          onClick={(e) => onAction(e, "insertAssertionAfter")}
          size="large"><PostAddIcon fontSize="small" /></IconButton>
      </Tooltip>      
      <Tooltip title="Remove this Section">
        <IconButton
          className={classes.toolsButton}
          onClick={(e) => onAction(e, "deleteItem")}
          size="large"><RemoveIcon fontSize="small" /></IconButton>
      </Tooltip>
    </Grid>
  );
}

function DeletedSection({item, values, onUndelete}){
  const { classes }   = buildStyles();
  if(!item.isDeleted) return null;

  return (
    <Grid container className={classes.deletedSection} alignItems="center">
      <Grid item xs={9}>
        <Tooltip title="This section has been deleted.  Click Undo to un-delete this section.">
          <DeleteIcon fontSize="small" className={classes.sectionIcon}/>
        </Tooltip>
        <Typography className={classes.deletedText}>{values.sectionName} ({item.checklistItems?.length} assertions)</Typography>
      </Grid>
      <Grid item xs={3} container alignItems="center" justifyContent="flex-end">
        <Button size="small" onClick={onUndelete} className={classes.deleteButton}>Undo Delete</Button>
      </Grid>
    </Grid>
  )
}