import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { useHover } from 'hooks/use-hover';

const AssertionMenu = ({onAction, assertion, disabled}) => {
  const { classes }   = buildStyles();
  const [anchor, isOpen, onEnter, onExit, position]   = useHover();  

  function onMenuClick(e){
    onAction(e.currentTarget.id);
    onExit();
  }

  const txtLabel  = !!assertion.textInput ? "Edit Textbox (alt-t)" : "Add Textbox (alt-t)";
  const optLabel  = !!assertion.optInput ? "Edit Options (alt-o)" : "Add Options (alt-o)";

  return (
    <Grid item className={classes.actionButtonGrid}>
      <Tooltip title="Click for additional options">
        <>
          <IconButton
            className={classes.actionButton}
            onClick={onEnter}
            disabled={disabled}
            size="large"><Icon fontSize="small">more_vert</Icon></IconButton>
          </>
      </Tooltip>
      
      <Popover open={isOpen} anchorEl={anchor} {...position} onClose={onExit}>
        <Grid container justifyContent="center">
          <MenuList>
            <MenuItem id="openTextInput" onClick={onMenuClick}>
              <ListItemIcon>
                <Icon fontSize="small">text_fields</Icon>
              </ListItemIcon>
              <Typography className={classes.menuText}>{txtLabel}</Typography>
            </MenuItem>
            <MenuItem id="openOptionsInput" onClick={onMenuClick}>
              <ListItemIcon>
                <Icon fontSize="small">menu</Icon>
              </ListItemIcon>
              <Typography className={classes.menuText}>{optLabel}</Typography>
            </MenuItem>
            <Divider/>
            <MenuItem id="insertAssertionAfter" onClick={onMenuClick}>
              <ListItemIcon>
                <Icon fontSize="small">add_circle_outline</Icon>
              </ListItemIcon>
              <Typography className={classes.menuText}>Add Assertion below (alt-a)</Typography>
            </MenuItem>
            <MenuItem id="insertAssertionBefore" onClick={onMenuClick}>
              <ListItemIcon>
                <Icon fontSize="small">add_box_outline</Icon>
              </ListItemIcon>
              <Typography className={classes.menuText}>Add Assertion above (alt-shift-a)</Typography>
            </MenuItem>
            <MenuItem id="deleteItem" onClick={onMenuClick}>
              <ListItemIcon>
                <Icon fontSize="small">delete</Icon>
              </ListItemIcon>
              <Typography className={classes.menuText}>Remove this item (alt-x)</Typography>
            </MenuItem>    
            <Divider/>
            <MenuItem id="moveUp" disabled={assertion?.isFirst} onClick={onMenuClick}>
              <ListItemIcon>
                <Icon fontSize="small">arrow_upward</Icon>
              </ListItemIcon>
              <Typography className={classes.menuText}>Move up (shift-up arrow)</Typography>
            </MenuItem>    
            <MenuItem id="moveDown" disabled={assertion?.isLast} onClick={onMenuClick}>
              <ListItemIcon>
                <Icon fontSize="small">arrow_downward</Icon>
              </ListItemIcon>
              <Typography className={classes.menuText}>Move down (shift-down arrow)</Typography>
            </MenuItem>                                
          </MenuList>
        </Grid>
      </Popover>

    </Grid>
  );
}

export default AssertionMenu;

const buildStyles   = makeStyles()(theme => ({
  actionButtonGrid : {
    margin      : `${theme.spacing(0)} ${theme.spacing(0.25)}`, //`    
    "& .MuiSvgIcon-root"  : {
      height    : "0.75em",
      width    : "0.75em",
    },
    "& .MuiIconButton-root"   : {
      padding     : `${theme.spacing(0)} ${theme.spacing(0.25)}`, //`
    },
  },
  menuText  : {
    fontSize    : "1rem",
  }
}));