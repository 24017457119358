import { IDocument, IDocumentStatus, } from "app-types";
import { isEmpty } from "lodash";

const pendingStatus : IDocumentStatus = {
  statusCode: 0,
  status: "PENDING",
  isApproved: false,
  isPreApproved: false,
};

const approvedStatus : IDocumentStatus = {
  statusCode: 100,
  status: "APPROVE",
  isApproved: true,
  isPreApproved: false,
};

export function getDocumentStatus(doc: IDocument) : IDocumentStatus {
  if(!doc.reviewRecords || isEmpty(doc.reviewRecords)) return pendingStatus;
  const primary = doc.reviewRecords.find(rr => rr.primaryReviewRecordId === null);
  if(!primary){
    throw new Error("Document has all non-primary review records.");
  }

  //TODO: deal with pre-approval. The base IDocument doesn't have enough information,
  // it would need to be an IDocumentForSet or an IDocumentExpanded (with the actual status records)

  //Check the number of approved CheckLists vs. the number of CheckLists
  const isApproved = primary.checkListCount === primary.checkListStatusApprovedCount;
  return isApproved ? approvedStatus : pendingStatus;  
}