import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { IDocumentType, IEngagement } from "app-types";
import { addDocumentTypeVersionThunk, copyDocumentTypeThunk, deleteAssignmentThunk, exportUsersThunk, fetchAllEngagementsThunk, fetchDocumentTypesThunk, fetchEngagementsThunk, fetchUserAssignmentsThunk, fetchUsersThunk, fetchDocumentTypeThunk, saveAssignmentThunk, saveUserThunk, toggleDocumentTypeCirculationThunk, fetchChecklistsThunk, fetchEngagementAssignmentsThunk } from "store/thunks/admin-thunks";
import { addOrReplace } from "./slice-helpers";
import { CheckListDto } from "types/model-types";
import { saveSettingThunk } from "store/thunks";
import { fetchReportQueueThunk } from "store/thunks/admin-report-thunks";

type AdminStatus = {
  isWorking: boolean;
  isInitialized: boolean;
  error: any | null;
}

type AdminCollection<T = any> = Record<string, any> & {
  items: T[];
  pagination?: any;   //TODO: pagination type?
  loadTime: number;
  params?: any;
}

export interface AdminSlice {
  status: { 
    users: AdminStatus;
    engagements: AdminStatus;
    assignments: AdminStatus;
    doctypes: AdminStatus;
    checklists: AdminStatus;
    reportQueue: AdminStatus;
    projects: AdminStatus;
    protocols: AdminStatus;
  };

  allEngagements: AdminCollection<IEngagement> | null;
  users: AdminCollection | null;
  engagements: AdminCollection<IEngagement> | null;
  engAssignments: AdminCollection | null;
  usrAssignments: AdminCollection | null;
  doctypes: AdminCollection<DocumentType> | null;
  checklists: AdminCollection<CheckListDto> | null;
  reportQueue: any | null;

  currentDocType: IDocumentType | null;
}

const DEFAULT_STATUS: AdminStatus = {
  isWorking: false,
  isInitialized: false,
  error: null,
};
const INITIALIZED = { ...DEFAULT_STATUS, isInitialized: true };

const INITIAL_STATE: AdminSlice = {
  status: {
    users: DEFAULT_STATUS,
    engagements: DEFAULT_STATUS,
    assignments: DEFAULT_STATUS,
    doctypes: DEFAULT_STATUS,
    checklists: DEFAULT_STATUS,
    reportQueue: DEFAULT_STATUS,
    projects: DEFAULT_STATUS,
    protocols: DEFAULT_STATUS,
  },
  allEngagements: null,
  users: null,
  engagements: null,
  engAssignments: null,
  usrAssignments: null,
  doctypes: null,
  checklists: null,
  reportQueue: null,
  currentDocType: null,
};

//=== Thunk Extra Reducers

//#region Users

const usersFetched = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(fetchUsersThunk.pending, pendingStatus("users"))
  .addCase(fetchUsersThunk.rejected, rejectStatus("users"))
  .addCase(fetchUsersThunk.fulfilled, (state, action) => {
    state.status.users = { ...INITIALIZED };
    const payload = action.payload;
    if(!payload) throw new Error("No payload found in fetchUsersThunk");
    state.users = {
      items: payload.data,
      pagination: payload.pagination,
      loadTime: new Date().getTime(),
      lastFilter: payload.filter,
    };
  });
};

const usersExported = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(exportUsersThunk.pending, pendingStatus("users"))
  .addCase(exportUsersThunk.rejected, rejectStatus("users"))
  .addCase(exportUsersThunk.fulfilled, (state, action) => {
    state.status.users.isWorking = false;
  });
};

const userAssignmentsFetched = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(fetchUserAssignmentsThunk.pending, pendingStatus("assignments"))
  .addCase(fetchUserAssignmentsThunk.rejected, rejectStatus("assignments"))
  .addCase(fetchUserAssignmentsThunk.fulfilled, (state, action) => {
    state.status.assignments = { ...INITIALIZED };
    state.usrAssignments = { 
      items: action.payload.data,
      loadTime: new Date().getTime(),
      //TODO: test if this is really needed, it doesn't seem to be populated in the old way
      // params: action.payload.params,
      userId: action.payload.userId,
    };
  });
};

const userSaved = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(saveUserThunk.pending, pendingStatus("users"))
  .addCase(saveUserThunk.rejected, rejectStatus("users"))
  .addCase(saveUserThunk.fulfilled, (state, action) => {
    state.users = {
      ...state.users,
      items: addOrReplace(state.users?.items ?? [], (u: any) => u.id === action.payload.userId, action.payload.data),
      loadTime: new Date().getTime(),
    };
    state.status.users = { ...INITIALIZED };
  });
};

//#endregion

//#region Engagements

const allEngagementsFetched = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(fetchAllEngagementsThunk.pending, pendingStatus("engagements"))
  .addCase(fetchAllEngagementsThunk.rejected, rejectStatus("engagements"))
  .addCase(fetchAllEngagementsThunk.fulfilled, (state, action) => {
    if(!action.payload) {
      if(!!state.allEngagements) return;    //we already have the engagements
      console.error("No payload found in fetchAllEngagementsThunk", action);
      throw new Error("No payload found in fetchAllEngagementsThunk");
    }
    state.status.engagements = { ...INITIALIZED };
    state.allEngagements = { 
      items: action.payload.data,
      pagination: action.payload.pagination,
      loadTime: new Date().getTime(),
      params: action.payload.filter,
    };
  });
};

const engagementsFetched = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(fetchEngagementsThunk.pending, pendingStatus("engagements"))
  .addCase(fetchEngagementsThunk.rejected, rejectStatus("engagements"))
  .addCase(fetchEngagementsThunk.fulfilled, (state, action) => {
    state.status.engagements = { ...INITIALIZED };
    state.engagements = {
      items: action.payload?.data,
      pagination: action.payload?.pagination,
      loadTime: new Date().getTime(),
      params: action.payload?.filter,
    };
  });
};

const engagementAssignmentsFetched = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(fetchEngagementAssignmentsThunk.pending, pendingStatus("assignments"))
  .addCase(fetchEngagementAssignmentsThunk.rejected, rejectStatus("assignments"))
  .addCase(fetchEngagementAssignmentsThunk.fulfilled, (state, action) => {
    state.status.assignments = { ...INITIALIZED };
    state.engAssignments = { 
      items: action.payload?.data,
      loadTime: new Date().getTime(),
      params: action.payload?.params,
      engagementId: action.payload?.engagementId,
    };
  });
};

//#endregion

//#region Assignments

const assignmentSaved = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(saveAssignmentThunk.pending, pendingStatus("assignments"))
  .addCase(saveAssignmentThunk.rejected, rejectStatus("assignments"))
  .addCase(saveAssignmentThunk.fulfilled, (state, action) => {
    const assignment = action.payload.data;
    if(state.engAssignments?.engagementId === assignment.entityId){
      const existing = state.engAssignments?.items.find((a: any) => a.id === action.payload.id);
      state.engAssignments = {
        ...state.engAssignments,
        items: addOrReplace(state.engAssignments?.items ?? [], (a: any) => a.id === action.payload.id, { ...existing, ...assignment }),
        loadTime: new Date().getTime(),
      };
    }

    if(state.usrAssignments?.userId === assignment.assignedUserId){
      const existing = state.usrAssignments?.items.find((a: any) => a.id === action.payload.id);
      state.usrAssignments = {
        ...state.usrAssignments,
        items: addOrReplace(state.usrAssignments?.items ?? [], (a: any) => a.id === action.payload.id, { ...existing, ...assignment }),
        loadTime: new Date().getTime(),
      };
    }

    state.status.assignments = { ...INITIALIZED };
  });
};

const assignmentDeleted = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(deleteAssignmentThunk.pending, pendingStatus("assignments"))
  .addCase(deleteAssignmentThunk.rejected, rejectStatus("assignments"))
  .addCase(deleteAssignmentThunk.fulfilled, (state, action) => {
    const { data: assignment, id, engagementId } = action.payload;

    if(engagementId && (state.engAssignments?.engagementId === assignment?.entityId || state.engAssignments?.engagementId === engagementId)){
      state.engAssignments = {
        ...state.engAssignments,
        items: state.engAssignments?.items.filter((a: any) => a.id !== id) ?? [],
        loadTime: new Date().getTime(),
      };
    }

    if(!engagementId && state.usrAssignments?.items){
      const existing = state.usrAssignments?.items.find((a: any) => a.id === id);
      if(existing){
        state.usrAssignments = {
          ...state.usrAssignments,
          items: state.usrAssignments?.items.filter((a: any) => a.id !== id) ?? [],
          loadTime: new Date().getTime(),
        };
      }
    }

    state.status.assignments = { ...INITIALIZED };
  });
};

//#endregion

//#region Document Types & Checklists

const documentTypesFetched = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(fetchDocumentTypesThunk.pending, pendingStatus("doctypes"))
  .addCase(fetchDocumentTypesThunk.rejected, rejectStatus("doctypes"))
  .addCase(fetchDocumentTypesThunk.fulfilled, (state, action) => {
    state.status.doctypes = { ...INITIALIZED };
    state.doctypes = {
      items: action.payload.data,
      pagination: action.payload.pagination,
      loadTime: new Date().getTime(),
      params: action.payload.filter,
    };
  });
};

const documentTypeFetched = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(fetchDocumentTypeThunk.pending, pendingStatus("doctypes")) 
  .addCase(fetchDocumentTypeThunk.rejected, rejectStatus("doctypes"))
  .addCase(fetchDocumentTypeThunk.fulfilled, (state, action) => {
    state.status.doctypes = { ...INITIALIZED };
    state.currentDocType = action.payload.data;
  });
};

const documentTypeVersioned = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(addDocumentTypeVersionThunk.pending, pendingStatus("doctypes"))
  .addCase(addDocumentTypeVersionThunk.rejected, rejectStatus("doctypes"))
  .addCase(addDocumentTypeVersionThunk.fulfilled, (state, action) => {
    state.status.doctypes = { ...INITIALIZED };
    //If there is no state.doctypes loaded, then we're in the engagement view, and doctypes are pulled from settings reducer
    if(state.doctypes){ 
      state.doctypes.items.unshift(action.payload.data);
    }
  });
};

const documentTypeCopied = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(copyDocumentTypeThunk.pending, pendingStatus("doctypes")) 
  .addCase(copyDocumentTypeThunk.rejected, rejectStatus("doctypes"))
  .addCase(copyDocumentTypeThunk.fulfilled, (state, action) => {
    state.status.doctypes = { ...INITIALIZED };
    //If there is no state.doctypes loaded, then we're in the engagement view, and doctypes are pulled from settings reducer
    if(state.doctypes){ 
      state.doctypes.items.unshift(action.payload.data);
    }
  });
};

const documentTypeCirculationChanged = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(toggleDocumentTypeCirculationThunk.pending, pendingStatus("doctypes"))
  .addCase(toggleDocumentTypeCirculationThunk.rejected, rejectStatus("doctypes"))
  .addCase(toggleDocumentTypeCirculationThunk.fulfilled, (state, action) => {
    state.status.doctypes = { ...INITIALIZED };
    const { id, data: docType } = action.payload;
    if(!state.doctypes) throw new Error("No doctypes found in state");
    const changes = addOrReplace(state.doctypes.items ?? [], (dt: any) => dt.id === id, docType);
    state.doctypes.items = changes;
    state.doctypes.loadTime = new Date().getTime();;
  });
};

const checklistsFetched = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(fetchChecklistsThunk.pending, pendingStatus("checklists"))
  .addCase(fetchChecklistsThunk.rejected, rejectStatus("checklists"))
  .addCase(fetchChecklistsThunk.fulfilled, (state, action) => {
    state.status.checklists = { ...INITIALIZED };
    state.checklists = {
      items: action.payload.data,
      pagination: action.payload.pagination,
      loadTime: new Date().getTime(),
      params: action.payload.filter,
    };
  });
};


//#endregion

//== Handles settings changes, which come from the setting thunks
const settingSaved = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder.addCase(saveSettingThunk.fulfilled, (state, action) => {
    const { id, key, data } = action.payload;
    if(key === "engagements"){
      state.engagements = {
        ...state.engagements,
        items: addOrReplace(state.engagements?.items ?? [], (e: any) => e.id === id, data),
        loadTime: state.engagements?.loadTime ?? new Date().getTime(),
      };

      state.allEngagements = {
        ...state.allEngagements,
        items: addOrReplace(state.allEngagements?.items ?? [], (e: any) => e.id === id, data),
        loadTime: state.allEngagements?.loadTime ?? new Date().getTime(),
      };      
    }
    else if (key === "doctypes"){
      state.doctypes = {
        ...state.doctypes,
        items: addOrReplace(state.doctypes?.items ?? [], (dt: any) => dt.id === id, data),
        loadTime: state.doctypes?.loadTime ?? new Date().getTime(),
      }
    }
  });
};


const reportQueueFetched = (builder: ActionReducerMapBuilder<AdminSlice>) => {
  builder
    .addCase(fetchReportQueueThunk.pending, pendingStatus("reportQueue"))
    .addCase(fetchReportQueueThunk.rejected, rejectStatus("reportQueue"))
    .addCase(fetchReportQueueThunk.fulfilled, (state, action) => {
      state.status.reportQueue = { ...INITIALIZED };
      state.reportQueue = { 
        items: action.payload.data,
        pagination: action.payload.pagination,
        loadTime: new Date().getTime(),
        params: action.payload.params,
      };
    });
};

//=== Slice
export const adminSlice = createSlice({
  name: "admin",
  initialState: INITIAL_STATE,
  reducers: {
    clearStatus: (state, action) => {
      const key = action.payload as keyof AdminSlice["status"];
      state.status[key] = { ...state.status[key], isWorking: false, error: null };
    }
  },
  extraReducers: (builder) => {
    usersFetched(builder);
    usersExported(builder);
    userAssignmentsFetched(builder);
    userSaved(builder);
    allEngagementsFetched(builder);
    engagementsFetched(builder);
    engagementAssignmentsFetched(builder);
    assignmentSaved(builder);
    assignmentDeleted(builder);
    documentTypesFetched(builder);
    documentTypeFetched(builder);
    documentTypeVersioned(builder);
    documentTypeCopied(builder);
    documentTypeCirculationChanged(builder);
    checklistsFetched(builder);
    settingSaved(builder);
    reportQueueFetched(builder);
  },
});

export default adminSlice.reducer;

//#region Helpers

const pendingStatus = (key: keyof AdminSlice["status"]) => (state: any, action: any) => {
  state.status[key].isWorking = true;
  state.status[key].error = null;
};
const rejectStatus = (key: keyof AdminSlice["status"]) => (state: any, action: any) => {
  state.status[key].isWorking = false;
  state.status[key].error = action.error;
};

//#endregion