import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Loading from 'features/common/loading';

const Initializing = () => {
  const { classes } = buildStyles();

  return (
    <Grid id="initializing-view" container alignItems="flex-start" justifyContent="center" className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          
          <Typography gutterBottom className={classes.text}>
            Initializing Checklist Tracker
          </Typography>
          
          <Loading isVisible={true} message="Please wait"/>

        </CardContent>
      </Card>
    </Grid>    
  );

}

const buildStyles = makeStyles()(theme => ({
  root    : {
      height  : "100%",
      paddingTop  : "15%",
  },
  card    : {
    width         : "100%",
    maxWidth      : "450px",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
    }
  },
  cardContent   : {
    textAlign     : "center",
  },
  text  : {
    fontSize    : "1.5rem",
    fontWeight  : 400,
    marginBottom : theme.spacing(2),
  }
}));

export default Initializing;