import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { createAppTheme } from 'config/theme-config';
import Apis from 'store/api/quanta-api';
import AppContainer from 'features/app/app-container';
import InitializeFailure from 'features/app/initialize-failure';

import './x-styles/animate.css';
import './x-styles/quanta.scss';
import './x-styles/mui-overrides.scss';
import TelemetryProvider from 'utils/telemetry/telemetry-provider';
import AuthProvider from 'utils/auth/auth-provider';

const theme   = createAppTheme();

function App({store, baseUrl}) {
  const [serverConfig, setServerConfig] = useState(null);
  const [initError, setInitError] = useState(null);

  //effect to initialize the Api and create the Msal Instance
  useEffect(() => {
    async function doEffect(){
      const config = await Apis.getServerConfig();
      if(!config.ok){
        setInitError(config);
        return;
      }

      setServerConfig(config);
    }
  
    doEffect();
  }, []);
 
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {initError && <InitializeFailure error={initError} message="Oops, something went wrong!" />}
        {!initError && serverConfig && 
          <AuthProvider appConfig={serverConfig}>
            <Provider store={store}>
              <BrowserRouter basename={baseUrl}>
                <TelemetryProvider instrumentationKey={serverConfig?.aiInstrumentationKey}>
                  <CssBaseline/>
                  <AppContainer serverConfig={serverConfig}/>
                </TelemetryProvider>
              </BrowserRouter>
            </Provider>
          </AuthProvider>
        }
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
