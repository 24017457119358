import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import { acquireMsalToken, getTokenFromUrl, initializeMsalAuth } from './auth-utils';
import Apis from 'store/api/quanta-api';
import InitializeFailure from 'features/app/initialize-failure';
import Initializing from 'features/app/initializing';

//Interface for the auth provider
export interface IAuthProviderProps {
  appConfig: any;
  children: ReactNode;
}

//Interface for the context
export interface IAuthContext {
  token: string | null;  
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);
  
const AuthProvider = ({appConfig, children}: IAuthProviderProps) => {
  const [token, setToken] = useState("");
  const [msalApp, setMsalApp] = useState(null);
  const [authError, setAuthError] = useState<null | any>(null);

  //Effect to initialize authentication
  useEffect(() => {
    async function doEffect(){
      const accToken = getTokenFromUrl();
      if(accToken){
        console.log("using auth token from url");
        setToken(accToken);
        
        //add a function to get the token from the api requests
        appConfig.tokenFactory = addTokenFromSearch(accToken);
        await Apis.initialize(appConfig);
      }
      else{
        const authContext = await initializeMsalAuth(appConfig);
        if(!authContext.isOk){
          setAuthError(authContext);
          return;
        }
        else{
          appConfig.tokenFactoryAsync = addTokenFromMsal;
          await Apis.initialize(appConfig);
          setMsalApp((authContext as any).authApp);
        }
      }
    }

    doEffect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue : IAuthContext = {
    token,
  };

  return (
      <>
      {authError && <InitializeFailure error={authError} message="Oops, something went wrong!" /> }
      {!authError && !msalApp && !token && <Initializing /> }
      {!authError && (msalApp || token) &&
        <AuthContext.Provider value={contextValue}>
          {token ? children : 
            <MsalProvider instance={msalApp!}>
              {children}
            </MsalProvider>        
          }
        </AuthContext.Provider>
      }
    </>
  );
}

export default AuthProvider;

export function useAuthContext(){
  const context = useContext(AuthContext);
  if(context === undefined){
    throw new Error("AuthContext can only be used with AuthProvider");
  }
  return context;
}

//---
// Hook to get whether or not the user is authenticated. This hook
// will also consider the case where a token is passed in via the url
// search string, as handled by the auth-provider context.
export function useIsAuthed(){
  const isMsalAuthed = useIsAuthenticated();
  const authContext = useAuthContext();

  if(authContext && authContext.token){
    return true;    //authenticated via token in the URL
  }
  else{
    return isMsalAuthed;
  }
}

//---
// A factory method that will add the bearer token that
// comes from the query string to a request for 
const addTokenFromSearch = (token: string | null) => (request: any) => {
  if(token){
    request.headers.Authorization = `Bearer ${token}`;
  }  
  else{
    throw new Error("Failed to acquire authentication token for API request. This may happen if you attempt to access CheckList Tracker with an invalid account. Please sign out and and back in to try again.");
  }
}

//---Request Handler that will get the token from the adal stuff and add it to the 
const addTokenFromMsal = (path: string) => async (request: any) => {    
  const token = await acquireMsalToken();

  if(token){
    request.headers.Authorization = `Bearer ${token.accessToken}`;
  }  
  else{
    throw new Error("Failed to acquire authentication token for API request. This may happen if you attempt to access CheckList Tracker with an invalid account. Please sign out and and back in to try again.");
  }
}