import React, { useEffect } from 'react';
import { Grid } from "@mui/material";
import Helmet from "react-helmet";
import ReviewSetEditor from "./reviewset-editor";
import { useSelector } from 'react-redux';
import { useAppParams, useCurrentReviewSet } from 'hooks';
import { IReviewSetReducerStatus } from '../reviewset-types';
import { IAppState } from 'app-types';
import { WaitContainer } from 'features/common';
import { useAppDispatch } from 'store';
import { fetchSetThunk } from 'store/thunks';

const ReviewSetEdit = () => {
  const dispatch        = useAppDispatch();
  const status          = useSelector<IAppState, IReviewSetReducerStatus>(state => state.set.itemStatus);
  const set             = useCurrentReviewSet();
  const { reviewsetId, engagementId } = useAppParams();

  const title           = `${set?.title || "Loading"} ReviewSet`;

  //Load the ReviewSet if necessary
  useEffect(() => {    
    if(reviewsetId && ((status.isDocChanged) || (!set && !status.isLoading) || (set && set.id !== reviewsetId))){
      dispatch(fetchSetThunk({id: reviewsetId, engagementId}));
    }
  }, [reviewsetId, engagementId, status.isDocChanged, status.isLoading, set, dispatch]);
  
  return (
    <Grid id="reviewset-edit" container spacing={2} justifyContent="center">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <WaitContainer isWaiting={Boolean(reviewsetId && !set)} message="Loading ReviewSet..." variant="circular">  
        <ReviewSetEditor set={set} />
      </WaitContainer>

    </Grid>
  );
}

export default ReviewSetEdit;
