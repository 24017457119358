import React from 'react';
import { useSelector } from 'react-redux';
import { getNowString } from 'utils/general-helpers';

function ReportHeader(){
  
  const user          = useSelector(state => state.app.currentUser);
  const displayTime   = getNowString(true);

  return(
    <div className="row report-header">
      <div className="col-sm-12">
        <span className="pull-right" id="report-generated-at">
            Report Generated {user ? `by ${user.displayName}` : ""} at {displayTime} (UTC)
        </span>
      </div>
    </div>
  );
}

export default ReportHeader;