import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { Loading } from 'features/common';
import { selectSet } from 'store/selectors/reviewset-selectors';
import DocumentsList from '../../documents/documents-list';

function ReviewSetDocuments(){
  const { classes }         = buildStyles();
  const set             = useSelector(selectSet);
  
  //No set yet, so we're still waiting for it to load
  if(!set){
    return (
      <Grid container justifyContent="center" className={classes.loading}>
        <Loading isVisible={true} message="Loading Documents..."/>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <DocumentsList />
    </Grid>
  );
}

export default ReviewSetDocuments;

const buildStyles   = makeStyles()(theme => ({
  root  : {
    padding       : `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
  loading   : {
    margin      : theme.spacing(3),
  }
}));