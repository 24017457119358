import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import { ErrorDisplay, WaitButton, WaitContainer } from 'features/common';
import { useInputHandler } from 'hooks/general-hooks';
import { adminDialogClasses } from 'features/common/dialog-classes';
import { selectStatusByKey } from 'store/selectors/admin-selectors';
import Validation from 'utils/validation-helper';
import { muiDialogReason } from 'utils/ui-helpers';
import { useAppDispatch } from 'store';
import { saveSettingThunk } from 'store/thunks';

const defaultClient   = {name   : ""};

const ClientDialog = ({clientId, onClose}) => {
  const { classes }   = buildStyles();
  const dispatch  = useAppDispatch();
  const isAdd     = Boolean(clientId === -1);
  const clients   = useSelector(state => state.settings.clients);
  const status    = useSelector(state => selectStatusByKey(state, "clients"));
  const [validation, setValidation]	= useState({canSave: false});
  const [original, setOriginal]   = useState(null);
  const [item, onInputChange, setItem]  = useInputHandler({}, null, onValidate);
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if(clientId){
      if(clientId === -1){
        setOriginal(defaultClient);
        setItem(defaultClient);
      }
      else{
        const cli   = _.find(clients, c => c.id === clientId);
        setOriginal(cli);
        setItem(cli);
      }
      setInitialized(true);
    }
    else{
      setItem({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  function onValidate(toValidate){
    //Validate that there is a name
    if(toValidate.name?.length > 3){
      const isChanged   = Validation.hasChanges(original, toValidate);
      setValidation({...validation, canSave: isChanged});
    }
    else{
      setValidation({...validation, canSave: false});
    }
  }

  async function onSave(){
    if(validation.canSave){
      const result  = await dispatch(saveSettingThunk({ id: clientId, model: item, settingType: "clients" }));
      if(result?.payload?.ok){
        //Close the dialog and provide the info
        if(isAdd){
          onClose(result.payload.data.id);
        }
        else onClose();
      }
    }
  }

  function doClose(event, reason){
    if(status.isWorking && reason && muiDialogReason.isEither(reason)) return;
    onClose();
  }

  if(!clientId) return null;

  return (
    <Dialog open={Boolean(clientId)} onClose={doClose} className={classes.dialog} maxWidth="md">
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between">
          <Typography className={classes.titleText}>
            <Tooltip title="Client">
              <BusinessIcon fontSize="small" className={classes.titleIcon}/>
            </Tooltip>
            {isAdd ? "New " : ""}Client
          </Typography>
          <CloseIcon fontSize="small" onClick={doClose} className={classes.closeIcon}/>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <WaitContainer isWaiting={!isInitialized} message="Initializing...">
            <Grid item container>
              <ErrorDisplay error={status.error} />
            </Grid>

            <Grid item container className={classes.formRow}>
              <TextField name="name" value={item.name} onChange={onInputChange} 
                disabled={status.isWorking} fullWidth label="Name" className={classes.field}
                error={validation?.name?.hasError} helperText={validation?.name?.message}/>
            </Grid>
          </WaitContainer>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button size="small" disabled={status.isWorking} onClick={doClose}>Cancel</Button>
        <WaitButton isWaiting={status.isWorking} disabled={!validation.canSave} onClick={onSave} color="secondary" size="small">Save</WaitButton>
      </DialogActions>
    </Dialog>
  );
}

export default ClientDialog;

const buildStyles   = makeStyles()(theme => ({
  ...adminDialogClasses(theme),
  dialog  : {
    "& .MuiDialog-paper"  : {
      minWidth    : 450,
    }
  },
}));