import { createTheme } from '@mui/material/styles';
import { lightBlue, teal, blue, red, yellow, orange, grey, brown } from '@mui/material/colors';

const fontList  = ['Muli', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'];

//Customizations for the default Material UI Theme go here
const themeCustomizations   = {
    //Add a custom node for the status colors
    statuses    : {
        confirmed   : {
          background  : blue[50],
          text        : blue[900],
          lightText   : blue[500],
          border      : blue[200],
          color       : blue,
        },
        rejected    : {
          background  : red[50],
          text        : red[900],
          lightText   : red[400],
          border      : red[200],
          color       : red,
        },
        na  : {
          background  : yellow[50],
          text        : yellow[900],
          lightText   : yellow[600],
          border      : yellow[600],
          color       : yellow,
        },
        approved    : {
          background  : blue[50],
          text        : blue[900],
          lightText   : blue[500],
          border      : blue[200],
          color       : blue,
        },
        preApproved : {
          background  : orange[50],
          text        : orange[900],
          lightText   : orange[500],
          border      : orange[200],
          color       : orange,
        },
        pending     : {
          background  : yellow[50],
          text        : yellow[900],
          lightText   : yellow[600],
          border      : yellow[600],
          color       : yellow,
        },
        training    : {
          background  : lightBlue[50],
          text        : lightBlue[900],
          lightText   : lightBlue[600],
          border      : lightBlue[600],
          color       : lightBlue,
        },
        archived    : {
          background  : brown[50],
          text        : brown[900],
          lightText   : brown[600],
          border      : brown[600],
          color       : brown,
        },
    },
    palette: {
        primary     : teal,
        secondary   : blue,
        // warning: {
        //   light: orange[300],
        //   main: orange[500],
        //   dark: orange[700],
        //   contrastText: "rgba(0, 0, 0, 0.87)",
        // },
        // error       : {
        //   light       : red[50],
        //   main        : red[300], //"#ed5565", //"#7f231c",
        //   dark        : red[500], //"#fccac7",
        //   contrastText: grey[700],
        // },
        // info       : {
        //   light       : blue[300],
        //   main        : blue[500], //"#ed5565", //"#7f231c",
        //   dark        : blue[800], //"#fccac7",
        //   contrastText: grey[700],
        // },
        // success       : {
        //   light       : green[50],
        //   main        : green[500], //"#ed5565", //"#7f231c",
        //   dark        : green[800], //"#fccac7",
        //   contrastText: grey[700],
        // },
        //used for default buttons
        default : {
          main: "rgba(0, 0, 0, 0.87)",
          contrastText: grey[700],
        },     
    },
    typography  : {
      //Override the default fonts and font weights
      // fontSize: "1rem",
      fontFamily                 : fontList.join(','),
      fontWeightLight            : 300,
      fontWeightRegular          : 400,
      fontWeightMedium           : 600,
      useNextVariants            : true,
      suppressDeprecationWarnings: true,
      // htmlFontSize               : 12,
    },
    components: {
      MuiTable: {
        styleOverrides : {
          root: {
            fontSize: "0.9rem",
            lineHeight: "18px",
          },
        },
      },
    //   MuiButton: {
    //     styleOverrides : {
    //       root: {
    //         fontSize: "1rem",
    //       },
    //     },
    //   },
    //   MuiTab: {
    //     styleOverrides : {
    //       root: {
    //         fontSize: "1rem",
    //       },
    //     },
    //   },
    // },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      }
    }
  },
};

//-----------------
// Creates and returns the custom theme for the Portal
export const createAppTheme = () => {
    return createTheme(themeCustomizations);
};

