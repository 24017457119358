import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CltTh from './clt-th';
import CltHidden from '../clt-hidden';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { ITableColumn } from './clt-table';

export interface ITableHeaderProps extends React.ComponentProps<"thead"> {
  cols: ITableColumn[];
  onSort: (key: string) => void;
  isWorking: boolean;
}

const CltThead = ({cols, onSort, isWorking, className, children, ...otherProps}: ITableHeaderProps) => {
  const { search }  = useLocation();
  const { classes } = buildStyles();
  const urp       = new URLSearchParams(search);
  const sort      = urp.get("sort");

  const sortCol = useMemo(() => {
    if(sort) return cols.find(c => c.sortKey && sort.indexOf(c.sortKey) >= 0);
    else return cols.find(c => !!c.defaultDir);
  }, [sort, cols]);

  const nextDirection = useMemo(() => {
    return sort?.includes("-asc") ? 
      {sortKey: `${sortCol?.sortKey}-desc`, label: `${sortCol?.label} Descending`} : 
      {sortKey: `${sortCol?.sortKey}-asc`, label: `${sortCol?.label} Ascending`};
  }, [sort, sortCol]);
  
  const handleSort = (e: any) => {
    const nextKey = e.target.value;
    if(nextKey.indexOf("-") > 0) 
      onSort(nextKey);   //other direction of same sort key already has the '-' in the value
    else{
      const nextDir = (nextKey === sortCol?.sortKey) ? "desc" : "asc";
      onSort(`${nextKey}-${nextDir}`);
    }
  }

  return (
    <>
      <CltHidden smDown>
        <TableHead>
          <TableRow>
            {cols.map(col => 
              col.noSort ? 
                <TableCell key={col.id} style={col.style}>{col.label}</TableCell> : 
                <CltTh key={col.id} sortKey={col.sortKey} onSort={onSort} query={search} defaultDir={col.defaultDir} isWorking={isWorking} style={col.style}>{col.label}</CltTh>
              )
            }
            {children}
          </TableRow>
        </TableHead>  
      </CltHidden>

      <CltHidden smUp>
        <Grid container spacing={2} className={classes.mobileHeader}>
          <Grid item xs>
            <Select size="small" value={sortCol?.sortKey ?? "id"} onChange={handleSort} fullWidth variant="outlined" disabled={isWorking} className={classes.sortSelect}>
              {sortCol && 
                <MenuItem value={nextDirection.sortKey}>
                  <span className={classes.label}>Sort By:&nbsp;</span><span>{nextDirection.label}</span>
                </MenuItem>
              }
              {cols.filter(c => !!c.sortKey).map(col => 
                <MenuItem key={col.sortKey} value={col.sortKey}>
                  <span className={classes.label}>{sortCol?.sortKey === col.sortKey ? "Sorted" : "Sort"} By:&nbsp;</span><span> {col.label}</span>
                </MenuItem>)}
            </Select>
          </Grid>
        </Grid>
      </CltHidden>
    </>
  )
};

const buildStyles = makeStyles()((theme: any) => ({
  mobileHeader: {
    marginBottom: theme.spacing(1),
  },
  sortSelect: {
    "& .MuiSelect-root": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    }
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[500]
  }
}));

export default CltThead;