import React, { useCallback, useEffect, useState } from "react";
import ReportIcon from '@mui/icons-material/Description';
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Button, Grid, Tooltip } from "@mui/material";
import { WarningAmber as WarningIcon, ErrorOutline, Summarize as SummaryIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useInterval } from "usehooks-ts";
import { useAppDispatch } from "store";
import { checkReportStatusThunk } from "store/thunks/report-thunks";
import { appRoutesLegacy } from "utils/route-helpers";
import { APPROVE_TYPES } from "utils/reviewset-helpers";
import { getAge } from "utils/date-helpers";
import { MenuButton } from "features/common";
import { ApprovalReportStatus, IReviewSet } from "../reviewset-types";
import { reportMenuOptions } from "./reviewset-utils";

export interface IReviewSetReportButtonProps {
  set?: IReviewSet;
}

interface IButtonProps {
  title: string;
  isDisabled: boolean;
  isWorking: boolean;
}
const defaultProps : IButtonProps = { title: "Loading...", isWorking: false, isDisabled: true };

const POLL_FREQUENCY = 15000;
const AGE_WARN = 300; //5 minutes
const AGE_DANGER = 600; //10 minutes

const ReviewSetReportButton = ({ set }: IReviewSetReportButtonProps) => {
  const dispatch = useAppDispatch();
  const reportPath = set ? appRoutesLegacy.reports.reviewSetItemReport(set.id) : "";
  const [pollInterval, setPollInterval] = useState<number | null>(null); //no polling to start
  const [buttonProps, setButtonProps] = useState<IButtonProps>(defaultProps);
  const [warnState, setWarnState] = useState<null | "warning" | "error">(null); //warn the user that the report is taking longer than expected?
  const [isPoisoned, setPoisoned] = useState(false);  //is the report queue row poisoned?
  
  //--
  // Calls the API for the status of the approval report
  const checkReportQueue = useCallback(async () => {
    const apiThunkResult = await dispatch(checkReportStatusThunk({ setId: set!.id }));
    const apiResult = (apiThunkResult as any).payload;

    if(apiResult.ok && apiResult.data){
      setPollInterval(apiResult.data.status === "pending" ? POLL_FREQUENCY : null);
      setPoisoned(apiResult.data.status === "poisoned");
      const props = buttonPropsForStatus(apiResult.data.status);
      setButtonProps(props);
      const age = getAge(apiResult.data.queuedDate);
      setWarnState(age >= AGE_DANGER ? "error" : (age >= AGE_WARN ? "warning" : null));
    }
    else{
      //TODO: deal with a failure calling the API...
      console.error("error while checking the status of the report.", apiResult.error, apiResult.errorInfo);
    }
  }, [set, dispatch]);

  //--
  // Will poll the api for changes to the status of the report queue row
  useInterval(() => {
    if(pollInterval === 0) setPollInterval(POLL_FREQUENCY);
    checkReportQueue();
  }, pollInterval);

  //--
  // Will kick off the first check of the report queue, and determine if it should poll.
  useEffect(() => {
    if(set && set.isApproved){
      //Need to check to see if we already have the report path in the status record
      const approval = set.reviewSetStatuses?.find(rss => rss.status === APPROVE_TYPES.approve);
      if(approval?.reportPath){
        const props = buttonPropsForStatus("available");
        setButtonProps(props);
      }
      else{
        //Don't have the report path, so need to poll for the report queue row.
        checkReportQueue();
      }
    }
    else if(set){
      setButtonProps({ title: "View Summary", isWorking: false, isDisabled: false });
    }
  }, [set, checkReportQueue]);

  return (
    <>
      {(set?.isApproved && !!warnState && !isPoisoned) && (     
        <>
          <Button size="small" variant="outlined" endIcon={<SummaryIcon />} component={Link} to={reportPath} target="_blank">
              Summary
          </Button>   
          <Tooltip title="Generating this report is taking longer than expected. You may want to contact your administrator and let them know.">
            <WarningIcon fontSize="large" color={warnState} />
          </Tooltip>
        </>
      )}
      {(set?.isApproved && !warnState) && 
        ( 
          <MenuButton 
            size="small" 
            variant="outlined" 
            endIcon={<ReportIcon />} 
            disabled={buttonProps.isDisabled} 
            options={reportMenuOptions(set, reportPath)}>
              {buttonProps.title}
          </MenuButton>
        )}
        {!set?.isApproved && (
          <Button 
            size="small" variant="outlined" endIcon={<FindInPageIcon />} 
            component={Link} 
            to={reportPath} 
            target="_blank" 
            disabled={buttonProps.isDisabled}>
              <Grid>
                {buttonProps.title}
              </Grid>
          </Button>
        )}
        {(isPoisoned) && (
          <>
            <Button size="small" variant="outlined" endIcon={<SummaryIcon />} component={Link} to={reportPath} target="_blank">
                Summary
            </Button>
            <Tooltip title="This report failed to render. You may want to contact your administrator and let them know.">
              <ErrorOutline fontSize="large" color="error" />
            </Tooltip>
          </>
        )}
    </>
  );
}

export default ReviewSetReportButton;


const buttonPropsForStatus = (status: ApprovalReportStatus) : IButtonProps => {
  switch(status){
    case "poisoned":
      return { title: "Unavailable", isWorking: false, isDisabled: true };
    case "available":
      return { title: "Report", isWorking: false, isDisabled: false };
    case "pending":
    default:
      return { title: "Processing", isWorking: true, isDisabled: true };
  }
};