import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AdminTaskPanel = ({task, isExpanded, onExpand}) => {
  const { classes }       = buildStyles();
  // const status        = useSelector(state => selectStatusByKey(state, task.id));
  
  return (
    <Accordion expanded={isExpanded} onChange={onExpand(task.id)}  TransitionProps={{ mountOnEnter: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
        <Typography className={classes.label}>{task.label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {task.component}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default AdminTaskPanel;

const buildStyles   = makeStyles()(theme => ({
  panel  : {
  },
  panelSummary  : {
    background      : theme.palette.grey[200],
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
    color           : theme.palette.primary.dark,
    "& .MuiAccordianSummary-content"   : {
      margin        : `${theme.spacing(1)} ${theme.spacing(0)}`, //`
    }
  },
  label   : {
    fontSize    : 18,
    fontWeight  : 300,
  },
}));