import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { useAppDispatch } from 'store';
import { fetchAllEngagementsThunk } from 'store/thunks';
import { selectAllEngagements } from 'store/selectors/admin-selectors';

///---
// Manages the list of all engagements for admins
export function useAllEngagements(orderArray, filter){
  const allEngagements  = useSelector(selectAllEngagements);
  const dispatch        = useAppDispatch();

  useEffect(() => {
    if(!allEngagements){
      dispatch(fetchAllEngagementsThunk({}));    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items   = useMemo(() => {
    if(allEngagements){
      let filtered  = filter ? allEngagements.items.filter(filter) : allEngagements.items;
      const ordered = orderBy(filtered, orderArray || ["id"]);
      return ordered;
    }
    else return null;
  }, [allEngagements, orderArray, filter]);

  return items;
}