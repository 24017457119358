import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

function TabPanel({value, index, children, ...otherProps}){
  if(value !== index) return null;

  return (
    <Grid container {...otherProps}>
      {children}
    </Grid>
  );
}

export default TabPanel;

TabPanel.propTypes = {
  value   : PropTypes.number.isRequired,
  index   : PropTypes.number.isRequired,
  children: PropTypes.element,
};