import _ from 'lodash';
import { createSelector } from 'reselect';
import { isBool, isText, isOption, isComment, isSection } from '../../utils/checklist-helpers';

const _getId      = (state, id) => id;
const _getWorking = state => state.builder.working;
const _getAllItems= state => state.builder.working?.checklistItems;
const _getStatus  = state => state.builder.status;

// const _getSections= state => state.builder.working?.sections;
// const _getItems   = state => state.builder.working?.items;

export const selectStatus = createSelector(
  [_getId, _getStatus],
  (statusKeys, status) => {
    const keys    = _.isArray(statusKeys) ? statusKeys : [statusKeys];
    const result  = _.reduce(keys, (overall, current, index) => {
      const currStatus  = status[current];
      return currStatus ? { 
        isWorking : (overall.isWorking || currStatus.isWorking), 
        errorKey  : overall.errorKey || (currStatus.error ? current : null),
        error     : overall.error || currStatus.error, 
      } : {};
    }, {isWorking: false, errorKey: null});

    return {
      ...result,
      items   : status,
    };
  }
);

export const selectChecklist = createSelector(
  [_getId, _getWorking],
  (id, checklist) => {
    if(!id || !checklist) return null;
    const idVal   = parseInt(id);
    if(idVal !== checklist.id) return null;

    const sections  = _.orderBy(_.filter(checklist.checklistItems, isSection), i => i.ordinal);
    const items     = _.orderBy(_.filter(checklist.checklistItems, i => !isSection(i)), i => i.ordinal);

    return {
      ...checklist,      
      sections  : sections,
      items     : items,
    };
  }
);

export const selectWorkingSection = createSelector(
  [_getId, _getAllItems],
  (sectionId, items) => {
    if(!sectionId || !items) return null;

    const section       = _.find(items, s => s.id === sectionId);
    const sectionItems  = _.filter(items, i => i.parentId === sectionId);
    const ordered       = _.orderBy(sectionItems, i => i.ordinal);
    if(!section) return null;

    return {
      id            : section.id,
      isNew         : section.id < 0 && !section.sectionName,
      sectionName   : section.sectionName || "",
      sectionDescription  : section.sectionDescription || "",
      items         : ordered,
      isExpanded    : !!section.isExpanded,
      isDeleted     : !!section.isDeleted,
    };
  }
);

export const selectWorkingItem = createSelector(
  [_getId, _getWorking],
  (itemId, working) => {
    if(!itemId || !working || !working.checklistItems) return null;

    const assertion   = _.find(working.checklistItems, i => i.id === itemId);
    if(!assertion) return null;
    
    const boolInp = _.find(assertion.checkListInputs, isBool);
    const bInput  = {
      ...boolInp, //assertion.checkListInputs[0],
      props     : working.props,      //pass through the protocol info to the bool input
    }

    return {
      id    : itemId,
      assertionText   : assertion.assertionText || "",
      isNew       : itemId < 0 && !assertion.assertionText,
      boolInput   : bInput,
      textInput   : _.find(assertion.checkListInputs, isText),
      optInput    : _.find(assertion.checkListInputs, isOption),
      cmtInput    : _.find(assertion.checkListInputs, isComment), 
      textIndex   : _.findIndex(assertion.checkListInputs, isText), 
      optIndex    : _.findIndex(assertion.checkListInputs, isOption),
      cmtIndex    : _.findIndex(assertion.checkListInputs, isComment), 
      isDeleted   : !!assertion.isDeleted,
    };    
  }
);