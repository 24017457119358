import { INormalizedError } from "app-types";
import { AnyAction } from 'redux';
import { createReducer } from "utils/reducer-helper";

export interface IStatusState {
  isWorking: boolean;
  isInitialized?: boolean;
  error: INormalizedError | null;
}

export interface IStatusAction extends AnyAction {
  key: string;
  value: IStatusAction;
}

export interface IStatusSlice {
  [key: string]: IStatusState;
}

export const DEFAULT_STATUS: IStatusState = {
  isWorking: false,
  error: null,
};

const INIT_STATE : IStatusSlice = {
  reportQueue: {...DEFAULT_STATUS },
};

function updateStatus(state : IStatusSlice, action: IStatusAction){
  return {
    ...state,
    [action.key]: {
      ...state[action.key],
      ...action.value,
    }
  };
}

export const STATUS_ACTIONS = {
  UPDATE: "STATUS_UPDATE",
};

const statusReducer = {
  [STATUS_ACTIONS.UPDATE]: updateStatus,
};

export default createReducer(INIT_STATE, statusReducer);