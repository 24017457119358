import React from 'react';
import { countBy } from 'lodash';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch } from 'store';
import { checkListFiltered } from 'store/actions';
import { selectCheckList, selectCheckListResponses } from 'store/selectors/checklist-selectors';
import { Button, Grid, Tooltip } from '@mui/material';
import teal from '@mui/material/colors/teal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HelpIcon from '@mui/icons-material/Help';

function CheckListFilter(){
  const { classes, cx }   = buildStyles();
  const dispatch  = useAppDispatch();
  const checkList = useSelector(selectCheckList);
  const responses = useSelector(state => selectCheckListResponses(state, checkList?.id));
  const filter    = useSelector(state => state.doc.checkListFilter);
  const props     = prepareFilters(responses, checkList);

  const toggleFilter  = (e) => {
    const id          = e.currentTarget.id;
    const val         = filter[id];
    const change      = {[id]: !val};
    dispatch(checkListFiltered(change));

  }

  //TODO: Move the titles to the buttons, not the styles.

  return (
    <Grid id="checklist-filters" container sx={{justifyContent: {xs: "center", sm: "flex-end"}}} alignItems="center">
      <Tooltip title="toggle sections">
        <span className={classes.filterSpan}>
          <Button id="open" className={cx(classes.collapseBtn, {[classes.notShown] : !filter.open})} onClick={toggleFilter} disabled={!filter.answered} variant="outlined" size="small" startIcon={<MenuOpenIcon/>}>{props.sectionCount}</Button>
        </span>
      </Tooltip>
      <Tooltip title="toggle answered items">
        <span className={classes.filterSpan}>
          <Button id="answered" className={cx(classes.answeredBtn, {[classes.notShown] : !filter.answered})} onClick={toggleFilter} disabled={!filter.open} variant="outlined" size="small" startIcon={<HelpIcon/>}>{props.answeredCount}</Button>
        </span>
      </Tooltip>
      <Tooltip title="toggle confirmed items">
        <span className={classes.filterSpan}>
          <Button id="approve" className={cx(classes.approvedBtn, {[classes.notShown] : !filter.approve})} onClick={toggleFilter} disabled={!filter.open || !filter.answered} variant="outlined" size="small" startIcon={<CheckCircleIcon/>}>{props.approveCount}</Button>
        </span>
      </Tooltip>
      <Tooltip title="toggle rejected items">
        <span className={classes.filterSpan}>
          <Button id="reject" className={cx(classes.rejectBtn, {[classes.notShown]: !filter.reject})} onClick={toggleFilter} disabled={!filter.open || !filter.answered} variant="outlined" size="small" startIcon={<CancelIcon/>}>{props.rejectCount}</Button>
        </span>
      </Tooltip>
      <Tooltip title="toggle n/a items">
        <span className={classes.filterSpan}>
          <Button id="na" className={cx(classes.naBtn, {[classes.notShown]: !filter.na})} onClick={toggleFilter} disabled={!filter.open || !filter.answered} variant="outlined" size="small" startIcon={<RemoveCircleIcon/>}>{props.naCount}</Button>
        </span>
      </Tooltip>      
    </Grid>
  );

}

export default CheckListFilter;

const buildStyles   = makeStyles()(theme => ({
  filterSpan    :{
    marginRight   : theme.spacing(0.5),
    "&:last-child" : {
      marginRight     : "0 !important",
    },
    marginBottom: theme.spacing(0.5),
  },
  approvedBtn   : {
    color         : theme.statuses.confirmed.text, //blue[500],
    background    : theme.statuses.confirmed.background, //blue[50],
    borderColor   : theme.statuses.confirmed.border, //blue[500],
    title         : "Show / Collapse all Approved"
  },
  rejectBtn   : {
    color         : theme.statuses.rejected.lightText, //red[300],
    background    : theme.statuses.rejected.background, //red[50],
    borderColor   : theme.statuses.rejected.border, //red[300],
    title         : "Show / Collapse all Rejected"
  },
  naBtn   : {
    color         : theme.statuses.na.text, //yellow[800],
    background    : theme.statuses.na.background, //yellow[50],
    borderColor   : theme.statuses.na.border, //yellow[800],
    title         : "Show / Collapse all Not Applicables"
  },
  collapseBtn : {
    color         : teal[500],
    background    : teal[50],
    borderColor   : teal[500],
    title         : "Show / Collapse all sections"
  },
  answeredBtn : {
    color         : teal[500],
    background    : teal[50],
    borderColor   : teal[700],
    title         : "Show / Collapse all answered items"
  },
  notShown  : {
    color         : theme.palette.grey[600],
    borderColor   : theme.palette.grey[600],
    background    : theme.palette.grey[200],
  }
}));

function prepareFilters(responses, checkList){
  // const responses     = doc.primaryReviewRecord.checkListResponses;

  let props  = {
    sectionCount    : countBy(checkList.checkListItems, i => i.discriminator === "Section").true || 0,
    approveCount    : countBy(responses, r => r.boolResponse === true).true || 0,
    rejectCount     : countBy(responses, r => r.boolResponse === false).true || 0,
    naCount         : countBy(responses, r => r.notApplicable === true).true || 0,    
  };

  props.answeredCount  = props.approveCount + props.rejectCount + props.naCount;
  return props;
}