import React, { useMemo, useState } from "react";
import { Button, TableCell, TableRow, TextField } from "@mui/material";
import { hasValue } from "utils/general-helpers-ts";
import { AdminReportDataTypes, IAdminReport, IParameterValidator } from "./admin-report-types";

///-----
/// Validator functions that map to the Data Types of the IReport.parameter property
const paramValidators: Record<AdminReportDataTypes, IParameterValidator> = {
  int: (v: string) => /^[0-9]*$/.test(v),
  string: (v: string) => true,
}

//---
// Interface for this component
export interface IAdminReportRowProps {
  item: IAdminReport;
  onRun: (item: IAdminReport, paramValue?: string) => void;
}

//---
// Component Definition
const AdminReportRow = ({item, onRun}: IAdminReportRowProps) => {

  const [param, setParam] = useState("");
  const [error, setError] = useState<string | null>(null);  
  const validator = useMemo(() =>   //capture a validator function based on the parameter type
    item?.parameter ? 
      paramValidators[item.parameter.dataType] : 
      paramValidators.string, 
    [item?.parameter]
  );

  //-- Handle changes to the parameter
  function onParamChange(e: React.ChangeEvent<HTMLInputElement>){
    const val = e.target.value;
    try{
      //validate the parameter
      const isOk = validator(val);
      if(isOk){
        setParam(val);
        setError(null);
      }
    }
    catch{}
  }

  //-- Run the Report (if the parameter is valid)
  function onRunReport(){
    if(item.parameter && !hasValue(param)){
      setError(`${item.parameter.label} is required`);
      return;
    }

    onRun(item, param);
  }

  return (
    <TableRow key={item.id} >
      <TableCell >
        {item.name}
      </TableCell>
      <TableCell >
        {item.description}
      </TableCell>
      <TableCell sx={{alignItems: "center"}}>
        {item.parameter && (
          <TextField 
            value={param} 
            onChange={onParamChange} 
            label={item.parameter.label} 
            sx={{maxWidth: 100}} size="small" margin="dense"
            error={!!error} helperText={error} />
        )}
      </TableCell>
      <TableCell >
        <Button size="small" variant="outlined" onClick={onRunReport}>Download</Button>
      </TableCell>
    </TableRow>
  );
};

export default AdminReportRow;