import React from 'react';
import { makeStyles } from 'tss-react/mui';
import LinearProgress from '@mui/material/LinearProgress';

interface ILoadingProps {
    isVisible?: boolean;  //default: false;
    message?: string;     //default: "Loading..."
    size?: string; //"sm" | "md" | "lg";  //default: "w-1/2"  //TODO: Not sure this is used
    className?: string;

}

const Loading = ({isVisible = false, message, size, className, ...otherProps}: ILoadingProps) => {
    if(!isVisible) return null;
    const { classes, cx } = buildStyles();
    const barClasses    = size ? size : "w-1/2";

    const loadingMessage    = message || "Loading...";
    
    return (
        <div className={cx(classes.root, className)} {...otherProps}>
          <span className={classes.message}>{loadingMessage}</span>
          <LinearProgress className={cx(classes.bar, barClasses)} />
        </div>
    );
}

//-------------------------
// Styles for the Loading Indicator
const buildStyles = makeStyles()((theme: any) => ({
    root    : {
        display         : "flex",
        flex            : "1 1 0%",
        flexDirection   : "column",
        alignItems      : "center",
        justifyContent  : "center"
    },
    message  : {
        fontSize        : "16px",
        marginBottom    : theme.spacing(2),
    },
    bar     : {
        minWidth    : "125px",
    },
    circle  : {

    }
}));

export default Loading;

// Loading.defaultProps = {
//   isVisible: false,
//   // variant: "linear",
// }