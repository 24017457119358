import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { IDocument } from "app-types";
import { ItemSlice } from "./slice-types";
import { approveCheckListThunk, fetchDocThunk, respondToItemThunk } from "store/thunks/document-thunks";
import { ReviewRecordDetailDto } from "features/documents/documents-api-types";
import { APPROVE_TYPES, prepareCheckList, reduceCheckListStatuses } from "utils/checklist-helpers";
import { CheckListDto, CheckListStatusDto, StatusRecord } from "types/model-types";
import { addOrReplace } from "./slice-helpers";

type DocumentFilter = {
  approve: boolean;
  reject: boolean;
  na: boolean;
  open: boolean;
  answered: boolean;
  collapsed: Record<string, any>;
}

interface DocumentSlice extends ItemSlice<IDocument> {
  itemError: any;
  isLoading: boolean;
  item: IDocument | null;
  itemTime: number | null;

  itemStatuses: CheckListStatusDto[];
  itemCheckLists: CheckListDto[];

  checkListIndex: number | null;
  checkListId: number | null;
  checkListFilter: DocumentFilter;

  responding: Record<string, StatusRecord>;
  approving: {
    checkListId: number | null;
    isWorking: boolean;
    error: any;
  }
}

const defaultFilter: DocumentFilter = {
  approve: true,
  reject: true,
  na: true,
  open: true,
  answered: true,
  collapsed: {},
};

const INITIAL_STATE: DocumentSlice = {
  itemError: null,
  isLoading: false,
  item: null,
  itemTime: null,
  
  //breaking these props out of the item to reduce changing the item, which triggers a re-render  
  itemStatuses: [],
  itemCheckLists: [],

  checkListIndex: null,
  checkListId: null,
  checkListFilter: defaultFilter,

  //items that are currently busy because they are being responded to
  responding: {},
  approving: {
    checkListId: null,
    isWorking: false,
    error: null,
  }
};

//=== Reducer for Fetch Document
const docFetched = (builder: ActionReducerMapBuilder<DocumentSlice>) => {
  builder.addCase(fetchDocThunk.pending, (state, action) => {
    state.isLoading = true;
    state.itemError = null;
    state.item = null;
    state.itemStatuses = [];
  })
  .addCase(fetchDocThunk.fulfilled, (state, action) => {
    if(!action?.payload?.data) return;  //happens if we already had the item loaded, so no change
    const { reviewRecords, checkLists, ...dto } = action.payload?.data;
    
    let primaryRr = reviewRecords?.find(rr => rr.primaryReviewRecordId === null);
    if(!primaryRr || !reviewRecords) return;
    
    const doc: IDocument = { ...dto };
    const statuses = reduceCheckListStatuses(primaryRr);
    const cls = checkLists.map(cl => prepareCheckList(cl));

    //remove the checklist from the 
    const { checkListResponses, checkListStatuses, ...pRR } = primaryRr;
    doc.reviewRecords = addOrReplace<any>(reviewRecords, (rr: ReviewRecordDetailDto) => rr.id === pRR.id, pRR);
    doc.primaryReviewRecord = pRR;

    const ts = new Date().getTime();
    state.isLoading = false;
    state.itemError = null;
    state.item = doc;
    state.itemTime = ts;
    state.itemStatuses = statuses;
    state.itemCheckLists = cls;
    state.checkListIndex = 0;
    state.checkListId = cls[0]?.id ?? null;
  })
  .addCase(fetchDocThunk.rejected, (state, action) => {
    state.isLoading = false;
    state.item = null;
    state.itemTime = null;
    state.itemError = action.error;
    console.error(action.error);
  })
};

//=== Reducer for Respond to Item
const itemResponded = (builder: ActionReducerMapBuilder<DocumentSlice>) => {
  builder.addCase(respondToItemThunk.pending, (state, action) => {
    const key = action.meta.arg.response.checkListItemId;
    state.responding[key] = { isWorking: true, isError: false, error: null };
  })
  .addCase(respondToItemThunk.fulfilled, (state, action) => {
    const key = action.meta.arg.response.checkListItemId;
    delete state.responding[key];
  })
  .addCase(respondToItemThunk.rejected, (state, action) => {
    const key = action.meta.arg.response.checkListItemId;
    state.responding[key] = { isWorking: false, isError: true, error: action.error  };
    console.error(action.error);
  });
};

const checklistApproved = (builder: ActionReducerMapBuilder<DocumentSlice>) => {
  builder.addCase(approveCheckListThunk.pending, (state, action) => {
    state.approving = { isWorking: true, error: null, checkListId: action.meta.arg.checkListId };
  })
  .addCase(approveCheckListThunk.fulfilled, (state, action) => {
    state.approving = { isWorking: false, error: null, checkListId: null };
    const { status, checkListId, reviewRecordId } = action.meta.arg.model;
    let statusRecords = state.itemStatuses;
    const isUnapprove = status === APPROVE_TYPES.unApprove;

    if(isUnapprove){
      //NOTE: at this time, we're not holding the disapprove status records in the state.
      //Remove any existing approval / preapproval records
      statusRecords = statusRecords.filter(s => s.reviewRecordId !== reviewRecordId && s.checkListId !== checkListId);
    }
    else {
      //add the status record
      let newStatus = action.payload.data as CheckListStatusDto;
      newStatus.statusDate.replace("Z", "");  //remove the timezone, we're using UTC
      statusRecords = addOrReplace(statusRecords, s => s.id === newStatus.id, newStatus);
    }

    state.approving = { isWorking: false, error: null, checkListId: null };
    state.itemStatuses = statusRecords
  })
  .addCase(approveCheckListThunk.rejected, (state, action) => {
    state.approving = { ...state.approving, isWorking: false, error: action.error };
    console.error(action.error);
  });
};

//=== Slice
export const documentSlice = createSlice({
  name: "document",
  initialState: INITIAL_STATE,
  reducers: {
    checkListSelected: (state, action) => {
      state.checkListIndex = action.payload.index;
      state.checkListId = state.itemCheckLists[action.payload.index]?.id ?? null;
      state.checkListFilter = defaultFilter;
    },
    checkListFiltered: (state, action) => {
      state.checkListFilter = { ...state.checkListFilter, ...action.payload };
    },
    clearApproving: (state) => {
      state.approving.checkListId = null;
      state.approving.isWorking = false;
      state.approving.error = null;
    },
   },
  extraReducers: (builder) => {
    docFetched(builder);
    itemResponded(builder);
    checklistApproved(builder);
  },
});

export default documentSlice.reducer;