import React from 'react';
import { makeStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';

const WaitOverlay = ({isWaiting, message, variant, color, classes, children}) => {
  const { classes: myClasses, cx }   = buildStyles();

  const progress      = variant === "circular" ? 
    <CircularWaiter color={color} classes={myClasses} className={classes} cx={cx} message={message}/> : 
    <LinearWaiter color={color} classes={myClasses} className={classes} cx={cx} message={message}/>;

  return (
    <div className={myClasses.waitContainer}>
      {children}
      <Backdrop className={myClasses.backdrop} open={isWaiting}>
        {progress}
      </Backdrop>
    </div>    
  );
}

export default WaitOverlay;

const buildStyles   = makeStyles()(theme => ({
  waitContainer  : {
    position  : "relative",
    width     : "100%",
  },
  backdrop  : {
    zIndex    : theme.zIndex.drawer + 1,
    position  : "absolute",
    color     : theme.palette.common.white,
    backgroundColor   : "#0001",
    alignItems  : "center",
  },
  waitRoot  : {
    textAlign     : "center",
    backgroundColor   : "#0008",
    borderRadius      : 5,
    padding           : `${theme.spacing(1)} ${theme.spacing(2)}`, //`,
    minWidth          : 200,
  },
  circular  : {
    display         : "flex",
    flexDirection   : "column",
    justifyContent  : "center",
    alignItems      : "center",
    padding         : theme.spacing(4),
  },
  circularProgress  : {
    marginBottom     : theme.spacing(1),
  },
  linear  : {

  },
  linearProgress  : {
    minWidth    : "100px",
    marginTop   : theme.spacing(1),
  },
  message   : {
  }
}));

const LinearWaiter = ({color, classes, cx, className, message}) => (
  <div className={cx("waiting waiting-linear", classes.linear, classes.waitRoot, className)}>
    <em className={classes.message}>{message}</em>
    <LinearProgress color={color} className={classes.linearProgress}/> 
  </div>
);

const CircularWaiter = ({color, classes, cx, className, message}) => (
  <div className={cx("waiting waiting-circular", classes.circular, classes.waitRoot, className)}>
    <CircularProgress color={color} className={classes.circularProgress}/>  
    <em className={classes.message}>{message}</em>
  </div>
);