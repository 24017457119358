import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { useAppDispatch } from 'store';
import { insertBuilderItem, moveBuilderItem, updateBuilderItem } from 'store/actions';
import { selectWorkingItem } from 'store/selectors/builder-selectors';
import { addOrReplaceItem, replaceAt, removeItem } from 'store/store-helpers';
import { keyDownCommand } from 'utils/general-helpers';
import { isBool } from 'utils/checklist-helpers';
import Validation from 'utils/validation-helper';
import { useDialogsWithoutUrl, useInputHandler } from 'hooks';
import BoolInputEditor from './input-editor-bool';
import AssertionMenu from './assertion-menu';
import AddTextDialog from './add-text-dialog';
import AddOptionsDialog from './add-options-dialog';
import { TextInputIcon, OptionInputIcon } from './input-icons';

const ChecklistEditorAssertion = ({rowIndex, assertion, section, checklist, status, isEditable}) => {
  const { classes, cx }     = buildStyles();
  const dispatch    = useAppDispatch();
  const [dialogs, openDialog, closeDialog, onOpenDialog, onCloseDialog]  = useDialogsWithoutUrl();
  const working                         = useSelector(state => selectWorkingItem(state, assertion.id));
  const original                        = useMemo(() => { return working; }, [working]);
  const [values, onChange]              = useInputHandler(original, null, onValidate);
  const [validation, setValidation]	    = useState({canSave: false});
  const altClass                        = useMemo(() => (rowIndex % 2 === 0) ? null : classes.altRow, [classes.altRow, rowIndex]);
  const delClass                        = useMemo(() => working.isDeleted ? classes.deletedRow : null, [classes.deletedRow, working.isDeleted]);

  useEffect(() => {
    //TODO: need to look at why we have this - it saves every checklist item when the checklist is first opened, before any changes...  not good.
    dispatch(updateBuilderItem({ id: assertion.id, changes: values }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.canSave]);
  
  function onValidate(toValidate){
    //Validate that there is a name
    const overall   = Validation.validatePropertyCondition(validation, "assertionText", toValidate, val => val.length >= 3, "Text is required (and must be at least 3 characters).");
    const hasErrors = _.some(overall, v => v.hasError);
    setValidation({
      ...overall,
      canSave   : !hasErrors,
    });
  }

  useEffect(() => {
    onValidate(values);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(!working) return null;

  function onCommandExecuted(command){
    switch(command){
      case "openTextInput"    :   {
        if(isEditable || working.textInput.id > 0) openDialog("textInput");   //only open if it's editable, or there's an existing input
        break;
      }
      case "openOptionsInput" :   {
        if(isEditable || working.optInput.id > 0) openDialog("optInput");   //only open if it's editable, or there's an existing input
        break;
      }
      case "deleteItem"       : toggleDelete(); break;
      case "moveUp"           : dispatch(moveBuilderItem({ id: assertion.id, offset: -1 })); break;
      case "moveDown"         : dispatch(moveBuilderItem({ id: assertion.id, offset: 1 }));; break;
      case "insertAssertionAfter"   : dispatch(insertBuilderItem({ parentId: assertion.parentId, refId: assertion.id, offset: 1 })); break;
      case "insertAssertionBefore" : dispatch(insertBuilderItem({ parentId: assertion.parentId, refId: assertion.id, offset: -1 })); break;
      default: console.log("unknown command: ", command); break;
    }
  }

  const onSaveInput = (inputKey) => async (value) => {
    const item  = working[inputKey];
    const changes   = {
      ...assertion,
      checkListInputs   : addOrReplaceItem(assertion.checkListInputs, item, value),
    };

    dispatch(updateBuilderItem({ id: working.id, changes }));
    closeDialog(inputKey);
  }

  const onDeleteInput = (inputKey) => async (e) => {
    const item  = working[inputKey];
    const changes   = {
      ...assertion,
      checkListInputs   : removeItem(assertion.checkListInputs, item),
    };

    dispatch(updateBuilderItem({ id: working.id, changes }));
    closeDialog(inputKey);
  }

  const onChangeInput = (input) => (e) => {
    let changes   = {};

    if(isBool(input)){
      const inputChanges  = {...working.boolInput, allowNA: e.target.checked };
      changes       = {
        ...working,
        checkListInputs   : replaceAt(assertion.checkListInputs, 0, inputChanges),
      };
    }

    dispatch(updateBuilderItem({ id: working.id, changes }));
  }

  function onTextBlur(e){
    if(!shallowEqual(values, original)){
      dispatch(updateBuilderItem({ id: assertion.id, changes: values }));
    }
  }

  function toggleDelete(){
    if(!isEditable) return;
    const changes   = {isDeleted: !working.isDeleted};
    dispatch(updateBuilderItem({ id: working.id, changes }));
  }

  function onKeyDown(e){
    const command   = keyDownCommand(e);
    if(!command) return;
    onCommandExecuted(command);
  }

  return (
    <Grid container className={cx(classes.assertionRoot, altClass, delClass)}>
        <Grid container item alignItems="center">
          
          <DeletedAssertion item={working} values={values} onUndelete={toggleDelete}/>
          
          {(!working.isDeleted) &&
            <>
              <Grid item className={classes.growCol}>
                <TextField id="assertionText" autoFocus={working.isNew} 
                  value={values.assertionText} onChange={onChange} onBlur={onTextBlur} onKeyDown={onKeyDown} 
                  disabled={!isEditable || status.isWorking || status.isDeleting} multiline minRows={2} maxRows={6} fullWidth 
                  className={classes.textField} variant="standard" size="small" placeholder="Assertion Text" {...validation?.assertionText?.fieldProps} autoComplete="off"/>
              </Grid>            
              <Grid item container className={classes.inputCol} alignItems="flex-end">
                  <BoolInputEditor input={working.boolInput} onChange={onChangeInput(working.boolInput)} disabled={!isEditable || status.isWorking}/>
                  <TextInputIcon input={working.textInput} onClick={onOpenDialog("textInput")} disabled={status.isWorking} isEditable={isEditable}/>
                  <OptionInputIcon input={working.optInput}  onClick={onOpenDialog("optInput")} disabled={status.isWorking} isEditable={isEditable}/>
                  <Grid item>
                    {isEditable && <AssertionMenu onAction={onCommandExecuted} assertion={working} style={{alignSelf: "flex-end"}}  disabled={status.isWorking}/>}
                  </Grid>
              </Grid>
            </>
          }
      </Grid>
      <AddTextDialog isOpen={dialogs.textInput} input={working.textInput} onClose={onCloseDialog("textInput")} onSave={onSaveInput("textInput")} onDelete={onDeleteInput("textInput")} isEditable={isEditable}/>
      {dialogs.optInput && <AddOptionsDialog isOpen={true} input={working.optInput} onClose={onCloseDialog("optInput")} onSave={onSaveInput("optInput")} onDelete={onDeleteInput("optInput")} isEditable={isEditable}/>}
    </Grid>
  );
}

export default ChecklistEditorAssertion;

const buildStyles   = makeStyles()(theme => ({
  assertionRoot  : {
    width         : "100%",
    padding       : `${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(3)} ${theme.spacing(1)}`, //`
    // marginBottom  : theme.spacing(2),
  },
  altRow      : {
    background    : theme.palette.grey[100],
  },
  deletingRow      : {
    background  : theme.statuses.rejected.border,
    padding     : `${theme.spacing(2)} ${theme.spacing(1)}`, //`,
  },
  deletedRow      : {
    background  : theme.statuses.rejected.background,
    padding     : `${theme.spacing(0.5)} ${theme.spacing(1)}`, //`,
    marginBottom  : theme.spacing(1),
    marginTop     : theme.spacing(1),
  },
  growCol   : {
    flexGrow  : 1,
    paddingLeft   : theme.spacing(1),
  },
  inputCol  : {
    width     : 230,
    paddingLeft   : theme.spacing(1),
    flexWrap      : "nowrap",
  },
  textField   : {
    "& .MuiInputBase-root"  : {
      fontSize      : "1.1rem",
    },
  },
  info  : {
    fontSize    : 14,
  },
  confirmText   : {
    fontSize    : 14,
    fontWeight  : 700,
    marginRight : theme.spacing(1),
  },
  deleteButton  : {
    // marginRight : theme.spacing(1),
  }
}));

function DeletedAssertion({item, values, onUndelete}){
  const { classes }   = buildStyles();
  if(!item.isDeleted) return null;

  return (
    <>
      <Grid item xs={1} container alignItems="center">
        <Tooltip title="This assertion has been deleted.  Click Undo to un-delete this assertion.">
          <DeleteIcon fontSize="small" className={classes.itemIcon}/>
        </Tooltip>
      </Grid>
      <Grid item xs={9}>
        <Typography className={classes.info}>{values.assertionText}</Typography>
      </Grid>
      <Grid item xs={2} container alignItems="center" justifyContent="flex-end">
          <Button size="small" onClick={onUndelete} className={classes.deleteButton}>Undo Delete</Button>
      </Grid>
    </>
  );
}