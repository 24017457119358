import React, { useEffect } from 'react';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { ErrorCard, WaitContainer } from 'features/common';
import { ReportHeader, ReviewSetReportTitleRow, ReviewSetReportHeader, DocumentReportPart, ReviewSetReportFooter } from './parts';
import { selectReportSet, selectReportEngagement, selectSetDocuments, selectReportSite } from 'store/selectors/report-selectors';
import { useQueryString } from 'hooks';

import './reports.scss';
import { useAppDispatch } from 'store';
import { fetchSetForReportThunk } from 'store/thunks/report-thunks';

function ReviewSetReport(){
  const { classes }       = buildStyles();
  const { reviewsetId } = useQueryString();
  const set = useSelector(selectReportSet);
  const engagement = useSelector(selectReportEngagement);
  const site = useSelector(selectReportSite);
  const documents     = useSelector(selectSetDocuments);
  const status        = useSelector(state => state.reports.status);
  const dispatch      = useAppDispatch();

  const errorString = status.error ? `${status.error.status ?? "500"}: ${status.error.title ?? status.error.problem ?? "Unknown Error"}` : null;

  useEffect(() => {
    if(reviewsetId && (!set || set.id !== reviewsetId) && status.isLoading === false){
      async function fetchReport(){
        // await dispatch(loadSetForReport(reviewsetId));
        await dispatch(fetchSetForReportThunk({ id: reviewsetId }));        
      }
      fetchReport();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewsetId, set]);

  return (
    <Grid id="reporting-page" container justifyContent="center" className={classes.root}>
      <Helmet>
        <title>{`ReviewSet ${reviewsetId} Report`}</title> {/*` */}
      </Helmet>

      {status.error && (
        <ErrorCard 
          id="reviewset-report-error"
          header={errorString} 
          message="The error above prevented the report from rendering."
          action="You can close this tab and try again. If this problem persists, please contact your system administrator." 
          sx={{maxWidth: "40%"}}
          errorObj={status.error}>
        </ErrorCard>
      )}

      {!status.error && 
        <WaitContainer isWaiting={status.isLoading || !set} message="Gathering ReviewSet data (this may take a minute)..." className={classes.waiting}>
          <div className="report-page">
            <ReportHeader />
            <ReviewSetReportTitleRow set={set} isWorking={status.isLoading} />
            <ReviewSetReportHeader set={set} engagement={engagement} site={site}/>
            {_.map(documents, doc => <DocumentReportPart key={doc.id} set={set} doc={doc} engagement={engagement}/>)}
            <ReviewSetReportFooter set={set} />
          </div>
        </WaitContainer>
      }
    </Grid>
  );
}

export default ReviewSetReport;

const buildStyles   = makeStyles()(theme => ({
  root: {
    width: "calc(100% - 60px)",
  },
  waiting   : {
    marginTop     : "15%",
  }
}));
