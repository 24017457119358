import { IListProps } from "features/common/list";
import { isString } from "lodash";
import { useMemo } from "react";
import { useLocation, useParams as useParams5 } from "react-router-dom";
import { hasValue, objectMap } from "utils/general-helpers-ts";
import { useQueryString } from "./general-hooks";

const safeParseInt = (v: string) => isString(v) ? parseInt(v) : v;

const parameterMapFuncs : Record<string, any> = {
  reviewsetId: safeParseInt,
  engagementId: safeParseInt,
  documentId: safeParseInt,
  checklistId: safeParseInt,
}


export interface IPathProps {
  reviewsetId?: number;
  engagementId?: number;
  documentId?: number;
  checklistId?: number;  
}

// useApp for with the compatibility useParams functionality
export function useAppParams(): IPathProps {
  const params = useParams5();
  const parsedParams : Record<string, string | number | null> = objectMap(params, (v, k, i) => {
    if(!hasValue(v)) return null; //return null if there's no value
    const mapFunc = parameterMapFuncs[k];
    const mappedValue = mapFunc ? mapFunc(v) : v;
    return mappedValue;
  });
  return parsedParams;
}

export interface IQueryProps extends IPathProps, IListProps {
  queryString: string;
}

export function useGridQueryString(){
  const queryString = useLocation().search;
  const parsed = useMemo<IListProps>(() => {
    const urlp  = new URLSearchParams(queryString);
    const sort  = urlp.get("sort");
    const filter= urlp.get("filter");
    const search  = urlp.get("search");
  
    let result : IListProps = { hasSort: false, hasFilter: false };
  
    if(sort) {
      result.sort = sort;
      result.hasSort = true;
    }
    if(filter) {
      result.filter = filter;
      result.hasFilter = true;
    }
    if(search) result.search = search;
    
    return result;
  }, [queryString]);
  
  return parsed;
}

export function useQuery() : IQueryProps {
  // const { search: searchString }    = useLocation();
  const params = useAppParams();
  const { sort, filter, search, queryString } = useQueryString();
  const result = {
    ...params,
    sort,
    filter,
    search,
    hasSort         : Boolean(sort),
    hasFilter       : Boolean(filter) || Boolean(search),
    queryString,
  };

  return result;
}