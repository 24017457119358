import appReducer from '../slices/app-slice';
import setsReducer from '../slices/reviewsets-slice';
import setReducer from "../slices/reviewset-slice";
import statusReducer from "../api-middleware/status-reducer";
import documentsReducer from 'store/slices/documents-slice';
import documentReducer from "store/slices/document-slice";
import checkListItemReducer from "store/slices/checklist-slice";
import reportsReducer from 'store/slices/reports-slice';
import settingsReducer from "store/slices/settings-slice";
import metricsReducer from 'store/slices/metrics-slice';
import adminReducer from 'store/slices/admin-slice';
import builderReducer from 'store/slices/builder-slice';
 
const rootReducer = {
  app: appReducer,
  settings: settingsReducer,
  sets: setsReducer,
  set: setReducer,
  docs: documentsReducer,
  doc: documentReducer,
  checklist: checkListItemReducer,
  reports: reportsReducer,
  metrics: metricsReducer,
  admin: adminReducer,
  builder: builderReducer,
  status: statusReducer,
};

export default rootReducer;