import React, { useMemo } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import grey from '@mui/material/colors/grey';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { formatDate } from 'utils/general-helpers';
import { useCurrentDocument } from 'hooks';

function RadioBoxesOption({item, input, choices, response, value, handleChange, isReadOnly}){
  const { classes, cx }   = buildStyles();
  const doc       = useCurrentDocument();
  const hasRsp    = Boolean(response);
  
  const responseIcon   = useMemo(() => {
    if(hasRsp && input.optionType === 0){
      const rspDate = hasRsp ? formatDate(response.responseDate) : "";
      const tip     = `by ${response?.userName} on ${rspDate}`; //`
      return (
        <Tooltip title={tip}>
          <InfoOutlinedIcon fontSize="small" className={classes.infoIcon} style={{color: grey[600]}}/>
        </Tooltip>
      );
    }
    else 
      return null;
  
  }, [classes.infoIcon, hasRsp, input.optionType, response?.responseDate, response?.userName]);

  const isDisabled  = item.isWorking || item.isFinished || !doc.isPrimary || isReadOnly;
  
  return (
    <FormControl id={"form-" + input.id} variant="outlined" className={classes.inputFormControl} fullWidth>
      <Grid container>
        <Grid item container>
          <Grid item xs={8}>
            <FormLabel id={"label-" + input.id} className={cx(classes.formLabel, item.className)}>{input.caption}</FormLabel>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
            {(hasRsp && !isDisabled) && <Button size="small" variant="outlined" className={classes.miniButton} title="Clear the selected value" onClick={() => handleChange("")}>clear</Button>}
          </Grid>
          <Grid item xs={1} container justifyContent="center" alignItems="center">
            {responseIcon}
          </Grid>
        </Grid>      
        <Grid item>
          <RadioGroup  id={"input-" + input.id} value={value} margin="dense" className={classes.radioGroup} onChange={handleChange}>
            {_.map(choices, c => <FormControlLabel key={c.value} value={c.value} className={classes.radioItem} disabled={isDisabled} control={<Radio size="small" />} label={c.label} />)}
          </RadioGroup> 
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default RadioBoxesOption;

const buildStyles   = makeStyles()(theme => ({
  formLabel   : {
    fontSize      : 14,
    marginBottom  : theme.spacing(1),
    borderBottom  : "none",
  },
  radioGroup  : {
    fontSize    : 14,
    "& .Mui-disabled" : {
      color     : grey[700],
    }
  },
  radioItem   : {
    "& .MuiFormControlLabel-label" : {
      fontSize    : 14,
    },
    "& .MuiButtonBase-root"   : {
      padding   : `0 ${theme.spacing(1)}`, //`
    }
  },
  miniButton  : {
    fontSize    : 15,
    padding     : "1px 6px",
    minWidth    : "unset",
    "& .MuiButton-label"  : {
      fontSize  : 12,
    }
  }
}));