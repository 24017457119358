import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { Button, Chip, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography,  } from '@mui/material';
import DocTypeIcon from '@mui/icons-material/InsertDriveFile';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import ExitIcon from '@mui/icons-material/ExitToApp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArchiveIcon from '@mui/icons-material/Archive';
import HistoryIcon from '@mui/icons-material/History';
import { useAppDispatch } from 'store';
import { clearAdminStatus } from 'store/actions';
import { fetchDocumentTypeThunk, saveSettingThunk, toggleDocumentTypeCirculationThunk } from 'store/thunks';
import { IfBlock, ErrorDisplay, WaitButton, ItemList, WaitContainer } from 'features/common';
import { useInputHandler } from 'hooks/general-hooks';
import { adminDialogClasses, listItemClasses } from 'features/common/dialog-classes';
import { selectStatusByKey } from 'store/selectors/admin-selectors';
import Validation from 'utils/validation-helper';
import { formatDate, isTrue } from 'utils/general-helpers';
import { muiDialogReason } from 'utils/ui-helpers';
import ConfirmDialog from '../../common/confirm-dialog';
import DocTypeCopyForm from '../doctypes/doctype-copy-form';
import DocTypeVersionForm from '../doctypes/doctype-version-form';

const defaultDocType   = {name: "", protocolId: -1, notes: "", propertyBag: {isDraft: true}};
const removeMessages = {
  'true': 'This action will remove this Document Type from circulation so that it is no longer available in the drop-down menu when users attempt to add a new Document to a ReviewSet in the selected protocol.  Are you sure you wish to continue?', //This action cannot be undone. 
  'false': 'This action will return this Document Type into circulation so that it is available in the drop-down menu when users attempt to add a new Document to a ReviewSet in the selected protocol.  Are you sure you wish to continue?',
};

const DocTypeDialog = ({docTypeId, onClose, props}) => {
  const { classes } = buildStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currId, setCurrId] = useState(docTypeId);
  const protocols = useSelector(state => state.settings.protocols);
  const status = useSelector(state => selectStatusByKey(state, "doctypes"));
  const [validation, setValidation]	      = useState({canSave: false, hasChanges: false});
  const [original, setOriginal]           = useState(null);
  const [isCopying, setIsCopying]         = useState(false);
  const [isVersioning, setIsVersioning]   = useState(false);
  const [docType, setDocType]             = useState(null);
  const [isConfirming, setConfirming]     = useState("");
  const [isRemoving, setRemoving] = useState(false);
  const [isPublishing, setPublishing] = useState(false);
  const [item, onInputChange, setItem, onSelectChange]    = useInputHandler({}, null, onValidate);
  const isProtocolLocked    = Boolean(currId >= 0 || props?.protocolId >= 0);
  const isAdd               = Boolean(currId === -1);
  const isReplaced          = Boolean(original?.propertyBag?.nextVersion);
  const isOutOfCirculation  = useMemo(() => isTrue(item?.propertyBag?.isOutOfCirculation), [item]);
  const isDraft = useMemo(() => isTrue(item?.propertyBag?.isDraft), [item]);
  const isLocked = docType && (docType.documentsCount > 0 || isReplaced || isOutOfCirculation);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    //Reset things if the DocTypeId changes
    setIsInitialized(false);
    setIsVersioning(false);
    setIsCopying(false);
    setCurrId(docTypeId);
  }, [docTypeId]);

  useEffect(() => {
    async function loadCurrent(){
      const result    = await dispatch(fetchDocumentTypeThunk({ id: currId }));
      const docType   = result.payload.data;
      const preped  = docType.notes === null ? {...docType, notes: ""} : docType;
      setDocType(preped);
      setOriginal(preped);
      setItem(preped);
      setIsInitialized(true);
    }
    
    if(currId){
      if(currId === -1){
        const myDefault   = props?.protocolId ? {...defaultDocType, protocolId: props.protocolId } : defaultDocType;
        setDocType(null);
        setOriginal(myDefault);
        setItem(myDefault);
        setIsInitialized(true);
      }
      else{
        //Get the full doctype from the server
        setItem(defaultDocType)
        loadCurrent();
      }
    }
    else{
      setItem({});
      console.log("closing down dialog");
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currId]);

  if(!currId) return null;

  function onValidate(toValidate){
    //Validate that there is a name
    if(toValidate.name?.length > 3){
      const isChanged   = Validation.hasChanges(original, toValidate);
      setValidation({...validation, canSave: isChanged, hasChanges: isChanged});
    }
    else{
      setValidation({...validation, canSave: false, hasChanges : true});
    }
  }

  const prepDoc = (isDraft = true, isOutOfCirculation = null) => {
    const pBag = {...item.propertyBag };
    if(isDraft !== null) pBag.isDraft = isDraft;
    if(isOutOfCirculation !== null) pBag.isOutOfCirculation = isOutOfCirculation;

    const preped = {
      ...item,
      propertyBag: pBag,
    };
    
    setItem(preped);
    onValidate(preped);
    return preped;
  }

  const onSaveAs = (draft = true, isConfirmed = false) => async() => {
    if(draft === false && !isConfirmed){
      setPublishing(true);
    }
    else{
      setPublishing(false);
      const preped = prepDoc(draft);
      await saveItem(preped);
    }
  }

  const onRemove = (remove = true, isConfirmed = false) => async() => {
    if(!isConfirmed){
      setRemoving(remove.toString().toLowerCase());
    }
    else{
      setRemoving(null);
      // const preped = prepDoc(null, Boolean(remove));
      // await saveItem(preped);

      const result  = await dispatch(toggleDocumentTypeCirculationThunk({ id: currId, inCirculation: !remove }));
      if(result?.payload?.ok){
        //Close the dialog and provide the info
        const newItem = result.payload.data;
        setItem(newItem);
        setOriginal(newItem);  //need to update the original so we know when there are changes. 
        setValidation({canSave: false, hasChanges: false});
      }
    }    
  }

  const onCloseClick = () => {
    if(validation.hasChanges){
      setConfirming("close");
      return;
    }
    
    doClose();
  }

  const onSave = (andClose) => async () => {
    await doSave(andClose);
  }

  const doSave = async (andClose) => {
    if(validation.canSave){
      const result = await saveItem(item);
      if(result.payload?.ok && andClose) doClose();
    }
  }

  const saveItem = async (toSave) => {
    const myItem  = toSave.notes === "" ? {...toSave, notes: null} : toSave;   //deal with any nulls, which aren't valid binding targets, but save to db with null
    // const result  = await dispatch(saveSetting(currId, myItem, "doctypes"));
    const result = await dispatch(saveSettingThunk({id: currId, model: myItem, settingType: "doctypes" }));
    if(result.payload?.ok){
      //Close the dialog and provide the info
      const payload = result.payload;
      if(isAdd){
        setCurrId(payload.response.id);        
      }
      const newItem = payload.response;
      setOriginal(newItem);  //need to update the original so we know when there are changes. 
      setValidation({canSave: false, hasChanges: false});
    }
    
    return result;
  }

  async function doClose(event, reason){
    if(status.isWorking && muiDialogReason.isEither(reason)) return;

    dispatch(clearAdminStatus("doctypes"));
    setOriginal(null);
    setDocType(null);
    setValidation({canSave: false, hasChanges: false});   //clear out the validation
    onClose();
  }

  const onNav = (shouldContinue) => () => {
    const url = isConfirming;
    setConfirming(false);
    if(shouldContinue){
      if(url === "close") doClose();
      else navigate(url);
    }
  }

  const onConditionalNav = (url) => {
    if(validation.canSave){
      //means the doc type has changed, need to ask if they want to lose changes
      setConfirming(url);
    }
    else{
      navigate(url);
    }
  }

  const config  = listConfig({canEdit: () => !isLocked, navTo: onConditionalNav });
  const addUrl  = `/admin/checklists/-1/edit?doctype=${currId}`;

  return (
    <Dialog open={Boolean(currId)} onClose={doClose} className={classes.dialog} maxWidth="md">
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between">
          <Grid item xs={8} container>
            <Typography className={classes.titleText}>
              <Tooltip title="Document Type">
                <DocTypeIcon fontSize="small" className={classes.titleIcon}/>
              </Tooltip>
              {isAdd ? "New " : ""}Document Type
            </Typography>
            {isLocked && <Chip className={classes.titleChip} icon={<LockIcon fontSize="small"/>} variant="outlined" label="in use" title="This Document Type cannot be changed because it is in use"/> }
            {isReplaced && <Chip className={classes.titleChip} icon={<HistoryIcon fontSize="small"/>} variant="outlined" label="Historic" title="This Document Type has been replaced by a newer version"/> }
            {isOutOfCirculation && <Chip className={classes.titleChip} icon={<ArchiveIcon fontSize="small"/>} variant="outlined" label="Out of Circulation" title="This Document Type has been removed from circulation"/> }
            {isDraft && <Chip className={classes.titleChip} icon={<EditIcon fontSize="small"/>} variant="outlined" label="Draft" title="This is a draft Document Type"/>}
          </Grid>
          <CloseIcon fontSize="small" onClick={doClose} className={classes.closeIcon}/>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item container>
            <ErrorDisplay error={status.error} />
          </Grid>
          
          <IfBlock condition={isCopying}>
            <DocTypeCopyForm item={item} onClose={() => setIsCopying(false)} />
          </IfBlock>
          <IfBlock condition={isVersioning}>
            <DocTypeVersionForm item={item} onClose={() => setIsVersioning(false)} />
          </IfBlock>

          {!!item && (
            <IfBlock condition={!isCopying && !isVersioning}>
              <WaitContainer isWaiting={!isInitialized} message="Initializing..." alignItems="center">
                <Grid container>
                  <Grid item xs={8} container className={classes.formRow}>
                    <TextField name="name" value={item.name} onChange={onInputChange} 
                      disabled={status.isWorking || isLocked} fullWidth label="Name" className={classes.field}
                      error={validation?.name?.hasError} helperText={validation?.name?.message}/>                  
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
                    {(isLocked && !isReplaced) && <Button size="small" disabled={status.isWorking} onClick={() => setIsVersioning(true)} variant="outlined" color="primary" className={classes.actionButton}>New Version...</Button>}
                    {!isAdd && <Button size="small" disabled={status.isWorking || validation.hasChanges} onClick={() => setIsCopying(true)} variant="outlined" color="primary">Copy To...</Button>}
                  </Grid>

                  <Grid item xs={8} container className={classes.formRow}>
                    <FormControl className={classes.formControl} label="Protocol" fullWidth >
                      <InputLabel id="protocol-label" className={classes.selectLabel}>Protocol</InputLabel>
                      <Select id="protocolId" labelId="protocol-label" label="Protocol" placeholder="Protocol"
                        value={item.protocolId} onChange={onSelectChange("protocolId")} 
                        disabled={isProtocolLocked || isLocked || status.isWorking} 
                        fullWidth className={classes.selectContainer} classes={{select: classes.select}} size="small">
                        <MenuItem value={-1} disabled className={classes.option}><em>Select Protocol</em></MenuItem>
                        {_.map(protocols, option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.name}</MenuItem>)}              
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item container className={classes.formRow}>
                    <TextField name="notes" multiline minRows={3} value={item.notes} onChange={onInputChange} 
                      disabled={status.isWorking || isLocked} fullWidth label="Notes" className={classes.field}
                      error={validation?.notes?.hasError} helperText={validation?.notes?.message}/>
                  </Grid>
                  
                  {!isAdd && 
                    <Grid item container rowGap={1}>
                      <Typography className={classes.subHead}>Checklists</Typography>
                      <ItemList config={config} items={item.checkLists} isWorking={status.isWorking || false} onRefresh={() => {}} RowComponent={CheckListItem} noToolbar={true} />
                      {(!isLocked) && (
                        <Grid item xs={12}>
                          <Tooltip title="Create a new Checklist - this will navigate away from this page">
                            <span>
                              <Link to={addUrl} className={classes.linkButton}>
                                Add Checklist
                                <ExitIcon fontSize="small" color="primary"/>
                              {/* <Button size="small" disabled={status.isWorking} onClick={onAddChecklist} variant="outlined" color="primary" className={classes.actionButton} endIcon={<ExitIcon fontSize="small"/>}>Add Checklist</Button> */}
                              </Link>
                            </span>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  }
                </Grid>
              </WaitContainer>
            </IfBlock>
          )}
          
          <ConfirmDialog open={Boolean(isConfirming)} 
            title="Unsaved changes" 
            prompt="You have unsaved changes for this Document Type. Would you like to continue and lose your changes? Press Yes to continue and lose your changes, or No to cancel so you can save your changes first." 
            onYes={onNav(true)} onNo={onNav(false)} 
          />
          <ConfirmDialog open={Boolean(isRemoving)} 
            title={isRemoving === "true" ? "Remove from Circulation" : "Return to Circulation"}
            prompt={removeMessages[isRemoving]}
            onYes={onRemove(isRemoving === 'true', true)} onNo={() => setRemoving(null)} 
          />
          <ConfirmDialog open={Boolean(isPublishing)} 
            title="Publish Document Type"
            prompt="This action will 'Publish' this Document Type and corresponding Checklist Template so that it is available in the drop-down menu when users enter a new Document to a ReviewSet in the specified Protocol. Are you sure you wish to Continue?"
            onYes={onSaveAs(false, true)} onNo={() => setPublishing(false)} 
          />            
        </Grid>
      </DialogContent>

      <DialogActions>
        <IfBlock condition={!isCopying && !isVersioning}>
          {/* TODO: Test out the add state */}
          {!isLocked && <Button size="small" disabled={status.isWorking} onClick={doClose}>Cancel</Button>}
          <IfBlock condition={isDraft}>
            <WaitButton isWaiting={status.isWorking} disabled={!validation.canSave} onClick={onSaveAs(true)} color="secondary" size="small">Save Draft</WaitButton>
            {!isAdd && <WaitButton isWaiting={status.isWorking} disabled={validation.hasChanges || item?.checkLists?.length === 0} onClick={onSaveAs(false)} color="secondary" size="small">Publish</WaitButton>}
            {!isAdd && <Button disabled={status.isWorking} onClick={onCloseClick} size="small">Close</Button>}
          </IfBlock>
          <IfBlock condition={!isDraft && !isReplaced && !isOutOfCirculation}>
            <WaitButton isWaiting={status.isWorking} disabled={validation.hasChanges} onClick={onRemove(true)} color="secondary" size="small">Remove from Circulation</WaitButton>
            <WaitButton isWaiting={status.isWorking} disabled={!validation.canSave} onClick={onSave(false)} color="secondary" size="small">Save</WaitButton>
            <WaitButton isWaiting={status.isWorking} disabled={!validation.canSave} onClick={onSave(true)} color="secondary" size="small">Save &amp; Close</WaitButton>
          </IfBlock>
          {isOutOfCirculation && <WaitButton isWaiting={status.isWorking} onClick={onRemove(false)} color="secondary" size="small">Return to Circulation</WaitButton>}
          {(isReplaced || isOutOfCirculation) && <Button disabled={status.isWorking} onClick={onCloseClick} size="small">Close</Button>}
        </IfBlock>
      </DialogActions>
    </Dialog>
  );
}

export default DocTypeDialog;

const buildStyles   = makeStyles()(theme => ({
  ...adminDialogClasses(theme),
  dialog  : {
    "& .MuiDialog-paper"  : {
      minWidth    : 450,
    }
  },
  titleChip   : {
    marginLeft  : theme.spacing(2),
    color       : theme.palette.primary.contrastText,
    borderColor  : theme.palette.primary.contrastText,
    "& .MuiChip-icon"   : {
      color     : theme.palette.common.white,
    }
  },
  subHead   : {
    fontSize    : 18,
    fontWeight  : 400,
    color       : theme.palette.grey[600],
    marginTop   : theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight : theme.spacing(1),
  },  
  actionButton  : {
    marginRight     : theme.spacing(1),
  },
  linkButton    : {
    minHeight : 32,
    border    : `1px solid ${theme.palette.primary.main}`, //`
    borderRadius  : theme.spacing(0.5),
    padding     : "3px 9px",
    fontSize        : "1rem",
    fontWeight      : "600",
    color           : theme.palette.primary.main,
    textTransform   : "uppercase",
    "& svg"   : {
      fontSize      : 20,
      marginBottom  : "-5px",
      marginLeft    : theme.spacing(1),
    }
  },
  ...listItemClasses(theme),
}));

const LIST_COLS   = [
  {
    id        : 0,
    sortKey   : "id",
    label     : "Id",
  },
  {
    id        : 10,
    sortKey   : "name",
    label     : "Name",
    defaultDir: "asc",
  },
  {
    id        : 20,
    sortKey   : "validFrom",
    label     : "Last Modified",
  },
  {
    id        : 999,
    label     : "",
    style     : {width: "25px"},
  },  
];

const listConfig = (actions) => ({
  keyFunc : item => item.id,
  cols    : LIST_COLS,
  actions : {
    onShow    : actions.onShow,
    onEdit    : actions.onEdit,
    canEdit   : actions.canEdit,
    navTo     : actions.navTo,
  }
});

function CheckListItem({item, actions}){
  const { classes }   = buildStyles();
  const url       = `/admin/checklists/${item.id}`;     //`

  const onEdit = () => {
    actions.navTo(url + "/edit");
  }

  return (
    <tr className={classes.listItemRow}>
      <td className={classes.listItemCol}>
        {item.id}
      </td>
      <td className={classes.listItemCol}>
        <Link to={url + "/print"} target="_blank" className={classes.listItemEditButton}  title="Click to view checklist (opens in new tab)">
          {item.name}<OpenInNewIcon fontSize="small" className={classes.endIcon}/>
        </Link>
      </td>
      <td className={classes.listItemCol}>
        {formatDate(item.validFrom)}
      </td>
      <td className={classes.itemCol}>
        {actions.canEdit() && 
          <Tooltip title="Edit checklist">
            {/* <Link to={url + "/edit"} className={classes.listItemEditButton}> */}
            <IconButton size="small" color="primary" onClick={onEdit}>
              <EditIcon fontSize="small"/>
            </IconButton>
            {/* </Link> */}
          </Tooltip>
        }
      </td>
    </tr>
  );
}