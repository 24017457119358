import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { clearApproving } from 'store/actions';
import { approveCheckListThunk } from 'store/thunks/document-thunks';
import { useCurrentPermissions, useCurrentDocument } from 'hooks';
import { APPROVE_TYPES } from 'utils/checklist-helpers';
import { muiDialogReason } from 'utils/ui-helpers';
import { IfBlock, WaitButton, ErrorDisplay } from 'features/common';

function ApprovalDialog({isOpen, checkList, type, onClose}){
  const { classes, cx }   = buildStyles();
  const perms     = useCurrentPermissions();
  const doc       = useCurrentDocument();
  
  const dispatch    = useDispatch();
  const approving   = useSelector(state => state.doc.approving);
  const [content, setContent]   = useState({name: "", notes: "", nameError: false, adornment: null});

  const isUnapprove   = Boolean(type === APPROVE_TYPES.unApprove);
  const isEmpty   = Boolean((!isUnapprove && content.name === "") || (isUnapprove && content.notes === "") || content.nameError);

  //---
  //Capture text changes and update the state
  const onChange = (e) => {
    const key   = e.currentTarget.id;
    const val   = e.currentTarget.value;
    const newContent  = {
      ...content,
      [key]   : val,
    };
    setContent(newContent);
  }

  //---
  //Some validation when the Name textfield loses focus
  const onBlur = () => {
    if(content.name !== perms.user.displayName){
      setContent({
        ...content,
        nameError   : true,
        adornment   : <InputAdornment position="end"><Tooltip title="Your name must match the display name associated with your login."><WarningIcon color="error" fontSize="small"/></Tooltip></InputAdornment>
      });
    }
    else{
      setContent({...content, nameError: false, adornment: null});
    }
  }

  const doClose = async (event, reason) => {
    if(muiDialogReason.isBackdrop(reason) || (muiDialogReason.isEscape(reason) && approving.isWorking)) return;   //not when we're busy
    await dispatch(clearApproving());
    onClose();
  }

  //---
  //Do the approve / pre-approve / unapprove
  const doSave = async() => {
    const model     = {
      checkListId     : checkList.id,
      notes           : content.notes,
      signature       : isUnapprove ? null : content.name,
      status          : type,
      reviewRecordId  : doc.currentReviewRecord.id,   //should be the primary, or approve wouldn't be an option...
    };

    const result  = await dispatch(approveCheckListThunk({ model }));
    if(result.payload.ok){
      setContent({name: "", notes: "", nameError: false, adornment: null});
      onClose();
    }
  }

  const title     = (type === APPROVE_TYPES.approve) ? "Approval" : ((type === APPROVE_TYPES.preApprove) ? "Pre Approval" : "Unapproval");
  const message   = isUnapprove ? "Are you sure you would like to un-approve this CheckList?" : "By typing your name in the box below, you declare this Check List complete and approved.";

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent>
        <Grid container className={classes.content} spacing={2}>
          <ErrorDisplay error={approving.error} />
          <Grid item xs={12}>
            <Typography className={classes.text}>
              {message}
            </Typography>
          </Grid>
          <IfBlock condition={!isUnapprove}>
            <Grid item xs={12}>
              <TextField id="name" fullWidth label="Enter your full name" variant="outlined" disabled={approving.isWorking} value={content.name} classes={{root: cx({[classes.error]: content.nameError})}} onChange={onChange} onBlur={onBlur} InputProps={{endAdornment: content.adornment}}/>
            </Grid>
          </IfBlock>
          <Grid item xs={12}>
            <TextField id="notes" multiline minRows={4} fullWidth label="Notes" variant="outlined" disabled={approving.isWorking} value={content.notes} onChange={onChange}/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid item>
        </Grid>
        <Grid item md container justifyContent="flex-end">
          <WaitButton onClick={doClose} variant="outlined" color="default" isWaiting={approving.isWorking}>Cancel</WaitButton>
          <WaitButton onClick={doSave} variant="outlined" color="primary" disabled={isEmpty} isWaiting={approving.isWorking}>{type}</WaitButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ApprovalDialog;

const buildStyles   = makeStyles()(theme => ({
  title   : {
    background  : theme.palette.grey[50],
    borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
  },
  text  : {
    fontSize  : 15,
  },
  error : {
    background    : theme.palette.error.light,  
  },
  actions: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`, //`
  }
}));