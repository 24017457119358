import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import AssignedIcon from '@mui/icons-material/Person';
import ApproveIcon from '@mui/icons-material/AssignmentTurnedIn';
import AuditIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { toast } from 'react-toastify';
import { selectStatusByKey, selectEngagementAssignments } from 'store/selectors/admin-selectors';
import { useQuery } from 'hooks';
import { ItemList } from 'features/common';
import UserDialog from '../users/user-dialog';
import AssignmentDialog from '../dialogs/assignment-dialog';
import auth from "utils/auth/auth-helper";
import AssignmentEditDialog from '../dialogs/assignment-edit-dialog';
import { useAppDispatch } from 'store';
import { deleteAssignmentThunk, fetchEngagementAssignmentsThunk, fetchUsersThunk } from 'store/thunks/admin-thunks';

const EngagementAssignments = () => {
  const { classes }   = buildStyles();
  const { engagementId }  = useParams();
  const { sort }          = useQuery();
  const engId             = parseInt(engagementId);
  const dispatch      = useAppDispatch();
  const users         = useSelector(state => state.admin.users);
  const assignments   = useSelector(state => selectEngagementAssignments(state, engId));
  const status        = useSelector(state => selectStatusByKey(state, "assignments"));
  const ustatus        = useSelector(state => selectStatusByKey(state, "users"));
  const [userId, setUserId]   = useState(null);
  const [assignmentId, setAssignmentId] = useState(null);
  const [isAdding, setAdding] = useState(false);
  
  const sorted        = useMemo(() => {
    if(assignments?.length > 0){
      const parts   = (sort || "assignedUserName-asc").split("-");
      return _.orderBy(assignments, [parts[0]], [parts[1]]);
    }
    else{
      return [];
    }
  }, [assignments, sort]);

  function onRefresh(){
    if(engagementId !== "add"){
      //TODO: Can this be moved the AssignmentDialog?  Doesn't seem necessary here, only in the dialog...
      if(needUsers(users) ) dispatch(fetchUsersThunk({ pageNumber: 1, pageSize: 9999 }));
      if(!assignments) dispatch(fetchEngagementAssignmentsThunk({ id: engId }));
    }
  }

  async function onDelete(id){
    const result = await dispatch(deleteAssignmentThunk({ id, engagementId: engId }));
    if(result.ok){
      toast.success("User successfully removed");
    }
  }
  function onShowUser(id){
    setUserId(id);
  }
  function onEditAssignment(id){
    setAssignmentId(id);
  }

  const config  = listConfig({onDelete, onShowUser, onEditAssignment});

  return (
    <Grid id="data-view" container className={classes.assignmentRoot}>
      
      <Grid item xs={12} container alignItems="center" justifyContent="flex-end">
        <Tooltip title="Add assignment">
          <Button className={classes.addButton} color="primary" onClick={() => setAdding(!isAdding)}>
            <PersonAddIcon fontSize="small" style={{marginRight: "8px"}}/>
            Add Assignment  
          </Button>
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <ItemList config={config} items={sorted} isWorking={status.isWorking || ustatus.isWorking} onRefresh={onRefresh} RowComponent={AssignmentListItem} noToolbar={true} />
      </Grid>

      {userId && <UserDialog isOpen={Boolean(userId)} onClose={() => setUserId(null)} userId={userId} />}
      {isAdding && <AssignmentDialog isOpen={isAdding} onClose={() => setAdding(false)} engagementId={engId} />}
      {assignmentId && <AssignmentEditDialog isOpen={Boolean(assignmentId)} onClose={() => setAssignmentId(null)} assignmentId={assignmentId} />}
    </Grid>
  );
}

export default EngagementAssignments;

const buildStyles   = makeStyles()(theme => ({
  assignmentRoot  : {
    "& table"   : {
      fontSize        : 15,
    }
  },
  subHead   : {
    fontSize    : 20,
    fontWeight  : 400,
    marginBottom: theme.spacing(1),
    "& svg"   : {
      marginRight   : theme.spacing(1),
      marginBottom  : theme.spacing(-0.5),
    },
  },
  addButton   : {
    marginLeft    : theme.spacing(2),
    marginBottom  : theme.spacing(0.5),
  },
  itemRow   : {

  },
  itemCol   : {
    verticalAlign   : "middle !important",
    "& svg"   : {
      marginRight   : theme.spacing(1),
      marginBottom  : theme.spacing(-0.5),
      fontSize      : 18,
      color         : theme.palette.grey[600],
    }
  },
  actionButton  : {
    padding     : 2,
    "& svg"     : {
      fontSize    : 18,
    }
  },
  editButton  : {
    textTransform   : "none",
    fontWeight      : "700",
    padding         : 0,
    color           : "#337ab7",
    "& span"        : {
      textAlign       : "left",
      justifyContent  : "flex-start",
    },
  },
  
}));

const LIST_COLS   = [
  {
    id        : 0,
    sortKey   : "assignedUserName",
    label     : "Name",
    defaultDir: "asc",
  },
  {
    id        : 10,
    sortKey   : "role",
    label     : "Role",
  },
  {
    id        : 20,
    label     : "",
    style     : {width: "25px"},
  },  
];

const listConfig = (actions) => ({
  keyFunc : item => item.id,
  cols    : LIST_COLS,
  actions : {
    onDelete  : actions.onDelete,
    onShowUser: actions.onShowUser,
    onEditAssignment: actions.onEditAssignment,
  }
});

function AssignmentListItem({item, actions}){
  const { classes }   = buildStyles();
  return (
    <tr className={classes.itemRow}>
      <td className={classes.itemCol}>
        <Button onClick={() => actions.onShowUser(item.assignedUserId)} className={classes.editButton} title="Click to view / edit user">
          {item.assignedUserName}
        </Button>
      </td>
      <td className={classes.itemCol}>
        <Button onClick={() => actions.onEditAssignment(item.id)} className={classes.editButton} title="Click to view / edit this assignment">
          {item.role === "approver" && <ApproveIcon fontSize="small" />}
          {item.role === "reader" && <AuditIcon fontSize="small" /> }
          {item.role.toLowerCase() === "user" && <AssignedIcon fontSize="small" />}
          {auth.roleLabels[item.role.toLowerCase()]}
        </Button>
      </td>
      <td className={classes.itemCol}>
        <Tooltip title="Remove assignment">
        <IconButton
          onClick={() => actions.onDelete(item.id)}
          className={classes.actionButton}
          size="large">
          <DeleteIcon fontSize="small"/>
        </IconButton>
        </Tooltip>
      </td>
    </tr>
  );
}

function needUsers(users){
  return Boolean(!users ||  //don't have any users
    users.pagination.CurrentPage !== 1 || //aren't on the first page of users...
    users.pagination.TotalItems > users.pagination.ItemsPerPage) || //don't have all the users, only one page of them
    (users.lastFilter && users.lastFilter.hasFilter); //the current list of users is filtered
}