import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CltHidden } from 'features/common';

const StatCard = ({title, icon, stat, to, color}) => {

  const { classes, cx }   = buildStyles({color: color});

  return (
    <Card className={classes.statCard}>
      <CardContent className={classes.cardContent}>
        <Link to={to}>
          <Grid container>
            
            <Grid container alignItems="center" className={classes.headerRow}>
              <Grid item container justifyContent="center" alignItems="center" className={classes.iconGrid}>
                {icon}
              </Grid>

              <Grid item container className={classes.textGrid} alignItems="center">
                <Typography className={classes.title}>{title}</Typography>
              </Grid>
              
              <Grid item container justifyContent="center" alignItems="center" className={classes.actionsGrid}>
                <CltHidden smDown>
                  {to && 
                    <IconButton size="small" className={classes.actionButton}>
                      <ArrowRightIcon fontSize="small"/>
                    </IconButton> 
                  }
                </CltHidden>
                <CltHidden smUp>
                  <Typography className={classes.stat}>{stat?.curr}</Typography>
                </CltHidden>
              </Grid>
            </Grid>

            <CltHidden smDown>
              <Grid container className={classes.statRow}>
                <Grid item xs={6}>
                  <Typography className={classes.stat}>{stat?.curr}</Typography>
                </Grid>
                {stat?.direction && 
                  <Grid item xs={6} container alignItems="center" justifyContent="flex-end" className={cx(classes.diffGrid, stat?.direction)} >
                      {stat?.direction === "up" && <ArrowDropUpIcon fontSize="small" className={classes.diffIcon}/>}
                      {stat?.direction === "down" && <ArrowDropDownIcon fontSize="small" className={classes.diffIcon}/>}
                      <Typography className={classes.diff}>{stat?.pctDiff}</Typography>
                  </Grid>
                }
              </Grid>
            </CltHidden>

          </Grid>
        </Link>
      </CardContent>      
    </Card>
  );
}

export default StatCard;

const buildStyles   = makeStyles()((theme, props) => ({
  statCard  : {
    marginBottom  : theme.spacing(2),
  },
  cardContent   : {
    padding     : theme.spacing(1),
    paddingBottom   : `${theme.spacing(1)} !important`,
  },
  title   : {
    marginBottom  : 0,
    marginLeft    : theme.spacing(0.5),
    fontSize      : 18,
    fontWeight    : 300,
    color         : props.color[500], //theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      // marginLeft: theme.spacing(1),
      fontSize: 16,
    }
  },
  statRow   : {
    padding       : `${theme.spacing(0)} ${theme.spacing(2)}`, //`
  },
  stat  : {
    paddingTop   : theme.spacing(1),
    fontSize    : 35,
    fontWeight  : 700,
    color       : theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      fontWeight: 600
    }
  },
  iconGrid  : {
    borderRadius  : "50%",
    width         : 42,
    height        : 42,
    background    : `${props.color[500]}44`, //`
    color         : theme.palette.grey[600], //theme.palette.secondary.main, 
    border        : `1px solid ${props.color[200]}`,  //`
    [theme.breakpoints.down('sm')]: {
      width: 24,
      height: 24,
      "& svg": {
        width: 16,
        height: 16,
      }
    }
  },
  textGrid  : {
    height  : "100%",
    width   : "calc(100% - 84px)",
  },
  actionsGrid   : {
    width         : 42,    
  },
  diff  : {
    fontSize      : 13,
    fontWeight    : 600,
  },
  diffGrid  : {
    "&.up"  : {
      color     : theme.palette.success.main,
    },
    "&.down"  : {
      color     : theme.palette.error.main,
    },    
    "&.even"  : {
      color     : theme.palette.info.main,
    },
  },
  actionButton  : {
    "& svg" : {
      fontSize    : 24,
      fontWeight  : 100,
      color       : theme.palette.primary.light,
    }
  }
}));