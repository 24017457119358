import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { IDocument, IPagination } from "app-types";
import { createDocThunk, exportDocsThunk, fetchDocsThunk, fetchExistingDocsThunk, fetchMoveDestinationsThunk, moveDocThunk, removeDocThunk } from "store/thunks";

type DocumentsSliceStatus = {
  isWorking: boolean;
  error: any;
  pagination: IPagination | null;
  query: any;
  isEmpty: boolean;
}

//TODO: migrate to the common PaginatedSlice. For now, it doesn't work because of the itemsStatus grouping
interface DocumentsSlice { // extends PaginatedSlice<IDocument> {
  isWorking: boolean;
  isMoving: boolean;    //TODO: used to be isMoveWorking
  isCreating: boolean;
  isRemoving: boolean;
  isExistingWorking: boolean;   //TODO: used to be existingIsWorking

  error: any;
  existingError: any;
  createError: any;
  moveError: any;
  removeError: any;
  
  items: IDocument[] | null;
  itemsStatus: DocumentsSliceStatus;

  //TODO: organize this better, maybe move to a separate slice
  //This is for the existing items list, used to validate new items, or assign / move items
  existingFilter: any;
  existingItems: IDocument[] | null;
}

const INITIAL_STATE: DocumentsSlice = {
  isWorking: false,
  isMoving: false,
  isCreating: false,
  isRemoving: false,
  isExistingWorking: false,

  error: null,
  existingError: null,
  createError: null,
  moveError: null,
  removeError: null,

  items: null,
  itemsStatus: {
    isWorking: false,
    error: null,
    pagination: null,
    query: null,
    isEmpty: false,
  },

  existingFilter: null,
  existingItems: null,
};

//=== Async Thunk Reducers

const docsFetched = (builder: ActionReducerMapBuilder<DocumentsSlice>) => {
  builder.addCase(fetchDocsThunk.pending, (state, action) => {
    state.items = null;
    state.itemsStatus = { ...state.itemsStatus, isWorking: true, pagination: null, error: null, isEmpty: false };
  })
  .addCase(fetchDocsThunk.fulfilled, (state, action) => {
    state.items = action.payload?.data ?? null;
    state.itemsStatus = {
      ...state.itemsStatus,
      isWorking: false,
      error: null,
      pagination: action.payload?.pagination ?? null,
      isEmpty: Boolean(action.payload?.data?.length === 0),
    };
  })
  .addCase(fetchDocsThunk.rejected, (state, action) => {
    state.items = null;
    state.itemsStatus = {
      ...state.itemsStatus,
      isWorking: false,
      pagination: null,
      error: action.error,
    };
  });
};

const docCreated = (builder: ActionReducerMapBuilder<DocumentsSlice>) => {
  builder.addCase(createDocThunk.pending, (state, action) => {
    state.isCreating = true;
    state.createError = null;
  })
  .addCase(createDocThunk.fulfilled, (state, action) => {
    const { doc, reviewRecords } = action.payload;
    //add it to the existing items collection, if necessary
    if(state.existingItems){
      let exItem = (state.existingItems || []).find(e => e.id === doc.id);
      if(!exItem) {
        exItem = doc;
        state.existingItems.push(doc);
      }

      exItem.reviewRecords = reviewRecords;
    }

    state.isCreating = false;
    state.createError = null;
    
    if(state.items) state.items.splice(0, 0, doc); //add to the top
    else state.items = [doc];
    
    state.itemsStatus = { ...state.itemsStatus, isEmpty: false };
  })
  .addCase(createDocThunk.rejected, (state, action) => {
    state.isCreating = false;
    state.createError = action.error;
  });
};

const existingDocsFetched = (builder: ActionReducerMapBuilder<DocumentsSlice>) => {
  builder.addCase(fetchExistingDocsThunk.pending, (state, action) => {
    state.existingError = null;
    state.isExistingWorking = true;
  })
  .addCase(fetchExistingDocsThunk.fulfilled, (state, action) => {
    state.existingItems = action.payload?.data ?? null;
    state.existingFilter = action.payload.filter;
    state.isExistingWorking = false;
  })
  .addCase(fetchExistingDocsThunk.rejected, (state, action) => {
    state.existingError = action.error;
    state.isExistingWorking = false;
    state.existingItems = null;
    state.existingFilter = null;
  });
};

const docMoveTargetsFetched = (builder: ActionReducerMapBuilder<DocumentsSlice>) => {
  builder.addCase(fetchMoveDestinationsThunk.pending, (state, action) => {
    state.isMoving = true;
  })
  .addCase(fetchMoveDestinationsThunk.fulfilled, (state, action) => {
    state.isMoving = true;
  });
};

const docMoved = (builder: ActionReducerMapBuilder<DocumentsSlice>) => {
  builder.addCase(moveDocThunk.pending, (state, action) => {
    state.moveError = null;
    state.isMoving = true;
  })
  builder.addCase(moveDocThunk.fulfilled, (state, action) => {
    const { reviewRecordId } = action.payload;
    //Filter out the moved item from the items collection
    state.items = state.items?.filter(i => i.reviewRecordId !== reviewRecordId) ?? null;
    state.itemsStatus = { ...state.itemsStatus, isEmpty: !Boolean(state.items?.length) };
    state.isMoving = false;
  })
  .addCase(moveDocThunk.rejected, (state, action) => {
    state.isMoving = false;
    state.moveError = action.error;
  });
};

const docRemoved = (builder: ActionReducerMapBuilder<DocumentsSlice>) => {
  builder.addCase(removeDocThunk.pending, (state, action) => {
    state.removeError = null;
    state.isRemoving = true;
  })
  .addCase(removeDocThunk.fulfilled, (state, action) => {
    const { reviewRecordId } = action.payload;

    //Filter out the removed item from the items collection
    state.items = state.items?.filter(i => i.reviewRecordId !== reviewRecordId) ?? null;
    state.itemsStatus = { ...state.itemsStatus, isEmpty: !Boolean(state.items?.length) };
    
    //Also remove it from the existing items collection
    if(state.existingItems){
      const existing = state.existingItems.find(doc => doc.id === reviewRecordId);
      if(existing && !!existing.reviewRecords){
        //remove the reviewRecord from the reviewRecords collection
        const rrIndex = existing.reviewRecords.findIndex(rr => rr.id === reviewRecordId);
        if(rrIndex >= 0){
          const replacement = { ...existing, reviewRecords: existing.reviewRecords.filter(rr => rr.id !== reviewRecordId) };
          state.existingItems = state.existingItems.map(doc => doc.id === reviewRecordId ? replacement : doc);
        }
      }
    }
    state.isRemoving = false;
  })
  .addCase(removeDocThunk.rejected, (state, action) => {
    state.isRemoving = false;
    state.removeError = action.error;
  });
};

const docsExported = (builder: ActionReducerMapBuilder<DocumentsSlice>) => {
  builder.addCase(exportDocsThunk.pending, (state, action) => {
    state.itemsStatus = { ...state.itemsStatus, isWorking: true, error: null };
  })
  .addCase(exportDocsThunk.fulfilled, (state, action) => {
    state.itemsStatus = { ...state.itemsStatus, isWorking: false };
  })
  .addCase(exportDocsThunk.rejected, (state, action) => {
    state.itemsStatus = { ...state.itemsStatus, isWorking: false, error: action.error };
  });
};

//=== The Slice and Reducer
export const documentsSlice = createSlice({
  name: "documents",
  initialState: INITIAL_STATE,
  reducers: {
    clearCreateDocError: (state) => {
      state.createError = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DocumentsSlice>) => {
    docsFetched(builder);
    docCreated(builder);
    existingDocsFetched(builder);
    docMoveTargetsFetched(builder);
    docMoved(builder);
    docRemoved(builder);
    docsExported(builder);
  },
});

export default documentsSlice.reducer;