import React, { useCallback, useMemo } from "react";
import { NavLink, useHref, useLocation } from "react-router-dom";
import { NavLinkProps } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import { getRelativePath } from "utils/route-helpers-ts";

interface IControlProps extends NavLinkProps {
  disabled?: boolean;
  exact?: boolean;
}


const buildStyles = makeStyles()({
  disabled: {
    pointerEvents: "none",
    cursor: "not-allowed",
    color: "#cacaca",
    opacity: 0.65,    
  }
});

// const isExactMatch = (location: any, url: string) => {
//   return `${location.pathname}${location.search}` === url;
// }

const NavLinkP = React.forwardRef((props: IControlProps, ref: any) => {
  const {to, disabled, className, exact, children, ...otherProps} = props;
  const location = useLocation();
  const toStr = to.toString();
  const href = useHref(toStr);
  // const resolved = resolvePath(location.pathname, toStr);
  const { classes, cx } = buildStyles();

  const toUrl = useMemo(() => {
    return getRelativePath(toStr, location.pathname);
  }, [location, toStr]);

  const isReallyActive = useCallback((isActive: boolean) => {
    if(!exact) return isActive;   //if we're not using exact, then just use the built-in isActive flag.

    const path = `${location?.pathname}${location?.search}`;
    return isActive && path === href;    
  }, [href, location, exact]);

  const cname = className as string;

  return (
    <NavLink 
      to={toUrl}
      ref={ref} 
      aria-disabled={disabled} 
      className={({isActive}) => cx(cname, disabled && classes.disabled, isReallyActive(isActive) && "active")} 
      {...otherProps as any}
    >
      {children}
    </NavLink>
  );
});

export default NavLinkP;
