import React from 'react';
import { Assessment, Dashboard, InsertDriveFile, Folder, People, Task } from '@mui/icons-material';

export const LIST_SIZE_KEY  = "admin-list-page-size";

//Admin Menu
export const menuConfig  = [
  { 
    id        : 10, 
    content   : "Home", 
    path      : "",
    icon      : <Dashboard fontSize="small"/>,
  },
  { 
    id        : 20, 
    content   : "Users", 
    path      : "users",
    icon      : <People fontSize="small"/>,
  },
  { 
    id        : 30, 
    content   : "Engagements",
    path      : "engagements",
    icon      : <Folder fontSize="small" />,
  },
  { 
    id        : 40, 
    content   : "Document Types",
    path      : "documenttypes?filter=current",
    icon      : <InsertDriveFile fontSize="small" />,
  },
  { 
    id        : 50, 
    content   : "Tasks",
    path      : "tasks",
    icon      : <Task fontSize="small" />,
  },
  { 
    id        : 60, 
    content   : "Reports",
    path      : "reports",
    icon      : <Assessment fontSize="small" />,
  },
];