//-----
// Keys for the various search string parameters that are used in the app
export const searchStringKeys = {
  redirect: "redirect",
  filter: "filter",
  sort: "sort",
};

//----
// Returns a path that uses the provided pathname, and to string. Will deal with relative paths,
// such that each '../' at the beginning of toStr will remove a segment from the end of pathname.
// This is what the react-router v6 documentation says should happen automatically, but at least
// with the compat library (as of 9.9.22) doesn't work.
// Examples:
//  /engagement/4/reviewsets + ../documents => /engagements/4/documents
//  /engagements/4/reviewsets/5/documents/123 + ../../ => /engagements/4/reviewsets/5

// Reference: see ../__tests__/route-helpers-ts.test.js for more examples
export const getRelativePath = (toStr: string, pathname: string) => {
  if(toStr.startsWith("../")){
    const toParts = toStr.split("/");
    const segmentsToRemove = toParts.filter(p => p === "..").length;

    const pathParts = pathname.split("/").filter(pp => pp.length > 0);
    
    const outPath = pathParts.slice(0, pathParts.length - segmentsToRemove).join("/");
    const outTo = toParts.slice(segmentsToRemove).join("/");
    
    const outUrl = `/${outPath}${outPath.length > 0 ? "/" : ""}${outTo}`;
    return outUrl;
  }

  return toStr;
};