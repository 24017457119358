import React, { useEffect } from 'react';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { WaitContainer, ErrorDisplay } from 'features/common';
import { ReportHeader, ReviewSetReportTitleRow, ReviewSetReportHeader, DocumentReportHeader, CheckListReportPart } from './parts';
import { selectCheckListReportData } from 'store/selectors/report-selectors';
import { useQueryString } from 'hooks';

import './reports.scss';
import { fetchCheckListForReportThunk } from 'store/thunks/report-thunks';
import { useAppDispatch } from 'store';

function CheckListReport(){
  const { classes }       = buildStyles();
  const { reviewsetId, documentId, checkListId} = useQueryString();
  const data          = useSelector(selectCheckListReportData);
  const status        = useSelector(state => state.reports.status);
  const dispatch      = useAppDispatch();

  const checkList     = data?.doc.checkLists ? _.find(data.doc.checkLists, c => c.id === checkListId) : null;

  useEffect(() => {
    if(reviewsetId && documentId && !data){
      fetchReportData(reviewsetId, documentId, dispatch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewsetId, documentId]);

  return (
    <Grid id="reporting-page" container justifyContent="center" className={classes.root}>
      <Helmet>
        <title>{`CheckList ${checkListId} Report`}</title> {/*` */}
      </Helmet>
      <ErrorDisplay error={status.error}/>
      <WaitContainer isWaiting={!data || status.isLoading} message="Gathering CheckList data (this may take a minute)..." className={classes.waiting}>
        <div className="report-page">
          <ReportHeader />
          <ReviewSetReportTitleRow set={data?.set} />
          <ReviewSetReportHeader set={data?.set} engagement={data?.engagement} site={data?.engagement?.site} />   
          <DocumentReportHeader doc={data?.doc} set={data?.set} />
          <CheckListReportPart key={checkList?.id} set={data?.set} doc={data?.doc} checklist={checkList} protocol={data?.engagement?.protocol} />
        </div>
      </WaitContainer>
    </Grid>
  );
}

export default CheckListReport;

const buildStyles   = makeStyles()(theme => ({
  root: {
    width: "calc(100% - 60px)",
  },
  waiting   : {
    marginTop     : "15%",
  }
}));


async function fetchReportData(rsId, docId, dispatch){
  // return await dispatch(loadCheckListReportData(rsId, docId));
  return await dispatch(fetchCheckListForReportThunk({ setId: rsId, docId }));
}