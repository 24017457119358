import React from 'react';
import { useSelector, } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useAppDispatch } from 'store';
import { addDocumentTypeVersionThunk } from 'store/thunks';
import { selectStatusByKey } from 'store/selectors/admin-selectors';
import { WaitButton } from 'features/common';
import { useInputHandler } from 'hooks/general-hooks';
import { useNavigate } from 'react-router-dom';

const DocTypeVersionForm = ({item, onClose}) => {
  const { classes }   = buildStyles();
  const navigate = useNavigate();
  const dispatch  = useAppDispatch();
  const status    = useSelector(state => selectStatusByKey(state, "doctypes"));
  const [values, onChange]  = useInputHandler({name: `Copy of ${item.name}`});

  async function onSave(){
    const result = await dispatch(addDocumentTypeVersionThunk({ id: item.id, newName: values.name }));
    if(result?.payload?.ok && result?.payload?.data?.id){
      //Navigate to the new doctype...
      navigate(`/admin/documenttypes?dialogs=doctype:${result.payload.data.id}`);  //`
    }
  }

  return (
    <Grid container direction="column" alignItems="center">
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Grid item xs={12} container justifyContent="center" className={classes.formRow}>
            <Typography className={classes.copyText}>Create a new version of <strong className={classes.secondary}>{item.name}</strong></Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" className={classes.formRow}>
            <Typography className={classes.copyText}>with the name</Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <TextField name="name" value={values.name} onChange={onChange} disabled={status.isWorking} fullWidth placeholder="Name of new version" className={classes.field}/>
          </Grid>
          <Grid container justifyContent="center" className={classes.formRow}>
            <Typography className={classes.info}>This will disable the current version and replace it with the new version.</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
            <Button size="small" disabled={status.isWorking} onClick={onClose}>Cancel</Button>
            <WaitButton isWaiting={status.isWorking} disabled={values.name === ""} onClick={onSave} color="secondary" size="small">Create Version</WaitButton>
          </Grid>
        </Grid>            
    </Grid>
  );
}

export default DocTypeVersionForm;

const buildStyles   = makeStyles()(theme => ({
  formRow  : {
    marginBottom    : theme.spacing(2),
  },
  field   : {
    width        : "50%",
  },
  secondary  : {
    color       : theme.palette.secondary.main,
  },
  info        : {
    marginTop       : theme.spacing(1),
    fontStyle       : "italic",
    fontSize        : 16,
    fontWeight      : 300,
    color           : theme.palette.grey[500],
  }
}));