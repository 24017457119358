import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import DescriptionIcon from '@mui/icons-material/Description';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AdjustIcon from '@mui/icons-material/Adjust';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useHover } from 'hooks';
import { PropertiesGrid } from 'features/common';
import { formatDate } from 'utils/general-helpers';

const DocumentIcon = ({doc}) => {
  const { classes, cx }   = buildStyles();
  const [anchor, isHovering, onEnter, onExit, position]   = useHover("bottomLeft"); //for tracking the hover icon

  const properties  = [
    // { label: "Id:", value: doc.documentId },
    // { label: "Type:", value: "Document", },
    { label: "ReviewSet:", value: doc.reviewSetTitle },
    { label: "Engagement:", value: (doc.engagementName || `Engagement ${doc.engagementId}`) }, //`
    { label: "Last touched:", value: formatDate(doc.responseDate) },
  ];

  // const isOpen  = Boolean(doc.documentId === 24027);
  const props   = styles[doc?.documentStatusCode] || {};

  return <>
    <Grid item className={cx(classes.iconCircle, props.className)} container alignItems="center" justifyContent="center">
      <DescriptionIcon className={classes.icon} onMouseEnter={onEnter} onMouseLeave={onExit} />
    </Grid>
    <Popover anchorEl={anchor} open={isHovering} onClose={onExit} {...position} className={classes.tip} classes={{paper: classes.tipPaper}} disableRestoreFocus>
      <Grid container direction="column">
        
        <Grid item container className={cx(classes.popHeader)}>
            <DescriptionIcon className={cx(classes.tipIcon, props.className)}/>
            <Typography className={classes.popHeaderText}>Document: {doc.documentKey}</Typography>
        </Grid>
        
        <Grid item container className={classes.popContent}>
          <PropertiesGrid props={properties} maxWidth={600}/>
        </Grid>

        <Grid container className={classes.statusGrid}>
          <Grid item container className={cx(classes.statusRow, props.className)} alignItems="center">
            <Grid item>
              {props.icon}
            </Grid>
            <Grid item>
              <Typography className={classes.statusText}>{props.label(doc)}</Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Popover>
  </>;
}

export default DocumentIcon;

const buildStyles   = makeStyles()(theme => ({
  iconCircle  : {
    borderRadius  : "50%",
    width         : 32,
    height        : 32,
    // background    : theme.palette.primary.light, //`${theme.palette.primary.main}44`, //`
    color         : theme.palette.primary.contrastText,
    "&.pending"  : {
      color         : theme.statuses.pending.color[300],
    },
    "&.preApproved"  : {
      color         : theme.statuses.preApproved.color[200],
    },
    "&.approved"  : {
      color         : theme.statuses.approved.color[200],
    },
  },
  icon  : {
    fontSize    : 18,
    fontWeight  : 400,
  },
  tip   : {
    pointerEvents   : "none",
  },
  tipPaper     : {
    minWidth        : 300,
  },
  tipIcon   : {
    marginRight   : theme.spacing(1),
    marginTop     : theme.spacing(-0.5),
    "&.pending"  : {
      color         : theme.statuses.pending.color[400],
    },
    "&.preApproved"  : {
      color         : theme.statuses.preApproved.color[200],
    },
    "&.approved"  : {
      color         : theme.statuses.approved.color[200],
    },
  },
  popHeader     : {
    padding     : theme.spacing(1),
    background      : theme.palette.primary.dark, //`${theme.palette.primary.main}44`, //`
    // background      : theme.palette.grey[200],
    color           : theme.palette.common.white, //grey[700],
    borderBottom    : `1px solid ${theme.palette.grey[200]}`, //`
    "&.training"  : {
      background    : theme.statuses.training.color[200], //`${theme.palette.primary.main}44`, //`
      color         : theme.palette.grey[700], //theme.palette.primary.dark, //theme.palette.secondary.main, 
    },
  },
  popHeaderText   : {
    fontSize    : 15,
    fontWeight  : 600,
  },
  popContent    : {
    padding         : theme.spacing(1),
    // maxWidth        : 400,
  },
  statusRow   : {
    padding   : theme.spacing(0.5),
    "& svg" : {
      display   : "inline-block",
      marginRight   : theme.spacing(0.5),
      marginBottom  : theme.spacing(-0.5),
    },
    "&.pending"  : {
      background  : theme.statuses.pending.background,
      borderTop   : `1px solid ${theme.statuses.pending.border}`,  //`
      "& svg" : {
        color     : theme.statuses.pending.text,
      }
    },
    "&.preApproved"  : {
      background  : theme.statuses.preApproved.background,
      borderTop   : `1px solid ${theme.statuses.preApproved.border}`,  //`
      "& svg" : {
        color     : theme.statuses.preApproved.text,
      }
    },
    "&.approved"  : {
      background  : theme.statuses.approved.background,
      borderTop   : `1px solid ${theme.statuses.approved.border}`,  //`
      "& svg" : {
        color     : theme.statuses.approved.text,
      }
    },
  },
  statusText  : {
    fontSize    : 14,
    fontWeight  : 300,
    color       : theme.palette.grey[600],    
    display   : "inline-block",    
  },
}));

const styles  = {
  100   : {
    className   : "approved",
    icon  : <CheckCircleOutlineIcon fontSize="small" />,
    // label : (rec) => <span>Approved by <strong>{rec.signature}</strong> on <strong>{formatDate(rec.statusDate)}</strong></span>,
    label : (rec) => "Approved",
  },
  50  : {
    className   : "preApproved",
    icon  : <AdjustIcon fontSize="small" />,
    // label : (rec) => <span>Pre-Approved by <strong>{rec.signature}</strong> on <strong>{formatDate(rec.statusDate)}</strong></span>,
    label : (rec) => "Pre-Approved"
  },
  0     : {
    className   : "pending",
    icon  : <RadioButtonUncheckedIcon fontSize="small" />,
    label : (rec) => "Pending",
  },  
}