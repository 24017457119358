import _ from 'lodash';
import { createSelector } from 'reselect';
import { engagementName, formatDate } from 'utils/general-helpers';

const _getStatus        = state => state.admin.status;
const _getStatusByKey   = (state, key) => state.admin.status[key];
const _getUsers         = state => state.admin.users;
const _idFunc           = (state, id) => id;
// const _keyIdFunc        = (state, key, id) => { return {key: key, id: id}; };
const _getAllEngagements   = state => state.admin.allEngagements;
const _getEngagements   = state => state.admin.engagements;
const _getSettings      = state => state.settings;
const _getEngAssignments= state => state.admin.engAssignments;
const _getUsrAssignments= state => state.admin.usrAssignments;
const _getDocTypes      = state => state.admin.doctypes;
const _getChecklists    = state => state.admin.checklists;
const _getCurrentDocType= state => state.admin.currentDocType;
const _getReportQueue = state => state.admin.reportQueue;

export const selectAdminStatus = createSelector(
  [_getStatus],
  (status) => {
    const keys    = _.keys(status);
    const result  = _.reduce(keys, (overall, current, index) => {
      const currStatus  = status[current];
      return { 
        isWorking : (overall.isWorking || currStatus.isWorking), 
        errorKey  : overall.errorKey || (currStatus.error ? current : null), 
      };
    }, {isWorking: false, errorKey: null});

    return {
      ...result,
      items   : status,
    };
  }
);

export const selectStatusByKey = createSelector(
  [_getStatusByKey],
  (status) => {
    return status || {};
  }
);

export const selectUser = createSelector(
  [_getUsers, _idFunc],
  (users, id) => {
    if(!users || !id) return null;

    let user  = null;
    if(id === -1){
      user = {
        id            : -1,
        displayName   : "",
        role          : "user",
        email         : "",
      };
    }
    else{
      user  = _.find(users.items, u => u.id === id);
    }

    //TODO: do any merging of user stuff here...
    return user;
  }
);

///-----
/// Gets the full list of Engagement from the state, based on the engagement id
///-----
export const selectAllEngagements = createSelector(
  _getAllEngagements, _getSettings,
  (engagements, settings) => {

    if(!engagements || !settings) return null;

    const { protocols, projects, sites, clients }  = settings;

    const items   = _.map(engagements.items, eng => {
      const protocol  = _.find(protocols, p => p.id === eng.protocolId);
      const site      = _.find(sites, s => s.id === eng.siteId);
      const client    = _.find(clients, c => c.id === site.clientId);
      const project   = _.find(projects, p => p.id === protocol.projectId);
      const isTraining= Boolean(eng.propertyBag?.isTraining);
      const isArchived= Boolean(eng.isArchived);

      const otherProps  = {
        name        : engagementName(eng), //eng.name || `Engagement ${eng.id}`, //`
        isTraining  : isTraining,
        isArchived  : isArchived,
        protocol    : protocol,
        project     : project,
        site        : site,
        client      : client,
        model       : {
          id          : eng.id,
          name        : eng.name || "",
          clientId    : client.id,
          projectId   : project.id,
          protocolId  : protocol.id,
          siteId      : site.id,
          isTraining  : isTraining,
          isArchived  : isArchived,
        }
      };
        
      return { ...eng, ...otherProps };
    });

    return {
      ...engagements, 
      items: items,
    };
  }
);
///-----
/// Gets the full Engagement from the state, based on the engagement id
///-----
export const selectEngagements = createSelector(
  _getEngagements, _getSettings,
  (engagements, settings) => {

    if(!engagements || !settings) return null;

    const { protocols, projects, sites, clients }  = settings;

    const items   = _.map(engagements.items, eng => {
      const protocol  = _.find(protocols, p => p.id === eng.protocolId);
      const site      = _.find(sites, s => s.id === eng.siteId);
      const client    = _.find(clients, c => c.id === site.clientId);
      const project   = _.find(projects, p => p.id === protocol.projectId);
      const isTraining= Boolean(eng.propertyBag?.isTraining);
      const isArchived= Boolean(eng.isArchived);

      const otherProps  = {
        name        : engagementName(eng), //eng.name || `Engagement ${eng.id}`, //`
        isTraining  : isTraining,
        isArchived  : isArchived,
        protocol    : protocol,
        project     : project,
        site        : site,
        client      : client,
        model       : {
          id          : eng.id,
          name        : eng.name || "",
          clientId    : client.id,
          projectId   : project.id,
          protocolId  : protocol.id,
          siteId      : site.id,
          isTraining  : isTraining,
          isArchived  : isArchived,
        }
      };
        
      return { ...eng, ...otherProps };
    });

    return {
      ...engagements, 
      items: items,
    };
  }
);

export const selectEngagementAssignments = createSelector(
  _idFunc, _getEngAssignments, _getUsers,
  (id, assignments, users) => {
    if(!assignments || !users) return null;
    if(assignments.engagementId !== id) return null;    //only return when looking at the right engagement

    const assigned  = _.map(assignments.items, asg => {
      const user  = _.find(users.items, u => u.id === asg.assignedUserId);
      return {
        ...asg, 
        user  : user,
      };
    });

    return assigned;
  }
);

export const selectEngagementAssignment = createSelector(
  _idFunc, _getEngAssignments, _getUsers,
  (id, assignments, users) => {
    if(!assignments || !users) return null;
    // if(assignments.engagementId !== id) return null;    //only return when looking at the right engagement

    const assignment = assignments.items.find(a => a.id === id);
    if(!assignment) return null;
    const user  = users.items.find(u => u.id === assignment.assignedUserId);

    return {
      ...assignment,
      user,
    };
  }
);

export const selectUserAssignments = createSelector(
  _idFunc, _getUsrAssignments, _getAllEngagements,
  (id, assignments, engagements) => {
    if(!assignments || !engagements) return null;
    if(assignments.userId !== id) return null;    //only return when looking at the right engagement

    // let already     = [];
    const assigned  = _.map(assignments.items, asg => {
      // already.push(asg.assignedUserId); //add it to the already assigned list
      // const user  = _.find(users.items, u => u.id === asg.assignedUserId);
      const eng   = _.find(engagements.items, e => e.id === asg.entityId);
      return {
        ...asg, 
        engagement  : eng,
      };
    });

    return assigned;

    // const available = _.filter(users, u => already.indexOf(u.id) < 0);

    // return {
    //   assigned    : assigned,
    //   available   : available,
    // };    
  }
);

export const selectDocTypes = createSelector(
  _idFunc, _getSettings, _getAllEngagements,
  (id, settings, allEngagements) => {
    if(!id || !settings || !allEngagements || !settings.documentTypes) return null;

    const eng       = _.find(allEngagements.items, e => e.id === id);
    if(!eng){
      console.error("Engagement not found while selecting doc types.");
      return [];
    }
    
    const protocolId= eng.protocolId;
    const docTypes  = _.filter(settings.documentTypes, dt => dt.protocolId === protocolId);
    
    const result    = _.map(docTypes, dt => {
      const modOn   = formatDate(dt.validFrom);
      return {
        ...dt,
        lastModified    : `${dt.userName} on ${modOn}`,
        lastModifiedOn  : modOn,
      };
    })
    return result;
  }
);

export const selectDocTypeById = createSelector(
  _idFunc, _getSettings,
  (id, settings) => {
    if(!id || !settings || !settings.documentTypes) return null;

    const docType   = _.find(settings.documentTypes, dt => dt.id === id);
    const modOn   = formatDate(docType.validFrom);
    return {
      ...docType,
      lastModified    : `${docType.userName} on ${modOn}`,
      lastModifiedOn  : modOn,
    };
  }
);

export const selectFullDocType = createSelector(
  _idFunc, _getCurrentDocType,
  (id, docType) => {
    if(!id || !docType || docType.id !== id) return null;
    return docType;
  }
)

export const selectChecklists = createSelector(
  _getChecklists, _getEngagements, _getSettings,
  (checklists, engagements, settings) => {
    //need to make sure we have everything we need
    if(!checklists?.items || !settings.documentTypes || !engagements || !engagements?.params?.filter === "all") return null;
    const docTypes    = settings.documentTypes;
    const protocols   = settings.protocols;
    const engItems    = engagements?.items;

    const fullItems   = _.map(checklists.items, item => {
      const docType   = _.find(docTypes, dt => dt.id === item.documentTypeId);
      const protocol  = _.find(protocols, p => p.id === docType.protocolId);
      const engs      = _.filter(engItems, eng => eng.protocolId === docType.protocolId);  //There can be multiple engagements per protocol
      const modOn     = formatDate(item.validFrom);

      const fullItem  = {
        ...item,
        documentType  : docType,
        protocol      : protocol,
        engagements   : engs,
        engagementString  : _.map(engs, eng => engagementName(eng)).join(", "),
        lastModified   : `${item.user?.displayName} on ${modOn}`,   //`
        lastModifiedOn : modOn,
        isReplaced     : Boolean(docType.propertyBag.nextVersion),
      };

      return fullItem;
    });

    return {
      ...checklists,
      items   : fullItems,
    };
    
  }
);

export const selectAdminDocTypes = createSelector(
  _getDocTypes, _getAllEngagements, _getSettings,
  (docTypes, engagements, settings) => {
    //need to make sure we have everything we need
    if(!docTypes?.items || !engagements || !engagements?.params?.filter === "all") return null;
    const protocols   = settings.protocols;
    const dTypes      = settings.documentTypes;
    const engItems    = engagements?.items;

    const fullItems   = _.map(docTypes.items, docType => {
      const protocol  = _.find(protocols, p => p.id === docType.protocolId);
      const eng       = _.find(engItems, eng => eng.protocolId === docType.protocolId);  //There can be multiple engagements per protocol (take the first)
      const modOn     = formatDate(docType.validFrom);
      //TODO: in the future, add isDraft to the doc type view, but for now, just pull it from the settings (which has the full doc type)
      const dType     = dTypes.find(dt => dt.id === docType.id);

      const fullItem  = {
        ...docType,
        propertyBag: {
          ...docType.propertyBag,
          ...dType?.propertyBag,
        },
        protocol    : protocol,
        engagement  : eng,
        engagementName : engagementName(eng),
        lastModified   : `${docType.userName} on ${modOn}`,   //`
        lastModifiedOn : modOn,
        isReplaced     : Boolean(docType.nextVersion), //Boolean(docType.propertyBag.nextVersion),
      };

      return fullItem;
    });

    return {
      ...docTypes,
      items   : fullItems,
    };
    
  }
);

export const selectReportQueue = createSelector(
  _getReportQueue,
  (queue) => {
    if(!queue) return null;
    else return queue;
  }
);