import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchUserMetricsThunk } from 'store/thunks/metrics-thunks';
import { WaitContainer } from 'features/common';
import RecentsTableItem from './recents-table-item';

const RecentsTable = () => {
  const dispatch  = useAppDispatch();
  const status    = useAppSelector(state => state.metrics.status.recents);
  const recents   = useAppSelector(state => state.metrics.recents);

  const subset    = (recents || []).slice(0, 6); 

  useEffect(() => {
    async function init(){
      await dispatch(fetchUserMetricsThunk());
    }
    if(!recents) init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <Grid container sx={{p: 1}}>
        <Grid item container justifyContent={{xs: "center", sm: "flex-start"}}>
          <Typography variant="h7" color="white" sx={{mb: 1}}>Pick up where you left off</Typography>
        </Grid>
        <Grid item container>
          <WaitContainer isWaiting={status.isWorking}>
            <table>
              <tbody>
                {subset.map(r => <RecentsTableItem key={r.documentId} item={r} />)}
              </tbody>
            </table>
          </WaitContainer>          
        </Grid>
      </Grid>
  );
}

export default RecentsTable;