import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { blue, red, orange } from '@mui/material/colors';
// import red from '@mui/material/colors/red';
// import orange from '@mui/material/colors/orange';
import { IfBlock, WaitContainer, ColorButton } from 'features/common';
import { AssertionIcon, AssertionError } from './';
import { selectInputLabels } from 'store/selectors/document-selectors';
import { useCurrentDocument } from 'hooks';
import { responseMap } from 'utils/checklist-helpers';
import { CommentDialog } from './';

function BoolInput({item, checkListStatus, onSave, isReadOnly}){
  const [isCommenting, setCommenting]   = useState(false);

  const { classes }     = buildStyles();
  const doc         = useCurrentDocument();
  const bInput      = item.primaryInput;
  const bResponse   = item.primaryResponse;
  const labels      = useSelector(selectInputLabels);

  const isEditable  = checkListStatus.isPending && doc.isPrimary;
  //Check to see if other inputs need responses first (comments aren't required before bool)
  const isDisabled  = !isEditable || !item.isReady;

  const onRespond = async (e) => {
    const value   = e.currentTarget.id;

    //If they are rejecting this item, need to see if it requires a comment
    if(value === "reject" && item.isCommentRequiredOnReject){
      //TODO: need to require additional content if there is already a comment...
      // const comResponse = _.find(item.responses, r => r.checkListInputId === item.commentInput.id);
      // if(!comResponse){
        setCommenting(true);
        return;
      // }
    }

    //Got here, so ok to save the response
    saveResponse(value);  
  }

  const commentSaved = async(value) => {
    const result  = await onSave(value);
    if(result.response?.id){
      setCommenting(false);
      saveResponse("reject"); //only get here if they rejected and there was a required comment.
    }
    else{
      //TODO: comment failed to save
    }
  }

  const saveResponse = (value) => {
    const model   = {
      ...responseMap.base(bInput, bResponse),
      ...responseMap[value],
    };

    onSave(model);
  }

  // console.log("** bool input rendered");

  return (
    <Grid container alignItems="center" justifyContent="flex-end" flexWrap="nowrap">
      <Grid item className={isEditable ? classes.iconSmall : classes.icon}>
        <AssertionIcon item={item} showEmpty={isReadOnly}/>
        <AssertionError status={item} />
      </Grid>
      <WaitContainer isWaiting={item.isWorking} message="" className={classes.inputWaiter}>
        <IfBlock condition={isEditable}>
          <IfBlock condition={item.isFinished && !isReadOnly}>
            <Button id="delete" size="small" sx={{fontSize: "0.85rem", fontWeight: "500"}} title="Undo this answer" onClick={onRespond}>Undo</Button>
          </IfBlock>
          <IfBlock condition={!item.isFinished && !isReadOnly}>
              <ColorButton id="confirm" size="small" sx={{fontSize: "0.85rem", fontWeight: "500"}} color={blue} title="Confirm this assertion" disabled={isDisabled} onClick={onRespond}>{labels.confirm}</ColorButton>
              <ColorButton id="reject" size="small" sx={{fontSize: "0.85rem", fontWeight: "500"}} color={red} title="Reject this assertion" disabled={isDisabled} onClick={onRespond}>{labels.reject}</ColorButton>            
              {bInput.allowNA && <ColorButton id="na" size="small" sx={{fontSize: "0.85rem", fontWeight: "500"}} color={orange} base={300} hover={500} title="Not Applicable" disabled={isDisabled} onClick={onRespond}>{labels.na}</ColorButton> }
          </IfBlock>
        </IfBlock> 
      </WaitContainer>
      <IfBlock condition={isCommenting}>
        <CommentDialog item={item} isOpen={isCommenting} onSave={commentSaved} isRequired={true} onCancel={() => setCommenting(false)} />
      </IfBlock>
    </Grid>
  );
}

export default BoolInput;

const buildStyles   = makeStyles()(theme => ({
  inputWaiter   : {
    padding     : `0 ${theme.spacing(2)}`,
  },
  iconSmall : {
    marginRight: theme.spacing(0),
    display: "flex",
  },
  icon : {
    marginRight: theme.spacing(1),
    display: "flex",
  }
}));