import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { IEngagement } from "app-types";
import { approveCheckListThunk, approveSetThunk, createDocThunk, createSetThunk, fetchSetAndDocMetricsThunk, fetchUserMetricsThunk } from "store/thunks";


interface MetricsSlice {
  engagement: IEngagement | null;
  recents: any[] | null;
  rsCounts: any[] | null;
  docCounts: any[] | null;

  status: { 
    [key: string]: {      //engagement, recents, setsAndDocs
      isInitialized: boolean;
      isWorking: boolean;
      error: any | null;
      loadedAt: number | null;
      lastId: number | null;
    }
  }
}

const INITIAL_STATE: MetricsSlice = {
  engagement: null,
  recents: null,
  rsCounts: null,
  docCounts: null,

  status: {
    engagement: {
      isInitialized: false,
      isWorking: false,
      error: null,
      loadedAt: null,
      lastId: null,
    },
    recents: {
      isInitialized: false,
      isWorking: false,
      error: null,
      loadedAt: null,
      lastId: null,
    },
    setsAndDocs: {
      isInitialized: false,
      isWorking: false,
      error: null,
      loadedAt: null,
      lastId: null,
    },
  }
};

//=== Thunk Extra Reducers
// const engagementMetricsFetched = (builder: ActionReducerMapBuilder<MetricsSlice>) => {
//   builder
//     .addCase(fetchEngagementMetricsThunk.pending, (state, action) => {
//       state.engagement = null;
//       state.status.engagement = { ...state.status.engagement, isWorking: true, error: null, loadedAt: null, lastId: null };
//     })
//     .addCase(fetchEngagementMetricsThunk.fulfilled, (state, action) => {
//       if(!action.payload?.ok || !action.payload?.data){
//         state.status.engagement.error = action.payload;
//         state.status.engagement.isWorking = false;
//         return;
//       }

//       const result = action.payload.data;
//       state.engagement = result;
//       state.status.engagement = { ...state.status.engagement, isWorking: false, isInitialized: true, loadedAt: Date.now(), lastId: result.id };
//     })
//     .addCase(fetchEngagementMetricsThunk.rejected, (state, action) => {
//       state.status.engagement.error = action.payload;
//       state.status.engagement.isWorking = false;
//     });
// };

const recentsMetricsFetched = (builder: ActionReducerMapBuilder<MetricsSlice>) => {
  builder
    .addCase(fetchUserMetricsThunk.pending, (state, action) => {
      state.recents = null;
      state.status.recents = { ...state.status.recents, isWorking: true, error: null, loadedAt: null, lastId: null };
    })
    .addCase(fetchUserMetricsThunk.fulfilled, (state, action) => {
      if(!action.payload?.ok || !action.payload?.data){
        state.status.recents.error = action.payload;
        state.status.recents.isWorking = false;
        return;
      }

      const result = action.payload.data;
      state.recents = result;
      state.status.recents = { ...state.status.recents, isWorking: false, isInitialized: true, loadedAt: Date.now(), lastId: result.id };
    })
    .addCase(fetchUserMetricsThunk.rejected, (state, action) => {
      state.status.recents.error = action.payload;
      state.status.recents.isWorking = false;
    });
};

const setsAndDocsMetricsFetched = (builder: ActionReducerMapBuilder<MetricsSlice>) => {
  builder
    .addCase(fetchSetAndDocMetricsThunk.pending, (state, action) => {
      state.rsCounts = null;
      state.docCounts = null;
      state.status.setsAndDocs = { 
        ...state.status.setsAndDocs, 
        isWorking: true, 
        error: null, 
        loadedAt: null, 
        lastId: null 
      };
    })
    .addCase(fetchSetAndDocMetricsThunk.fulfilled, (state, action) => {
      const result = action.payload;
      state.rsCounts = result.set;
      state.docCounts = result.doc;
      state.status.setsAndDocs = { ...state.status.setsAndDocs, isWorking: false, isInitialized: true, loadedAt: Date.now(), lastId: result.id };
    })
    .addCase(fetchSetAndDocMetricsThunk.rejected, (state, action) => {
      state.status.setsAndDocs.error = action.payload;
      state.status.setsAndDocs.isWorking = false;
    });
};

const externalReducers = (builder: ActionReducerMapBuilder<MetricsSlice>) => {
  builder
    .addCase(createSetThunk.fulfilled, (state, action) => {
      state.status.setsAndDocs.lastId = null;
    })
    .addCase(approveSetThunk.fulfilled, (state, action) => {
      state.status.setsAndDocs.lastId = null;
    })
    .addCase(createDocThunk.fulfilled, (state, action) => {
      state.status.setsAndDocs.lastId = null;
    })
    .addCase(approveCheckListThunk.fulfilled, (state, action) => {
      state.status.setsAndDocs.lastId = null;
    })
};

const metricsSlice = createSlice({
  name: "metrics",
  initialState: INITIAL_STATE,
  reducers: {    
  },
  extraReducers: (builder) => {
    // engagementMetricsFetched(builder);
    recentsMetricsFetched(builder);
    setsAndDocsMetricsFetched(builder);
    externalReducers(builder);
  }
});

export default metricsSlice.reducer;