import { paginationHeader } from "api";
import { IDocument, IDocumentType, IReviewSet } from "app-types";
import { pick } from "lodash";
import Apis, { downloadFile } from "store/api";
import { createAppAsyncThunk, parseResponse } from "store/slices/slice-helpers";
import { getSearchProps } from "utils/action-helpers";
import { getDocumentStatus } from "utils/document-helpers";

export const DOCS_THUNKS = {
  fetch: "documents/fetch",
  fetchExisting: "documents/fetchExisting",
  create: "documents/create",
  moveDestinations: "documents/moveDestinations",  //fetches the destinations where a doc can be moved
  move: "documents/move",
  remove: "documents/remove",
  export: "documents/export",
};

const DOC_FIELDS = ["documentKey", "documentTypeId", "documentTypeName", "id", "lastModifiedBy", "lastModifiedOn", "primaryReviewRecordId", "reviewRecordId"];
const DOC_STATUS_FIELDS = ["status", "statusCode"];

//=== Will fetch documents for either an engagement or a set, depending on the params
export const fetchDocsThunk = createAppAsyncThunk(DOCS_THUNKS.fetch, async (params: any, thunkAPI) => {
  const { engId, setId, filter, pageNumber = 1, pageSize = 25 } = params;

  const headers   = paginationHeader(pageNumber, pageSize);
  const searchProps = getSearchProps(filter);
  const search = {
    ...searchProps,
    ...(engId && { engagementId: engId }),
  };
  const path = setId ? `/forset/${setId}` : "";
  const result = await Apis.Documents.get<IDocument[], any>(path, search, headers);
  const response = parseResponse<IDocument[]>(DOCS_THUNKS.fetch, result, { filter });
  return response;
});

//=== Fetches documents that are already in a set
export const fetchExistingDocsThunk = createAppAsyncThunk(DOCS_THUNKS.fetchExisting, async (params: any, thunkAPI) => {
  const { filter } = params;
  const path = "/existing";
  const result = await Apis.Documents.get<IDocument[], any>(path, filter);
  const response = parseResponse<IDocument[]>(DOCS_THUNKS.fetchExisting, result, { filter });
  return response;
});

//=== Will create a new document and review record
export const createDocThunk = createAppAsyncThunk(DOCS_THUNKS.create, async (params: any, thunkAPI) => {
  const { doc, set } = params;

  let data = {};
  if(!doc.docId){
    //new document
    data = {
      documentKey: doc.docKey,
      documentTypeId: doc.docTypeId,
      siteId: set.siteId,
    };

    //create the document
    const docResult = await Apis.Documents.post<IDocument, any>("", data);
    if(!docResult.ok || !docResult.data){
      console.error("Error creating document", docResult);
      throw docResult.originalError;
    }

    doc.docId = docResult.data.id;  //this gives us the doc id
  }

  //Create the ReviewRecord
  const reviewRecord = {
    reviewSetId: set.id,
    documentId: doc.docId,
  };

  const rrResult = await Apis.ReviewRecords.post<IDocument, any>("", reviewRecord);
  if(!rrResult.ok || !rrResult.data || !rrResult.data.reviewRecords){
    console.error("Error creating review record", rrResult);
    throw rrResult.originalError;
  }

  const rawDoc = rrResult.data;
  const docStatus = getDocumentStatus(rrResult.data);
  const newDoc: Record<string, any> = {
    ...pick(rawDoc, DOC_FIELDS),
    ...pick(docStatus, DOC_STATUS_FIELDS)
  };

  //Get the document type name from the settings
  const allDocTypes: IDocumentType[] = thunkAPI.getState().settings.documentTypes ?? [];
  const docType = allDocTypes.find(dt => dt.id === newDoc.documentTypeId);
  newDoc.documentTypeName = docType?.name || "";

  const reviewRecords = rawDoc.reviewRecords!;
  const setReviewRecord = reviewRecords.find(rr => rr.reviewSetId === reviewRecord.reviewSetId);
  if(!setReviewRecord) throw new Error("Set review record missing, internal error");

  newDoc.reviewRecordId = setReviewRecord.id;
  if(newDoc.reviewRecordId === newDoc.primaryReviewRecordId) newDoc.primaryReviewRecordId = null; //fix it up so we know this is the primary

 return { ok: true, doc: newDoc as IDocument, data, reviewRecords };  
});

//=== Will fetch the destinations where a document can be moved
export const fetchMoveDestinationsThunk = createAppAsyncThunk(DOCS_THUNKS.moveDestinations, async (params: any, thunkAPI) => {
  const { docId } = params;
  const result = await Apis.Documents.get<IReviewSet[], any>(`/${docId}/move-targets`);
  if(result.ok){
    return result.data ?? [];   //Don't put this data into redux, just return it
  }
  else if(!result.ok){
    console.error("Error fetching move destinations", result);
    throw result.originalError;
  }
});

//=== Will move a document to a different reviewset
export const moveDocThunk = createAppAsyncThunk(DOCS_THUNKS.move, async (params: any, thunkAPI) => {
  const { docId, reviewRecordId, targetReviewSetId } = params;
  const result = await Apis.ReviewRecords.put(`${reviewRecordId}/move-to/reviewset/${targetReviewSetId}`);
  const response = parseResponse<any>(DOCS_THUNKS.move, result, { reviewRecordId, docId });
  return response;
});

//=== Will remove a document from a reviewset
// note, this only deletes the ReviewRecord, it doesn't touch the document itself.
export const removeDocThunk = createAppAsyncThunk(DOCS_THUNKS.remove, async (params: any, thunkAPI) => {
  const { reviewRecordId, docId } = params;
  const result = await Apis.ReviewRecords.delete(`/${reviewRecordId}`);
  const response = parseResponse<any>(DOCS_THUNKS.remove, result, { reviewRecordId, docId });
  return response;
});

//=== Will export documents to a CSV file
export const exportDocsThunk = createAppAsyncThunk(DOCS_THUNKS.export, async (params: any, thunkAPI) => {
  const { setId, engId, filter } = params;
  if(!setId && !engId) throw new Error("Export requires either a set id or an engagement id. Please provide setId or engId.");
  else if(!!setId && !!engId) throw new Error("Export is for either a set or an engagement, not both. Please provide only setId or engId, not both.");

  const headers = paginationHeader(1, 10000); //export everything, so set pagination to 10,000
  const searchProps = getSearchProps(filter);
  const otherSearchProps = engId ? { engagementId: engId } : {};  //if we have an engagement id, use that
  const search = { ...searchProps, ...otherSearchProps, responseType: "arraybuffer" };
  const path = setId ? `/export/forset/${setId}` : `/export/forengagement/${engId}`;
  const result = await Apis.Documents.get(path, search, headers);

  if(result.ok){
    //download the export file
    const isOk = await downloadFile(result, "text/csv");
    if(!isOk) throw new Error("Error downloading export file");
  }

  const response = parseResponse<any>(DOCS_THUNKS.export, result);
  return response;
});