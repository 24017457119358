import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector, } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Button, FormControl, InputLabel, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useAppDispatch } from 'store';
import { copyDocumentTypeThunk } from 'store/thunks';
import { selectStatusByKey } from 'store/selectors/admin-selectors';
import { useAllEngagements } from 'hooks/admin-hooks';
import { WaitButton } from 'features/common';
import { engagementName } from 'utils/general-helpers';
import { useNavigate } from 'react-router-dom';

const DocTypeCopyForm = ({item, onClose}) => {
  const { classes }   = buildStyles();
  const dispatch  = useAppDispatch();
  const navigate = useNavigate();
  const status    = useSelector(state => selectStatusByKey(state, "doctypes"));
  const engStatus = useSelector(state => selectStatusByKey(state, "engagements"));
  const engagements = useAllEngagements(null, eng => eng.protocolId !== item.protocolId);
  const [engId, setEngId]   = useState("");
  const isWorking = (status.isWorking || engStatus.isWorking);

  function onChange(e){
    setEngId(e.target.value);
  }

  async function onSave(){
    //Get the protocol from the engagement
    const eng   = _.find(engagements, e => e.id === engId);
    const protocolId  = eng.protocolId;

    const result = await dispatch(copyDocumentTypeThunk({ id: item.id, protocolId }));
    if(result?.payload?.ok && result?.payload?.data?.id){
      //Navigate to the new doctype...
      navigate(`/admin/documenttypes?dialogs=doctype:${result.payload.data.id}`);  //`
    }
  }

  return (
    <Grid container direction="column" alignItems="center">
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Grid item xs={12} container justifyContent="center" className={classes.formRow}>
            <Typography className={classes.copyText}>Create a copy of <strong className={classes.secondary}>{item.name}</strong> with <strong className={classes.secondary}>{item.checkLists?.length}</strong> Checklist(s)</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Typography className={classes.copyText}>in engagement</Typography>
          </Grid>
          <Grid item container justifyContent="center" className={classes.formRow}>
            <FormControl className={classes.formControl} fullWidth >
              <InputLabel id="engagement-label" className={classes.selectLabel}>Engagement</InputLabel>
              <Select id="engagementId" labelId="engagement-label" value={engId} onChange={onChange} disabled={isWorking} fullWidth className={classes.selectContainer} classes={{select: classes.select}}  size="small">
                <MenuItem value="" disabled className={classes.option}><em>Select Engagement</em></MenuItem>
                {_.map(engagements, option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.id}. {engagementName(option)}</MenuItem>)}              
              </Select>
            </FormControl>
          </Grid>
          <Grid container justifyContent="center" className={classes.formRow}>
            <Typography className={classes.info}>This will not affect this document type.</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
            <Button size="small" disabled={isWorking} onClick={onClose}>Cancel</Button>
            <WaitButton isWaiting={isWorking} disabled={engId < 0} onClick={onSave} color="secondary" size="small">Copy</WaitButton>
          </Grid>
        </Grid>            
    </Grid>
  );
}

export default DocTypeCopyForm;

const buildStyles   = makeStyles()(theme => ({
  formRow  : {
    marginBottom    : theme.spacing(2),
  },
  formControl   : {
    width        : "50%",
  },
  secondary  : {
    color       : theme.palette.secondary.main,
  },
  info        : {
    marginTop       : theme.spacing(1),
    fontStyle       : "italic",
    fontSize        : 16,
    fontWeight      : 300,
    color           : theme.palette.grey[500],
  }
}));