import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';

export default function ColorButton({color, base, hover, children, ...otherProps}){
  const props     = {color: color, base: (base || 500), hover: (hover || 700)};
  const { classes }   = buildStyles(props, {
    props: props
  });

  return (
    <Button className={classes.root} {...otherProps}>{children}</Button>
  );
}

const buildStyles   = makeStyles()((theme, props) => ({
  root: {
    color             : theme.palette.getContrastText(props.color[props.base]),
    backgroundColor   : props.color[props.base],
    '&:hover': {
      backgroundColor : props.color[props.hover],
    },
    "&:disabled": {
      backgroundColor : props.color[100],
    },
  },
}));