import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import red from '@mui/material/colors/red';
import { IfBlock, ErrorContent } from 'features/common';

function AssertionError({status}){
  const [anchorEl, setAnchorEl]   = useState(null);
  const onEnter = (e) => { setAnchorEl(e.currentTarget); }
  const onExit  = (e) => { setAnchorEl(null); }
  
  const { classes }   = buildStyles();
  const isOpen  = Boolean(anchorEl);
  const error   = status?.error;

  return(
    <IfBlock condition={status && status.error}>
      <WarningIcon style={{color: red[700]}} fontSize="small" onMouseEnter={onEnter} onMouseLeave={onExit}/>
      <Popover open={isOpen} anchorEl={anchorEl} className={classes.popover} classes={{paper: classes.paper}} {...popoverPosition} onClose={onExit} disableRestoreFocus>
        <Grid container direction="column" className={classes.popGrid}>
          <Grid item container alignItems="center" className={classes.popHeader}>
            <WarningIcon style={{color: red[700]}} fontSize="small" className={classes.popHeaderIcon}/>
            <Typography className={classes.popHeaderText}>Error</Typography>
          </Grid>
          <Grid container direction="column" className={classes.popContent}>
            <Typography className={classes.popContentText}>This item failed to save</Typography>
            <ErrorContent error={error} />
          </Grid>
        </Grid>
      </Popover>
    </IfBlock>
  );

}

export default AssertionError;

const popoverPosition   = {
  anchorOrigin  : {
    vertical    : "bottom", 
    horizontal  : "right"
  },
  transformOrigin   : {
    vertical    : "top",
    horizontal  : "right",
  }
};

const buildStyles   = makeStyles()(theme => ({
  popover   : {
    pointerEvents   : "none",
    // padding: 0,
  },
  paper     : {
    // padding         : theme.spacing(1),
  },
  popHeader     : {
    padding     : theme.spacing(1),
    background      : red[50],
    color           : red[700],
    borderBottom    : `1px solid ${red[200]}`, //`
  },
  popHeaderIcon : {
    marginRight: theme.spacing(0.5),
  },
  popHeaderText   : {
    fontSize    : 15,
    fontWeight  : 600,
  },
  popContent    : {
    padding         : theme.spacing(1),
    maxWidth        : 600,
  },
  popContentText     : {
    fontSize    : 15,
    fontWeight  : 600,
  },  
}));