import React from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import { useHover, useQuery } from 'hooks';
import { updateUrlSearch } from 'utils/general-helpers';
import { useNavigate } from 'react-router-dom';
import { FilterAlt } from '@mui/icons-material';

const FilterButton = ({filterOptions, isDisabled, onFiltered}) => {
  const { classes }   = buildStyles();
  const [anchor, isOpen, onOpen, onClose]  = useHover("bottomCenter");
  const navigate = useNavigate();
  const { filter }= useQuery();

  if(!filterOptions) return null;

  function onClick(option){
    const value   = option.isDefault ? null : option.key;
    const newUrl  = updateUrlSearch("filter", value);
    navigate(newUrl, { replace: true });
    if(onFiltered) onFiltered(option);
    onClose();
  }

  return (
    <>
      <Button onClick={onOpen} disabled={isDisabled} title="filter this list" sx={{borderColor: "rgba(0, 0, 0, 0.23)"}}>
        {/* <BubbleChartIcon fontSize="small"/> */}
        {/* <span className="glyphicon glyphicon-filter"></span> */}
        <FilterAlt sx={{height: 18, width: 18}} />
      </Button>
      <Menu id="filter-menu" anchorEl={anchor} keepMounted open={isOpen} onClose={onClose}>
        {
          _.map(filterOptions, opt => {
            const isChecked   = Boolean((!filter && opt.isDefault) || (filter === opt.key));
            return (
              <MenuItem key={opt.key} onClick={() => onClick(opt)} className={classes.menuItem}>
                {opt.label}
                {isChecked && <CheckIcon className={classes.checkIcon} fontSize="small" />}
              </MenuItem>
            );
          })
        }
      </Menu>  
    </>
  );
}

export default FilterButton;

const buildStyles   = makeStyles()(theme => ({
  filterButton  : {
    minWidth  : 40,
    height    : 35,
    marginLeft  : -1,
    border    : `1px solid rgb(231, 234, 236)`, //${theme.palette.grey[300]}`, //`
    borderRadius  : 0,
  },
  menuItem  : {
    fontSize  : 16,
  },
  checkIcon   : {
    marginLeft    : theme.spacing(1),
  }
}));