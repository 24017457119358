import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Status } from 'features/common';
import { getReviewSetStatusForReport } from 'utils/report-helpers';

export default function ReviewsetReportHeader({set, engagement, site}){
  if(!set) return null;
  const { classes }   = buildStyles();
  const status    = getReviewSetStatusForReport(set);

  return (
    <div className={classes.root}>
    <div className="row report-header">
        <div className="col-xs-12">
            <div className="row report-header-row">
                <div className="col-xs-1 label-col">
                    <span className="report-header-label">Title</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{set.title}</span>
                </div>
                <div className="col-xs-1 label-col text-right">
                    <span className="report-header-label">Client</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{engagement?.client?.name}</span>
                </div>
                <div className="col-xs-1 label-col text-right">
                    <span className="report-header-label">Status</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">
                        <Status code={status.code}/>
                    </span>
                </div>
            </div>
            <div className="row report-header-row">
                <div className="col-xs-1 label-col">
                    <span className="report-header-label">Lot #</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{set.propertyBag.lotNumber}</span>
                </div>
                <div className="col-xs-1 label-col text-right">
                    <span className="report-header-label">Project</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{engagement?.project?.title}</span>
                </div>
                <div className="col-xs-1 label-col text-right">
                    <span className="report-header-label">Date</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{status.on}</span>
                </div>

            </div>
            <div className="row report-header-row">
                <div className="col-xs-1 label-col">
                    <span className="report-header-label">Part #</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{set.propertyBag.partNumber}</span>
                </div>
                <div className="col-xs-1 label-col text-right">
                    <span className="report-header-label">Protocol</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{engagement?.protocol?.name}</span>
                </div>
                <div className="col-xs-1 label-col text-right">
                    <span className="report-header-label">By</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{status.by}</span>
                </div>
            </div>
            <div className="row report-header-row">
                <div className="col-xs-1 label-col">
                    <span className="report-header-label">Product</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{set.propertyBag.productName}</span>
                </div>
                <div className="col-xs-1 label-col text-right">
                    <span className="report-header-label">Site</span>
                </div>
                <div className="col-xs-3">
                    <span className="report-header-value">{site?.name ?? engagement?.site?.name}</span>
                </div>

            </div>
        </div>
    </div>
    </div>
  );
}

const buildStyles   = makeStyles()(theme => ({
  root  : {
    width     : "100%",
    fontSize  : "10px",    
  }
}));