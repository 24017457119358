export const appRoutesLegacy   = {
  home          : "*",
  login         : "/login",
  engagements   : {
    view    : "/engagements/:engagementId",
    reports : "/engagements/:engagementId/reports",
  },
  reviewsets  : {
    list  : "/engagements/:engagementId/reviewsets",
    add   : "/engagements/:engagementId/reviewsets/add",
    edit  : "/engagements/:engagementId/reviewsets/:reviewsetId/edit",
    view  : "/engagements/:engagementId/reviewsets/:reviewsetId",
    reports: "/engagements/:engagementId/reviewset/reports",
  },
  documents   : {
    list  : "/engagements/:engagementId/documents",
    view  : "/engagements/:engagementId/reviewsets/:reviewsetId/documents/:documentId",
  },
  reports     : {
    list  : "/reports",
    reviewSetReport   : "/reports/reviewset",
    checkListReport   : "/reports/checklist",
    reviewSetItemReport   : (setId) => `/reports/reviewset?reviewsetid=${setId}`, //`
    checkListItemReport   : (setId, docId, clId) => `/reports/checklist?reviewsetid=${setId}&documentid=${docId}&checklistid=${clId}`, //`
  },
  admin       : {
    home    : "/admin",
    users   : "/admin/users",
    tasks   : "/admin/tasks",
    engagements   : "/admin/engagements",
    engagement    : "/admin/engagements/:engagementId",
    checklists    : "/admin/checklists",
    checklist     : "/admin/checklists/:checklistId/:action",
    checklistPrint: "/admin/checklists/:checklistId/print",
    documentTypes   : "/admin/documenttypes",
    reports: "/admin/reports",
  },
  other     : {
    test    : "/test",
  },
}

//Paths for the new v6 router.
export const appRoutes = {
  home: "",
  login: "login",
  engagements: {
    dashboard: "",
    engagement: ":engagementId",
    reviewsets: "reviewsets",
    documents: "documents",
    reports: "reports",
    reviewset: {
      add: "add",
      edit: ":reviewsetId/edit",
      view: ":reviewsetId",
    },
    document: {
      view: "/reviewsets/:reviewsetId/documents/:documentId",
    },    
  },
  reports: {
    list: "reports",
    reviewSetReport   : "reviewset",
    checkListReport   : "checklist",    
  },
  test: "test",
  admin: {
    dashboard: "admin",
    users   : "users",
    tasks   : "tasks",
    engagements   : "engagements",
    engagement    : "engagements/:engagementId",
    checklists    : "checklists",
    checklist     : "checklists/:checklistId/:action",
    checklistPrint: "checklists/:checklistId/print",
    documentTypes   : "documenttypes",
    reports: "reports",
  },
};

//These will match the pathname property of the react-router v6,
// they're slightly different from the routes, since they including the
// leading slash
export const appPathnames = {
  home: "/",
  login: "/login",
}

export function buildPath(parts, sep){
  const separator = sep || '/';
  const replace   = new RegExp(separator+'{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}

export function addSearchParam(base, key, value){
  const ups   = new URLSearchParams(base);
  ups.set(key, encodeURIComponent(value));
  return ups.toString();
}

export function getSearchParam(search, key){
  const ups   = new URLSearchParams(search);
  const val   = ups.get(key);
  return val ? decodeURIComponent(val) : null;
}