import React from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import BookIcon from '@mui/icons-material/Book';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AdjustIcon from '@mui/icons-material/Adjust';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { PropertiesGrid } from 'features/common';
import { formatDate } from 'utils/general-helpers';
import { APPROVE_TYPES } from 'utils/reviewset-helpers';
import { useHover } from 'hooks';

const ReviewSetIcon = ({isVisible, set}) => {

  const { classes, cx }   = buildStyles();
  const [anchor, isHovering, onEnter, onExit, position]   = useHover("bottomLeft");

  if(!isVisible) return null;

  const statusRecords = _.filter(set.reviewSetStatuses, s => s.status !== APPROVE_TYPES.unApprove);
  const statusNodes   = statusRecords.length === 0 ? <StatusRow classes={classes} cx={cx}/> : _.map(statusRecords, sr => <StatusRow key={sr.id} classes={classes} cx={cx} statusRec={sr} />);
  const props  = [
    { label: "Description:", value: set.propertyBag?.description },
    { label: "Lot #:", value: set.propertyBag?.lotNumber },
    { label: "Part #:", value: set.propertyBag?.partNumber },
    { label: "Product:", value: set.propertyBag?.productName },
    { label: "Checklists:", value: `${set.approvedCheckListCount} of ${set.checkListCount > 0 ? set.checkListCount : 0} Complete`}, //`
    { label: "Notes:", value: set.notes },
  ];

  return (
    <>
      <BookIcon color="primary" className={classes.iconRoot} onMouseEnter={onEnter} onMouseLeave={onExit}/>
      <Popover open={isHovering} anchorEl={anchor} className={classes.iconPop} classes={{paper: classes.popPaper}} {...position} onClose={onExit} disableRestoreFocus>
        <Grid container alignItems="center" className={classes.infoGrid}>
        
          <Grid item xs={12} container alignItems="center" className={classes.descriptionRow}>
            <Grid item xs={12} container alignItems="center">
              <BookOutlinedIcon fontSize="small" className={classes.icon}/>
              <Typography className={classes.itemTitle}>ReviewSet {set.id}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.itemDescription}>{set.title}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.contentGrid}>
            <PropertiesGrid props={props} maxWidth={400}/>            
          </Grid>
        
          <Grid container className={classes.statusGrid}>
            {statusNodes}
          </Grid>

        </Grid>
      </Popover>
    </>       
  );
}

export default ReviewSetIcon;

const buildStyles   = makeStyles()(theme => ({
  iconRoot  : {
    marginLeft  : theme.spacing(1),
    marginRight : theme.spacing(1),
  },
  iconPop   : {
    pointerEvents   : "none",
  },
  popPaper  : {
  },
  infoGrid  : {
    maxWidth      : 400,
  },
  contentGrid   : {
    padding   : theme.spacing(1),
  },
  itemDescription   : {
    fontSize      : 15,
    fontWeight    : 300,
    fontStyle     : "italic",
    // color         : theme.palette.grey[800],
    color           : theme.palette.primary.contrastText,
    padding       : `${theme.spacing(0.5)} 0`,    
  },
  descriptionRow  : {
    // borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
    // background      : theme.palette.grey[50],
    background      : theme.palette.primary.dark,
    borderBottom    : `1px solid ${theme.palette.primary.main}`, //`
    paddingTop      : theme.spacing(1),
    paddingLeft     : theme.spacing(1),
    color           : theme.palette.primary.contrastText,
  },
  itemLabel   : {
    fontSize      : 13,
    fontWeight    : 400,
    color         : theme.palette.grey[500],
    width         : 85,
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    // textAlign     : "right",
  },
  itemValue  : {
    minWidth      : 200,
    maxWidth      : 300,
    fontSize      : 15,
    fontWeight    : 400,
    color         : theme.palette.grey[800],
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`,    
  },
  itemTitle   : {
    fontSize    : 15,
    fontWeight  : 600,
    display     : "inline-block",
    // color       : theme.palette.primary.dark,
    color           : theme.palette.primary.contrastText,
  },
  icon  : {
    display       : "inline-block",
    marginRight   : theme.spacing(0.5),
    color         : theme.palette.primary.contrastText,
    // marginLeft    : theme.spacing(0.5),
  },
  statusGrid  : {
  },
  statusRow   : {
    padding   : theme.spacing(0.5),
    "& svg" : {
      display   : "inline-block",
      marginRight   : theme.spacing(0.5),
      marginBottom  : theme.spacing(-0.5),
    }
  },
  statusText  : {
    fontSize    : 14,
    fontWeight  : 300,
    color       : theme.palette.grey[600],    
    display   : "inline-block",
  },
  approved  : {
    background  : theme.statuses.approved.background,
    borderTop   : `1px solid ${theme.statuses.approved.border}`,  //`
    "& svg" : {
      color     : theme.statuses.approved.text,
    }
  },
  preApproved   : {
    background  : theme.statuses.preApproved.background,
    borderTop   : `1px solid ${theme.statuses.preApproved.border}`,  //`
    "& svg" : {
      color     : theme.statuses.preApproved.text,
    }
  },
  pending   : {
    background  : theme.statuses.pending.background,
    borderTop   : `1px solid ${theme.statuses.pending.border}`,  //`
    "& svg" : {
      color     : theme.statuses.pending.text,
    }
  }
}));

function StatusRow({classes, cx, statusRec}){
  const status  = statusRec ? statusRec.status : "PENDING";
  const props   = styles[status];

  return (
    <Grid item container className={cx(classes.statusRow, classes[props.className])} alignItems="center">
      <Grid item>
        {props.icon}
      </Grid>
      <Grid item>
        <Typography className={classes.statusText}>{props.label(statusRec)}</Typography>
      </Grid>
    </Grid>
  );
}

const styles  = {
  APPROVE   : {
    className   : "approved",
    icon  : <CheckCircleOutlineIcon fontSize="small" />,
    label : (rec) => <span>Approved by <strong>{rec.signature}</strong> on <strong>{formatDate(rec.statusDate)}</strong></span>,
  },
  PREAPPROVE  : {
    className   : "preApproved",
    icon  : <AdjustIcon fontSize="small" />,
    label : (rec) => <span>Pre-Approved by <strong>{rec.signature}</strong> on <strong>{formatDate(rec.statusDate)}</strong></span>,
  },
  PENDING     : {
    className   : "pending",
    icon  : <RadioButtonUncheckedIcon fontSize="small" />,
    label : (rec) => "Pending",
  },
  DISAPPROVE     : {
    className   : "pending",
    icon  : <RadioButtonUncheckedIcon fontSize="small" />,
    label : (rec) => "Pending",
  }
}