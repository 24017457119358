import { last, maxBy, minBy, orderBy } from "lodash";
import { isSection } from "utils/checklist-helpers";
import { CheckListItem } from "./builder-types";

//#region Helper Functions

export function replaceBuilderItem(items: any[], original: any, update: any){
  const originalIndex = items.indexOf(original);
  return [...items.slice(0, originalIndex), update, ...items.slice(originalIndex + 1)];
};

export function doMoveItem(items: CheckListItem[], idToMove: number, offset: number){
  if(!items || !idToMove) return null;

  const item    = items.find(i => i.id === idToMove);
  if(!item) throw new Error("Invalid item id: " + idToMove);
  const myIndex = items.indexOf(item);

  if(isSection(item)){
    const sections  = orderBy(items.filter(isSection), s => s.ordinal);
    const mySectionIndex   = sections.indexOf(item);

    //Make sure this move is valid
    if(item && ((offset > 0 && mySectionIndex < sections.length - 1) || (offset < 0 && mySectionIndex > 0))){
      const otherSection  = sections[mySectionIndex + offset];
      const otherIndex    = items.indexOf(otherSection);

      const them          = [otherSection, ...items.filter(i => i.parentId === otherSection.id)];
      const themCount     = them.length;
      const themLastIndex = otherIndex + themCount -1;

      const us            = [item, ...items.filter(i => i.parentId === idToMove)];
      const usCount       = us.length;
      const usLastIndex   = myIndex + usCount -1;

      if(offset === -1){    //up
        const before    = items.slice(0, otherIndex);
        const after     = items.slice(usLastIndex + 1);
        const mes       = us.map(itm => { return {...itm, ordinal: itm.ordinal - themCount}});
        const theys     = them.map(itm => {return {...itm, ordinal: itm.ordinal + usCount}});
        return [...before, ...mes, ...theys, ...after];
      }
      else{
        const before    = items.slice(0, myIndex);
        const after     = items.slice(themLastIndex + 1);
        const mes       = us.map(itm => { return {...itm, ordinal: itm.ordinal + themCount}});
        const theys     = them.map(itm => {return {...itm, ordinal: itm.ordinal - usCount}});
        return [...before, ...theys, ...mes, ...after];
      }
    }
  }
  else{
    const otherIndex  = myIndex + offset;
    let displaced     = items[otherIndex];
    if(!displaced || isSection(displaced)){
        return null;   //can't move outside our own section right now...
    }
    else{
      const currOrdinal   = item.ordinal;
      const otherIndex    = items.indexOf(displaced);
      const me            = {...item, ordinal: displaced.ordinal};
      const other         = {...displaced, ordinal: currOrdinal};

        if(offset === 1){ //down
            const before    = items.slice(0, myIndex);
            const after     = items.slice(otherIndex + 1);
            return [...before, other, me, ...after];

        }
        else{ //up
            const before    = items.slice(0, otherIndex);
            const after     = items.slice(myIndex + 1);
            return [...before, me, other, ...after];
        }
    }
  }
}

export function doInsertItem(items: CheckListItem[], item: CheckListItem, refId?: number, offset?: number){
  const minIdItem   = minBy(items, i => i.id);
  if(!minIdItem) return null;
  const minId       = minIdItem.id;
  const nextId      = (minId >= 0) ? -1 : minId - 1;
  let toInsert      = {...item, id: nextId};

  if(!refId){
    if(isSection(item)){
      //Add to the bottom of the list
      const lastItem  = maxBy(items, i => i.ordinal);
      if(!lastItem) throw new Error("Invalid state - no last item found");
      toInsert        = {...toInsert, ordinal:  lastItem.ordinal + 1};
      // insertIndex     = items.length;
      return [...items, toInsert];
    }
    else{
      if(item.parentId !== null && item.parentId !== undefined){
        //Add to the bottom of the section...
        const section     = items.find(i => i.id === item.parentId);
        const secItems    = orderBy(items.filter(i => i.parentId === item.parentId), ["ordinal"]);
        const otherItem   = (secItems && secItems.length > 0) ? last(secItems) : section;
        if(!otherItem) throw new Error("Invalid state - no other item found");
        const otherIndex  = items.indexOf(otherItem);
        toInsert          = {...toInsert, ordinal: otherItem.ordinal + 1};
        const before  = items.slice(0, otherIndex + 1);
        const after   = items.slice(otherIndex + 1).map(itm => { return {...itm, ordinal: itm.ordinal + 1}});
        return [...before, toInsert, ...after];
  
      }
      else{
        return null;    //invalid, need to insert an assertion after a current insertion
      }
    }
  }
  else{
    if(!offset) offset = -1;

    if(isSection(item)){
      const otherItem   = getSectionToInsertBefore(items, item, refId, offset);      
      if(!otherItem){ //get the next section, and insert it before that one...        
        //We're adding after the last section, so just add it to the end of the list
        const maxItem = maxBy(items, i => i.ordinal);
        if(!maxItem) throw new Error("Invalid state - no max item found");
        const ordinal   = maxItem.ordinal;
        toInsert        = {...toInsert, ordinal: ordinal + 1};
        return [...items, toInsert];
      }
    
      const otherIndex  = items.indexOf(otherItem);
      const before      = items.slice(0, otherIndex);
      toInsert          = {...toInsert, ordinal: otherItem.ordinal};
      const after       = items.slice(otherIndex).map(itm => {return {...itm, ordinal: itm.ordinal + 1}});
      return [...before, toInsert, ...after];
    }
    else{
      const otherItem   = getAssertionToInsertAfter(items, item, refId, offset);
      if(!otherItem) throw new Error("Invalid state - other item not found");
      const otherIndex  = items.indexOf(otherItem);
      toInsert          = {...toInsert, ordinal: otherItem.ordinal + 1};
      const before  = items.slice(0, otherIndex + 1);
      const after   = items.slice(otherIndex + 1).map(itm => { return {...itm, ordinal: itm.ordinal + 1}});
      return [...before, toInsert, ...after];
    }
  }
}

//---
// gets the item that we're going to insert the new item BEFORE
function getSectionToInsertBefore(items: CheckListItem[], item: CheckListItem, refId: number, offset: number){
  let otherItem   = items.find(i => i.id === refId);
  if(!otherItem) throw new Error("Invalid state - other item not found");
  if(isSection(item)){  // Insert Section    
    if(isSection(otherItem)){
      if(offset === -1){
        // Insert before a Section
        return otherItem;
      }
      else{
        // Insert after a section
        // get the next section, so we can insert before it
        const sections    = items.filter(isSection);
        const otherIndex  = sections.indexOf(otherItem);
        return otherIndex >= sections.length - 1 ? null : sections[otherIndex + 1];
      }
    }
    else{
      // Insert before an Item
      // Insert after an item
    }    
  }
}

// NOTE: if returning a section, means insert at the beginning of the section
function getAssertionToInsertAfter(items: CheckListItem[], item: CheckListItem, refId: number, offset: number){
  let otherItem   = items.find(i => i.id === refId);
  if(!otherItem) throw new Error("Invalid state - other item not found");

  if(!isSection(item)){ //Insert Assertion
    const secItems  = items.filter(i => i.parentId === item.parentId);        
    const section   = items.find(i => i.id === item.parentId);
    if(isSection(otherItem)){
      if(offset === 1){
        //insert assertion at the end of the section, so return last item
        return secItems[secItems.length - 1]; 
      }
      else{
        //insert assertion at the beginning of the section
        return section;
      }
    }
    else{ //ref item is an assertion
      if(offset === 1){
        //Insert after Assertion
        return otherItem;
      }
      else{
        //before the assertion - get the previous item in this section (if there is one)
        if(secItems.length === 0) return section;  //just add it at the beginning of this section
        const secIndex  = secItems.indexOf(otherItem);
        if(secIndex === 0) return section;   //insert it at the beginning
        return secItems[secIndex - 1];
      }
    }
  }
}

//#endregion