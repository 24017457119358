import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { compact } from "lodash";
import { fetchDocThunk, respondToItemThunk } from "store/thunks/document-thunks";
import { CheckListItemEnhanced, CheckListItemRecord, CheckListDto, CheckListResponseDto } from "types/model-types";
import { prepareCheckList } from "utils/checklist-helpers";
import { addOrReplace } from "./slice-helpers";

interface CheckListItemSlice extends Record<string, any> {
  responses: CheckListResponseDto[];
  items: Record<string, CheckListItemEnhanced>;
}

const INITIAL_STATE: CheckListItemSlice = {
  responses: [],
  items: {},
};

const docFetched = (builder: ActionReducerMapBuilder<CheckListItemSlice>) => {
  builder.addCase(fetchDocThunk.fulfilled, (state, action) => {
    if(!action?.payload?.data) return;
    const doc = action.payload.data;
    const primaryRR = doc.reviewRecords?.find(rr => rr.primaryReviewRecordId === null);
    const responses = primaryRR?.checkListResponses ?? [];
    const checkLists = doc.checkLists.map(cl => prepareCheckList(cl) as CheckListDto);
    const checkListItems = compact(checkLists.flatMap(cl => cl.checkListItems?.map(cli => reduceCheckListItem(cli, responses))));

    //create a map of the checkListItems
    const checkListMap = checkListItems.reduce((acc, cli) => {
      acc[cli.id] = cli;
      return acc;
    }, {} as Record<string, any>);

    state.responses = responses;
    state.items = checkListMap;
  });
  builder.addCase(fetchDocThunk.rejected, (state, action) => {
    state.responses = [];
    state.items = {};
  });
};

const itemResponded = (builder: ActionReducerMapBuilder<CheckListItemSlice>) => {
  builder.addCase(respondToItemThunk.pending, (state, action) => {
    const { response } = action.meta.arg;
    const { checkListItemId, checkListInputId } = response;

    const existing = state.items[checkListItemId];
    const rsp = { id: checkListInputId, isWorking: true, error: null };

    state.items[checkListItemId] = {
      ...existing,
      isWorking: true,
      error: null,
      responding: addOrReplace(existing.responding, r => r.id === checkListInputId, rsp),
    };
  })
  .addCase(respondToItemThunk.fulfilled, (state, action) => {
    const { response: arg } = action.meta.arg;
    const { checkListItemId, checkListInputId } = arg;
    const existing = state.items[checkListItemId];

    let nextResponses = state.responses;
    if(arg.isDelete) nextResponses = state.responses.filter(rsp => rsp.id !== arg.id);
    else nextResponses = addOrReplace(state.responses, r => r.checkListInputId === checkListInputId, action.payload?.data);
    state.responses = nextResponses;
    
    const nextResponding = existing.responding.filter((r: any) => r.id !== checkListInputId);
    const nextItem = reduceCheckListItem(existing, nextResponses, nextResponding);
    state.items[checkListItemId] = nextItem;
    state.isWorking = false;
  })
  .addCase(respondToItemThunk.rejected, (state, action) => {
    const { response } = action.meta.arg;
    const { checkListItemId, checkListInputId } = response;
    const existing = state.items[checkListItemId];

    const rsp = { id: checkListInputId, isWorking: false, error: action.error };
    const nextItem = {
      ...existing,
      isWorking: false,
      error: action.error,
      responding: addOrReplace(existing.responding, r => r.id === checkListInputId, rsp),
    };

    state.items[checkListItemId] = nextItem;
  });
};

//=== Slice
export const checkListItemSlice = createSlice({
  name: "clitems",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    docFetched(builder);
    itemResponded(builder);
  }
});

export default checkListItemSlice.reducer;

//=== Helper Methods

function reduceCheckListItem(cli: CheckListItemRecord, responses?: CheckListResponseDto[], responding?: any[]){
  //get the inputs for this item
  const inputIds = cli.checkListInputs.map(inp => inp.id);
  //every item has a boolean input, so grab that one
  const boolInput = cli.checkListInputs.find(inp => inp.discriminator === "Bool");
  //get the responses for all inputs for this item
  const myResponses = compact(inputIds.map(id => responses?.find(rsp => rsp.checkListInputId === id)));
  //get the response for the boolean input
  const bResponse   = myResponses.find(rsp => rsp.checkListInputId === boolInput?.id);

  let item: CheckListItemEnhanced = {
    ...cli,
    responses : myResponses,
    primaryInput : boolInput,
    primaryResponse : bResponse,
    isWorking : false,
    error : null, 
    responding : responding || [],     
  };

  return item;
}