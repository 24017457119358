import React, { ReactNode } from "react";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { useIsMobile } from "hooks/general-hooks";
import CltHidden from "../clt-hidden";

export interface ITableSectionProps extends React.ComponentProps<"tr"> {
}

export const RsvTr = ({children, className, ...props}: ITableSectionProps) => {
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile && 
        <tr className={className} {...props}>
          {children}
        </tr>
      }
      {isMobile && 
        <Grid item container>
          <Card className={className} variant="outlined">
            {/* <CardContent>
              <Grid container direction="column"> */}
                {children}
              {/* </Grid>
            </CardContent> */}
          </Card>
        </Grid>
      }
    </>    
  );
}

///====
/// This is so that we can differentiate between the content and the
/// actions when in mobile view. In non-mobile view, it just returns the children.
export const RsvRowContainer = ({children}: {children: ReactNode}) => {
  return (
    <>
      <CltHidden smUp>
        <CardContent>
          <Grid container direction="column">
            {children}
          </Grid>
        </CardContent>
      </CltHidden>
      <CltHidden xsDown>
        {children}
      </CltHidden>
    </>
  );
}

export const RsvTd = ({children, className, ...props}: React.ComponentProps<"td">) => {
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile && 
        <td className={className} {...props}>
          {children}
        </td>
      }
      {isMobile && 
        <Grid item className={className} >
          {children}
        </Grid>
      }
    </>    
  );
}

export const RsvActionsCol = ({children, className, ...props}: React.ComponentProps<"td">) => {
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile && 
        <td className={className} {...props}>
          {children}
        </td>
      }
      {isMobile &&
        <CardActions className={className}>
          {children}
        </CardActions>
      }
    </>    
  );
}