import React, {Component, Fragment} from 'react';
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import {ai} from './telemetry-service';

/**
 * This Component provides telemetry with Azure App Insights
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
    state = {
        initialized: false
    };

    tryToInitialize(props, state){
        const {history} = props;
        const {initialized} = state;
        const AppInsightsInstrumentationKey = props.instrumentationKey; // PUT YOUR KEY HERE
        if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
            ai.initialize(AppInsightsInstrumentationKey, history);
            console.log(`Telemetry has been initialized with key: ${AppInsightsInstrumentationKey.slice(0, 6)}...`);
            return true;
        }

        return false;
    }

    componentDidMount() {
        const isInitialized = this.tryToInitialize(this.props, this.state);        
        if(isInitialized){
            this.setState({initialized: true});
        }
        // this.props.after();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(!this.state.isInitialized){
            const isInitialized = this.tryToInitialize(this.props, this.state);
            if(isInitialized){
                this.setState({initialized: true});
            }
        }
    }

    render() {
      const {children} = this.props;
      return (
        <>
        	{children}
        </>
      );
    }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);