import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SchoolIcon from '@mui/icons-material/School';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useHover } from 'hooks';
import { PropertiesGrid } from 'features/common';

const EngagementIcon = ({engagement}) => {

  const { classes, cx }   = buildStyles();
  const [anchor, isHovering, onEnter, onExit, position]   = useHover("bottomLeft"); //for tracking the hover icon

  const properties  = [
    { label: "Id:", value: engagement.id },
    { label: "Client:", value: engagement.client.name },
    { label: "Project:", value: engagement.project.title },
    { label: "Protocol:", value: engagement.protocol.name },
    { label: "Site:", value: engagement.site.name },
  ];

  // const isOpen  = Boolean(engagement.id === 24);

  return <>
    <Grid item className={cx(classes.iconCircle, {"training" : engagement.isTraining, "archived" : engagement.isArchived})} container alignItems="center" justifyContent="center">
      <Icon engagement={engagement} className={classes.icon} onMouseEnter={onEnter} onMouseLeave={onExit} />
    </Grid>
    <Popover anchorEl={anchor} open={isHovering} onClose={onExit} {...position} className={classes.tip} classes={{paper: classes.tipPaper}} disableRestoreFocus>
      <Grid container direction="column">
        <Grid item container className={cx(classes.popHeader, {"training" : engagement.isTraining, "archived" : engagement.isArchived})}>
            <Icon engagement={engagement} className={classes.tipIcon}/>
            <Typography className={classes.popHeaderText}>
              {engagement.name}
              {engagement.isArchived && " (Archived)"}
            </Typography>
        </Grid>
        <Grid item container className={classes.popContent}>
          <PropertiesGrid props={properties} />
        </Grid>
      </Grid>
    </Popover>
  </>;
}

export default EngagementIcon;

const buildStyles   = makeStyles()(theme => ({
  iconCircle  : {
    borderRadius  : "50%",
    width         : 32,
    height        : 32,
    background    : theme.palette.primary.light, //`${theme.palette.primary.main}44`, //`
    color         : theme.palette.primary.contrastText, //theme.palette.primary.dark, //theme.palette.secondary.main, 
    "&.training"  : {
      background    : theme.statuses.training.color[200], //`${theme.palette.primary.main}44`, //`
      color         : theme.palette.grey[200], //theme.palette.primary.dark, //theme.palette.secondary.main, 
    },
    "&.archived"  : {
      background    : theme.statuses.archived.color[200], //`${theme.palette.primary.main}44`, //`
      // color         : theme.palette.grey[200], //theme.palette.primary.dark, //theme.palette.secondary.main, 
    },
  },
  icon  : {
    fontSize    : 18,
    fontWeight  : 400,
  },
  tip   : {
    pointerEvents   : "none",
  },
  tipPaper     : {
    minWidth        : 300,
  },
  tipIcon   : {
    marginRight   : theme.spacing(1),
    marginTop     : theme.spacing(-0.5),
  },
  popHeader     : {
    padding     : theme.spacing(1),
    background      : theme.palette.primary.main, //`${theme.palette.primary.main}44`, //`
    color           : theme.palette.common.white, //grey[700],
    borderBottom    : `1px solid ${theme.palette.grey[200]}`, //`
    "&.training"  : {
      background    : theme.statuses.training.color[200], //`${theme.palette.primary.main}44`, //`
      color         : theme.palette.grey[700], //theme.palette.primary.dark, //theme.palette.secondary.main, 
    },
    "&.archived"  : {
      background    : theme.statuses.archived.color[200], //`${theme.palette.primary.main}44`, //`
    },
  },
  popHeaderText   : {
    fontSize    : 15,
    fontWeight  : 600,
  },
  popContent    : {
    padding         : theme.spacing(1),
    // maxWidth        : 400,
  },
}));

///===========
/// The actual icon for this Engagement, based on the type
function Icon({engagement, ...otherProps}){
  const isTraining  = engagement.isTraining;
  const isArchived  = engagement.isArchived;

  return (
    <>
      {isTraining && !isArchived && <SchoolIcon {...otherProps} /> }
      {isArchived && <ArchiveIcon {...otherProps} /> }
      {!isTraining && !isArchived && <FolderOpenIcon {...otherProps} /> }
    </>
  );
}