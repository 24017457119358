import Apis from "store/api";
import { createAppAsyncThunk, parseResponse } from "store/slices/slice-helpers";
import { ApiResponseAction } from "app-types";

export const METRICS_THUNKS = {
  // engagement: "metrics/engagement/fetch",
  user: "metrics/user/fetch",
  setAndDoc: "metrics/setAndDoc/fetch",
};

// export const fetchEngagementMetricsThunk = createAppAsyncThunk<ApiResponseAction<any>>(METRICS_THUNKS.engagement, async (params: any, thunkAPI) => {
//   const { id } = params;
//   const result = await Apis.Metrics.get<any>(`/engagement/${id}/rollup`);
//   const response = parseResponse(METRICS_THUNKS.engagement, result);
//   return response;
// });

export const fetchUserMetricsThunk = createAppAsyncThunk<ApiResponseAction<any>>(METRICS_THUNKS.user, async (params: any, thunkAPI) => {
  const result = await Apis.Metrics.get<any>(`/user/recentactivity`);
  const response = parseResponse(METRICS_THUNKS.user, result);
  return response;
});

export const fetchSetAndDocMetricsThunk = createAppAsyncThunk(METRICS_THUNKS.setAndDoc, async (params: any, thunkAPI) => {
  const { id } = params;
  const setsPromise = Apis.Metrics.get<any>(`/engagement/${id}/reviewsetcounts`);
  const docsPromise = Apis.Metrics.get<any>(`/engagement/${id}/documentcounts`);
  const [setsResult, docsResult] = await Promise.all([setsPromise, docsPromise]);

  if(setsResult?.ok && docsResult?.ok){
    const payload = {
      id: id,
      set: setsResult.data,
      doc: docsResult.data,
    };

    return payload;
  }
  else {
    const failure = !setsResult?.ok ? setsResult : docsResult;
    throw failure;
  }
});