import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { Pagination } from './';
import Pagination2 from "./pagination";
import CltHidden from './clt-hidden';

const pageSizes = [10, 25, 50, 100];

const ListFooter = ({pagination, onChangePage, isWorking}) => {
  const { classes }   = buildStyles();
  if(!pagination || isWorking) return null;

  const myVal     = pagination.ItemsPerPage > 100 ? 100 : pagination.ItemsPerPage;  //to deal with cases where we load everything...

  return (
    <Grid id="list-footer" container className={classes.footerRoot}>
      <Grid item xs={8} container alignItems="center" className={classes.leftCol}>
        <Typography className={classes.footerText}>Page size:</Typography>

        <Select id="pageSize" value={myVal} onChange={e => onChangePage(1, e.target.value)} className={classes.pageSizeSelect} classes={{root: classes.selectRoot}}>
          {pageSizes.map(size => <MenuItem key={size} value={size} className={classes.menuItem}>{size}</MenuItem>)}
        </Select>

        <CltHidden smDown>
          <Typography className={classes.footerSubText}>{formatPagination(pagination)}</Typography>
        </CltHidden>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end" sx={{pr: 1}}>
        {/* <Pagination info={pagination} onChangePage={onChangePage} isDisabled={isWorking} />  */}
        <Pagination2 pagination={pagination} onChangePage={onChangePage} isDisabled={isWorking} />
      </Grid>
      
      <CltHidden smUp>
        <Grid item xs={12} container justifyContent="center">
          <Typography className={classes.footerSubText}>{formatPagination(pagination)}</Typography>
        </Grid>
      </CltHidden>

    </Grid>
  );
}

export default ListFooter;

const buildStyles   = makeStyles()(theme => ({
  footerRoot  : {
    margin    : `${theme.spacing(2)} -${theme.spacing(2)} -${theme.spacing(3)}`, //`
    padding   : theme.spacing(1),
    paddingRight  : theme.spacing(2),
    width     : "104%",
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: theme.spacing(0.5),
      paddingRight: 0,
      paddingLeft: "16px",
      width: "108%",
    }
  },
  pageSizeSelect  : {
    marginLeft    : theme.spacing(0.5),
    marginRight   : theme.spacing(0.5),
    paddingLeft: 2,
    fontSize    : 14,
    fontWeight  : 400,
    color       : theme.palette.grey[700],
    border      : `1px solid transparent`,
    borderRadius: "4px",         
    "&.MuiInput-underline:after" :{
        borderBottom: "none",
    },
    "&.MuiInput-underline:before" :{
        borderBottom: "none",
    },
    "&.MuiInput-underline:hover": {
        background  : theme.palette.grey[100],
        borderColor : theme.palette.grey[300], //`
        "&.MuiInput-underline:after" :{
            borderBottom: "none",
        },
        "&.MuiInput-underline:before" :{
            borderBottom: "none",
        },
    },
    "& svg"   : {
      marginTop   : -2,
      marginLeft  : theme.spacing(0.5),
      width       : theme.spacing(3),
    } 
  },
  selectRoot  : {
    marginLeft    : theme.spacing(0.5),
  },
  footerText  : {    
    fontSize    : 14,
    fontWeight  : 400,
    display     : "inline-block",
    color       : theme.palette.grey[500],
  },
  footerSubText   : {
    fontSize    : 14,
    display     : "inline-block",
    color       : theme.palette.grey[500],
    marginLeft   : theme.spacing(1),
  },
  menuItem  : {    
    fontSize    : 14,
    fontWeight  : 400,
    color       : theme.palette.grey[700],
  },    
}));

function formatPagination(pagination){
  if(!pagination) return null;
  const { ItemsPerPage, TotalItems, CurrentPage, TotalPages }   = pagination;
  const startItem     = ((CurrentPage - 1) * ItemsPerPage) + 1;
  const endItem       = (startItem - 1) + ItemsPerPage;
  const start         = startItem ? startItem.toLocaleString() : '';
  const end           = endItem > TotalItems ? TotalItems.toLocaleString() : endItem.toLocaleString();

  const pageInfo      = `Showing ${start} to ${end} of ${TotalItems.toLocaleString()} (Page ${CurrentPage.toLocaleString()} of ${TotalPages.toLocaleString()})`; //`
  return pageInfo;    
}