import React, { ReactNode } from "react";
import { IconProps, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { usePopover } from "hooks/use-popover";
import { MenuOption } from "./menu-button";

export interface IMenuIconOptions extends Omit<IconProps, "onClick"> {
  icon: ReactNode;
  options: MenuOption[];
}

const MenuIcon = ({icon, options, ...iconProps}: IMenuIconOptions) => {

  const {openPopover, closePopover, popoverProps} = usePopover();

  const itemClicked = (option: MenuOption) => async () => {
    await option.onClick();
    closePopover();
  }

  const Icon = icon as any;

  return (
    <>
      <Icon onClick={openPopover} {...iconProps} />
      <Menu {...popoverProps}>
        <MenuList>
          {options.map(opt => 
            <MenuItem key={opt.title} onClick={itemClicked(opt)}>
              {opt.icon && (!opt.iconPosition || opt.iconPosition === "start") && <ListItemIcon>{opt.icon}</ListItemIcon>}
              <ListItemText>{opt.title}</ListItemText>
              {opt.icon && opt.iconPosition === "end" && <ListItemIcon>{opt.icon}</ListItemIcon>}
            </MenuItem>)
          }
        </MenuList>
      </Menu>
    </>
  );
};

export default MenuIcon;