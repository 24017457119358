import { isObject } from "lodash";
import { INormalizedError } from "./core-types";

//===
// An easy way to run a map over the entries in an object. From: https://stackoverflow.com/questions/14810506/map-function-for-objects-instead-of-arrays
export const objectMap = (obj: {}, fn: (v: any, k: string, i: number) => any) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  );

  //===
  // Determines if a value has an actual value - is not nullish, and isn't an empty array
  export const hasValue = (value: any) => {
    if(!!value){
      if(Array.isArray(value)) return value.length > 0;
      else if(isObject(value)) return Object.entries(value).length > 0;
      else return true;
    }
    else
      return (value === 0 || value === false);
  };

  //===
  // Removes all entries from an object that are falsy.
  export const prune = (obj: {}) => {
    let initValue = {} as Iterable<readonly [PropertyKey, any]>

    const result = Object.entries(obj).reduce(
      (result, [k, v]) => {
        if(hasValue(v)) return {...result, [k]: v };
        else return result;
      }, 
      initValue
    );

    return result;
  };

//===
// Compares two ojects and returns true if they are shallowly equal
export const shallowEquals = (a : Record<string, any>, b : Record<string, any>) => {
  for(var key in a) {
    if(!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for(var key2 in b) {
    if(!(key2 in a) || a[key2] !== b[key2]) {
      return false;
    }
  }
  return true;
};


//===
/// Attempts to normalize an error so that it can be displayed in a consistent way.
export function normalizeError(result: any, defaultStatus: string, defaultProblem: string) : INormalizedError
{
  const error  = result.value?.error || result;
  if(error.data?.validation){
    return {
      status    : defaultStatus || "Error",
      problem   : defaultProblem || "See the error details below",
      data      : error.data.validation[0].split(";"),
    };
  }
  else if(error.message){
    return {
      status    : defaultStatus || "Error",
      problem   : defaultProblem || "See the error details below",
      data      : error.message,
    };
  }
  else if(!error.data && error.originalError){
    return {
      status    : error.status ?? defaultStatus ?? "Error",
      problem   : error.problem ?? defaultProblem ?? "See the error details below",
      data      : error.originalError.message,
    };
  }
  else{
    console.warn("failed to normalize an unrecognized error: ", result);
    return error;    //not sure...
  }
}