import React, { useEffect, useMemo, useState } from 'react';
import { IPagination } from 'app-types';
import Grid from "@mui/material/Unstable_Grid2";
import { IconButton, TextField } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { tryParseInt } from 'utils/general-helpers';
import { useTheme, Theme } from '@mui/material/styles';
import { hasValue } from 'utils/general-helpers-ts';

export interface IPaginationControlProps {
  pagination: IPagination;
  onChangePage: (nextPage: number) => void;
  isDisabled?: boolean;
}

const Pagination = ({pagination, onChangePage, isDisabled}: IPaginationControlProps) => {
  const theme = useTheme();
  const { CurrentPage, ItemsPerPage, TotalPages, TotalItems } = pagination ?? {};

  //Determine which buttons are enabled
  const isEnabled = useMemo(() => {
    return {
      previous: !isDisabled && CurrentPage > 1,
      next: !isDisabled && CurrentPage < TotalPages,      
    };
  }, [isDisabled, CurrentPage, TotalPages]);

  const [displayValue, setDisplayValue] = useState(CurrentPage ?? ".");

  //Determine the tooltip for the input
  const ttipBtn = useMemo(() => {
    const startItem = ((CurrentPage - 1) * ItemsPerPage) + 1;
    const endItem = (startItem - 1) + ItemsPerPage;

    return ItemsPerPage ? 
      `Showing ${startItem} to ${endItem > TotalItems ? TotalItems : endItem} of ${TotalItems}` : 
      "Loading..."
  }, [CurrentPage, ItemsPerPage, TotalItems]);
  
  //And the tooltip for the buttons
  const ttipInput = useMemo(() => 
    CurrentPage ? 
      `Showing page ${CurrentPage} of ${TotalPages}` : 
      "Loading...", 
  [CurrentPage, TotalPages]);

  //Monitor for changes, update the value in the input
  useEffect(() => {
    setDisplayValue(CurrentPage ?? ".");
  }, [CurrentPage]);

  //event handlers
  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === "Enter"){
      onBlur();
    }
    else if(e.key === "Escape"){
      setDisplayValue(CurrentPage);
    }
  }
  
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextVal = e.target.value;
    const nextIntVal = tryParseInt(nextVal, -1);
    if(nextIntVal >= 1 && nextIntVal <= (TotalPages ?? 1)){
      setDisplayValue(nextIntVal);
    }
  }
  
  const onArrowClick = (change: number) => () => {
    if(onChangePage) onChangePage(CurrentPage + change);
  }
  
  const onBlur = () => {
    const nextPage = tryParseInt(displayValue, -1);
    if(nextPage >= 1 && nextPage !== CurrentPage && nextPage <= TotalPages){
      onChangePage(nextPage);
    }
    else{
      setDisplayValue(CurrentPage ?? ".");
    }
  }

  if(!hasValue(CurrentPage)) return null;

  return (
    <Grid container alignItems="center" columnSpacing={1}>
      <IconButton size="small" disabled={!isEnabled.previous || isDisabled} onClick={onArrowClick(-1)} title={ttipBtn} >
          <ArrowBack fontSize="small" sx={{fontSize: 14}}/>
      </IconButton>
      <TextField 
        size="small" 
        margin="dense"
        variant="filled"
        sx={textStyle(theme)}        
        disabled={isDisabled} 
        value={displayValue} 
        onChange={onInputChange} 
        onKeyUp={onKeyUp} 
        onBlur={onBlur} 
        title={ttipInput} />
      <IconButton size="small" disabled={!isEnabled.next || isDisabled} onClick={onArrowClick(1)} title={ttipBtn}>
        <ArrowForward fontSize="small" sx={{fontSize: 14}}/>
      </IconButton>
    </Grid>
  );
}

export default Pagination;

const textStyle = (theme: Theme) => ({
  my: 0,
  width: 35,
  "& .MuiFilledInput-root:before": {
    borderWidth: 0,
  },
  "& .MuiInputBase-root": {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[700],
    borderBottom: 0,
    "& .MuiInputBase-input": {
      p: 0.5,
      textAlign: "center",
      bgcolor: "unset !important",
    }
  }
});
