import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { HeartBroken, Favorite } from "@mui/icons-material";
import { useInterval } from "usehooks-ts";
import { getQuantaApi } from "utils/action-helpers";

const HB_INTERVAL_GOOD = 30000; //while things look good, check every 30 seconds
const HB_INTERVAL_BAD = 15000; //while things aren't good, check every 15 seconds
type HeartbeatStatus = "success" | "error" | "checking";

const ReportQueueHeartbeat = () => {
  const [status, setStatus] = useState<HeartbeatStatus>("checking");
  const [interval, setInterval] = useState<number | null>(null);
  const api = getQuantaApi();

  //Function to do the heartbeat check on the report queue
  const checkHeartbeat = useCallback(async () => {
    // setStatus("checking");
    const path = "/hb";
    const rsp  = await api.ReportQueue.get(path)
    if(rsp.ok){
      setStatus("success");
      setInterval(HB_INTERVAL_GOOD);
    }
    else{
      setStatus("error");
      setInterval(HB_INTERVAL_BAD); //re-check more frequently if there's a problem
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Effect to check when we first start
  useEffect(() => {
    checkHeartbeat();
  }, [checkHeartbeat]);

  //Interval to periodically re-check the status
  useInterval(async () => {
    checkHeartbeat();
  }, interval);

  return (
    <>
      {status === "checking" && (
        <CircularProgress color="primary" sx={{height: "35px !important", width: "35px !important"}} />
      )}
      {status === "success" && (
        <Tooltip title="The Report Queue Azure Functions are available.">
          <Favorite color="success" />
        </Tooltip>
      )}
      {status === "error" && (
        <Tooltip title="The Report Queue Azure Functions are not responding.">
          <HeartBroken color="error" />
        </Tooltip>
      )}
    </>
  );
};

export default ReportQueueHeartbeat;