import React from 'react';
import _ from 'lodash';
import { isSection } from 'utils/checklist-helpers';
import { getSetStatusRecord } from 'utils/reviewset-helpers';
import { formatDate } from 'utils/general-helpers';
import { DATE_ONLY_REPORT_FORMAT } from 'utils/date-helpers';

//-----------------------------
// This module handles Report Versioning for now
// since we need to be very specific about backward-compatibility with
// reports, I have a qReportVersion property in the PropertyBag of the
// ReviewSetStatus, and use that to specify which report version to use.  This
// gets set when the RS is approved, so that the report version is determined
// at that point.  From that, we determine what rendering methods to use when
// rendering the report.
//-----------------------------

//NOTE: This needs to be kept in sync with the server version - which is in Quanta.UI ReviewSetHelper.cs.
// The value is assigned to the reviewset property bag by the server, not by the client.
const CURRENT_VERSION   = '2';

const renderMap = {
    '0' : { item   : GetItem_v0 },
    '1' : { item   : getItem_v1 },
    '2' : { item   : getItem_v1 }
};

const getRenderMap = function(versionNumber){
    return renderMap[versionNumber];
};

///----------------
/// Gets the report version fo this ReviewSet so we know how to properly
/// render this report.
///----------------
const getReportVersion = (set) => {
    let reportVer       = '0';
    const approval      = getSetStatusRecord(set);
    //If this set is Approved with a specific version of the report, use that version
  
    if(!approval){
        //Not approved yet, so use the current version of the report
        reportVer       = CURRENT_VERSION;
    }
    else if(approval && approval.propertyBag.qReportVersion){
        //If approved but no version specified, means version 0... so let it fall through.
        reportVer       = approval.propertyBag.qReportVersion;
    }
  
    return reportVer;
  }

//-------------------------------------------
//          VERSION 2   4/5/2022
//-------------------------------------------
// Document Header component changed => see document-report-header.js component.

//-------------------------------------------
//          VERSION 1   12/7/2017
//-------------------------------------------
function getItem_v1(item, responses, protocol){
    if(isSection(item)){
        return renderSection_v1(item);
    }

    const confirmText   = protocol.confirmLabel || (protocol.propertyBag ? protocol.propertyBag.qConfirmLabel : null) || 'Confirm';
    const rejectText    = protocol.rejectLabel || (protocol.propertyBag ? protocol.propertyBag.qRejectLabel : null) ||'Reject';
    const pInput        = _.find(item.checkListInputs, {discriminator: 'Bool'});
    const pResponse     = _.find(responses, {checkListInputId: pInput.id});
    const answer        = pResponse ? (pResponse.notApplicable ? 'NA' : (pResponse.boolResponse ? confirmText : rejectText)) : null;

    const date          = pResponse ? formatDate(pResponse.responseDate, DATE_ONLY_REPORT_FORMAT) : null;
    const name          = pResponse ? pResponse.userName : null;

    //Sort inputs descending by discriminator so it will go Text, Option, Comment.
    const otherInputs   = _.reverse(_.sortBy(_.without(item.checkListInputs, [pInput]), inp => inp.discriminator));
    const otherInputNodes   = _.map(otherInputs, inp => getInputNode_v1(inp, responses));
    
    return (
      <tr key={item.key} className="assertion-row">
          <td>{item.key}</td>
          <td className="question-col">
              <span className="assertion-text">{item.assertionText}</span>
              {otherInputNodes}
          </td>
          <td className="answer-col">
            <span>{answer}</span>
        </td>
          <td className="by-col">
            <span>{name}</span>
        </td>
          <td className="date-col">
            <span>{date}</span>
          </td>
      </tr>
    );
}

function renderSection_v1(section){
    return (
        <tr key={section.key} className="section-row">
            <td>{section.key}</td>
            <td colSpan="4">{section.sectionName}</td>
        </tr>
    );
}

function getInputNode_v1(input, responses){
    let inputNode   = null;
    let response    = _.find(responses, {checkListInputId: input.id});

    switch(input.discriminator.toLowerCase()){
        case "comment":
            if(!response){
                return null;      //exit out here since we aren't showing this comment
            }

            inputNode    = (
                <div className="input-item"  key={input.id}>
                    <div className="row input-row">
                        <div className="col-sm-3">
                            <span className="input-caption">{input.caption || "Comment"}</span>
                        </div>
                        <div className="col-sm-9">
                            <span className="input-answer comment-answer">{response.textResponse}</span>
                        </div>
                    </div>
                </div>
            );
            break;

        case "option":
            if(response){
                inputNode   = (
                    <div className="input-item" key={input.id}>
                        <div className="row input-row">
                            <div className="col-sm-3">
                                <span className="input-caption">{input.caption || "Option"}</span>
                            </div>
                            <div className="col-sm-9">
                                <span className="input-answer options-answer">{response.textResponse}</span>
                            </div>
                        </div>
                    </div>
                );
            }
            break;

        case "text":
            inputNode    = (
                <div className="input-item"  key={input.id}>
                    <div className="row input-row">
                        <div className="col-sm-3">
                            <span className="input-caption">{input.caption || "Text"}</span>
                        </div>
                        <div className="col-sm-9">
                            <span className="input-answer text-answer">{response ? response.textResponse : null}</span>
                        </div>                            
                    </div>
                </div>
            );
        break;

    default: return null;
        
    }

    return inputNode;
}

//-------------------------------------------
//          VERSION 0
//-------------------------------------------
function GetItem_v0(item, responses, protocol){
    if(isSection(item)){
        return renderSection_v0(item);
    }

    const pInput        = _.find(item.checkListInputs, {discriminator: 'Bool'});
    const pResponse     = _.find(responses, {checkListInputId: pInput.id});
    const answer        = pResponse ? (pResponse.notApplicable ? 'NA' : (pResponse.boolResponse ? 'Confirm' : 'Reject')) : null;

    const date          = pResponse ? formatDate(pResponse.responseDate, DATE_ONLY_REPORT_FORMAT) : null;
    const name          = pResponse ? pResponse.userName : null;

    let text            = null;
    if(item.checkListInputs.length > 1){
        //There is also a text input element...
        const tInput    = _.find(item.checkListInputs, {discriminator: 'Text'});
        //tInput may be null if it is an input type unsupported in v0 (shouldn't happen)
        if(tInput){
            const tAnswer   = _.find(responses, {checkListInputId: tInput.id});
            text            = (<p className="text-answer">{tAnswer ? tAnswer.textResponse : null}</p>);
        }
    }

    return (
      <tr key={item.key} className="assertion-row">
          <td>{item.key}</td>
          <td className="question-col">
              {item.assertionText}
              {text}
          </td>
          <td className="answer-col">{answer}</td>
          <td>{name}</td>
          <td>{date}</td>
      </tr>
    );
}

function renderSection_v0(section){
    return (
        <tr key={section.key} className="section-row">
            <td>{section.key}</td>
            <td colSpan="4">{section.sectionName}</td>
        </tr>
    );
}

//-------------------------------------------
//          Exports
//-------------------------------------------
const partVersioner = {
    getReportVersion : getReportVersion,
    getRenderers    : getRenderMap,
    CURRENT_VERSION : CURRENT_VERSION
};

export default partVersioner;
