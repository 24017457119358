import { ErrorDisplay } from 'features/common';
import { Card, CardContent, Grid } from "@mui/material";
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectStatusByKey } from 'store/selectors/admin-selectors';

export interface IAdminCardProps {
  statusKey: string;
  children: ReactNode;
}

const AdminCard = ({statusKey, children}: IAdminCardProps) => {
  const selector = selectStatusByKey as any;
  const status      = useSelector(state => selector(state, statusKey));

  return (
    <Grid container id="admin-card">
      <Card sx={{width: "100%"}}>
        <CardContent>
          <>
            <ErrorDisplay error={status.error} />
            {children}
          </>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default AdminCard;