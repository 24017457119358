
const ERROR_CODES : Record<string | number, any>   = {
  404   : {
    code      : 404,
    title     : "Not Found",
    header    : "The item was not found",
    message   : "This could happen if you entered an invalid url, the item was removed, or you don't have permissions to view the item.",
    action    : "You may need to sign out and back in to continue."
  },
  401   : {
    code      : 401,
    title     : "Not Authorized",
    header    : "You are not authorized to perform this request.",
    message   : "This could happen if your login token expired, your password has expired or changed or your permissions have changed.",
    action    : "You may need to sign out and back in to continue."
  },
  "NETWORK_ERROR"   : {
    code      : null,
    title     : "Network Error",
    header    : "The system encountered a problem connecting to the server.",
    message   : "This could happen if there is a network problem, if you did not provide consent for this application, or if there was an issue with your token.",
    action    : "You may need to sign out and back in to continue."
  },
  "popup_window_error"   : {
    code      : null,
    title     : "Popup Window Error",
    header    : "Error opening popup window",
    message   : "This can happen if you are using IE or if popups are blocked in the browser. You need to grant permission for this website to show popup windows (we promise not to abuse this privilege).",
    action    : "Click the popup icon at the end of your browser address bar and allow popups for this site. Then refresh the page (F5)"
  }
};

//TODO: standardize the returned error so we can rely on it
// export interface StandardizedError {
//   code?: number;
//   title?: string;
//   header: string;
//   message: string;
// }

export function parseError(code: string, title: string, header: string, message: string, error: Record<string, any>) {
  if(code || error?.errorCode || error?.problem){
    const errCode   = code ? parseInt(code) : (error?.errorCode || error?.problem);
    const err       = ERROR_CODES[errCode];
    if(err){
      return err;
    }
  }  

  if(error){
    if(error.error) error = error.error;  //unwrap the contained error

    const errCode   = error.status;
    if(errCode){
      const err       = ERROR_CODES[errCode];
      if(err){
        return err;
      }
    }

    if(error.header && error.message){
      return {...error}; // as StandardizedError;
    }

    //Still here, so the code isn't one of the known ones...
    return {
      code      : errCode,
      title     : "",
      header    : error.problem || "An error occurred",
      message   : error.message || error.originalError?.message,
    }
  }
  else if(title || header || message){
    return {
      code      : -1,
      title     : title,
      header    : header || "An error occurred",
      message   : message || "We're sorry for this inconvenience.  Please select an option below to continue.",
    }
  }
  
}
