import { shallowEquals } from "utils/general-helpers-ts";
import { ItemSlice, PaginatedSlice } from "./slice-types";
import { AppDispatch, RootState } from './../store';
import { createAsyncThunk, GetThunkAPI } from "@reduxjs/toolkit";
import { ApiResponse, ApisauceInstance } from "apisauce";
import { ApiResponseAction, IIdentifiable } from "app-types";
import { AsyncThunkConfig } from "@reduxjs/toolkit/dist/createAsyncThunk";

const ITEMS_LIFETIME = 5 * 60 * 1000; //5 minutes

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  extra: any //{ s: string; n: number }
}>();

//===
// createApiThunk does the work of a standard thunk that calls the API.
export type RequestVerb = "get" | "post" | "put" | "delete";
export type ApiVerb<TParams> = RequestVerb | ((params: TParams) => RequestVerb);
export type ApiPath<TParams> = string | ((params: TParams) => string);
export type ApiParamsHandler<TParams> = undefined | null | Record<string, any> | ((params: TParams, thunkApi: GetThunkAPI<AsyncThunkConfig>) => any);
export type ApiHeadersHandler<TParams> = undefined | null | Record<string, any> | ((params: TParams, thunkApi: GetThunkAPI<AsyncThunkConfig>) => any);

export const createApiThunk = <TParams, TResult = any, TError = any>(
  thunkType: string, 
  api: ApisauceInstance, 
  verb: ApiVerb<TParams>, 
  path: ApiPath<TParams>,
  params?: ApiParamsHandler<TParams>,
  headers?: ApiHeadersHandler<TParams>,
  beforeApiCall?: (thunkParams: TParams, thunkAPI: GetThunkAPI<AsyncThunkConfig>, apiParams: any, apiHeaders: any) => Promise<void>,
  afterApiCall?: <TResult, TError>(response: ApiResponse<TResult, TError>, thunkParams: TParams, thunkAPI: GetThunkAPI<AsyncThunkConfig>, apiParams: any, apiHeaders: any) => Promise<void>) => {

  const thunk = createAppAsyncThunk(thunkType, async (thunkParams: TParams, thunkAPI) => {
    const apiVerb = typeof verb === "function" ? verb(thunkParams) : verb;
    const apiPath = typeof path === "function" ? path(thunkParams) : path;
    const apiParams = typeof params === "function" ? params(thunkParams, thunkAPI) : params;
    const apiHeaders = typeof headers === "function" ? headers(thunkParams, thunkAPI) : headers;

    if(beforeApiCall) await beforeApiCall(thunkParams, thunkAPI, apiParams, apiHeaders);

    const result: ApiResponse<TResult | TError> = await api[apiVerb]<TError, TResult>(apiPath, apiParams, apiHeaders);
    
    if(afterApiCall) await afterApiCall(result, thunkParams, thunkAPI, apiParams, apiHeaders);

    const parsed = parseResponse(thunkType, result, { ...params, filter: apiParams });
    
    return parsed;
  });
    
  return thunk;

};

export function parseResponse<T>(type: string, result: ApiResponse<T>, otherProps: Record<string, any> = {}){
  
  if(result.ok){    
    const pagination  = (result.headers && result.headers.pagination) ? JSON.parse(result.headers.pagination) : null;
    const { headers, params, url } = result.config ?? {};

    const response: ApiResponseAction<T> = {
      type,
      ok: result.ok,
      data: result.data,
      response: { ...result, config: { headers, params, url } },
      pagination,
      params: result.config?.params,
      ...otherProps,
    };

    return response;
  }
  else {
    console.error("Slice response error", result);
    throw result;
  }
}

export function haveItems<T extends IIdentifiable>(filter: any, pageNumber: number, pageSize: number, state: PaginatedSlice<T>){
  const { items, loadTime, lastFilter, pagination }  = state;
  if(items && loadTime && lastFilter){
    if(pageNumber === pagination.CurrentPage && pageSize === pagination.ItemsPerPage){
      if(shallowEquals(filter, lastFilter)){
        const diff = new Date().getTime() - loadTime;
        if(diff <= ITEMS_LIFETIME) return true;
      }
    }
  }

  return false;
}

export function haveItem<T extends IIdentifiable>(id: number, state: ItemSlice<T>){
  const { item, itemTime }  = state;
  if(item && itemTime){
    if(item.id === id){
      const diff = new Date().getTime() - itemTime;
      if(diff <= ITEMS_LIFETIME) return true;
    }
  }

  return false;
}

type Predicate<T> = (i: T) => boolean;

export function replaceAt<T = any>(array: T[], index: number, value: T){
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

export function replaceItem<T = any>(array: T[], item: T, value: T){
  const index = array.indexOf(item);
  if(index >= 0){
    return replaceAt<T>(array, index, value);
  }
  else{
    return array;
  }
}

export function addOrReplace<T = any>(array: T[], predicate: Predicate<T>, value: T){
  const index   = predicate ? array.findIndex(i => predicate(i)) : -1;
  if(index >= 0){
    return replaceAt<T>(array, index, value);
  }
  else{
    return [...array, value];
  }
}

export function removeItem<T = any>(array: T[], predicate: T | Predicate<T>){
  const isFunc = typeof predicate === "function";
  const pred = isFunc ? predicate as Predicate<T> : (i: T) => i === predicate;
  return array.filter(i => !pred(i));
}