import { useMemo, useState } from "react";
import { PopoverPlacement, PopoverPlacements } from "./hook-types";

export function usePopover(position: PopoverPlacement = "bottomRight"){
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const isPopoverOpen = useMemo(() => Boolean(anchor), [anchor]);
  const onOpen = (event: React.MouseEvent<HTMLElement>) => { setAnchor(event.currentTarget); };
  const onClose = () => setAnchor(null);
  const positionProps = PopoverPlacements[position];

  return {
    popoverAnchor: anchor,
    isPopoverOpen,
    openPopover: onOpen,
    closePopover: onClose,
    popoverProps: {
      anchorEl: anchor,
      open: isPopoverOpen,
      onClose: onClose,
      ...positionProps,      
    }
  };
}