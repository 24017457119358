import React, { useMemo } from "react";
import { TableCell, TableRow, Typography, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { MoreVert as MenuIcon } from "@mui/icons-material";
import { ErrorDisplay, ItemList } from "features/common";
import { useSelector } from "react-redux"
import { selectReportQueue } from "store/selectors/admin-selectors";
import { formatDate, getAge, getDateDistance } from "utils/date-helpers";
import { usePopover } from "hooks/use-popover";
import { useGridQueryString } from "hooks/general-hooks-ts";
import { IReportQueueRow } from "./admin-report-types";
// import { useDispatchWithApi } from "utils/action-helpers";
import { statusKeys, useStatus } from "store/api-middleware";
import { IPaginatedListProps } from "features/common/list";
import ReportQueueHeartbeat from "./admin-reports-heartbeat";
import ReportQueuePoke from "./admin-reports-poke";
import { deleteReportQueueThunk, fetchReportQueueThunk, retryReportThunk } from "store/thunks";
import { useAppDispatch } from "store";

const AdminReportsQueue = () => {
  // const dispatch = useDispatchWithApi();
  const dispatch = useAppDispatch();
  const status = useStatus(statusKeys.reportQueue);
  const queue = useSelector(selectReportQueue);
  const gridQsProps = useGridQueryString();
  // const [isPoking, setPoking] = useState(false);

  let items     = queue?.items;

  async function onRefresh(props: Record<string, any>, query: Record<string, any>){
    // const action = createLoadReportQueueAction(query, props?.pageNumber, props?.pageSize);
    await dispatch(fetchReportQueueThunk({ filter: query, pageNumber: props?.pageNumber, pageSize: props?.pageSize }));
  }

  async function onManualRefresh(props: IPaginatedListProps){
    // const action = createLoadReportQueueAction(props, props?.pageNumber, props?.pageSize);
    await dispatch(fetchReportQueueThunk({ filter: props, pageNumber: props.pageNumber, pageSize: props.pageSize }));
  }

  async function onRetry(queueId: number){
    // const action = createRetryReportQueueAction(queueId);
    const result: any = await dispatch(retryReportThunk({ rowId: queueId }));
    if(result.payload.ok){
      onRefresh(queue?.pagination, gridQsProps);
    }
  }

  async function onCancel(queueId: number){
    // const action = createDeleteReportQueueAction(queueId);
    const result: any = await dispatch(deleteReportQueueThunk({ rowId: queueId }));
    if(result.payload.ok){
      onRefresh(queue?.pagination, gridQsProps);
    }
  }
  

  const gridConfig  = {
    title     : (
      <Stack direction="row" columnGap={2} alignItems="center">
        <Typography variant="h5">Report Queue</Typography>
        <ReportQueueHeartbeat />
        <ReportQueuePoke />
      </Stack>),
    baseUrl   : "/admin/reports/queue",
    searchPlaceholder   : "Search by reviewset Id...",
    pageSizeKey : "admin-rptqueue-list",
    keyFunc   : (item: any) => item.id,
    filterOptions   : [
      { key: 'all',       label: 'All', isDefault: true, },
      { key: 'pending', label: 'Pending', },
      { key: 'failed',   label: 'Failed', },
      { key: 'poisoned', label: 'Poisoned', }
    ],
    actions   : {
      onRetry: (id: number) => onRetry(id),
      onCancel: (id: number) => onCancel(id),
    },
    cols  : GRID_HEADER,
  };

  return (
    <>
      {/* <Grid container sx={{mb: 2}}>
        <Button onClick={onGetNextItem}>Get Next Item</Button>
        <Button disabled={isPoking} onClick={onPoke}>Poke</Button>
      </Grid> */}
      {status.error && <ErrorDisplay error={status.error} /> }
      <ItemList 
        config={gridConfig} 
        items={items}        
        pagination={items?.pagination} 
        isWorking={status.isWorking}
        onRefresh={onRefresh}
        onSort={null}
        noToolbar={false}
        RowComponent={QueueListItem}
        onManualRefresh={onManualRefresh}
      />

      
    </>
  );
};

export default AdminReportsQueue;

interface IRowProps {
  item: IReportQueueRow;
  actions: any;
}

function QueueListItem({item, actions}: IRowProps){
  const { openPopover: openMenu, closePopover: closeMenu, popoverProps: menuProps } = usePopover("bottomRight");

  const status = useMemo(() => {
    return (item.status === "failed" && item.failCount >= 3) ? "poisoned" : item.status;
  }, [item.status, item.failCount]);

  const statusColor = useMemo(() => {
    switch(status){
      case "failed": return "warning";
      case "poisoned": return "error";
      case "processing": return "success";
      case "queued":
      default: return "info";
    }
  }, [status]);

  const age = getAge(item.queuedDate);
  const ageAgo = getDateDistance(item.queuedDate);
  const ageColor = (age > 480) ? statusChip("error", 0.25) : ((age > 180) ? statusChip("warning", 0.25) : {});
  const canRestart = ["processing", "poisoned"].indexOf(status) >= 0;
  const canCancel = status !== "processing";

  const menuClicked = (label: string) => async () => {
    switch(label){
      case "retry": 
        await actions.onRetry(item.id);
        break;
      case "cancel": 
        await actions.onCancel(item.id);
        break;
    }

    closeMenu();
  };

  return (
    <TableRow>
      <TableCell sx={{minWidth: "90px"}}>
        {formatDate(item.queuedDate)}
      </TableCell>
      <TableCell sx={{minWidth: "150px"}}>
        <Typography sx={{...ageColor}}>{ageAgo}</Typography>
      </TableCell>
      <TableCell >
        {item.reviewSetName ? `${item.reviewSetName} (${item.reviewSetId})` : item.reviewSetId}
      </TableCell>
      <TableCell>
        {item.engagementName ? `${item.engagementName} (${item.engagementId})` : item.engagementId}
      </TableCell>
      <TableCell>
        {item.reportType}
      </TableCell>
      <TableCell>
        {item.failCount}
      </TableCell>
      <TableCell>
        <Typography sx={statusChip(statusColor)}>
          {status}
        </Typography>
      </TableCell>
      <TableCell>
        {(canRestart || canCancel) && (
          <>
            <IconButton onClick={openMenu} title="Options">
              <MenuIcon />
            </IconButton>
            <Menu {...menuProps}>
              {canRestart && <MenuItem onClick={menuClicked("retry")}>Re-Start</MenuItem>}
              {/* {status === "poisoned" && <MenuItem onClick={menuClicked("retry")}>Re-Try</MenuItem>} */}
              {canCancel && <MenuItem onClick={menuClicked("cancel")}>Cancel</MenuItem>}
            </Menu>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

const GRID_HEADER   = [
  {
    id        : 0,
    sortKey   : "date",
    defaulTableCellDir: "asc",
    label     : "Queued On",
    style     : {minWidth: "90px"},
  },
  {
    id        : 15,
    label     : "Age",
  },
  {
    id        : 10,
    sortKey   : "reviewset",
    label     : "ReviewSet",
    style     : {minWidth: "150px"},    
  },
  {
    id        : 20,
    sortKey   : "engagement",
    label     : "Engagement",
  },
  {
    id        : 30,
    label     : "Type",
  },
  {
    id        : 40,
    label     : "Attempts",
  },
  {
    id        : 50,
    sortKey   : "status",
    label     : "Status",
  },
  {
    id  : 60,
    label: "",
  }
];

const statusChip = (statusColor: string, px: number = 1) => ({
  backgroundColor: `${statusColor}.light`, 
  borderColor: `${statusColor}.dark`, 
  color: `${statusColor}.contrastText`, 
  borderRadius: 3,
  border: "0.5px solid",
  display: "flex",
  justifyContent: "center",
  px: px,
  py: 0.25
});