import React, { useState } from 'react';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Grid, Card, CardContent, Button, Tooltip, TableRow, TableCell } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import NavMenu from 'features/app/nav-menu';
import { useAppParams } from 'hooks';
import { CltHidden, ErrorDisplay, ListToolbar, StatusCard } from 'features/common';
import CltTable, { CltTableCard } from "features/common/table";
import { reportsMenu, reportItems } from '../engagements/engagements-config';
import { useIsMobile } from 'hooks/general-hooks';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { downloadReportThunk } from 'store/thunks/report-thunks';
import { useAppDispatch } from 'store';

function EngagementReportsView(){
  const { classes, cx }       = buildStyles();
  const { pathname } = useLocation();
  const { engagementId }  = useAppParams();
  const status        = useSelector(state => state.reports.downloads);
  const dispatch      = useAppDispatch();
  const title         = `Eng ${engagementId} Reports`;

  const onRun = async (item) => {
    const url   = item.path.replace("{engagement_id}", engagementId);
    const report  = {
      ...item,
      url     : url,
    };

    try{
      // await dispatch(downloadReport(report));
      await dispatch(downloadReportThunk({ url: report.url }));
    }
    catch(ex){
      console.log("an error occurred", ex);
    }
  }

  return (
    <Grid id="engagement-reports-view" container spacing={2} direction="row" className={classes.reportsView}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <CltHidden smDown>
        <Grid id="reports-menu" item>
          <NavMenu settingsKey="engagement-nav" menu={reportsMenu} basePath={pathname} isWorking={status.isWorking} />                                                            
        </Grid>
      </CltHidden>

      <Grid id="reports-content" item className={classes.content}>
        <ErrorDisplay error={status.error}></ErrorDisplay>
        <Grid item container>
          <Card id="reports-list" className={cx("panel", classes.reportsList)}>
            <CardContent>
              <ListToolbar title="Engagement Reports" isWorking={status.isWorking} noSearch={true}/>
              <ReportsList items={reportItems} status={status} classes={classes} cx={cx} onRun={onRun} />              
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </Grid>
  );

}

export default EngagementReportsView;

const buildStyles   = makeStyles()(theme => ({
  reportsView   : {
    flexWrap  : "nowrap",
  },
  content : {
    width   : "100%",
  },
  reportsList : {
    width     : "100%",
  },
  reportsTable   : {
    fontSize  : "0.9rem",
  },
  reportRow : {
    
  },
  nameCol   : {
    verticalAlign : "middle !important",
    // width   : "calc(33% - 100px)",
    "& svg" : {
      paddingTop  : "5px",
      marginBottom : "-5px",
      marginLeft   : theme.spacing(1),
    }
  },
  descCol   : {
    // width   : "calc(67% - 100px)",
    verticalAlign: "inherit !important",
  },
  actionsCol  : {
    width   : 100,
  },
  title: {
    fontWeight: 600,
    fontSize: "1.2rem",
    marginBottom: theme.spacing(0.5),
  },
  tip: {
    fontSize: "0.9rem",    
  },
  buttonGrid: {
    marginTop: theme.spacing(1),
  }
}));

const REPORT_COLS = [
  {
    id: 0,
    sortKey: "name",
    label: "Name",
  },
  {
    id: 1,
    sortKey: "Description",  
    label: "Description",
  },
  {
    id: 2,
    label: "Actions",
    noSort: true,
  }
];

function ReportsList({classes, cx, items, status, onRun }){
  const navigate = useNavigate();
  const [reports, setReports]   = useState({ items: items, sort: ""});
  const isMobile = useIsMobile();

  const onSort = (sortStr) => {
    //TODO: could extract this sort url stuff into a hook...
    const url   = new URL(window.location.href);
    if(url.searchParams.has("sort") && !sortStr) 
        url.searchParams.delete("sort");
    else 
        url.searchParams.set("sort", sortStr);
    
    const relative  = url.href.replace(window.location.origin, ""); //need to remove the origin for sake of the history
    navigate(relative, { replace: true });
    
    const parts   = sortStr.split("-");
    const sorted  = _.orderBy(reports.items, i => i[parts[0]], [parts[1]]);
    setReports({items: sorted, sort: sortStr});
  }

  return (
    <CltTable
      cols={REPORT_COLS}
      onSort={onSort}
      isWorking={status.isWorking}
      className={cx("table table-striped", classes.reportsTable)}>
        {reports?.items.map(item => <ReportRow key={item.id} item={item} status={status} onRun={onRun} isMobile={isMobile} classes={classes} />)}
      </CltTable>
  );
}

const ReportRow = ({item, status, onRun, isMobile, classes}) => {

  return <>
    <CltHidden smDown>
      <TableRow key={item.id} className={classes.reporTableRowow}>
        <TableCell className={classes.nameCol}>
          {item.name}
          {item.tip && <Tooltip title={item.tip}><InfoIcon fontSize="small"/></Tooltip>}
        </TableCell>
        <TableCell className={classes.descCol}>
          {item.description}
        </TableCell>
        <TableCell className={classes.actionCol}>
          <Button
            size="small"
            variant="outlined"
            disabled={status.isWorking}
            onClick={() => onRun(item)}>Download</Button>
        </TableCell>
      </TableRow>
    </CltHidden>

    <CltHidden smUp>
      <CltTableCard>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6" className={classes.title}>{item.name}</Typography>
          </Grid>
          <Grid item>
            {item.description}
          </Grid>
          {item.tip && (
            <Grid item>
              <StatusCard status="info" isVisible={!!item.tip}>
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <InfoIcon fontSize="small"/>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography component="span" className={classes.tip}>{item.tip}</Typography>
                  </Grid>
                </Grid>
              </StatusCard>
            </Grid>
          )}
          <Grid item container justifyContent="flex-end" className={classes.buttonGrid}>
            <Button
              size="small"
              variant="outlined"
              disabled={status.isWorking}
              onClick={() => onRun(item)}>Download</Button>
          </Grid>
        </Grid>
      </CltTableCard>
    </CltHidden>
  </>;
};