import React from 'react';
import { createRoot } from "react-dom/client";
import { store } from "./store";
import App from './App';
import 'react-toastify/dist/ReactToastify.css';

const baseUrl     = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const render = () => {
  const root = createRoot(rootElement);
  root.render(<App store={store} baseUrl={baseUrl}/>);
};

//Render the app the first time through
render();

//Try to accept any HMR changes, and not re-render the whole app...
if(module.hot) {
    module.hot.accept('./App', () => {
        render();
    });
}