import React from 'react';
import { Loading } from 'features/common';

function ReviewSetReportTitleRow({set, isWorking}){

  const isApproved = !!set.approvedBy;

  return (
    <div className="row report-row-darker">
      <div className="col-sm-9" style={{paddingLeft: "0"}}>
          <h1 className="m-sm">ReviewSet: {set?.title}</h1>
      </div>
      <div className="col-sm-3">
          <div>
              <Loading isVisible={isWorking} message="Queueing Report..." />
              {/* Only show print button if the reviewset isn't approved. */}
              {!isApproved && <button className="btn btn-xs btn-default pull-right print-button" style={{marginTop: "4.5px"}} onClick={() => window.print()}>Print</button>}
          </div>
      </div>
    </div>
  );

}

export default ReviewSetReportTitleRow;