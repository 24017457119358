import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import SecondaryIcon from '@mui/icons-material/Lock';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { formatDate } from 'utils/general-helpers';
import { buildPath } from 'utils/route-helpers';
import { CltHidden, Status } from 'features/common';
import DocumentListItemMoving from "./document-list-item-moving";
import DocumentListItemDeleting from "./document-list-item-deleting";
import { useCurrentPermissions } from 'hooks';
import { TableCell, TableRow } from '@mui/material';
import { CltTableCardRaw } from 'features/common/table';

function DocumentListItem({doc, targetUrl, onDelete, onMove, isForEngagement}){
  const { classes }     = buildStyles();
  const [isDeleting, setIsDeleting]   = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const url         = buildPath([targetUrl, doc.id]);  
  const permissions = useCurrentPermissions();

  const rsList      = useMemo(() => {
    if(!isForEngagement || !doc.reviewRecords) return null;
    const primaryId = doc.primaryReviewRecordId;
    const primary = doc.reviewRecords.find(rr => rr.id === primaryId);
    const others = doc.reviewRecords.filter(rr => rr.id !== primaryId);
    const items = (
      <>
        <Tooltip title="This is the primary reviewset">
          <Link to={`/engagements/${isForEngagement}/reviewsets/${primary.reviewSetId}`} className={classes.primaryRr}>{primary.reviewSetId}</Link>
        </Tooltip>
        {others?.map(o => <Tooltip key={o.id} title="This is a secondary reviewset, so the document will be read-only here"><Link to={`/engagements/${isForEngagement}/reviewsets/${o.reviewSetId}`} key={o.id} className={classes.secondaryRr}>, {o.reviewSetId}</Link></Tooltip>)}
      </>
    );
    return items;

  }, [isForEngagement, doc, classes]);

  const toggleMove = async (targetValue) => {
    setIsMoving(targetValue);
  }

  //we have separate row components for delete and move workflow
  if(isDeleting) 
    return <DocumentListItemDeleting doc={doc} onDelete={onDelete} onCancel={() => setIsDeleting(false)} />;
  else if(isMoving) 
    return <DocumentListItemMoving doc={doc} onMove={onMove} onCancel={() => toggleMove(false)} />;

  return <>
    {/* For Tablet / Desktop */}
      <CltHidden smDown>
        <TableRow>
          <TableCell component="th" scope="row" sx={{fontWeight: "bold"}}>
            <Link to={url}>{doc.documentKey}</Link>
            {!isForEngagement && doc.primaryReviewRecordId !== null && <Tooltip title="This document belongs to a different ReviewSet and is read-only here."><SecondaryIcon fontSize="small" className={classes.secondary}/></Tooltip>}
          </TableCell>
          <TableCell className={classes.docCol}>{doc.documentTypeName}</TableCell>
          <TableCell className={classes.docCol}><Status code={doc.statusCode} /></TableCell>
          <TableCell className={classes.docCol}>{formatDate(doc.lastModifiedOn)} by {doc.lastModifiedBy}</TableCell>
          {isForEngagement && 
            <TableCell className={classes.docCol}>{rsList}</TableCell>
          }
          {onDelete && 
            <TableCell className={classes.docCol}>
              <IconButton
                aria-label="delete"
                className={classes.btn}
                title="delete document"
                onClick={() => setIsDeleting(true)}
                size="large">
                <DeleteIcon style={{fontSize: "1.25rem"}} />
              </IconButton>
              {permissions.isAdmin && 
                <IconButton
                  aria-label="delete"
                  className={classes.btn}
                  title="move document"
                  onClick={() => toggleMove(true)}
                  size="large">
                  <SubdirectoryArrowRightIcon style={{fontSize: "1.25rem"}} />
                </IconButton>}
            </TableCell>
          }
        </TableRow>
      </CltHidden>
      
      <CltHidden smUp>
        <CltTableCardRaw>
          <CardContent>
            <Grid container>
              <Grid item xs={12} container wrap="wrap" className={classes.cardTitle}>
                <Link to={url}>
                  {doc.documentKey}: {doc.documentTypeName}
                </Link>
              </Grid>
              <Grid item xs={12} className={classes.cardText}>
                <span><span className={classes.cardLabel}>modified</span> {formatDate(doc.lastModifiedOn)}</span><br/>
                <span><span className={classes.cardLabel}>by</span> {doc.lastModifiedBy}</span>
              </Grid>
              {isForEngagement && 
                <Grid item xs={12}>
                  <span className={classes.cardLabel}>Reviewset(s): </span>{rsList}
                </Grid>
              }
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Status code={doc.statusCode}/>
                {!isForEngagement && doc.primaryReviewRecordId !== null && <Tooltip title="This document belongs to a different ReviewSet and is read-only here."><SecondaryIcon className={classes.secondary}/></Tooltip>}
              </Grid>
              {onDelete && 
                <Grid item>
                  <IconButton
                    aria-label="delete"
                    className={classes.btn}
                    title="delete document"
                    onClick={() => setIsDeleting(true)}
                    size="large">
                    <DeleteIcon style={{fontSize: "1.25rem"}} />
                  </IconButton>
                  {permissions.isAdmin && 
                    <IconButton
                      aria-label="delete"
                      className={classes.btn}
                      title="move document"
                      onClick={() => toggleMove(true)}
                      size="large">
                      <SubdirectoryArrowRightIcon style={{fontSize: "1.25rem"}} />
                    </IconButton>}
                </Grid>
              }
            </Grid>
          </CardActions>
        </CltTableCardRaw>
      </CltHidden>
  </>;
}

export default DocumentListItem;

DocumentListItem.propTypes  = {
  doc         : PropTypes.object.isRequired,
  targetUrl   : PropTypes.string.isRequired,
  onDelete    : PropTypes.func,
  isDocList   : PropTypes.bool,
};

const buildStyles   = makeStyles()(theme => ({
  docCol      : {
    verticalAlign     : "middle !important",
  },
  btn   : {
    padding       : theme.spacing(0.5),
    marginRight   : theme.spacing(0.5),
  },
  secondary: {
    marginLeft: theme.spacing(0.5),
    verticalAlign: "inherit",
    fontSize: "1rem",
    color: theme.palette.grey[600],
    marginTop: theme.spacing(-0.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: theme.spacing(-0.5),
      fontSize: "1.5rem",
    }
  },
  primaryRr: {
    fontWeight: 700,
  },
  secondaryRr: {
    fontWeight: 500,
  },
  cardTitle: {
    paddingBottom: theme.spacing(0.5),
  },
  cardStatus: {
    paddingBottom: theme.spacing(0.5),
  },
  cardText: {
    fontSize: theme.typography.body2.fontSize,
    paddingBottom: theme.spacing(0.5),
  },
  cardLabel: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[400]
  },
  cardNotes: {
    fontSize: theme.typography.caption.fontSize,
  },
}));