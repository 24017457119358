import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useIsAuthed } from "utils/auth/auth-provider";
// import { useIsAuthenticated } from "@azure/msal-react";
import { addSearchParam } from "utils/route-helpers";
import { searchStringKeys } from "utils/route-helpers-ts";

export interface IProtectedRouteProps {
  children: ReactNode;
}

//----
// Used to secure a route, and re-direct the user to the Login page when 
// they're not logged in.
const ProtectedRoute = ({children}: IProtectedRouteProps) => {
  // const isAuthenticated = useIsAuthenticated();
  const isAuthed = useIsAuthed(); //considers the token from the url as well
  const { pathname, search } = useLocation();

  if(!isAuthed){ //!isAuthenticated){
    //Need to add the target path to the url so we know where to redirect them after login
    let searchStr = "";
    if(pathname !== ""){
      searchStr = `?${addSearchParam("", searchStringKeys.redirect, pathname + search)}`
    }
    const loginUrl = `/login${searchStr}`;

    return <Navigate to={loginUrl} replace/>;
  }

  return children;
};

export default ProtectedRoute;