export const isIE = () => {
  const ua      = window.navigator.userAgent;
  const msie    = ua.indexOf("MSIE ") > -1;
  const msie11  = ua.indexOf("Trident/") > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

//Get the redirect url from the current location
const redirectUrl   = window.location.origin;

const MSADL_BASE_CONFIG   = {
  auth  : {
    clientId                  : null,
    authority                 : "https://login.microsoftonline.com/common",
    validateAuthority         : true,
    postLogoutRedirectUri     : redirectUrl,
    navigateToLoginRequestUrl : false
  },
  cache : {
    cacheLocation             : "localStorage", //"sessionStorage",
    storeAuthStateInCookie    : isIE(),
  },
  system: {
    navigateFrameWait : 0,
    logger: {
        error     : console.error,
        errorPii  : console.error,
        info      : console.log,
        infoPii   : console.log,
        verbose   : console.log,
        verbosePii: console.log,
        warning   : console.warn,
        warningPii: console.warn,
        isPiiLoggingEnabled: () => false,
    }
  }
};

const AUTH_SCOPES  = {
  OPENID    : "openid",
  PROFILE   : "profile",
  USER_READ : "User.Read",
};

const AUTH_REQUESTS = {
  LOGIN   : {
    scopes  : [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE, AUTH_SCOPES.USER_READ],
  },
  //API Scope depends on the environment
  API     : (scopes) => {
    // console.log("auth redirect URL", redirectUrl);
    return {
      scopes        : [scopes],
      redirectUri   : redirectUrl,
    };
  },
}

//====================
// Exports

//------
// Initializes the auth configuration, and returns the config used to setup the MSADL UserAgentApplication
export function initMsalAuthConfig(config){
  const clientId    = config.msalAppId;
  const apiScope    = config.authApiScopes;
  
  //Make sure the environment is valid
  if(!clientId || !apiScope){
    return {
      isOk    : false,
      error   : {
        code      : null,
        title     : "Configuration Error",
        header    : "Authentication Configuration for the current environment is invalid",
        message   : "This issue may be caused by corrupt or invalid configuration.",
        action    : "Please contact the system administrator with this error information to correct the issue."
      },
    };
  }

  //Environment is good...
  const initConfig  = {
    ...MSADL_BASE_CONFIG,
    auth  : {
      ...MSADL_BASE_CONFIG.auth,
      clientId    : clientId,
    },
  };

  return {
    ok            : true,
    agentConfig   : initConfig,
    loginRequest  : AUTH_REQUESTS.LOGIN,
    apiRequest    : AUTH_REQUESTS.API(config.authApiScopes),
    error         : null,
  };
}