import { useSelector } from 'react-redux';
import { IAppState } from "app-types";
import { createSelector } from "reselect";
import { DEFAULT_STATUS, IStatusState } from "store/api-middleware/status-reducer";

//--- 
// A Selector to get the status for a specific key
const _getStatus = (state: IAppState, key: string) => state.status[key];
const selectStatus = createSelector(
  [_getStatus],
  (status) => { 
    return status || DEFAULT_STATUS; }
);

//---
// A Hook to easily get the status for a key
export const useStatus = (key: string) => {
  const value = useSelector<IStatusState>((state: any) => selectStatus(state, key));
  return value as IStatusState;
}

//---
// The centralized keys used for status by different parts of the app
// Add new items here as different parts of the app implement the status
// pattern.
export const statusKeys = {
  reportStatus: "reportStatus",
  reportQueue: "reportQueue",
};