import { IQuantApi } from "app-types";
import Apis, { manualFetch, downloadBody, downloadFile, handleResult, handleResultAsync } from "./quanta-api";

export default (Apis as any) as IQuantApi;
export { 
  manualFetch, 
  downloadBody, 
  downloadFile, 
  handleResult, 
  handleResultAsync 
};