import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { NotFound } from 'features/common';
import ReviewSetReport from './reviewset-report';
import CheckListReport from './checklist-report';
import { appRoutes } from 'utils/route-helpers';
import { Route, Routes } from 'react-router-dom';

function ReportsView(){
  require("./reports-includes.css");
  require('../../x-styles/inspinia.css');
  require('../../x-styles/inspinia-overrides.scss');
  
  const { classes }           = buildStyles();

  return (
    <Grid id="reports-view" container className={classes.root} sx={{fontFamily: "'Muli', 'Roboto'"}}>
      <Routes>
        <Route exact path={appRoutes.reports.reviewSetReport} element={<ReviewSetReport/>} />
        <Route exact path={appRoutes.reports.checkListReport} element={<CheckListReport/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>      
    </Grid>
  );

}

export default ReportsView;

const buildStyles   = makeStyles()(theme => ({
  root: {
    display: "block", //NOTE: This is required to support page-break logic to avoid breaking inside trs
    paddingBottom: theme.spacing(0.5),
  },
  waiting   : {
    marginTop     : "15%",
  }
}));