import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Card, CardContent, Icon } from '@mui/material';
import { appRoutesLegacy } from 'utils/route-helpers';
import BackIcon from '@mui/icons-material/KeyboardBackspace';

const AdminHome = () => {
  return (
    <Grid id="data-view" container justifyContent="flex-start" sx={{ p: 1 }}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" sx={{fontSize: 26, fontWeight: 500, textAlign: "center", mb: 1 }}>Checklist Tracker Administration</Typography>        
      </Grid>
      
      <Grid item xs={12} container sx={{mt: 3}} justifyContent="flex-start">
        <ItemPanel label="Users" icon="people" path={appRoutesLegacy.admin.users} />
        <ItemPanel label="Engagements" icon="folder" path={appRoutesLegacy.admin.engagements} />
        <ItemPanel label="Doc Types" icon="insert_drive_file" path="/admin/documenttypes?filter=current" />
        <ItemPanel label="Tasks" icon="assignment_turned_in" path={appRoutesLegacy.admin.tasks} />
        <ItemPanel label="Reports" icon="assessment" path={appRoutesLegacy.admin.reports} />
      </Grid>

      <Grid item xs={12} container sx={{mt: 5}} justifyContent="center">
        <Link to={appRoutesLegacy.home}>
          <BackIcon fontSize="small" sx={{ mb: "-5px", mr: 1 }}/>
          <Typography component="span">Return to homepage</Typography>
        </Link>
      </Grid>

    </Grid>
  );
}

export default AdminHome;

function ItemPanel({label, icon, path}){

  return (
    <Grid item xs={4} container alignItems="center" justifyContent="center" sx={{mb: 2}}>
      <Link to={path}>
        <Card sx={{height: 175, width: 200}} variant="outlined">
          <CardContent sx={{height: "100%", "&:hover": { backgroundColor: "#448aff22"}}}>   
            <Grid container sx={{height: "100%"}} alignItems="center" justifyContent="center" direction="column">
              <Icon color="primary">{icon}</Icon>
              <Typography sx={{contSize: 24, fontWeight: 500, mb: 1}}>{label}</Typography>
            </Grid>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}