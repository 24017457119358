import React from 'react';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import { purple, blue, orange, yellow } from '@mui/material/colors';
// import blue from '@mui/material/colors/blue';
// import orange from '@mui/material/colors/orange';
// import yellow from '@mui/material/colors/yellow';

export const reportsMenu  = [
  { 
    id        : 10, 
    content   : 'ReviewSets',
    path      : "../reviewsets",
    icon      : <CollectionsBookmarkIcon fontSize="small" />,
  },  
  { 
    id          : 20, 
    content     : "Documents",
    path        : "../documents",
    icon      : <DescriptionIcon fontSize="small" />,
  },
  { 
    id          : 999, 
    type        : "separator", 
    content     : "Other Links" 
  },
  { 
    id        : 0, 
    content   : "All Reports", 
    path      : "",
    icon      : <AssignmentIcon fontSize="small"/>,
  },
  
];

export const reportItems  = [
  {
    id      : 0,
    order   : 0,
    path    : "engagements/{engagement_id}/rollup",
    name    : "Engagement Rollup Report",
    description   : "A historical report summarizing the work done in a given work week including the number of ReviewSets and Documents created, number of ReviewSets and Documents approved, and number of ReviewSets and Documents in process",    
    tip     : "",
  },
  {
    id      : 1,
    order   : 10,
    path    : "engagements/{engagement_id}/clstatus",
    name    : "CheckList Status Report",
    description   : "A list of all Documents created in this Engagement and the current status of each Checklist (pending or approved)",    
    isSlow  : true,
    tip     : "It is highly recommended to run this report outside normal business hours",
  },
  {
    id    : 6,
    order : 15,
    path  : "engagements/{engagement_id}/clshistory",
    name  : "CheckList Status History Report",
    description : "A historical report listing the dates and times the status of a Checklist changed for all Documents/Checklists in this Engagement",
  },  
  {
    id      : 2,
    order   : 20,
    path    : "engagements/{engagement_id}/rejections",
    name    : "Assertion Rejection Report",
    description   : "A list of all Assertions which have been answered as 'Reject'",    
    tip     : "",    
  },
  {
    id      : 3,
    order   : 30,
    path    : "engagements/{engagement_id}/clresponses",
    name    : "Assertion Responses Report",
    description   : "A list of Assertion responses for all Documents/Checklists in this Engagement",
    isSlow  : true,
    tip     : "It is highly recommended to run this report outside normal business hours",    
  },
  {
    id      : 4,
    order   : 40,
    path    : "engagements/{engagement_id}/rsmetadata",
    name    : "ReviewSet Metadata",
    description   : "A list of all ReviewSets in this Engagement including all ReviewSet identification data",    
  },
  {
    id      : 5,
    order   : 50,
    path    : "engagements/{engagement_id}/usrappr",
    name    : "Daily Document Approvals by User",
    description   : "Daily Document approval count and disapproval count per user",    
  }
];

// Colors to use with the chart, and other places
export const itemColors  = {
  created   : purple,
  active    : orange,
  approved  : blue,
  pending   : yellow,
  preApproved : orange,
};

export const statusColors   = {
  0   : yellow[300],
  50  : orange[200],
  100 : blue[200],
}

export function statusCodeColor(statusCode, opacityHex){
  return `${statusColors[statusCode]}${opacityHex || ""}`;
}