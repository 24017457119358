import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';

const Status = ({code, className, ...otherProps}) => {
  
  const { classes, cx }   = buildStyles();
  const info  = _.find(STATUSES, s => s.keys.indexOf(code) >= 0) || STATUSES[0];
  // const info  = _.find(STATUSES, s => s.code === code) || STATUSES[0];

  return <span className={cx(classes.status, info.className, className)} {...otherProps}>{info.label}</span>;
}

Status.propTypes  = {
  code    : PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Status;

const STATUSES  = [
  {
    keys        : [0, "pending", "PENDING", "Pending", "disapprove", "DISAPPROVE",],
    code        : 0,
    className   : "status-pending",
    label       : "Pending",
  },
  {
    keys        : [50, "pre-approve", "PREAPPROVE", "Pre-Approve"],
    code        : 50,
    className   : "status-preapproved",
    label       : "Pre-Approved",
  },
  {
    keys        : [100, "approve", "APPROVE", "Approve"],
    code        : 100,
    className   : "status-approved",
    label       : "Approved",
  },  
];

const buildStyles   = makeStyles()(theme => ({
  status  : {
    padding         :  "2px 4px",
    borderRadius   : 5,

    "&.status-pending"  : {
      border        : `1px solid ${theme.statuses.pending.border}`, //#faebcc
      background    : theme.statuses.pending.background, //"#fcf8e3",
      color         : theme.statuses.pending.text, //"#8a6d3b",
    },
    "&.status-preapproved"  : {
      border        : `1px solid ${theme.statuses.preApproved.border}`, //#faebcc
      background    : theme.statuses.preApproved.background, //"#fcf8e3",
      color         : theme.statuses.preApproved.text, //"#8a6d3b",
    },
    "&.status-approved"  : {
      border        : `1px solid ${theme.statuses.approved.border}`, //#bce8f1
      background    : theme.statuses.approved.background, //"#d9edf7",
      color         : theme.statuses.approved.text, //"#31708f",
    },
  }
}));