import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SectionEditor from './checklist-editor-section';
import { selectStatus } from 'store/selectors/builder-selectors';
import { isSection } from 'utils/checklist-helpers';
import { insertBuilderSection } from 'store/actions';
import { useAppDispatch } from 'store';

const ChecklistEditorBody = ({checklist, isEditable}) => {
  const { classes }   = buildStyles();
  const dispatch  = useAppDispatch();
  const status      = useSelector(state => selectStatus(state, "checklists"));
  
  //Only show the "add section" button if the last section isn't expanded
  const showLastAdd = useMemo(() => { 
    const lastSection   = _.findLast(checklist.checklistItems, isSection);
    return !lastSection?.isExpanded;
  }, [checklist.checklistItems]);

  async function onAddSection(){
    dispatch(insertBuilderSection({}));
  }

  return (
    <>
      <Grid id="body-view" container spacing={1} className={classes.editorBodyRoot}>
        {_.map(checklist.sections, section => <SectionEditor key={section.id} section={section} checklist={checklist} status={status} isEditable={isEditable}/>)}
      </Grid>

      <Grid id="footer" container spacing={1} className={classes.footer} justifyContent="center">
        {(isEditable && showLastAdd) && <Button color="primary" onClick={onAddSection} disabled={status.isWorking}>Add Section</Button>}        
      </Grid>
    </>
  );
}

export default ChecklistEditorBody;

const buildStyles   = makeStyles()(theme => ({
  editorBodyRoot  : {
    padding     : theme.spacing(1),
  },   
}));