import { IReviewSet } from "app-types";
import { paginationHeader } from "api";
import Apis, { downloadFile } from "store/api";
import { createAppAsyncThunk, haveItems, parseResponse } from "store/slices/slice-helpers";

export const SETS_THUNKS = {
  fetch: "reviewsets/fetchSets",
  fetchPending: "reviewsets/fetchPendingSets",
  export: "reviewsets/exportSets",
};

export const fetchSetsThunk = createAppAsyncThunk(SETS_THUNKS.fetch, async (params: any, thunkAPI) => {
  const setsState = thunkAPI.getState().sets;
  const { filter, pageNumber = 1, pageSize = 25, forceRefresh = false } = params;
  
  if(!forceRefresh && !setsState.isItemChanged && haveItems(filter, pageNumber, pageSize, setsState)){
    return;
  }

  const headers = paginationHeader(pageNumber, pageSize);
  const result = await Apis.Reviewsets.get<IReviewSet[], any>("", filter, headers);
  const response = parseResponse<IReviewSet[]>(SETS_THUNKS.fetch, result, { filter });
  return response;  
});


export const fetchPendingSetsThunk = createAppAsyncThunk(SETS_THUNKS.fetchPending, async (params: any, thunkAPI) => {
  
  const { engId } = params;
  const filter = {
    engagementId: engId,
    filter: "status:pending",
    search: null,
    sort: null,
  };
  const headers   = paginationHeader(1, 9999);

  const result = await Apis.Reviewsets.get<IReviewSet[], any>("", filter, headers);
  const response = parseResponse<IReviewSet[]>(SETS_THUNKS.fetchPending, result);
  return response;  
});

export const exportSetsThunk = createAppAsyncThunk(SETS_THUNKS.export, async (params: any, thunkAPI) => {
  // const { filter = null } = params;
  const headers   = paginationHeader(1, 10000);  //limit export to 10,000 items (need to pass in some pagination number, otherwise server defaults it to 200)
  const result = await Apis.Reviewsets.get("export", { ...params, responseType: "arraybuffer"}, headers);

  //Pass through the filter to help determine if/when the data needs refreshing
  if(result.ok){
    const isOk  = await downloadFile(result, "text/csv");
    if(!isOk){
      console.log("Export failed.");
      throw new Error("Export failed.");
    }

    return { data: result };
  }
  else{
    console.error("Export failed.", result);
    throw result.originalError;
  }
});