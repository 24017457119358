import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FolderOpenIcon from '@mui/icons-material/Folder';
import { appRoutesLegacy } from 'utils/route-helpers';
import { useHover } from 'hooks';
import { CltHidden, IfBlock } from 'features/common';
import DocumentIcon from './document-icon';
import { generatePath, Link } from 'react-router-dom';
import { noHoverColor } from 'utils/sx-helpers';
import { formatDistanceToNow } from 'date-fns';

//TODO: add status information and make the document icon a hover with a popup
//TODO: Change this so it uses a Card in mobile mode
const RecentsTableItem = ({item}) => {
  const { classes }   = buildStyles();
  const [, isOver, onEnter, onExit]  = useHover(); //for tracking if it's over this item
  //NOTE: need to do the props here because of the casing of the reviewSetId vs reviewsetId - in the object vs the route path...
  const props   = {engagementId: item.engagementId, reviewsetId: item.reviewSetId, documentId: item.documentId};
  const docUrl  = generatePath(appRoutesLegacy.documents.view, props);
  const rsUrl   = generatePath(appRoutesLegacy.reviewsets.view, props);
  const engUrl  = generatePath(appRoutesLegacy.engagements.view, props);

  const fromTime = formatDistanceToNow(new Date(`${item.responseDate}Z`));
  
  return (
    <tr className={classes.itemRow} onMouseEnter={onEnter} onMouseLeave={onExit}>
      <td className={classes.iconCol}>
          <DocumentIcon doc={item} />
      </td>
      <td className={classes.itemCol}>
        <Link to={docUrl}>
          <Typography className={classes.docKey} sx={noHoverColor}>{item.documentKey}</Typography>
        </Link>
      </td>
      <td className={classes.itemCol}>
        <Link to={rsUrl}>
          <Typography className={classes.setName} sx={noHoverColor}>{item.reviewSetTitle}</Typography>
        </Link>
      </td>
      <CltHidden smDown>
        <td className={classes.actionsCol}>
          <IfBlock condition={!isOver}>
            <Typography className={classes.date}>{fromTime} ago</Typography>
          </IfBlock>
          <IfBlock condition={isOver}>
            <IconButton size="small" className={classes.actionButton} title="Go to Engagement" component={Link} to={engUrl} sx={noHoverColor}>
              <FolderOpenIcon/>
            </IconButton>
          </IfBlock>
        </td>
      </CltHidden>
    </tr>
  );
}

export default RecentsTableItem;

const buildStyles   = makeStyles()(theme => ({
  itemRow   : {
    fontSize    : 14,
    fontWeight  : 300,
    color       : theme.palette.common.white,
    minHeight   : 35,
    height      : 35,
  },
  iconCol  : {
    width       : 45,
    "& svg"   : {
      fontSize    : 22,
      fontWeight  : 300,
    }
  },
  itemCol   : {
    "& a :hover" : {
      color           : theme.palette.common.white,
      textDecoration  : "underline",
    }
  },
  docKey   : {
    fontSize    : 16,
    fontWeight  : 500,
    color       : theme.palette.common.white,
  },
  setName   : {
    marginLeft  : theme.spacing(1),
    fontSize    : 14,
    fontWeight  : 400,
    color       : theme.palette.grey[300],
  },
  date    : {
    fontSize    : 14,
    fontWeight  : 400,
    color       : theme.palette.grey[300],
  },
  dateGrid  : {
    width       : 120,
    paddingRight  : theme.spacing(2),
  },
  actionsCol   : {
    width       : 160,
    flexWrap    : "nowrap",
    textAlign   : "right", 
    [theme.breakpoints.down('md')]: {
      display: "none",
    } 
  },  
  actionButton  : {
    color         : theme.palette.common.white,
    marginLeft    : 0,
    "& svg"   : {
      fontSize    : 22,
    }
  }
}));