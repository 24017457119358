import React from 'react';
import { Status } from 'features/common';
import { formatDate } from 'utils/general-helpers';

export default function StatusRow({statusRec}){
  const isPending     = Boolean(!statusRec);
  const userName      = isPending ? null : statusRec.userName || '[unavailable]';
  const apprDate      = isPending ? null : formatDate(statusRec.statusDate);
  const code          = isPending ? "PENDING" : statusRec.status;

  return (
    <div className="row status-row">

      <div className="col-xs-4">
        <h3>
          <span className="m-t-sm">
            <Status code={code} /> {!isPending && <span>by {userName} on {apprDate} (UTC)</span>}
          </span>
        </h3>
      </div>

      {!isPending && 
        <div className="col-xs-8 p-t-10 status-notes-col">
          <span className="notes"><span className="font-bold">Comments:</span> {statusRec.notes}</span>
        </div>
      }
    </div>
    
  );
}
