import { STATUS_ACTIONS } from './status-reducer';
import { apiResponseToAction, paginationHeader } from 'api';
import { IApiAction, IAppState, INormalizedError } from 'app-types';
import { Dispatch, Middleware } from 'redux';
import { RootState } from 'store';

const NO_STATUS = async (isWorking: boolean, error?: INormalizedError | null | undefined) => {};
const statusActionFactory = 
  (dispatch: Dispatch<any>, type?: string, key?: string) => 
  async (isWorking: boolean = false, error: INormalizedError | null = null) => {
    if(type && key && dispatch){
      // await dispatch({ type, key, value: { isWorking, error }});      
      await dispatch({ type: STATUS_ACTIONS.UPDATE, key, value: { isWorking, error }});      
    }
    
    return await Promise.resolve();
  };

const apiMiddleware = (store: any) => (next: any) => async (action: any) => {
  if(!action.api){
    return next(action);
  }

  const { api, type: successType, errorType, ...otherProps } = action;
  const { status, endpoint, path, verb, pagination, params }  = api;
  const { dispatch } = store;
  const statusAction = (status) ? statusActionFactory(dispatch, status.statusType, status.statusKey) : NO_STATUS;

  //first, dispatch the status update:
  await statusAction(true);

  //If there is pagination info, need to deal with that
  const header = pagination ? paginationHeader(pagination.pageNumber, pagination.pageSize) : undefined;

  //call the api endpoint, and turn the result into an action
  const apiResponse  = await endpoint[verb](path, params, header);
  const responseAction = apiResponseToAction(apiResponse, successType, errorType, otherProps);

  //dispatch the action and the status change
  await dispatch(responseAction);
  await statusAction(false, responseAction.error);

  return responseAction; //apiResponse;
};

export default apiMiddleware;

export const apiThunkMiddleware: Middleware<{}, RootState> = storeApi => next => async action => {
  const apiAction = action as IApiAction;
  if(!apiAction.api){
    return next(action);
  }

  const { api, type: successType, errorType, ...otherProps } = apiAction;
  const { status, endpoint, path, verb, pagination, params }  = api;
  const { dispatch } = storeApi;
  const statusAction = (status) ? statusActionFactory(dispatch, status.statusType, status.statusKey) : NO_STATUS;

  //first, dispatch the status update:
  await statusAction(true);

  //If there is pagination info, need to deal with that
  const header = pagination ? paginationHeader(pagination.pageNumber, pagination.pageSize) : undefined;

  //call the api endpoint, and turn the result into an action
  const apiResponse  = await endpoint[verb](path, params, header);
  const responseAction = apiResponseToAction(apiResponse, successType, errorType, otherProps);

  //dispatch the action and the status change
  await dispatch(responseAction as any);    //TODO: figure out if I can type this
  await statusAction(false, responseAction.error);

  return responseAction; //apiResponse;
}