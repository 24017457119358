import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, DialogTitle, DialogContent, Grid, DialogActions, Tabs, Tab } from '@mui/material';
import { createDocThunk } from 'store/thunks';
import { clearCreateDocError } from 'store/actions';
import { selectSet } from 'store/selectors/reviewset-selectors';
import { TabPanel, ErrorDisplay, WaitButton } from 'features/common';
import AddNewPanel from './add-document-new-panel';
import AddExistingPanel from './add-document-existing-panel';
import { muiDialogReason } from 'utils/ui-helpers';
import { useIsMobile } from "hooks/general-hooks";

const DEFAULT_DOC   = {docKey: "", docTypeId: "", docId: "", doc: null, docType: null};

function AddDocumentDialog({isOpen, onClose}){
  const { classes }                   = buildStyles();
  const [tabIndex, setTabIndex]   = useState(0);
  const [doc, setDoc]             = useState(DEFAULT_DOC);
  const isWorking                 = useSelector(state => state.docs.isCreating);
  const saveError                 = useSelector(state => state.docs.createError);
  const set                       = useSelector(selectSet);
  const isMobile = useIsMobile();
  const dispatch                  = useDispatch();
  const [error, setError]         = useState(saveError);
  const canSave = Boolean((tabIndex === 0 && doc.docKey && doc.docTypeId) || (tabIndex === 1 && doc.docId));

  //TODO: Feature upgrade - merge new & existing into one view with the "free solo" auto-complete so 
  // that it reduces the risk of creating duplicate documents.

  const doClose = (event, reason) => {
    if(muiDialogReason.isEither(reason)) return;

    setTabIndex(0);
    setError(null);
    dispatch(clearCreateDocError());
    setDoc(DEFAULT_DOC);
    onClose();
  }

  const doSave  = async () => {
    //Make sure we're all good
    const err   = validateDoc(doc);
    if(err){
      setError({status: "Validation Failed", problem: err[tabIndex]});
      return;
    }

    //Save the new / existing document
    const myDoc   = _.omit(doc, ["doc", "docType"]);
    // const result  = await dispatch(createDocument(myDoc, set));
    const result = await dispatch(createDocThunk({ doc: myDoc, set }));
    if(result.payload?.ok){
      //reload is triggered automatically by the set.status.isDocChanged flag in the ReviewSet Reducer.
      doClose();
    }    
  }

  const onDocChanged = (changes) => {
    setDoc({
      ...doc,
      ...changes,
    });
  }

  return (
    <Dialog open={isOpen} onClose={doClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>Add {tabIndex === 1 && "Existing "}Document</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item sm={3} container>
            <Tabs disabled={isWorking} orientation={isMobile ?  "horizontal" : "vertical"} className={classes.tabs} value={tabIndex} onChange={(e, i) => { setTabIndex(i); setError(null); }}>
              <Tab label="New"></Tab>
              <Tab label="Existing"></Tab>
            </Tabs>
          </Grid>
          <Grid item sm={9} container>

            <ErrorDisplay error={error || saveError} />

            <TabPanel id="new-panel" value={tabIndex} index={0} className={classes.tabPanel}>
              <AddNewPanel doc={doc} onDocChanged={onDocChanged} />
            </TabPanel>

            <TabPanel id="existing-panel" value={tabIndex} index={1} className={classes.tabPanel}>
              <AddExistingPanel doc={doc} onDocChanged={onDocChanged} />              
            </TabPanel>
            
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <WaitButton onClick={doSave} variant="outlined" color="primary" disabled={!canSave} isWaiting={isWorking}>Save</WaitButton>
        <Button onClick={doClose} variant="outlined" disabled={isWorking}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

const buildStyles   = makeStyles()(theme => ({
  tabs  : {
    borderRight   : `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      borderRight: "none",
      marginBottom: theme.spacing(2),
    }
  },
  tabPanel    : {
    marginBottom  : theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    },
  },
}));

export default AddDocumentDialog;

AddDocumentDialog.propTypes   = {
  isOpen    : PropTypes.bool,
  onClose   : PropTypes.func.isRequired,
};

function validateDoc(doc){
  if((doc.docTypeId && doc.docKey) || (doc.docId)){
    return null;    //all good,
  }
  else{
    return [
      "All fields are required",
      "You must choose a document from the list.",
    ];
  }
}


