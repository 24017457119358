import { PopoverOrigin } from "@mui/material";

export type PopoverPlacement = "bottomRight" | "bottomLeft" | "bottomCenter";

export interface IPosition {
  anchorOrigin: PopoverOrigin,
  transformOrigin: PopoverOrigin,
}

export const PopoverPlacements : Record<PopoverPlacement, IPosition>  = {
  bottomRight   : {
    anchorOrigin  : {
      vertical    : "bottom", 
      horizontal  : "right"
    },
    transformOrigin   : {
      vertical    : "top",
      horizontal  : "right",
    }
  },
  bottomLeft   : {
    anchorOrigin  : {
      vertical    : "bottom", 
      horizontal  : "left"
    },
    transformOrigin   : {
      vertical    : "top",
      horizontal  : "left",
    }
  },
  bottomCenter   : {
    anchorOrigin  : {
      vertical    : "bottom", 
      horizontal  : "center"
    },
    transformOrigin   : {
      vertical    : "top",
      horizontal  : "center",
    }
  },
}