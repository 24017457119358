import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/ArrowBackOutlined';
import OpenIcon from '@mui/icons-material/ArrowForwardOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { CltHidden, IfBlock, NavLinkP } from 'features/common';
import { useHover, useSetting } from 'hooks';
import { useIsBreakpointDown } from 'hooks/general-hooks';

interface INavMenuProps {
  menu: any; 
  basePath: string; 
  isWorking: boolean; 
  conditionProps: any; 
  noCollapse?: boolean; 
  settingsKey: string;
}

const NavMenu = ({menu, basePath, isWorking, conditionProps, noCollapse, settingsKey}: INavMenuProps) => {
  const { classes, cx } = buildStyles();
  const loc       = useLocation();
  const storeKey: string  = settingsKey || loc.pathname;
  const [anchor, isOpen, onOpen, onClose]  = useHover("bottomCenter");
  const [isCollapsed, setCollapsed]   = useSetting(storeKey, false);
  const isSmDown = useIsBreakpointDown("sm");

  return (
    <Grid id="nav-menu" container direction="column" wrap="nowrap" justifyContent="flex-start" className={cx(classes.root, {[classes.collapsed]: isCollapsed})}>

      <CltHidden smUp>
        <IconButton
          id="nav-menu-button"
          aria-haspopup="true"
          onClick={onOpen}
          disabled={isWorking}
          size="large">
          <MenuIcon fontSize="small"/>
        </IconButton>
        <Menu id="nav-menu-menu" anchorEl={anchor} open={isOpen} onClose={onClose}>
          {menu.map((m : any) => 
            <NavMenuItem 
              key={m.id} 
              item={m} 
              basePath={basePath}
              location={loc}
              isWorking={isWorking}
              isCollapsed={isCollapsed && !isSmDown}
              classes={classes}
              cx={cx}
              conditionProps={conditionProps}
              onClick={onClose} />)
            }
        </Menu> 
      </CltHidden>

      <CltHidden smDown>
        <ul id="nav-menu-list" className={classes.list}>
          {menu.map((m : any) => 
            <NavMenuItem 
              key={m.id} 
              item={m} 
              basePath={basePath}
              location={loc}
              isWorking={isWorking}
              isCollapsed={isCollapsed && !isSmDown}
              classes={classes}
              cx={cx}
              conditionProps={conditionProps} />)
            }
        </ul> 
        <IfBlock condition={!noCollapse}>
          <Grid item container justifyContent="flex-start">
            <Tooltip title="Toggle Menu Visibility">
              <IconButton size="small" color="default" className={cx(classes.collapseBtn, {noMargin: isCollapsed})} onClick={() => setCollapsed(!isCollapsed)}>
                  {!isCollapsed && <CloseIcon fontSize="small"/>}
                  {isCollapsed && <OpenIcon fontSize="small"/>}
              </IconButton>
            </Tooltip>
          </Grid>
        </IfBlock>
      </CltHidden>

    </Grid>
  );
}

const buildStyles = makeStyles()((theme: any) => ({
  root  : {
    padding   : theme.spacing(2),  
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    alignItems: "stretch",
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      width: "auto",
      display: "block",
      alignItems: "unset",
    }  
  },
  list  : {
    listStyle   : "none",
    paddingLeft : 0,
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      marginBottom: 0,
    }
  },
  item: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      marginRight: theme.spacing(1),
      "& a": {
        marginBottom: 0,
      }
    }
  },
  linkItem  : {
    width       : "100%",
    minWidth    : 220,
    textAlign   : "left",

    "&.active"   : {
      background    : theme.palette.grey[300],
    },
    marginBottom  : theme.spacing(1),
    fontSize      : 16,
    fontWeight    : 300,
    "& svg"   : {
      marginRight   : theme.spacing(1),
    },    
  },
  buttonItem  : {
    width       : "100%",
    textAlign   : "center",
    cornerRadius  : "3px",
    background    : theme.palette.primary.main,
    color         : theme.palette.primary.contrastText,
    marginBottom  : theme.spacing(2),
    fontSize      : 16,
    fontWeight    : 300,
    "& svg"   : {
      marginRight   : theme.spacing(1),
    },    
  },
  collapseBtn : {
    marginLeft  : 10,
    "&.noMargin":{
      marginLeft  : 3,
    }
  },
  collapsed    : {
    // width     : 69,
    "& a" : {
        padding     : theme.spacing(0.5),
        minWidth    : "unset",
    },
    "& svg": {
        marginRight     : 0,
    }
  },
  separator  : {
    marginTop     : theme.spacing(1),
    marginBottom  : theme.spacing(2),
  },
}));

export default NavMenu;

interface IMenuItemProps {
  item: any;
  basePath: string;
  location: any;
  isWorking: boolean;
  isCollapsed: boolean;
  classes: Record<string, string>;
  cx: any;
  conditionProps: any;
  onClick?: (item: any) => void;
}

const NavMenuItem = React.forwardRef((props: IMenuItemProps, ref) => {
  const {item, basePath, location, isWorking, isCollapsed, classes, cx, conditionProps, onClick} = props;
  
  //If there's a condition on this menu item, make sure it is met
  if(item.condition && !item.condition(conditionProps)) return null;

  let content   = null;
  
  if(item.type === "separator"){
    content   = (
      <>
        <hr className={classes.separator}/>
      </>
    );
  }
  else{
    const className   = item.className ? item.className : (item.type === "button" ? cx("btn btn-md", classes.buttonItem) : cx("btn btn-link", classes.linkItem));   //fallback class name
    const isReplace   = !(location.pathname === basePath && !location.search);   //replace if we're not at the base 

    content           = (
      <Tooltip title={item.content} ref={ref}>
        <NavLinkP to={item.path} disabled={isWorking} className={className} replace={isReplace} onClick={onClick} exact> 
          <Grid container alignItems="center" justifyContent={isCollapsed ? "center" : "flex-start"}>
            {item.icon}
            {!isCollapsed && item.content}
          </Grid>
        </NavLinkP>
      </Tooltip>
    );
  }

  const menuId  = `nav-menu-item-${item.id}`;
  return (
    <li id={menuId} className={classes.item}>
      {content}
    </li>
  );
});