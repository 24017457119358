import React from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { INP_TYPES } from 'utils/checklist-helpers';
import { TextInput, OptionsInput } from './';

function InputList({item, onSave, isReadOnly}){
  const inputs    = _.filter(item.checkListInputs, inp => ["Bool", "Comment"].indexOf(inp.discriminator) < 0);    //get the non-bool inputs

  return (
    <Grid container direction="column">
      {_.map(inputs, inp => <InputItem key={inp.id} item={item} input={inp} onSave={onSave} isReadOnly={isReadOnly}/>)}
    </Grid>
  )
}

export default InputList;

function InputItem({item, input, onSave, isReadOnly}){
  //memo this input so we don't have to constantly re-generate it
  
  if(input.discriminator === INP_TYPES.text){
    return <TextInput item={item} input={input} onSave={onSave} isReadOnly={isReadOnly}/>;
  }
  else if(input.discriminator === INP_TYPES.option){
    return <OptionsInput item={item} input={input} onSave={onSave} isReadOnly={isReadOnly}/>;
  }  
  else{
    return <div>placeholder for input of type {input.discriminator}</div>
  }
}

