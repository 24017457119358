import React from 'react';
import Header from './header';
import { Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';

function AppLayout({children}){

  return (
    <div id="app-layout">
      <Header />
      <Grid component="main" id="app-main" sx={{pt: {xs: "110px", sm: 8}}}>
        <Grid id="app-layout-container" sx={{px: 2}}>
          {children}
        </Grid>
        <ToastContainer />
      </Grid>
    </div>
  );
}

export default AppLayout;