import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CltTable, ListToolbar, ListFooter } from 'features/common';
import ReviewSetsListItem from './reviewsets-list-item';
import { readSetting, saveSetting } from 'utils/localstorage-helpers';
import { SET_LIST_SIZE_KEY } from './reviewsets-config';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'hooks';

const DEFAULT_PAGE_SIZE = 25;

const ReviewSetsList = ({ canEdit, changePage, target, isWorking, pagination, onExport, sets, download, isReportsWorking, error }) => {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const hasSets       = Boolean(!isWorking && sets && sets.length > 0);
    const { search, filter } = useQuery();
    const isFiltered    = Boolean(search || filter);
    const emptyText     = getEmptyMessage(hasSets, isFiltered, error);
    const filterOptions = getFilters(filter);
    const addUrl        = `${target}/add`;
    const title         = getTitle(filter);

    // Defaults the page size to the user's last selection
    useEffect(() => {
      setPageSize(readSetting(SET_LIST_SIZE_KEY, DEFAULT_PAGE_SIZE))
    }, []);

    const onChangePage = (nextNumber, nextSize) => {
      const pSize     = nextSize || pageSize;
      const pNum      = nextNumber || 1;

      if(nextSize && nextSize !== pageSize){
          saveSetting(SET_LIST_SIZE_KEY, nextSize);
          setPageSize(nextSize);
      }

      changePage(pNum, pSize);
    };

    const onSort = (sortStr) => {
      const url   = new URL(window.location.href);
      if(url.searchParams.has("sort") && !sortStr) 
          url.searchParams.delete("sort");
      else 
          url.searchParams.set("sort", sortStr);
      
      const relative  = url.href.replace(window.location.origin, ""); //need to remove the origin for sake of the history
      navigate(relative, { replace: true });
    };

    return (
      <Card id="reviewset-list" sx={{width: "100%"}}>
        <CardContent>                    
          <ListToolbar
            title={title}
            filterOptions={filterOptions}
            isWorking={isWorking}
            baseUrl={target}
            newUrl={canEdit ? addUrl : null}
            pagination={pagination}
            onChangePage={onChangePage}
            searchPlaceholder="Search by Name..."
            onExport={onExport}
          />
          <Grid container>
            <CltTable 
              cols={GRID_COLUMNS} 
              onSort={onSort} 
              isWorking={isWorking}
              isEmpty={sets && sets.length === 0}
              emptyText={emptyText}>
                {hasSets && sets.map(s => <ReviewSetsListItem key={s.id} set={s} target={target} canEdit={canEdit} download={download} isReportsWorking={isReportsWorking}/>)}
            </CltTable>
          </Grid>
          <ListFooter pagination={pagination} isWorking={isWorking} onChangePage={onChangePage} />
        </CardContent>           
      </Card>
    );
}

export default ReviewSetsList;

ReviewSetsList.propTypes = {
    sets        : PropTypes.arrayOf(PropTypes.object),
    query       : PropTypes.object,
    pagination  : PropTypes.object,
    target      : PropTypes.string.isRequired,
    canEdit     : PropTypes.bool,
    changePage  : PropTypes.func.isRequired,
    engagement  : PropTypes.object.isRequired,
    isWorking   : PropTypes.bool,
    isReportsWorking : PropTypes.bool,  //used by reporting (currently disabled)
    download    : PropTypes.func,       //used by reporting to download a reviewset report (currently disabled)
    onExport    : PropTypes.func, 
};

const GRID_COLUMNS   = [
  {
    id        : 0,
    sortKey   : "id",
    label     : "Id",
    style     : {minWidth: "45px"},
    defaultDir: "asc",
  },
  {
    id        : 10,
    sortKey   : "title",
    label     : "Name",
    style     : {minWidth: "150px"},    
  },
  {
    id        : 20,
    sortKey   : "status",
    label     : "Status",
  },
  {
    id        : 30,
    sortKey   : "date",
    label     : "Last Modified",
    style     : {minWidth: "150px"},
  },
  {
    id: 40,
    label: "Description",
    noSort: true
  },
  {
    id: 50,
    label: "",
    noSort: true,
  }
];

const getFilters = (filter) => {
    if(!filter) return null;    
    else if(filter.indexOf("owner:me") === 0){
        return [
            { key: 'owner:me',                   label: 'No Filter'},
            { key: 'owner:me|status:pending',    label: 'My Pending ReviewSets', subTitle: "pending" },
            { key: 'owner:me|status:approved',   label: 'My Approved ReviewSets', subTitle: "approved"},
        ];
    }
    else if(filter.indexOf("status:pending") === 0){
        return [
            { key: 'status:pending',            label: 'No Filter' },         
            { key: 'status:pending|owner:me',   label: 'My Pending ReviewSets', subTitle: "mine" },
        ];
    }
    else if(filter.indexOf("status:approved") === 0){
        return [
            { key: 'status:approved',           label: 'No Filter' },          
            { key: 'status:approved|owner:me',  label: 'My Approved ReviewSets', subTitle: "approved" },
        ];
    }

    return null;
}

function getTitle(filter){
    let prefix  = "";
    if(filter){
      if(filter.startsWith("owner:me")) prefix = "My ";
      else if(filter.startsWith("status:pending")) prefix = "Pending ";
      else if(filter.startsWith("status:preapproved")) prefix = "PreApproved ";
      else if(filter.startsWith("status:approved")) prefix = "Approved ";
    }

    return `${prefix}ReviewSets`; //`
}

function getEmptyMessage(hasSets, isFiltered, error){
    if(!hasSets){
        if(error){
          return "The error above prevented this list from loading"
        }
        return `There are no ReviewSets${isFiltered ? " matching your current filter / search criteria" : ""}`;
    }
    return null;
}