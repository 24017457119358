import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { IDocumentType } from "app-types";
import { fetchSettingsThunk, saveSettingThunk } from "store/thunks/setting-thunks";
import { addOrReplace } from "./slice-helpers";
import { addDocumentTypeVersionThunk, copyDocumentTypeThunk, toggleDocumentTypeCirculationThunk } from "store/thunks";

interface SettingsSlice extends Record<string, any>{
  isInitialized: boolean;
  isWorking: boolean;
  error: any | null;

  clients: any[] | null;
  sites: any[] | null;
  protocols: any[] | null;
  projects: any[] | null;
  engagements: any[] | null;
  documentTypes: IDocumentType[] | null;
  user: any | null;
}

const INITIAL_STATE: SettingsSlice = {
  isInitialized: false,
  isWorking: false,
  error: null,

  clients: null,
  sites: null,
  protocols: null,
  projects: null,
  engagements: null,
  documentTypes: null,
  user: null,
};

//=== Thunk Extra Reducers

const settingsFetched = (builder: ActionReducerMapBuilder<SettingsSlice>) => {
  builder.addCase(fetchSettingsThunk.pending, (state, action) => {
    state.isWorking = true;
    state.error = null;
  })
  .addCase(fetchSettingsThunk.fulfilled, (state, action) => {
    if(!action.payload?.ok || !action.payload?.data){
      state.error = action.payload;
      state.isWorking = false;
      return;
    }

    const payload = action.payload.data;

    state.isWorking = false;
    state.isInitialized = true;
    state.clients = payload.settings.clients;
    state.sites = payload.settings.sites;
    state.protocols = payload.settings.protocols;
    state.projects = payload.settings.projects;
    state.engagements = payload.settings.engagements;
    state.documentTypes = payload.settings.documentTypes;
    state.user = payload.settings.user;    
  })
  .addCase(fetchSettingsThunk.rejected, (state, action) => {
    state.isWorking = false;
    state.error = action.error;
  });
};

const settingSaved = (builder: ActionReducerMapBuilder<SettingsSlice>) => {
  builder.addCase(saveSettingThunk.pending, (state, action) => {
    state.isWorking = true;
    state.error = null;
  })
  .addCase(saveSettingThunk.fulfilled, (state, action) => {
    state.isWorking = false;
    const payload = action.payload;
    //Make sure this is a setting we care about / have
    const myKey   = payload.key === "doctypes" ? "documentTypes" : payload.key;
    const currentState = state[myKey];
    if(currentState === undefined) return state;

    state[myKey] = addOrReplace(state[myKey], item => item.id === payload.data.id, payload.data)
  })
};

//These are reducers for thunks from other slices. We need to update settings when certain other thunks execute
const externalReducers = (builder: ActionReducerMapBuilder<SettingsSlice>) => {
  builder.addCase(copyDocumentTypeThunk.fulfilled, (state, action) => {
    const { data: newDoc } = action.payload;
    if(!newDoc || !state.documentTypes) return;
    else state.documentTypes.push(newDoc);
  })
  .addCase(addDocumentTypeVersionThunk.fulfilled, (state, action) => {
    const { id: originalId, data } = action.payload;
    const newId = data?.id;

    if(!newId || !state.documentTypes) return;

    //Mark the existing one as having a new version
    const existing    = state.documentTypes.find(dt => dt.id === originalId);
    if(!existing) return;
    const updated     = {...existing, propertyBag   : {...existing.propertyBag, nextVersion: newId.toString() }};
    let items = addOrReplace(state.documentTypes, dt => dt.id === originalId, updated);
    items.push(data);
    state.documentTypes = items;
  })
  .addCase(toggleDocumentTypeCirculationThunk.fulfilled, (state, action) => {
    if(!state.documentTypes) return;
    const { id, data: docType } = action.payload;
    state.documentTypes = addOrReplace(state.documentTypes, dt => dt.id === id, docType);
  });
};


const settingsSlice = createSlice({
  name: "settings",
  initialState: INITIAL_STATE,
  reducers: { 
    clearStatus: (state, action) => {
      const key = action.payload as keyof SettingsSlice["status"];
      state.status[key] = { ...state.status[key], isWorking: false, error: null };
    }
  },
  extraReducers: (builder) => {
    settingsFetched(builder);
    settingSaved(builder);
    externalReducers(builder);
  },
});

export default settingsSlice.reducer;