import _ from 'lodash';
import { create } from 'apisauce';
import { acquireMsalToken } from 'utils/auth/auth-utils';
import { normalizeError } from 'utils/general-helpers-ts';

//for backwards compatibility
export { paginationHeader } from "../../api";

const CD_HEADER           = "content-disposition";
const CD_HEADER_CAP       = "Content-Disposition";    //historically, it has come back both ways
const DEFAULT_HEADERS     = {
  'Accept'        : 'application/json', 
  'Content-Type'  : 'application/json'
};

//TODO: For the separate Quanta.API project (removed from Quanta.UI)
const ENV_API_URL = process.env.REACT_APP_API_URL; //"https://localhost:44312"; 
const API_URL = ENV_API_URL ?? ""; //
const API_SLASH = API_URL.endsWith("/") ? "" : "/";
// console.log("api url from env: ", ENV_API_URL);

//TODO: Put the handleResults and downloadFile methods into this object too?
let Apis            = { 
  isInitialized   : false,
  error           : null,
  url             : "",
  getServerConfig : getServerConfig,
  initialize      : initializeApis, 
  home            : createAnonymousApi(`${API_URL}${API_SLASH}api`, "config"),
};

async function getServerConfig() {
  const config  = await Apis.home.get("");
  if(!config.ok){
    console.error("Failed to get server config.", config);
    Apis.error  = config;
    return config;
  }
  else{
    return {
      ok      : true, 
      error   : null,
      ...config.data   
    };
  }
}

async function initializeApis(config){
  if(Apis.isInitialized) return { ok: true };
  const apiUrl  = `${API_URL}${API_SLASH}${config.apiUrl}`;
  Apis.url      = apiUrl;
  const createApi = createApiFactory(config);

  Apis.Heartbeat           = createApi(apiUrl, "heartbeat", null, true);
  Apis.Settings            = createApi(apiUrl, "settings");
  Apis.Reports             = createApi(apiUrl, "reports");
  Apis.Reviewsets          = createApi(apiUrl, "reviewsets");
  Apis.Documents           = createApi(apiUrl, "documents");
  Apis.ReviewRecords       = createApi(apiUrl, "reviewrecords");
  Apis.CheckListResponses  = createApi(apiUrl, "checklistresponses");
  Apis.Metrics             = createApi(apiUrl, "metrics");
  Apis.Users               = createApi(apiUrl, "users");
  Apis.Engagements         = createApi(apiUrl, "engagements");
  Apis.Clients             = createApi(apiUrl, "clients");
  Apis.Projects            = createApi(apiUrl, "projects");
  Apis.Protocols           = createApi(apiUrl, "protocols");
  Apis.Sites               = createApi(apiUrl, "sites");
  Apis.Assignments         = createApi(apiUrl, "assignments");
  Apis.DocumentTypes       = createApi(apiUrl, "documenttypes");
  Apis.Checklists          = createApi(apiUrl, "checklists");
  Apis.ReportQueue         = createApi(apiUrl, "reportqueue");
  
  Apis.isInitialized = true;
  return {
    ok      : true, 
    error   : null,
  };
}

export default Apis;

//=============================
// OTHER EXPORTS
//=============================

//---- manually fetches a pdf file from the server. Necessary because the
// default approach, through apisauce wasn't working for the pdf files.
export const manualFetch = async (path, contentType = "application/pdf") => {
  const url = `${Apis.url}${path}`;
  const token = await acquireMsalToken();
  if(!token){
    throw new Error("Failed to acquire authentication token for API request. This may happen if you attempt to access CheckList Tracker with an invalid account. Please sign out and and back in to try again.");
  }

  const result = await fetch(url, {
    method: "get",
    headers: new Headers({
      "Authorization": `Bearer ${token.accessToken}`,
      "Content-Type": contentType,
      "Accept": contentType,
    })
  });

  return result;
}

//---Takes a result and dispatches the necessary actions as a result of the action
export const handleResult = (dispatch, result, okAction, errorAction, otherProps) => {
  if(result.ok){
      const pagination  = (result.headers && result.headers.pagination) ? JSON.parse(result.headers.pagination) : null;
      
      const output  = {
        type          : okAction, 
        ok            : result.ok,
        response      : result.data || result, 
        pagination    : pagination,
        params        : result.config?.params,
        ...otherProps
      };

      return dispatch(output);
  }
  else{
    return dispatch({type: errorAction, response: result, ...otherProps});
  }
}

//--------------------------
// Takes a result and dispatches the necessary actions as a result of the action
export async function handleResultAsync(dispatch, result, okAction, errorAction, otherProps){
  if(result.ok){
    const pagination  = (result.headers && result.headers.pagination) ? JSON.parse(result.headers.pagination) : null;
    
    const output  = {
      type          : okAction, 
      ok            : result.ok,
      response      : result.data || result, 
      pagination    : pagination,
      ...otherProps
    };

    try{
      return await dispatch(output);
    }
    catch(ex){
      //catch an exception thrown by the reducer
      const err = normalizeError(ex, "Refresh Required", "Your changes were saved, but an error occurred after.")
      return {
        ok: false,
        error: err,
        errorInfo: "reducer error",   //just some info to help with troubleshooting
        response: result
      };
    }
  }
  else{
    return await dispatch({type: errorAction, response: result, ...otherProps});
  }
}


//---Handle a blob/arraybuffer response and trigger the download
export async function downloadFile(result, mimeType, isRedirect){
  const cd  = result.headers[CD_HEADER] || result.headers[CD_HEADER_CAP];

  if(cd){
      //The file to download was returned as a Blob in the response.
      let fileName    = null;
      const cdParts   = cd.split(';');
      const fname     = _.find(cdParts, cdp => cdp.indexOf("filename=") >= 0);
      if(fname){
          fileName    = fname.trim().substr("filename=".length);
      }

      if(fileName && mimeType){
          var linkElement = document.createElement('a');
          try {
              var blob = new Blob([result.data], { type: mimeType });
              var url = window.URL.createObjectURL(blob);
  
              linkElement.setAttribute('href', url);
              linkElement.setAttribute("download", fileName);
  
              var clickEvent = new MouseEvent("click", {
                  "view": window,
                  "bubbles": true,
                  "cancelable": false
              });
              linkElement.dispatchEvent(clickEvent);

              return true;    //indicate it worked

          } catch (ex) {
              console.log(ex);
          }
      }
  }
  else if(isRedirect){
      //This is a redirect-type download.  Create a link and click it for the URL that is returned in the response
      var redirectElement = document.createElement('a');
      try {
          //NOTE: Server is required to return a response with a property named downloadUrl
          var redirectUrl = result.data.downloadUrl;

          redirectElement.setAttribute("href", redirectUrl);
          redirectElement.setAttribute("target", "_blank");
          // linkElement.setAttribute("download", fileName);

          var redirectClick = new MouseEvent("click", {
              "view": window,
              "bubbles": true,
              "cancelable": false
          });
          redirectElement.dispatchEvent(redirectClick);

          return true;    //indicate it worked

      } catch (ex) {
          console.log(ex);
      }
  }

  return false;
}

//---Handle a blob/arraybuffer response and trigger the download
export async function downloadBody(result, mimeType, openInTab = false){
  const fileName = getFileName(result.headers, "download.pdf");
  if(fileName && mimeType){
    const data = await result.blob();
    if(!openInTab){
      return openFile(data, fileName, mimeType);
    }
    else{
      return openFileInTab(data, fileName, mimeType);
    }
  }

  return false;
}

//=============================
// HELPER FUNCTIONS
//=============================

//---Request Handler that will get the token from the adal stuff and add it to the 
// NOTE: This is now a fallback. The toke acquisition should be happening in the
// auth-provider.tsx, so that it can either come from the url or msal, depending.
function addTokenFallback(path){
  return async (request) => {    
    const token = await acquireMsalToken();

    if(token){
      request.headers.Authorization = `Bearer ${token.accessToken}`;
    }  
    else{
      throw new Error("Failed to acquire authentication token for API request. This may happen if you attempt to access CheckList Tracker with an invalid account. Please sign out and and back in to try again.");
    }
  }
}


//---Creates an api based on the standard template of apis.
// function createApiOriginal(base, path, headers, noAuth = false){
//   const api   = create({
//     baseURL   : `${base}/${path}`,
//     headers   : {...DEFAULT_HEADERS, ...headers},
//   });

//   if(!noAuth){
//     api.addAsyncRequestTransform(addTokenToRequest(path));
//   }
//   //TODO: Could put the download file handler as a monitor on the response...
//   return api;
// }

//---
// Takes the app config, and then returns a function that will create an API. The
// app config is used to determine how the bearer token is added to the request,
// since the token can either come from the MSAL library, or directly from the
// URL Search string, in the case of the report generator.
const createApiFactory = (config) => (base, path, headers, noAuth = false) => {
  const api   = create({
    baseURL   : `${base}/${path}`,
    headers   : {...DEFAULT_HEADERS, ...headers},
  });

  if(!noAuth){
    if(config.tokenFactory){
      api.addRequestTransform(config.tokenFactory);
    }
    else if(config.tokenFactoryAsync){
      api.addAsyncRequestTransform(config.tokenFactoryAsync(path));
    }
    else{
      api.addAsyncRequestTransform(addTokenFallback(path));
    }
  }
  //TODO: Could put the download file handler as a monitor on the response...
  return api;
};

//---
// Creates a standard api, with default headers and no authentication. Used to 
// create the config endpoint to get the setup for the rest of the protected
// apis.
function createAnonymousApi(base, path){
  const api   = create({
    baseURL   : `${base}/${path}`,
    headers   : { ...DEFAULT_HEADERS },
  });

  return api;
}

//----
// Gets the file name from the content disposition header of a request, or
// uses a fallback if necessary
const getFileName = (headers, fallback) => {
  let cd = headers.get(CD_HEADER_CAP);  //this comes through both capitalized and lower case.
  if(!cd)  cd = headers.get(CD_HEADER); //to be safe, check both

  let fileName    = null;
  if(cd){
    //The file to download was returned as a Blob in the response.
    const cdParts   = cd.split(';');
    const fname     = _.find(cdParts, cdp => cdp.indexOf("filename=") >= 0);
    if(fname){
        fileName    = fname.trim().substr("filename=".length);
    }
  }
  else{
    fileName = fallback;
  }

  return fileName;
}

//----
// Takes blob data, and opens it (a.k.a. downloads it) using a
// dynamic link to an object url.
const openFile = (blobData, fileName, mimeType) => {
  try {
    var linkElement = document.createElement('a');
    var blob = new Blob([blobData], { type: mimeType });
    var url = window.URL.createObjectURL(blob);

    linkElement.setAttribute('href', url);
    linkElement.setAttribute("download", fileName);

    var clickEvent = new MouseEvent("click", {
        "view": window,
        "bubbles": true,
        "cancelable": false
    });
    linkElement.dispatchEvent(clickEvent);

    return true;    //indicate it worked
  
  } catch (ex) {
      console.log(ex);
  }

  return false;
}

//----
// Takes blob data, and opens it (a.k.a. downloads it) using a
// dynamic link to an object url.
const openFileInTab = (blobData, fileName, mimeType) => {
  try {
    var blob = new Blob([blobData], { type: mimeType });
    var url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
    return true;    //indicate it worked
  
  } catch (ex) {
      console.log(ex);
  }

  return false;
}
