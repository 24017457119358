import React from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { addCheckListItemKeys } from 'utils/checklist-helpers';
import { useCurrentDocument, useCurrentPermissions } from 'hooks';
import { SectionItem, ApprovalCard } from './';

function CheckList({checkList}){
  const { classes }   = buildStyles();
  const doc       = useCurrentDocument();
  const perms     = useCurrentPermissions();
  let items       = checkList.checkListItems;
  
  //Make sure we're all setup and ready to go
  if(!items[0].key){
    items     = addCheckListItemKeys(items);
  }
  const sections  = _.filter(items, i => i.isSection);


  return (
    <Grid id={"checklist-" + checkList.id} container direction="column">
      {checkList.description && 
        <Grid item container className={classes.descriptionGrid} title="CheckList Description">
          <Typography className={classes.description}>{checkList.description}</Typography>
        </Grid>
      }
      <Grid item container direction="row" sx={{ mx: -1, width: "102%" }}>
        {_.map(sections, item => <SectionItem key={item.id} item={item} isReadOnly={perms.isReadOnly}/>)}
      </Grid>

      <Grid item container direction="row" sx={{ mx: -1, width: "102%" }}>
        <ApprovalCard checkList={checkList} doc={doc}/>
      </Grid>
    </Grid>
  );
}

export default CheckList;

const buildStyles   = makeStyles()(theme => ({
  descriptionGrid   : {
    margin        : `-${theme.spacing(1)} -${theme.spacing(2)} ${theme.spacing(1)} -${theme.spacing(1)}`,
    padding       : theme.spacing(1),
    backgroundColor   : theme.palette.grey[200],
    width         : "102%",
  },
  description : {
    fontSize      : "1.2rem",
    fontWeight    : 300,
    color         : theme.palette.grey[700],
  },

}));