import _ from 'lodash';
import { blue, green, yellow } from '@mui/material/colors';
// import green from '@mui/material/colors/green';
// import yellow from '@mui/material/colors/yellow';
import { REPORT_API_PATHS } from './report-helpers';
import { downloadBody, manualFetch } from 'store/api/quanta-api';

export const APPROVE_TYPES  = {
  approve   : "APPROVE",
  preApprove: "PREAPPROVE",
  unApprove : "DISAPPROVE",
};

export const APPROVE_CODES = {
  approve: 100,
  preApprove: 50,
  pending: 0,
};

const STATUS_MAP  = [
  {
    keys        : [0, "pending", "PENDING", "Pending", "disapprove", "DISAPPROVE",],
    code        : 0,
  },
  {
    keys        : [50, "pre-approve", "PREAPPROVE", "Pre-Approve"],
    code        : 50,
  },
  {
    keys        : [100, "approve", "APPROVE", "Approve"],
    code        : 100,
  },  
];

export const getStatusCode = (code) => {
  const mapItem  = _.find(STATUS_MAP, s => s.keys.indexOf(code) >= 0) || STATUS_MAP[0];
  return mapItem.code;
}

export const getSetStatusRecord = function(reviewSet, statusType = 'APPROVE'){
    
  if(_.isArray(statusType)){
      return _.filter(reviewSet.reviewSetStatuses, rss => statusType.indexOf(rss.status) >= 0);
  }

  return _.find(reviewSet.reviewSetStatuses, {status: statusType});
};

//----------------
// Generates styles for the status classes of an assertion / item
//
export function statusBackgrounds(theme){
  return {
    "& .approved"   : {
      background      : blue[50],
    },
    "& .preApproved"   : {
      background      : green[50],
    },
    "& .pending"   : {
      background      : yellow[50],
    },
  };
}

export const openReviewSetReport = async (reviewSetId, isOpen = true) => {
  if(reviewSetId){
    const url = REPORT_API_PATHS.reviewSetDownload(reviewSetId);
    const result = await manualFetch(`/reports${url}`, "application/pdf");

    if(result.ok){
      console.log("report download successfully");      
      // const downloadedOk = await downloadBody(result, "application/pdf");
      const downloadedOk = await downloadBody(result, "application/pdf", isOpen);
      if(!downloadedOk){
        console.error("failed to download reviewset approval report.");
      }
    }
    else if(result.error){
      //something bad happened with the report
      console.error("failed to get reviewset approval report.", result.error);
    }
};
}