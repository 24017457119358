import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ReportIcon from '@mui/icons-material/Description';
import { CltHidden, MenuIcon, Status } from 'features/common';
import { getSetSummary } from 'store/selectors/reviewset-selectors';
import { CardActions, TableCell, TableRow, Tooltip } from '@mui/material';
import { CltTableCardRaw } from 'features/common/table';
import { reportMenuOptions } from './reviewset/reviewset-utils';
import { appRoutesLegacy } from 'utils/route-helpers';

const ReviewSetsListItem = (props) => {
  const { classes } = buildStyles();
  const mySet = getSetSummary(props);
  const reportPath = mySet ? appRoutesLegacy.reports.reviewSetItemReport(mySet.id) : "";

  return(
    <>
      {/* For Tablet / Desktop */}
      <CltHidden smDown>
        <TableRow>
          <TableCell component="th" scope="row" sx={{fontWeight: "bold"}}>{mySet.id}</TableCell>
          <TableCell><Link to={mySet.url}>{mySet.title || "[Unnamed ReviewSet]"}</Link></TableCell>
          <TableCell><Status code={mySet.statusCode}/></TableCell>
          <TableCell>
              <span className="set-modified-on">{mySet.lastModifiedDate}</span><span className="set-modified-by"> by {mySet.lastModifiedUser}</span>
          </TableCell>
          <TableCell>{mySet.description || mySet.notes}</TableCell>
          <TableCell>
            {mySet.reportLocation ? 
              <Tooltip title="Download approval report">
                <>
                  <MenuIcon icon={ReportIcon} fontSize="small" sx={{cursor: "pointer"}} options={reportMenuOptions(mySet, reportPath)} />
                </>
              </Tooltip> : null
            }
          </TableCell>
        </TableRow>
      </CltHidden>

      {/* For Mobile */}
      <CltHidden smUp>
        <CltTableCardRaw>
          <CardContent>
            <Grid container>
              <Grid item xs={12} container wrap="wrap" className={classes.cardTitle}>
                <Link to={mySet.url}>{mySet.id}. {mySet.title || "[Unnamed ReviewSet]"}</Link>
              </Grid>
              <Grid item xs={12} className={classes.cardText}>
                <span><span className={classes.cardLabel}>modified</span> {mySet.lastModifiedDate}</span><br/>
                <span><span className={classes.cardLabel}>by</span> {mySet.lastModifiedUser}</span>
              </Grid>
              <Grid item xs={12} className={classes.cardNotes}>
                {mySet.description || mySet.notes}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Status code={mySet.statusCode}/>
          </CardActions>
        </CltTableCardRaw>
      </CltHidden>
    </>
    
  );
}

export default ReviewSetsListItem;

const buildStyles   = makeStyles()(theme => ({
  cardTitle: {
    paddingBottom: theme.spacing(0.5),
  },
  cardStatus: {
    paddingBottom: theme.spacing(0.5),
  },
  cardText: {
    fontSize: theme.typography.body2.fontSize,
    paddingBottom: theme.spacing(0.5),
  },
  cardLabel: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[400]
  },
  cardNotes: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

ReviewSetsListItem.propTypes = {
  set     : PropTypes.object.isRequired,
  target  : PropTypes.string.isRequired,
};