import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { WaitContainer } from 'features/common';
import { selectSetDocTypes } from 'store/selectors/reviewset-selectors';

function AddNewPanel({doc, onDocChanged}){
  const { classes }     = buildStyles();
  const docTypes    = useSelector(selectSetDocTypes);
  const isSaving    = useSelector(state => state.docs.isCreating);
  const noDocTypes  = docTypes.length === 0;              
  
  const handleChange = (e, value) => {
    let updates   = value ? { docTypeId  : value?.id, docType: value } : { docKey: e.target.value };
    onDocChanged(updates);
  }

  const filterOptions   = createFilterOptions({
    stringify: opt => opt.name,
  });

  return (
    <WaitContainer isWaiting={false} isEmpty={noDocTypes} emptyMessage="There are no Document Types available.  Please contact your system administrator.">
      <Typography className={classes.instructions}>Please enter the requested information and click Save</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} container>
          <TextField id="docKey" label="Document Key" value={doc.docKey} onChange={handleChange} fullWidth disabled={isSaving}/>
        </Grid>
        <Grid item xs={12} md={8} container>
          <Autocomplete id="docId" 
              value={doc.docType} onChange={handleChange} disabled={isSaving} style={{width: "100%"}}
              options={docTypes} 
              filterOptions={filterOptions}
              getOptionLabel={opt => opt.name}
              renderInput={params => <TextField {...params} label="Document Type" />}
            />
        </Grid>        
      </Grid>
    </WaitContainer>
  )
}

export default AddNewPanel;

const buildStyles   = makeStyles()(theme => ({
  instructions  : {
    color         : theme.palette.grey[800],
    marginBottom  : theme.spacing(2),
  },
}));