import React, { useState } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AdminTaskPanel from './admin-task-panel';
import UnapproveReviewSetTask from './unapprove-rs-task';
import DeleteReviewSetTask from './delete-rs-task';
import QueueReportsTask from './queue-reports-task';

const AdminTasksView = () => {
  const [expanded, setExpanded]   = useState(false);

  const onExpand = (panel) => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  return (
    <Card id="engagements-card" sx={{width: "100%"}}>
      <CardContent>
        <Grid>   
          <Grid item xs={12}>
            <Typography variant="h5">
              Administrative Tasks
            </Typography>
          </Grid>       
          {
            _.map(Tasks, task => <AdminTaskPanel key={task.id} task={task} isExpanded={expanded === task.id} onExpand={onExpand} />)
          }
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AdminTasksView;

const Tasks = [
  {
    id      : "unapprove_rs",
    label   : "Unapprove Multiple ReviewSets",   
    component   : <UnapproveReviewSetTask />,
    statusKey   : "unapprove_rs"
  },
  {
    id      : "delete_rs",
    label   : "Delete ReviewSet",
    component   : <DeleteReviewSetTask />,
    statusKey   : "delete_rs"
  },
  {
    id      : "queue_reports",
    label   : "Queue ReviewSet Reports",
    component   : <QueueReportsTask />,
    statusKey   : "queue_reports"
  }
]