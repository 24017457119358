import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography, } from '@mui/material';
import AssignmentsIcon from '@mui/icons-material/AssignmentInd';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
import { saveAssignmentThunk } from 'store/thunks';
import { selectEngagementAssignment, selectStatusByKey } from 'store/selectors/admin-selectors';
import { ErrorDisplay, WaitButton } from 'features/common';
import { useInputHandler } from 'hooks/general-hooks';
import { adminDialogClasses } from 'features/common/dialog-classes';
import { muiDialogReason } from 'utils/ui-helpers';

const defaultAssignment   = { assignedUserId: "", role: "user" };
const ROLES = [
  { id: 0, value: "reader", label: "Reader" },
  { id: 10, value: "user", label: "Editor"},
  { id: 20, value: "approver", label: "Approver" },
];

const AssignmentEditDialog = ({isOpen, onClose, assignmentId}) => {
  const { classes }       = buildStyles();
  const dispatch      = useAppDispatch();
  const status        = useSelector(state => selectStatusByKey(state, "assignments"));
  const assignment    = useSelector(state => selectEngagementAssignment(state, assignmentId));
  const [item, , setItem, onSelectChange]  = useInputHandler({...assignment});
  const canSave = useMemo(() => { return Boolean(assignment && item && assignment.role !== item.role)}, [assignment, item]);
  
  if(!isOpen || !assignment) return null;

  async function onSave(andClose){
    if(canSave){
      const result  = await dispatch(saveAssignmentThunk({ id: assignment.id, model: item }));
      if(result?.payload?.ok){
        setItem({...defaultAssignment});
        //Close the dialog and provide the info
        if(andClose === true) onClose(true);
      }
    }
  }

  function doClose(event, reason){
    if(status.isWorking && muiDialogReason.isEither(reason)) return;
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={doClose} className={classes.dialog} maxWidth="md">
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between">
          <Typography className={classes.titleText}>
            <Tooltip title="Project">
              <AssignmentsIcon fontSize="small" className={classes.titleIcon}/>
            </Tooltip>
            Edit Assignment
          </Typography>
          <CloseIcon fontSize="small" onClick={onClose} className={classes.closeIcon}/>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item container>
            <ErrorDisplay error={status.error} />
          </Grid>

          <Grid item container className={classes.formRow}>
            <Typography>{assignment?.user?.displayName}</Typography>
          </Grid>

          <Grid item container className={classes.formRow}>
            <FormControl className={classes.formControl} label="Role" fullWidth >
              <InputLabel id="role-label" className={classes.selectLabel}>Role</InputLabel>
              <Select id="role" labelId="role-label" label="Role" value={item.role} onChange={onSelectChange("role")} fullWidth className={classes.selectContainer} classes={{select: classes.select}}  size="small">
                <MenuItem value="" disabled className={classes.option}><em>Select Role</em></MenuItem>
                {_.map(ROLES, option => <MenuItem key={option.id} value={option.value} name="role" className={classes.option}>{option.label}</MenuItem>)}              
              </Select>
            </FormControl>
          </Grid>
          
        </Grid>
        
      </DialogContent>

      <DialogActions>
        <Button size="small" disabled={status.isWorking} onClick={onClose}>Cancel</Button>
        {/* <WaitButton isWaiting={status.isWorking} disabled={!canSave} onClick={onSave} color="secondary" size="small">Save</WaitButton> */}
        <WaitButton isWaiting={status.isWorking} disabled={!canSave} onClick={() => onSave(true)} color="secondary" size="small">Save and Close</WaitButton>
      </DialogActions>
    </Dialog>      
  );
}

export default AssignmentEditDialog;

const buildStyles   = makeStyles()(theme => ({
  ...adminDialogClasses(theme),
  dialog  : {
    "& .MuiDialog-paper"  : {
      minWidth    : 450,
    }
  },
  switchGrid      : {
    marginLeft    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  switchLabel   : {
    color           : theme.palette.grey[600],
    fontSize        : 16,
  },
}));