import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { IfBlock } from 'features/common';
import { parseError } from 'utils/error-helpers';
import { useAppDispatch } from 'store';
import { logoutThunk } from 'store/thunks/app-thunks';

function ErrorView({code, title, header, message, error}){

  const { classes }   = buildStyles();
  const dispatch  = useAppDispatch();
  const details   = parseError(code, title, header, message, error);

  function onLogout(){
    return dispatch(logoutThunk());
  }

  return (
    <Grid id="not-found" container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item xs={6}>
        <Card className={classes.errorCard}>
          {/* title */}
          <IfBlock condition={details.title}>
            <Grid item container justifyContent="center" alignItems="center" className={classes.titleGrid}>
              <Typography variant="h2" className={classes.title}>{details.title}</Typography>
            </Grid>
          </IfBlock>
          {/* body */}
          <Grid container justifyContent="center" alignItems="center" className={classes.bodyGrid}>
            <Grid item xs={12} container justifyContent="center">
              <Grid item xs={12} container justifyContent="center">
                <Typography variant="h2" className={classes.header}>{details.header}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.message}>{details.message}</Typography>
              </Grid>

              {/* action */}
              <IfBlock condition={details.action}>
                <Grid item container className={classes.message}>
                  <Typography variant="h2" className={classes.message}>{details.action}</Typography>
                </Grid>
              </IfBlock>
            </Grid>


            {/* Footer */}
            <Grid item xs={12} container spacing={2} justifyContent="center">              
              <Button variant="contained" color="secondary" component={Link} to="/" className={classes.btn}>Return Home</Button>
              <Button variant="outlined" color="secondary" onClick={onLogout} className={classes.btn}>Sign Out</Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ErrorView;

const buildStyles   = makeStyles()(theme => ({
  root        : {
    height        : "95vh",
  },
  errorCard   : {
    backgroundColor   : theme.palette.error.light,
    color             : theme.palette.error.contrastText,
    borderColor       : theme.palette.error.main,
  },
  titleGrid   : {
    backgroundColor   : theme.palette.error.main,
    borderBottom      : `1px solid ${theme.palette.error.main}`, //`
    color             : theme.palette.common.white,
    padding           : theme.spacing(2),
  },
  title   : {
    fontSize          : 23,
    fontWeight        : 500,    
  },
  bodyGrid  : {
    padding           : theme.spacing(2),
  },
  header  : {
    fontSize          : 20,
    fontWeight        : 400,
    marginBottom      : theme.spacing(2),
  },
  message    : {
    fontSize          : 18,
    fontWeight        : 300,
    marginBottom      : theme.spacing(2),
  },
  btn : {
    margin  : theme.spacing(1),
  }
}));

// const ERROR_CODES   = {
//   404   : {
//     code      : 404,
//     title     : "Not Found",
//     header    : "The item was not found",
//     message   : "This could happen if you entered an invalid url, the item was removed, or you don't have permissions to view the item.",
//     action    : "You may need to sign out and back in to continue."
//   },
//   401   : {
//     code      : 401,
//     title     : "Not Authorized",
//     header    : "You are not authorized to perform this request.",
//     message   : "This could happen if your login token expired, your password has expired or changed or your permissions have changed.",
//     action    : "You may need to sign out and back in to continue."
//   },
//   "NETWORK_ERROR"   : {
//     code      : null,
//     title     : "Network Error",
//     header    : "The system encountered a problem connecting to the server.",
//     message   : "This could happen if there is a network problem, if you did not provide consent for this application, or if there was an issue with your token.",
//     action    : "You may need to sign out and back in to continue."
//   },
//   "popup_window_error"   : {
//     code      : null,
//     title     : "Popup Window Error",
//     header    : "Error opening popup window",
//     message   : "This can happen if you are using IE or if popups are blocked in the browser. You need to grant permission for this website to show popup windows (we promise not to abuse this privilege).",
//     action    : "Click the popup icon at the end of your browser address bar and allow popups for this site. Then refresh the page (F5)"
//   }
// };

// function parseError(code, title, header, message, error){
//   if(code || error?.errorCode || error?.problem){
//     const errCode   = code ? parseInt(code) : (error?.errorCode || error?.problem);
//     const err       = ERROR_CODES[errCode];
//     if(err){
//       return err;
//     }
//   }  

//   if(error){
//     if(error.error) error = error.error;  //unwrap the contained error

//     const errCode   = error.status;
//     if(errCode){
//       const err       = ERROR_CODES[errCode];
//       if(err){
//         return err;
//       }
//     }

//     if(error.header && error.message){
//       return {...error};
//     }

//     //Still here, so the code isn't one of the known ones...
//     return {
//       code      : errCode,
//       title     : "",
//       header    : error.problem || "An error occurred",
//       message   : error.message || error.originalError?.message,
//     }
//   }
//   else if(title || header || message){
//     return {
//       code      : -1,
//       title     : title,
//       header    : header || "An error occurred",
//       message   : message || "We're sorry for this inconvenience.  Please select an option below to continue.",
//     }
//   }

// }
