import React from 'react';
import _ from 'lodash';
import { APPROVE_CODES, APPROVE_TYPES, getStatusCode } from "utils/reviewset-helpers";
import { formatDate } from './general-helpers';
import { DATE_TIME_REPORT_FORMAT } from './date-helpers';

export const REPORT_API_PATHS = {
  reviewSetStatus: (setId) => `/reviewsets/${setId}/status`,
  reviewSetDownload: (setId) => `/reviewsets/${setId}/download`,
};

const getStatusRecord = function(reviewSet, statusType = APPROVE_TYPES.approve){
    
  if(_.isArray(statusType)){
      return _.filter(reviewSet.reviewSetStatuses, rss => statusType.indexOf(rss.status) >= 0);
  }

  return _.find(reviewSet.reviewSetStatuses, {status: statusType});
};

const getStatusElement = function(set){
  let status      = null;
  var maxStatus   = _.maxBy(set.reviewSetStatuses, status => Date(status.statusDate));
  if(maxStatus && maxStatus.status === APPROVE_TYPES.preApprove){
      status  = (<span className="status status-pending">Pre-Approved</span>)
  }
  else if(maxStatus && maxStatus.status === APPROVE_TYPES.approve){
      status  = (<span className="status status-approved">Approved</span>)
  }
  else{
      status  = (<span className="status status-pending">Pending</span>);
  }
  
  return status;
}

const getStatusElement2 = function(vSet){
  let status      = null;
  let statusCode  = vSet.statusCode;
  if(statusCode === APPROVE_CODES.preApprove){
      status  = (<span className="status status-pending">Pre-Approved</span>)
  }
  else if(statusCode === APPROVE_CODES.approve){
      status  = (<span className="status status-approved">Approved</span>)
  }
  else{
      status  = (<span className="status status-pending">Pending</span>);
  }
  
  return status;
}


export const getReviewSetStatusForReport = function(set){
    
  if(set.reviewSetStatuses && set.reviewSetStatuses.length > 0){
      const rec   = getStatusRecord(set);
      return {
          label   : getStatusElement(set),
          on      : rec ? formatDate(rec.statusDate, DATE_TIME_REPORT_FORMAT) + ' (UTC)' : null,
          by      : rec?.userName ?? set.statusUserName ?? null,
          code    : rec ? getStatusCode(rec.status) : 0,
      };
  }
  else if(_.isNumber(set.statusCode)){
      return {
          label   : getStatusElement2(set),
          on      : set.statusDate ? formatDate(set.statusDate, DATE_TIME_REPORT_FORMAT) + ' (UTC)' : null, 
          by      : set.statusUserName,
          code    : set.statusCode,
      }    
  }

  return null;

}

export const fetchFromCDN = (src, externals = []) => {
  new Promise((resolve, reject) => {
    const script = document.createElement('link')
    script.setAttribute('href', src)
    script.setAttribute('rel', 'stylesheet');
    script.setAttribute('type', 'text/css');
    script.addEventListener('load', () => {
      resolve(externals.map(key => {
        const ext = window[key]
        typeof ext === 'undefined' && console.warn(`No external named '${key}' in window`)
        return ext
      }))
    })
    script.addEventListener('error', reject)
    document.body.appendChild(script)
  })
}