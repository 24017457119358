import { adminSlice } from "./slices/admin-slice";
import { appSlice } from "./slices/app-slice";
import { builderSlice } from "./slices/builder-slice";
import { documentSlice } from "./slices/document-slice";
import { documentsSlice } from "./slices/documents-slice";
import { reviewsetSlice } from "./slices/reviewset-slice";

export const { appInitialized, loggedIn, loggedOut, } = appSlice.actions;
export const { approvalCleared } = reviewsetSlice.actions;
export const { clearCreateDocError } = documentsSlice.actions;
export const { checkListSelected, checkListFiltered, clearApproving } = documentSlice.actions;
export const { clearStatus: clearAdminStatus } = adminSlice.actions;
export const { 
  startBuilderChecklist, 
  modifyBuilderChecklist, 
  resetBuilderChecklist, 
  clearBuilderStatus,
  updateBuilderSection,
  toggleBuilderSection,
  updateBuilderItem,
  moveBuilderItem,
  insertBuilderSection,
  insertBuilderItem,  
 } = builderSlice.actions;