import { useDispatch } from 'react-redux';
import { apiResponseToAction } from "api";
import { ApiErrorResponse, ApiResponse } from "apisauce";
import { pick } from "lodash";
import { Dispatch } from "redux";
import Apis from "store/api/quanta-api";
import { normalizeError, prune } from "utils/general-helpers-ts";
import { INormalizedError, IQuantaApi, ApiResponseAction } from "../app-types";
import { useCallback } from 'react';

//===
// Used to extract only the relevant parameters from the search string parameters
// this will retrieve the ["search", "filter", "sort"] properties by default,
// and prune out any values that are empty.
export const getSearchProps = (queryObj: Record<string, any> | null | undefined, props: string[] = ["search", "filter", "sort"]) => {
  //Only a couple of the props are needed from the 'filter' string that's passed in here
  if(!queryObj) return {};
  const sProps = prune(pick(queryObj, props));
  return sProps;
};

//===
// Used to access the Quanta API from a typescript file in a strongly-typed way.
export const getQuantaApi = () => {
  return (Apis as unknown) as IQuantaApi;
}

//TODO: Convert the status dispatcher stuff to middleware that just
// keys off a status: {} in the action object.
// https://redux.js.org/tutorials/fundamentals/part-6-async-logic#using-middleware-to-enable-async-logic

//---
// Factory method that returns a statusDispatcher function that will dispatch a status update.
// returns:
//   Action(isWorking: boolean, error: INormalizedError | null = null)
// usage:
//   const dispatchStatus = statusDispatcherFactory(dispatch, statusActionType, statusKey);
//   await dispatchStatus(true, null);    //toggle to working
//   await dispatchStatus(false, error);  //not working, with an error
//   await dispatchStatus(false);         //not working, no error
export const statusDispatcherFactory = (dispatch: Dispatch<any>, statusActionType: string, statusKey: string) => 
  async (isWorking: boolean, error: INormalizedError | null = null) => {
    const statusAction = {
      type: statusActionType,
      key: statusKey,
      value: {
        isWorking,
        error,
      }
    };

    return await dispatch(statusAction);
  }

//This function will return an ActionWrapper function that can be used to automatically handle the status
// for an operation and reducer
// params:
//  statusAction: the ACTION_TYPE that will update the status for the appropriate reducer
//  noOpAction: the 
export function dispatchWithStatusFactory(statusKey: string, statusActionType: string, errorActionType: string){

  const dispatchWrapper = <T = any>(dispatch: Dispatch<any>, successActionType: string) => async (
    action: () => Promise<ApiResponse<T>>, 
    otherProps?: Record<string, any>) : Promise<ApiResponseAction<T>> => {

      const dispatchStatus = statusDispatcherFactory(dispatch, statusActionType, statusKey);
      await dispatchStatus(true, null);

      try{
    
        //perform the action in question
        const response  = await action();
        const responseAction = await apiResponseToAction<T>(response, successActionType, errorActionType, otherProps); 
            
        // if(response.ok){
          try{
            const actionResult = await dispatch(responseAction);
            await dispatchStatus(false, responseAction.error);  //responseAction.error will be undefined if there is no error
            return actionResult;
          }
          catch(ex){
            //The action completed, but updating the reducer failed
            const reducerError = normalizeError(ex, "Refresh Required", "Your changes were saved, but an error occurred processing the result. Please refresh the page.");
            await dispatchStatus(false, reducerError);
            return responseAction;
          }
      }
      catch(ex){
        //This means the call to action() throw an exception. Probably a bug in the action method.
        // return await dispatch({type: statusActionType, key: statusKey, value: { isWorking: false, error: ex}});
        const apiError = normalizeError(ex, "Unexpected Action Failure", "Your changes were not saved. Please try again and contact your administrator if this problem persists.");
        dispatchStatus(false, apiError);

        return {
          type: errorActionType,
          ok: false,
          error: apiError,
          errorInfo: "unexpected action error",
          response: {
            ok: false,            
          } as ApiErrorResponse<any>,
        }
      }
  };

  return dispatchWrapper;
}

export function parseResponse<T>(dispatchResponse: any){
  var typed = (dispatchResponse as unknown) as ApiResponseAction<T>;
  return typed;  
}

// export type UnwrappableAction<TResult> = (dispatch: Dispatch<any>, getState: GetState) => Promise<QuantaApiResponse<TResult>>;

export const useDispatchWithApi = () => {
  const dispatch = useDispatch();

  //wraps the dispatch call so that the result can be parse and returned as an QuantaApiResponse<TResult>
  const dispatchWrapper = useCallback(async <TResult = any>(action: any) => {
    const dispatchResult = await dispatch(action);
    const result = parseResponse<TResult>(dispatchResult);
    return result;
  }, [dispatch]);
  
  return dispatchWrapper;
}

// export const useDispatchApi = () => {
//   const dispatch = useDispatch();

//   //wraps the dispatch call so that the result can be parse and returned as an QuantaApiResponse<TResult>
//   const dispatchWrapper = async <TResult = any>(action: AnyAction) => {
//     const dispatchResult = await dispatch<unknown>(action);
//     // const result = parseResponse<TResult>(dispatchResult);
//     return dispatchResult as ApiResponse<TResult>;
//   }
  
//   return dispatchWrapper;
// }