import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import grey from '@mui/material/colors/grey';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { formatDate } from 'utils/general-helpers';
import { useCurrentDocument } from 'hooks';

function CheckBoxesOption({item, input, choices, response, value, handleChange, isReadOnly}){
  const { classes }     = buildStyles();
  const doc         = useCurrentDocument();
  const hasRsp      = Boolean(response);
  const [values, setValues] = useState(splitValues(value));

  //Need to update the local values when the value changes
  useEffect(() => {
    setValues(splitValues(value));
  }, [value]);

  const onChanged = (e) => {
    const val         = e.target.value;
    const isChecked   = e.target.checked;
    
    //Do it this way because we want the selections to be in the same order as the 
    // choices, for historical and reporting purposes.
    let selections  = isChecked ? values.concat(val) : _.without(values, val);
    const temp      = _.map(choices, c => { return selections.indexOf(c.value) >= 0 ? c.value : null; });
    selections      = _.compact(temp);
    handleChange(selections.length > 0 ? selections.join(",") : "");
  }

  const responseIcon   = useMemo(() => {
    if(hasRsp){
      const rspDate = hasRsp ? formatDate(response.responseDate) : "";
      const tip     = `by ${response?.userName} on ${rspDate}`; //`
      return (
        <Tooltip title={tip}>
          <InfoOutlinedIcon fontSize="small" className={classes.infoIcon} style={{color: grey[600]}}/>
        </Tooltip>
      );
    }
    else 
      return null;
  
  }, [classes.infoIcon, hasRsp, response?.responseDate, response?.userName]);

  const isDisabled  = item.isWorking || item.isFinished || !doc.isPrimary || isReadOnly;
  
  return (
    <FormControl id={"form-" + input.id} variant="outlined" className={classes.multiCheckOptions} fullWidth>
      <Grid container>
        <Grid item container>
          <Grid item xs={8}>
            <FormLabel id={"label-" + input.id} className={classes.formLabel}>{input.caption}</FormLabel>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
            {(hasRsp && !isDisabled) && <Button size="small" variant="outlined" className={classes.miniButton} title="Clear the selected value" onClick={() => handleChange("")}>clear</Button>}
          </Grid>
          <Grid item xs={1} container justifyContent="center" alignItems="center">
            {responseIcon}
          </Grid>
        </Grid>      
        <Grid item container className={classes.itemsContainer}>
          <FormGroup  id={"input-" + input.id} value={value} margin="dense" className={classes.checkGroup} onChange={onChanged}>
            {_.map(choices, c => <FormControlLabel key={c.value} value={c.value} checked={isChecked(c.value, values)} className={classes.checkItem} disabled={isDisabled} control={<Checkbox size="small" />} label={c.label} />)}
          </FormGroup> 
        </Grid>
      </Grid>
    </FormControl>  
  );
}

export default CheckBoxesOption;

function splitValues(values){ return values ? _.map(values.split(','), itm => itm.trim()) : []; }
function isChecked(value, values) { return values && values.indexOf(value) >= 0; }

const buildStyles   = makeStyles()(theme => ({
  formLabel   : {
    fontSize      : 14,
    marginBottom  : theme.spacing(1),
    borderBottom  : "none",
  },
  checkGroup  : {
    fontSize    : 14,
    "& .Mui-disabled" : {
      color     : grey[700],
    }
  },
  checkItem   : {
    "& .MuiFormControlLabel-label" : {
      fontSize    : 14,
    },
    "& .MuiButtonBase-root"   : {
      padding   : `0 ${theme.spacing(1)}`, //`
    }
  },
  infoIcon  : {

  },
  miniButton  : {
    fontSize    : 15,
    padding     : "1px 6px",
    minWidth    : "unset",
    "& .MuiButton-label"  : {
      fontSize  : 12,
    }
  },
  itemsContainer  : {
    maxHeight   : 200,
    overflowY   : "auto",
    marginRight : 18,
  }
}));
