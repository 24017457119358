import React from 'react';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const WaitButton = ({children, isWaiting, color, disabled = false, ...otherProps}) => {
  const { classes }   = buildStyles();
  color           =  color || "primary";

  return (
    <div className={classes.wrapper}>
      {/* <Tooltip title={title || ""}> */}
        <Button
            {...otherProps}
          color={color || "primary"}
            disabled={isWaiting || disabled}
          >
            {children}
          </Button>
        {/* </Tooltip> */}
        {isWaiting && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}

export default WaitButton;

WaitButton.propTypes  = {
  isWaiting   : PropTypes.bool,  
};

const buildStyles   = makeStyles()(theme => ({
  wrapper: {
    margin      : theme.spacing(1),
    position    : "relative",
    // display     : "inline-block",
    display     : "flex",
  },
  buttonProgress: {
    color       : theme.palette.primary.main,
    position    : "absolute",
    top         : "50%",
    left        : "50%",
    marginTop   : -12,
    marginLeft  : -12,
  },
}));