import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import EngagementListItem from './engagement-list-item';
import { selectEngagements } from 'store/selectors/setting-selectors';
import { useSetting } from 'hooks';

const EngagementsList = () => {
  const { classes }       = buildStyles();
  const [isTraining, setTraining]   = useSetting("eng-list-training", true);
  const [isArchived, setArchived]   = useSetting("eng-list-archived", false);
  const engagements   = useSelector(selectEngagements);
  const filtered      = useMemo(() => filterEngagements(engagements, isTraining, isArchived), [engagements, isTraining, isArchived]);
  
  return (
    <Grid id="engagements-list" container className={classes.engGrid}>
      <Grid item container className={classes.headerRow}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.header}>Your Engagements</Typography>
        </Grid>
        <Grid item sm={12} container className={classes.listHeader} justifyContent="flex-end">
          <FormGroup row>
            <FormControlLabel className={classes.switch} classes={{label: classes.switchLabel}} value="training" label="Training" labelPlacement="start" control={<Switch color="primary"/>} checked={isTraining} onChange={() => setTraining(!isTraining)} title="Toggle visibility of Training Engagements"/>
            <FormControlLabel className={classes.switch} classes={{label: classes.switchLabel}} value="archived" label="Archived" labelPlacement="start" control={<Switch color="primary"/>} checked={isArchived} onChange={() => setArchived(!isArchived)} title="Toggle visibility of Archived Engagements"/>
          </FormGroup>
        </Grid>

      </Grid>
      <Grid item container className={classes.listContainer}>
        {_.map(filtered, eng => <EngagementListItem key={eng.id} engagement={eng} />)}
      </Grid>
    </Grid>
  );
}

export default EngagementsList;

const buildStyles   = makeStyles()(theme => ({
  headerRow   : {
    background      : theme.palette.grey[100],
    borderBottom    : `1px solid ${theme.palette.grey[200]}`, //`
    padding         : `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(2)}`, //`
    
  },
  header   : {
    fontSize        : 18,
    fontWeight      : 600,
    color           : theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
  },
  listHeader: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    },
  },
  listContainer   : {
    padding     : `${theme.spacing(1)} ${theme.spacing(2)}`, //`
  },
  switchLabel   : {
    fontSize  : 15,
    color     : theme.palette.grey[600],
  }
  
}));

function filterEngagements(allItems, isTraining, isArchived){
  let filtered  = allItems;
  if(!isArchived || !isTraining){
    filtered  = _.filter(filtered, f => (isTraining || !f.isTraining) && (isArchived || !f.isArchived));
  }
  return filtered;
}