import React from 'react';
import _ from 'lodash';
import { Status } from 'features/common';
import { StatusRow } from '.';
import { getSetStatusRecord } from 'utils/reviewset-helpers';

export default function ReviewSetReportFooter({set}){
  const statuses  = _.orderBy(getSetStatusRecord(set, ["PREAPPROVE", "APPROVE"]), s => new Date(s.statusDate), ["asc"]);
  const isPending = Boolean(!statuses || statuses.length === 0);
  // const pending = "";

  return (
    <div className="row reviewset-footer report-row-darker">
      <div className="col-xs-12">
          <div className="row p-t-10">
              <div className="col-xs-12">
                  <h3>ReviewSet {set.title} <span className="m-l-sm">{isPending ? <Status code={0} /> : null}</span></h3>
              </div>
          </div>
          { _.map(statuses, s => <StatusRow key={s.id} statusRec={s} />) }
      </div>
    </div>
  );
}