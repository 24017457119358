import React from 'react';
// import AddBoxIcon from '@mui/icons-material/AddBoxOutlined';
import BookIcon from '@mui/icons-material/BookOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
// import AdjustIcon from '@mui/icons-material/Adjust';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

//ReviewSet Menu


export const menuConfig  = [
  { 
    id          : 50, 
    content     : "ReviewSets",
    path        : "../reviewsets",
    icon      : <CollectionsBookmarkIcon fontSize="small" />,
  },
  { 
    id          : 98, 
    type        : "separator", 
    content     : "Other Links" 
  },
  { 
    id        : 10, 
    content   : 'All Documents', 
    path      : "",
    icon      : <DescriptionIcon fontSize="small"/>,
  },
  { 
    id        : 20, 
    content   : 'My Documents',
    path      : "?filter=owner:me",
    icon      : <BookIcon fontSize="small" />,
  },
  { 
    id        : 30, 
    content   : 'Pending Documents',
    path      : "?filter=status:pending",
    icon      : <RadioButtonUncheckedIcon fontSize="small" />,
  },
  { 
    id        : 40, 
    content   : 'Complete Documents',
    path      : "?filter=status:approved",
    icon      : <CheckCircleOutlineIcon fontSize="small" />,
  },
  { 
    id          : 99, 
    type        : "separator", 
    content     : "Other Links" 
  },
  { 
    id          : 60, 
    content     : 'Reports',
    path        : "../reports",
    icon      : <AssignmentIcon fontSize="small" />,
  },
];