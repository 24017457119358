import React from 'react';
import { isArray, isObject, isString, keys, map } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StatusCard from './status-card';

//TODO: typescript-ify this so it can be used more widely.
const ErrorDisplay = ({error, onClose = null, ...otherProps}) => {
  if(!error) return null;
  const isErrorAString  = isString(error);

  return (
    <StatusCard status="error" isVisible={true} {...otherProps}>
      <Grid container justifyContent="space-between" alignItems="center">
        {/* <span className={classes.errorHeader}>{header}</span> */}
        <ErrorHeader error={error} />
        {onClose && <IconButton onClick={onClose} size="small"><CloseIcon fontSize="small"/></IconButton>}
      </Grid>
      {!isErrorAString && <ErrorContent error={error}/> }
    </StatusCard>
  );
};

const buildStyles   = makeStyles()(theme => ({
  errorHeader   : {
    display     : "block",
    fontSize    : 15,
  },
  errorMessage  : {
    display     : "block",
    fontSize    : 14,
  }
}));

export default ErrorDisplay;

export function ErrorHeader({error}){
  const isErrorAString  = isString(error);
  const hasProps = !isErrorAString && error.status && error.problem;
  
  return (
    <>
      {isErrorAString && <span sx={{display: "block", fontSize: "1.1em"}}>{error}</span> }
      {hasProps && <span sx={{display: "block", fontSize: "1.1em"}}>{error.status}: {error.problem}</span> }
      {!isErrorAString && !hasProps && <span sx={{display: "block", fontSize: "1.1em"}}>An error occurred</span>}
    </>
  )
}

export function ErrorContent({error}){
  const { classes }   = buildStyles();
  //won't get a string error in here because it's handled above in the ErrorDisplay component.

  if(!error.data && !error.message && !error.originalError){
    console.warn("unrecognized error passed to ErrorDisplay: ", error);
    return null;
  } 

  if(isObject(error.data) && (error.data.validation || error.data.errors)){
    return (<ValidationList data={error.data.validation || error.data.errors} classes={classes} />);
  }
  else if(error.message){
    return (<span className={classes.errorMessage}>{error.message}</span>);
  }
  else if(error.originalError?.toJSON){
    const err   = error.originalError.toJSON();
    return (<span className={classes.errorMessage}>{err.message}</span>);
  }
  else if(error.data.error){
    if(isObject(error.data.error) && isObject(error.data.error.originalError)){
      return (<span className={classes.errorMessage}>{error.data.error.originalError.message}</span>);
    }
    else{
      return (<span className={classes.errorMessage}>{error.data.error.toString()}</span>);
    }
  }
  else if(isObject(error.data)){
    return (<ValidationList data={error.data} classes={classes} />);
  }
  else if(isString(error.data)){
    return error.data;
  }
  else{
    return null;
  }
}

function ValidationList({data, classes}){
  let i = 0;
  if(data){
    if(isArray(data)){
      //Need to enumerate the error objects in the data array
      return (
        <ul className={classes.validationList}>
          {map(data, err => <ValidationItem key={i++} data={err} errorKey="message" classes={classes} />) }
        </ul>
      );
    }
    else{
      //Need to enumerate the keys of the data object
      return (
        <ul className={classes.validationList}>
          { map(keys(data), key => <ValidationItem key={i++} data={data} errorKey={key} classes={classes} />) }
        </ul>
      );
    }
  }
}

function ValidationItem({data, errorKey, classes}){
  if(!data || !errorKey) return null;
  let content   = null;
  if(isString(data)){
    // content = <span>{errorKey}: {data[errorKey][0]}</span>
    content = <span>{data}</span>
  }
  else if(isArray(data[errorKey])){
    // content = <span>{errorKey}: {data[errorKey][0]}</span>
    content = <span>{data[errorKey][0]}</span>
  }
  else if(isObject(data[errorKey])){
    let i = 0;
    map(keys(data[errorKey]), key => <ValidationItem key={i++} data={data} errorKey={key} classes={classes} />)
  }
  else{
    // content = <span>{errorKey}: {data[errorKey]}</span>
    content = <span>{data[errorKey]}</span>
  }

  return <li className={classes.validationItem}>{content}</li>;
}