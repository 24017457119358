import React, { useMemo } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import grey from '@mui/material/colors/grey';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { statusBackgrounds } from 'utils/checklist-helpers';
import { formatDate } from 'utils/general-helpers';
import { useCurrentDocument } from 'hooks';

function SelectOption({item, input, choices, response, value, handleChange, isReadOnly}){
  const { classes, cx }     = buildStyles();
  const doc         = useCurrentDocument();
  const hasRsp      = Boolean(response);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const props       = useMemo(() => { return prepareProps(item, input, doc.isPrimary, isReadOnly, classes)}, [doc.isPrimary, item.isFinished, item.isWorking, input]);
  const adornment   = useMemo(() => {
    if(hasRsp){
      const rspDate = hasRsp ? formatDate(response.responseDate) : "";
      const tip     = `by ${response?.userName} on ${rspDate}`; //`
      return (<Tooltip title={tip}><InputAdornment position="end"><InfoOutlinedIcon fontSize="small" style={{color: grey[600]}}/></InputAdornment></Tooltip>);
    }
    else 
      return null;
  
  }, [hasRsp, response?.responseDate, response?.userName]);

  return (
    <FormControl id={"form-" + input.id} variant="outlined" className={cx(classes.inputFormControl, classes.selectOptions, { [classes.withAdornment]: hasRsp})} fullWidth>
      <InputLabel id={"label-" + input.id} className={cx(classes.inputLabel, item.className)}>{input.caption}</InputLabel>
      <Select id={"input-" + input.id} value={value} labelId={"label-" + input.id} margin="dense" onChange={handleChange} {...props} endAdornment={adornment}>
        <MenuItem value="">
          <em>{hasRsp ? "clear the selected value" : (input.placeholder || input.caption || "not selected")}</em>
        </MenuItem>
        {_.map(choices, c => <MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>)}
      </Select>  
    </FormControl>
  );
}

export default SelectOption;

function prepareProps(item, input, isPrimaryDoc, isReadOnly, classes){
  return {
    id        : `input-${input.id}`,  //`
    placeholder : input.textEmptyMessage,
    title       : input.toolTip,
    variant   : "outlined",
    size      : "small",
    className : classes.select,
    disabled  : item.isWorking || item.isFinished || !isPrimaryDoc || isReadOnly,
    fullWidth : true,
  };
}

const buildStyles   = makeStyles()(theme => ({
  withAdornment : {
    "& .MuiSelect-icon": {
      right   : "35px",
    },
  },
  inputLabel  : {
    marginTop       : "-10px",
    fontSize        : 15,
    "&.MuiInputLabel-shrink" : {
      padding       : "2px 8px",
      marginTop     : "-3px",
      background    : "white",
      fontStyle     : "unset",
      color         : grey[400],
      fontSize      : 18,
      fontWeight    : 400
    },
    ...statusBackgrounds(theme),
  },
  select  : {
    fontSize    : 14,
    "& .Mui-disabled" : {
      color     : grey[700],
    }
  },  
}));