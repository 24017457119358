import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import { useAppDispatch } from 'store';
import { saveSettingThunk } from 'store/thunks';
import { clearAdminStatus } from 'store/actions';
import { selectStatusByKey } from 'store/selectors/admin-selectors';
import { useInputHandler } from 'hooks/general-hooks';
import { shallowEquals } from 'utils/general-helpers';
import Validation from 'utils/validation-helper';
import { muiDialogReason } from 'utils/ui-helpers';
import { ErrorDisplay, WaitButton, WaitContainer } from 'features/common';
import { adminDialogClasses } from 'features/common/dialog-classes';

const defaultProtocol = {name   : "", projectId: -1, qReviewSetMulti: false, qCheckListMulti: false, qConfirmLabel: "", qRejectLabel: ""};

const ProtocolDialog = ({protocolId, onClose, props}) => {
  const { classes }   = buildStyles();
  const dispatch  = useAppDispatch();
  const isAdd     = Boolean(protocolId === -1);
  const projects   = useSelector(state => state.settings.projects);
  const protocols   = useSelector(state => state.settings.protocols);
  const status    = useSelector(state => selectStatusByKey(state, "protocols"));
  const [validation, setValidation]	= useState({canSave: false});
  const [original, setOriginal]   = useState(null);
  const [item, onInputChange, setItem]  = useInputHandler({}, null, onValidate);
  const isLocked  = Boolean(protocolId >= 0 || props?.projectId >= 0);
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if(protocolId){
      if(protocolId === -1){
        const myDefault   = props?.projectId ? {...defaultProtocol, projectId: props.projectId } : defaultProtocol;
        setOriginal(myDefault);
        setItem(myDefault);
      }
      else{
        const prt   = _.find(protocols, p => p.id === protocolId);  
        const flat  = flatten(prt);      
        setOriginal(flat);
        setItem(flat);
      }
      setInitialized(true);
    }
    else{
      setItem({});  //we're not fully initialized yet
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocolId]);

  function onValidate(toValidate){
    //Validate that there is a name
    if(toValidate.name?.length > 3){
      const isChanged   = Validation.hasChanges(original, toValidate);
      setValidation({...validation, canSave: isChanged});
    }
    else{
      setValidation({...validation, canSave: false});
    }
  }

  async function onSave(){
    if(validation.canSave){
      const model   = unFlatten(item, original);
      const result  = await dispatch(saveSettingThunk({ id: protocolId, model, settingType: "protocols" }));
      if(result?.payload?.ok){
        //Close the dialog and provide the info
        if(isAdd){
          onClose(result.payload.data.id);
        }
        else onClose();
      }
    }
  }

  async function doClose(event, reason){
    if(status.isWorking && muiDialogReason.isEither(reason)) return;
    if(status.error) await dispatch(clearAdminStatus("protocols"));
    onClose();
  }

  if(!protocolId) return null;

  return (
    <Dialog open={Boolean(protocolId)} onClose={doClose} className={classes.dialog} maxWidth="sm">
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between">
          <Typography className={classes.titleText}>
            <Tooltip title="Protocol">
              <TuneIcon fontSize="small" className={classes.titleIcon}/>
            </Tooltip>
            {isAdd ? "New " : ""}Protocol
          </Typography>
          <CloseIcon fontSize="small" onClick={doClose} className={classes.closeIcon}/>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <WaitContainer isWaiting={!isInitialized} message="Initializing...">
            <Grid item container>
              <ErrorDisplay error={status.error} />
            </Grid>

            <Grid item container className={classes.formRow}>
              <TextField name="name" value={item.name} onChange={onInputChange} 
                disabled={status.isWorking} fullWidth label="Name" className={classes.field}
                error={validation?.name?.hasError} helperText={validation?.name?.message}/>
            </Grid>

            <Grid item container className={classes.formRow}>
              <FormControl className={classes.formControl} label="Project" fullWidth >
                <InputLabel id="project-label" className={classes.selectLabel}>Project</InputLabel>
                <Select id="projectId" labelId="project-label" label="Project" value={item.projectId} onChange={onInputChange}  disabled={isLocked || status.isWorking} fullWidth className={classes.selectContainer} classes={{select: classes.select}}  size="small">
                  <MenuItem value="" disabled className={classes.option}><em>Select Project</em></MenuItem>
                  {_.map(projects, option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.title}</MenuItem>)}              
                </Select>
              </FormControl>
            </Grid>

            <Grid item container className={classes.formRow}>
              <Grid item xs={12} container>
                <Grid item xs={12} container>
                  
                  <Grid container alignItems="center">
                    <Grid item xs={7}>
                      <Tooltip title="Require 2 signatures (PreApproval, Approval) on ReviewSets">
                        <Typography className={classes.checkLabel}>Require PreApproval on ReviewSets</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={5}>
                      <FormControlLabel name="qReviewSetMulti" checked={item.qReviewSetMulti} onChange={onInputChange} disabled={!isAdd} className={classes.check} control={<Checkbox />} />
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center">
                    <Grid item xs={7}>
                      <Tooltip title="Require 2 signatures (PreApproval, Approval) on CheckLists">
                        <Typography className={classes.checkLabel}>Require PreApproval on CheckLists</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={5}>
                    <FormControlLabel name="qCheckListMulti" checked={item.qCheckListMulti} onChange={onInputChange} disabled={!isAdd} className={classes.check} control={<Checkbox />} />
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.formCompactRow}>
                    <Grid item xs={7}>
                      <Tooltip title="The label for the 'Confirm' button on CheckLists.">
                        <Typography className={classes.checkLabel}>Confirm Label</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField name="qConfirmLabel" value={item.qConfirmLabel} onChange={onInputChange} disabled={!isAdd || status.isWorking} className={classes.field} placeholder="Confirm" error={validation?.qConfirmLabel?.hasError} helperText={validation?.qConfirmLabel?.message}/>
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.formCompactRow}>
                    <Grid item xs={7}>
                      <Tooltip title="The label for the 'Reject' button on CheckLists.">
                        <Typography className={classes.checkLabel}>Reject Label</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField name="qRejectLabel" value={item.qRejectLabel} onChange={onInputChange} disabled={!isAdd || status.isWorking} className={classes.field} placeholder="Reject" error={validation?.qRejectLabel?.hasError} helperText={validation?.qRejectLabel?.message}/>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </WaitContainer>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button size="small" disabled={status.isWorking} onClick={doClose}>Cancel</Button>
        <WaitButton isWaiting={status.isWorking} disabled={!validation.canSave} onClick={onSave} color="secondary" size="small">Save</WaitButton>
      </DialogActions>
    </Dialog>
  );
}

export default ProtocolDialog;

const buildStyles   = makeStyles()(theme => ({
  ...adminDialogClasses(theme),
  dialog  : {
    "& .MuiDialog-paper"  : {
      minWidth    : 550,
    }
  },
  subTitleGrid  : {
    borderBottom  : `1px solid ${theme.palette.grey[100]}`, //`
    marginBottom  : theme.spacing(1),
  },
  subTitle  : {
    marginTop     : theme.spacing(2),
    fontSize      : 15,
    fontWeight    : 400,
    color         : theme.palette.grey[500],
  },
  checkLabel  : {
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[700],
  },
  check     : {
    paddingLeft   : 0,
  }
}));

const modelFields     = ["name", "projectId"];
const propBagFields   = ["qReviewSetMulti", "qCheckListMulti", "qConfirmLabel", "qRejectLabel"];

function flatten(item){
  let model   = {...defaultProtocol, ..._.pick(item, modelFields), ..._.pick(item?.propertyBag, propBagFields)};
  
  //Convert these fields to boolean values
  if(model.qReviewSetMulti) model.qReviewSetMulti = Boolean(model.qReviewSetMulti === "true");
  if(model.qCheckListMulti) model.qCheckListMulti = Boolean(model.qCheckListMulti === "true");
  
  return model;  
}

function unFlatten(item, original){
  let model     = _.pick(item, modelFields);
  const pb      = _.pick(item, propBagFields);
  const oPb     = _.pick(original, propBagFields);

  if(!shallowEquals(oPb, pb)){
    model.propertyBag   = pb;
  }

  return model;
}