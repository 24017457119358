import React from "react";
import { makeStyles } from 'tss-react/mui';
import { Card, Grid, Typography, CardProps } from "@mui/material";
import IfBlock from "./if-block";

const buildStyles   = makeStyles()((theme: any) => ({
  errorCard   : {
    backgroundColor   : theme.palette.error.light,
    color             : theme.palette.error.contrastText,
    borderColor       : theme.palette.error.main,
  },
  titleGrid   : {
    backgroundColor   : theme.palette.error.main,
    borderBottom      : `1px solid ${theme.palette.error.main}`, //`
    color             : theme.palette.common.white,
    padding           : theme.spacing(2),
  },
  title   : {
    fontSize          : 23,
    fontWeight        : 500,    
  },
  bodyGrid  : {
    padding           : theme.spacing(2),
  },
  header  : {
    fontSize          : 20,
    fontWeight        : 400,
    marginBottom      : theme.spacing(2),
  },
  message    : {
    fontSize          : 18,
    fontWeight        : 300,
    marginBottom      : theme.spacing(2),
  },
}));

export interface IApiError {
  status: number;
  title: string;

}

export interface IErrorCardProps extends CardProps {
  title?: string;
  header?: string;
  message?: string;
  action?: string;
  errorObj?: any;
}

const ErrorCard = ({ title = "An error occurred", header, message, action, errorObj, ...otherProps } : IErrorCardProps) => {
  const { classes }   = buildStyles();

  //These data props are to help with parsing the page from pupeteer (in report generation, for example).
  const dataProps = (errorObj) ? { 'data-status-code': errorObj.status, 'data-status-title': errorObj.title } : {};

  return (
    <Card className={`error-card ${classes.errorCard}`} {...otherProps} {...dataProps} >
      {/* title */}
      <IfBlock condition={title}>
        <Grid item container justifyContent="center" alignItems="center" className={classes.titleGrid}>
          <Typography variant="h2" className={`error-card-title ${classes.title}`}>{title}</Typography>
        </Grid>
      </IfBlock>
      {/* body */}
      <Grid container justifyContent="center" alignItems="center" className={classes.bodyGrid}>
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h2" className={`error-card-header ${classes.header}`}>{header}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={`error-card-message ${classes.message}`}>{message}</Typography>
          </Grid>

          {/* action */}
          <IfBlock condition={action}>
            <Grid item container className={classes.message}>
              <Typography variant="h2" className={classes.message}>{action}</Typography>
            </Grid>
          </IfBlock>
        </Grid>


        {/* Footer */}
        {/* <Grid item xs={12} container spacing={2} justifyContent="center">              
          <Button variant="contained" color="secondary" component={Link} to="/" className={classes.btn}>Return Home</Button>
          <Button variant="outlined" color="secondary" onClick={onLogout} className={classes.btn}>Sign Out</Button>
        </Grid> */}
      </Grid>
    </Card>
  );
}

export default ErrorCard;
