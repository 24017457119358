import React from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import { itemColors } from '../engagements-config';

const EngagementAreaChart = ({chartData, isHighContrast = false}) => {
  const theme     = useTheme();
  const { classes }   = buildStyles();
  const gridColor = isHighContrast ? theme.palette.grey[800] : `${theme.palette.grey[200]}55`; //`
  const axisColor = isHighContrast ? theme.palette.black : `${theme.palette.grey[200]}CC`; //`
  const stop1     = isHighContrast ? 0.75 : 0.5;
  const stop2     = isHighContrast ? 1 : 0.95;

  const cPending  = itemColors.active;
  const cApproved = itemColors.approved;
  const cCreated  = itemColors.created;
  const hasPreApproved  = chartData && chartData[0]?.preApproved >= 0;
  const contrastProps = isHighContrast ? {fillOpacity: 1} : {};
  const createdFill = isHighContrast ? `${itemColors.created[200]}55` : "url(#colorCreated)";
  const approvedFill = isHighContrast ? itemColors.approved["A700"] : "url(#colorApproved)";
  const pendingFill = isHighContrast ? itemColors.pending["A700"] : "url(#colorPending)";

  return (
        <Grid id="engagement-chart" container justifyContent="center" sx={{width: "100%", height: "300px"}}>
          
          <ResponsiveContainer>
            <AreaChart data={chartData} margin={{top: 30, right: 30, left: 0, bottom: 0}}>
              <defs>
                <linearGradient id="colorCreated" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={cCreated[200]} stopOpacity={stop2}/>
                    <stop offset="95%" stopColor={cCreated[200]} stopOpacity={stop1}/>
                </linearGradient>
                <linearGradient id="colorApproved" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={cApproved[200]} stopOpacity={stop2}/>
                    <stop offset="95%" stopColor={cApproved[200]} stopOpacity={stop1}/>
                </linearGradient>
                <linearGradient id="colorPending" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={cPending[100]} stopOpacity={stop2}/>
                    <stop offset="95%" stopColor={cPending[100]} stopOpacity={stop1}/>
                </linearGradient>                
              </defs>

              <CartesianGrid vertical={false} stroke={gridColor}/>
              <XAxis dataKey="label" axisLine={false} tickLine={false} tick={{fill: axisColor}} interval="preserveStart" minTickGap={100}/>
              <YAxis tick={{fill: axisColor}} stroke={axisColor} axisLine={false} tickLine={false}/>
              <Tooltip content={<AreaChartTooltip hasPreApproved={hasPreApproved} classes={classes}/>}/>

              <Area type="monotone" dataKey="created" stroke={cCreated[200]} fill={createdFill} {...contrastProps}/>
              <Area type="monotone" dataKey="approved" stroke={cApproved[300]} fill={approvedFill} {...contrastProps}/>
              {hasPreApproved && 
                <Area type="monotone" dataKey="preApproved" stroke={cPending[100]} fill={pendingFill} {...contrastProps}/>
              }
            </AreaChart>
          </ResponsiveContainer>

        </Grid>
  );
}

export default EngagementAreaChart;

const buildStyles   = makeStyles()(theme => ({
  tooltipCardHeader   : {
    background    : theme.palette.grey[200],
    borderBottom  : theme.palette.grey[700],
  },
  tooltipHeader   : {
    color         : theme.palette.grey[700],
  },
}));

function AreaChartTooltip({type, active, payload, label, classes}){
  if(!active) return null;
  const ttLabel   = payload && payload[0] && payload[0].payload ? payload[0].payload.ttTitle : label;
  const ordered   = payload; //_.reverse(payload);

  const chartColors = {
    "&.rsCreated"  : { color: itemColors.created[600], },
    "&.rsPending"  : { color: itemColors.pending[900], },
    "&.rsApproved"  : { color: itemColors.approved[600], }, 
  };

  const labelSx = {
    fontSize      : 13,
    textTransform : "uppercase",
    fontWeight    : 400,
    ...chartColors,     
  };

  const valueSx = {
    fontSize      : 16,
    fontWeight    : 500,
    mr   : 1,
    ...chartColors,   
  };

  return (
    <Grid>
      <Card sx={{width: 250}} variant="outlined">
        <CardContent sx={{padding: 0, paddingBottom: "0 !important"}}>
          <Grid className={classes.tooltipGrid}>
            <Grid item container alignItems="center" sx={{p: 1}} className={classes.tooltipCardHeader}>
              <Typography sx={{fontSize: 13, fontWeight: 700}} className={classes.tooltipHeader}>{ttLabel}</Typography>
            </Grid>
            <Grid item container sx={{p: 1}}>
              {
                _.map(ordered, (pl, i) => {
                  return (
                    <Grid key={i} item container sx={{flexWrap: "nowrap", mb: 0.5}} alignItems="center" style={{flexWrap: "nowrap"}}>
                      <Grid item xs={3}>
                        <Typography sx={valueSx} className={pl.dataKey}>{pl.value.toLocaleString()}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography sx={labelSx} className={pl.dataKey}>{ttMap[pl.dataKey]}</Typography>
                      </Grid>
                    </Grid>
                  );
                })
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

const ttMap   = {
  rsCreated     : "New ReviewSets",
  rsPending     : "Active ReviewSets",
  rsApproved    : "Approved ReviewSets",
  created       : "Total",
  approved      : "Approved",
  preApproved   : "Pre-Approved",
};