import React from "react";
import { Card, CardContent, Grid } from "@mui/material"
import { ReactNode } from "react";

export interface ICltTableCardProps {
  children: ReactNode;

}


export const CltTableCardRaw = ({children}: ICltTableCardProps) => {

  return (
    <Grid item container>
      <Card variant="outlined">
        {children}
      </Card>
    </Grid>
  );
};

const CltTableCard = ({children}: ICltTableCardProps) => {

  return (
    <CltTableCardRaw>
      <CardContent>
        <Grid container>
          {children}
        </Grid>
      </CardContent>
    </CltTableCardRaw>
  );
};

export default CltTableCard;
