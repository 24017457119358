import React, { useState } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { IfBlock, WaitButton, StatusCard } from 'features/common';
import { formatDate } from 'utils/general-helpers';
import { muiDialogReason } from 'utils/ui-helpers';
import { responseMap } from 'utils/checklist-helpers';
import { selectInputLabels } from 'store/selectors/document-selectors';
import { useCurrentDocument } from 'hooks';
import { useIsMobile } from 'hooks/general-hooks';

function CommentDialog({item, isOpen, isRequired, onSave, onCancel}){
  const { classes }   = buildStyles();  
  const labels    = useSelector(selectInputLabels);
  const doc                 = useCurrentDocument();
  const response  = _.find(item.responses, r => r.checkListInputId === item.commentInput.id);
  const [content, setContent]     = useState(response?.textResponse || "");
  const isChanged   = content !== "" && content !== (response?.textResponse || "");
  const isMobile = useIsMobile();

  // console.log("** comment dialog rendering");

  const onChange = (e) => {
    setContent(e.currentTarget.value);
  }

  const doSave = async () => { 
    const model   = {
      ...responseMap.base(item.commentInput, response),
      ...responseMap.comment(content),
    };
    
    await onSave(model);    
  }

  const doDelete = async () => {
    const model   = {
      ...responseMap.base(item.commentInput, response),
      ...responseMap.delete,
    };
    
    await onSave(model); 
  }

  const doClose = (event, reason) => {
    if((item.isWorking || isRequired) && muiDialogReason.isEither(reason)) return;  //don't close if we're working or required
    onCancel();
  }

  const title   = `Comments for ${item.key}: ${item.assertionText}`; //`
  const rspDate = response ? formatDate(response.responseDate) : "";
  const isDisabled  = item.isFinished || !doc.isPrimary;    //disabled if the item is answered, or this isn't the primary reviewrecord

  return (
    <Dialog open={isOpen} onClose={doClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container> 
          <StatusCard isVisible={!item.isFinished && (!isRequired || !response) && item.isCommentRequiredOnReject} status="info">
            This item requries a comment on {labels.reject}.
          </StatusCard>
          <StatusCard isVisible={!item.isFinished && isRequired && response} status="info">
            In order to {labels.reject} this item, additional comments are required.
          </StatusCard> 
          <TextField multiline={true} minRows={4} maxRows={4} fullWidth variant="outlined" disabled={isDisabled} value={content} onChange={onChange}/>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container direction={isMobile ? "column" : "row"} alignItems="center">
          <Grid item xs={12} md={6} container>
            <IfBlock condition={response}>
              <Typography className={classes.responseInfo}>by <span className={classes.popFooterLabel}>{response?.userName}</span> on <span className={classes.popFooterLabel}>{rspDate}</span></Typography>
            </IfBlock>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="flex-end">
            <WaitButton onClick={doSave} color="primary" disabled={!isChanged || isDisabled} isWaiting={item.isWorking}>Save</WaitButton>
            <WaitButton onClick={doDelete} color="default" disabled={!Boolean(response) || isDisabled || isRequired} isWaiting={item.isWorking}>Delete</WaitButton>
            <Button disabled={item.isWorking} onClick={doClose}>Cancel</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default CommentDialog;

const buildStyles   = makeStyles()(theme => ({
  actions   : {
    padding     : `${theme.spacing(1)} ${theme.spacing(3)}`, //`
    justifyContent : "space-between",
  },
  responseInfo  : {
    fontSize    : "1.1rem",
    color       : theme.palette.grey[600],
  }
}));