import Apis from "store/api";
import { createAppAsyncThunk, parseResponse } from "store/slices/slice-helpers";
import { AppEnvironment, AppSettings, FetchSettingsResponse, SaveSettingParams } from "./thunk-types";
import { ApiOkResponse, ApiResponse } from "apisauce";
import { ApiResponseAction } from "app-types";
import { pick } from "lodash";

export const SETTING_THUNKS = {
  fetch: "settings/fetch",
  save: "settings/save",
};


export const fetchSettingsThunk = createAppAsyncThunk<ApiResponseAction<FetchSettingsResponse>>(SETTING_THUNKS.fetch, async (params: any, thunkAPI) => {
  
  const settingsResult = await Apis.Settings.get<any, AppSettings>("");
  const environmentResult = await Apis.Settings.get<any, AppEnvironment>("/environment");

  if(!settingsResult.ok || !environmentResult.ok){
    const response = parseResponse(SETTING_THUNKS.fetch, settingsResult.ok ? environmentResult : settingsResult);
    return response;
  }
  else {
    const result: ApiOkResponse<FetchSettingsResponse> = {
      ok: true,
      data: {
        settings: settingsResult.data,
        environment: environmentResult.data,
      },
      problem: null,
      originalError: null,
    };

    const response = parseResponse<FetchSettingsResponse>(SETTING_THUNKS.fetch, result as any);
    return response;
  }
});

export const saveSettingThunk = createAppAsyncThunk<any, SaveSettingParams>(SETTING_THUNKS.save, async (params, thunkAPI) => {
  const { id, model, settingType } = params;

  if(!id || !model || !settingType) {
    console.error("Invalid parameters for saveSettingThunk", params);
    throw new Error("Internal Error, unable to save setting. Please contact the administrator.");
  }

  const api = APIS(settingType); 

  let apiResult: ApiResponse<any, any>;

  if(id === -1){
    const whiteList   = SETTING_WHITELIST[settingType];
    let myModel = pick(model, whiteList); //make sure we're not trying to send the id
    apiResult  = await api.post("", myModel);
  }
  else{
    apiResult  = await api.put(`/${id}`, model);
  }

  const response = parseResponse(SETTING_THUNKS.save, apiResult, { id, key: settingType });
  return response;
});

//=== Helpers
const APIS  = (settingType: string) => {
  switch(settingType){
    case "clients"  : return Apis.Clients;
    case "projects"  : return Apis.Projects;
    case "protocols"  : return Apis.Protocols;
    case "sites"  : return Apis.Sites;
    case "engagements" : return Apis.Engagements;
    case "doctypes" : return Apis.DocumentTypes;
    default: return Apis.home;
  }
};

const SETTING_WHITELIST: Record<string, string[]>   = {
  clients  : ["name", "propertyBag"],
  projects : ["title", "clientId", "notes", "propertyBag"],
  protocols: ["name", "projectId", "propertyBag"],
  sites    : ["name", "clientId", "propertyBag"],
  engagements : ["name", "isArchived", "siteId", "clientId", "projectId", "protocolId", "propertyBag"],
  doctypes : ["name", "protocolId", "notes", "propertyBag"],
};