import Apis from "store/api";
import { createAppAsyncThunk, parseResponse } from "store/slices/slice-helpers";
import { IdParams, SaveModelParams } from "./thunk-types";

export const BUILDER_THUNKS = {
  checklistFetched: "builder/checklistFetched",
  checklistSaved: "builder/checklistSaved",
};

export const fetchBuilderChecklistThunk = createAppAsyncThunk<any, IdParams>(BUILDER_THUNKS.checklistFetched, async (params, thunkAPI) => {
  const { id } = params;
  const apiResult = await Apis.Checklists.get(`/${id}`);
  return parseResponse(BUILDER_THUNKS.checklistFetched, apiResult, { id });
});

export const saveBuilderChecklistThunk = createAppAsyncThunk<any, SaveModelParams>(BUILDER_THUNKS.checklistSaved, async (params, thunkAPI) => {
  const { id, model } = params;
  const verb = id > 0 ? "put" : "post";
  const path = id > 0 ? `/${id}` : "";
  const apiResult = await Apis.Checklists[verb](path, model);
  return parseResponse(BUILDER_THUNKS.checklistSaved, apiResult);
});