import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import BookIcon from '@mui/icons-material/BookOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import { useHover } from 'hooks';
import { appRoutesLegacy } from 'utils/route-helpers';
import EngagementIcon from './engagement-icon';
import { CltHidden } from 'features/common';

const EngagementListItem = ({engagement}) => {
  const { classes, cx }   = buildStyles();
  const url       = `/engagements/${engagement.id}`;
  const [, isOver, onEnter, onExit]  = useHover(); //for tracking if it's over this item

  const urlP      = { engagementId: engagement.id};
  const rsUrl     = generatePath(appRoutesLegacy.reviewsets.list, urlP);
  const docUrl    = generatePath(appRoutesLegacy.documents.list, urlP);
  const rptUrl    = generatePath(appRoutesLegacy.engagements.reports, urlP);

  return (
    <Grid container className={classes.itemCard} onMouseEnter={onEnter} onMouseLeave={onExit}>
      <Grid container alignItems="center">
        <Grid item className={classes.contentGrid} container alignItems="center">
          <Grid item>
            <EngagementIcon engagement={engagement} />
          </Grid>
          <Grid item>
            <Link to={url}>
              <Typography variant="h5" className={classes.title}>{engagement.id} &#183; {engagement.name}</Typography>
            </Link>
          </Grid>
          <CltHidden smDown>
            <Grid item className={classes.actionsGrid} sx={{height: 30}} container alignItems="center" justifyContent="flex-start">
              <Tooltip title="Go to ReviewSets">
                <IconButton size="small" className={cx(classes.actionButton, {"show": isOver})} component={Link} to={rsUrl}><BookIcon /></IconButton>
              </Tooltip>
              <Tooltip title="Go to Documents">
                <IconButton size="small" className={cx(classes.actionButton, {"show": isOver})} component={Link} to={docUrl}><DescriptionIcon /></IconButton>
                </Tooltip>
              <Tooltip title="Go to Reports">
                <IconButton size="small" className={cx(classes.actionButton, {"show": isOver})} component={Link} to={rptUrl}><AssignmentIcon /></IconButton>
              </Tooltip>
            </Grid>
          </CltHidden>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EngagementListItem;

const buildStyles   = makeStyles()(theme => ({
  itemCard  : {
    marginBottom  : theme.spacing(1),
    paddingLeft   : theme.spacing(1),
    width         : "100%",
  },
  contentGrid: {
    [theme.breakpoints.down('md')]: {
      flexWrap: "nowrap",
    }
  },
  title   : {
    fontSize    : 16,
    fontWeight  : 500,
    color       : theme.palette.grey[900],
    marginLeft  : theme.spacing(1.5),
    "&:hover"   : {
      color       : theme.palette.secondary.main,
    }
  },
  actionsGrid   : {
    marginLeft  : theme.spacing(2),
    width       : 90,
    flexWrap    : "nowrap",
    [theme.breakpoints.down('md')]: {
      display: "none",
    }
  },  
  actionButton  : {
    marginLeft    : 0,
    fontSize: "12px",
    display       : "none",
    "&.show"      : {
      display     : "unset",
    },
    "& svg"   : {
      fontSize    : 20,
    },
    "&:hover"   : {
      color       : theme.palette.secondary.main,
    }
  }
}));