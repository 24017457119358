import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';

function StatusCard({message = null, status, isVisible, result = null, children, className, ...otherProps}){
  
  if(!isVisible || (!message && !children)) return null;

  const { classes, cx }   = buildStyles();
  
  if(result){
    //TODO: Automatically show the result information
  }

  const statusClass   = status ? classes[status] : "";

  return (
    <Card elevation={0} className={cx(classes.default, statusClass, className)} variant="outlined" {...otherProps}>
      {!children && <span>{message}</span>}
      {children}
    </Card>
  );
}

export default StatusCard;

StatusCard.propTypes  = {
  message   : PropTypes.string,
  status    : PropTypes.oneOf(["ok", "warning", "error", "info", "danger", "success"]).isRequired,
  isVisible : PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

const buildStyles   = makeStyles()(theme => ({
  default   : {
    borderRadius    : 5,
    margin          : `${theme.spacing(1)} 0`, // ${theme.spacing(0.5)}`, //`
    padding         : theme.spacing(1),
    position        : "relative",
    width           : "100%",
  },
  error     : {
    background      : theme.palette.error.light, //red[50], //theme.palette.error.light,
    border          : `1px solid ${theme.palette.error.main}`,  //theme.palette.error.dark
    color           : theme.palette.error.contrastText, //theme.palette.error.contrastText,  
  },
  danger     : {
    background      : theme.palette.error.light, //red[50], //theme.palette.error.light,
    border          : `1px solid ${theme.palette.error.main}`,  //theme.palette.error.dark
    color           : theme.palette.error.contrastText, //theme.palette.error.contrastText,    
  },
  ok      : {
    background      : theme.palette.info.light, //`${theme.palette.primary.light}33`,
    border          : `1px solid ${theme.palette.info.main}`,
    color           : theme.palette.info.constrastText, //theme.palette.primary.main,
  },
  warning : {
    background      : `${theme.palette.warning.light}99`, //yellow[50], //"#fff3cd",
    border          : `1px solid ${theme.palette.warning.main}`, //#ffeeba`,
    color           : theme.palette.grey[800], //theme.palette.warning.contrastText, //grey[700], //"#856404",
  },
  info : {
    background      : `${theme.palette.info.light}66`, //lightblue["50"], //"#fff3cd",
    border          : `1px solid ${theme.palette.info.main}`, //#ffeeba`,
    color           : theme.palette.grey[800], //theme.palette.info.contrastText, //lightblue["900"], //"#856404",
  },
  success : {
    background      : `${theme.palette.success.light}66`, //lightblue["50"], //"#fff3cd",
    border          : `1px solid ${theme.palette.success.main}`, //#ffeeba`,
    color           : theme.palette.grey[800], //theme.palette.success.contrastText, //lightblue["900"], //"#856404",
  },
}));