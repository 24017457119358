///Used with the sx={} on Mui Controls

// to override the a:hover { color: darkblue } from the quanta.scss styles
export const noHoverColor = {
  ":hover": {color: "unset"}
};

export const notMobile = (displayType: string = "flex") => {
  return {
    display: {
      xs: "none",
      sm: displayType,
    },
  };
};

export const onlyMobile = (displayType:  string = "flex") => {
  return {
    display: {
      xs: displayType,
      sm: "none",
    },
  };
};