import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import { Loading, Status, ConditionButton, IfBlock, CltHidden } from 'features/common';
import ReviewSetApproveButton from './reviewset-approve-button';
import ReviewSetIcon from './reviewset-icon';
import { useSetting, useCurrentPermissions, useCurrentReviewSet, useAppParams } from 'hooks';
import ReviewSetReportButton from './reviewset-report-button';
import { Box } from '@mui/material';

function ReviewSetHeader(){
  const set             = useCurrentReviewSet(); // useSelector(selectSet);
  const { reviewsetId } = useAppParams();
  const [isExpanded, setExpanded]   = useSetting("rs-header-expanded", true);
  const permissions      = useCurrentPermissions();

  //No set yet, so we're still waiting for it to load
  if(!set || set?.id !== reviewsetId){
    return (
      <Grid container justifyContent="center" sx={{m: 3}}>
        <Loading isVisible={true} message="Loading..."/>
      </Grid>
    );
  }

  return (
    <Grid id="reviewset-header" container sx={{p: {xs: 0, sm: 1}, mb: {xs: 0, sm: 2}}}>
      
      <Grid id="reviewset-title-container" item container sx={{px: 2, mt: 1}}>
        <Grid id="title-row" item sm={6} container flexWrap={{xs: "nowrap", sm: "nowrap"}} alignItems="flex-start">
          <Box sx={{pt: 0.75}}><ReviewSetIcon isVisible={true} set={set}/></Box>
          <Typography component="h2" sx={{fontSize: {xs: 20, sm: 22}, fontWeight: 300}} title="Reviewset Title">{set.title}</Typography>
          <IfBlock condition={!isExpanded}>
            <Box sx={{ml: 1, mt: 0.5}}>
              <Status code={set.statusCode}  />
            </Box>
          </IfBlock>          
        </Grid>

        <Grid item sm={6} container columnGap={0.5}
          alignItems="flex-start" justifyContent={{xs: "center", sm: "flex-end"}} flexWrap={{xs: "nowrap", sm: "unset"}} 
          sx={{"& .MuiButtonBase-root": { xs: { fontSize: "0.8rem" }, sm: { fontSize: "1rem"}}, mt: {xs: 1, sm: 0}}}>

          <CltHidden smDown>
            <ConditionButton size="small" variant="outlined" 
              condition={isExpanded} isIcon={true}
              titles={["Collapse the header", "Expand the header"]}
              onClicks={[() => setExpanded(false), () => setExpanded(true)]}
              contents={[<ArrowUpIcon fontSize="small"/>, <ArrowDownIcon fontSize="small"/>]}/>
          </CltHidden>

          {(!permissions.isReadOnly && !set.isApproved) && <Button size="small" variant="outlined" endIcon={<EditIcon />} component={Link} to="edit">Edit</Button>}
          <ReviewSetApproveButton set={set}/>
          <ReviewSetReportButton set={set} />

          <CltHidden smUp>
            <ConditionButton size="small" variant="outlined" 
              condition={isExpanded} isIcon={true}
              titles={["Collapse the header", "Expand the header"]}
              onClicks={[() => setExpanded(false), () => setExpanded(true)]}
              contents={[<ArrowUpIcon fontSize="small"/>, <ArrowDownIcon fontSize="small"/>]}/>
          </CltHidden>
        </Grid>

        <IfBlock condition={isExpanded}>
          <Grid item sx={{paddingLeft: 1.25}}>
            <Typography title="Reviewset Description" sx={{fontSize: 14, fontWeight: 400}}>{set.propertyBag?.description}</Typography>
          </Grid>
        </IfBlock>

      </Grid>

      <HeaderDetails set={set} isExpanded={isExpanded} />
      
    </Grid>
  );
}

export default ReviewSetHeader;

const buildStyles   = makeStyles()(theme => ({
  itemLabel   : {
    fontSize      : 13,
    fontWeight    : 400,
    color         : theme.palette.grey[500],
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  itemValue  : {
    fontSize      : 17,
    fontWeight    : 300,
    color         : theme.palette.grey[900],
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`,    
    [theme.breakpoints.up("sm")]: {
      minWidth      : 200,
      maxWidth      : 300,
    }
  }
}));


function HeaderDetails({isExpanded, set}){
  const { classes }         = buildStyles();
  
  return (
    <IfBlock condition={isExpanded}>
        <Grid container sx={{px: 2, mt: 1}}>
          <Grid item container>
            <Grid item container xs={12} sm={6}>
              <Grid item xs={3} md={2} container alignContent="center" className={classes.itemLabel}>Status</Grid>
              <Grid item xs={9} md={10} className={classes.itemValue}><Status code={set.statusCode} /></Grid>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Grid item xs={3} md={2} container alignContent="center" className={classes.itemLabel}>Lot #:</Grid>
              <Grid item xs={9} md={10} className={classes.itemValue}>{set.propertyBag?.lotNumber}</Grid>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Grid item xs={3} md={2} container alignContent="center" className={classes.itemLabel}>Checklists:</Grid>
              <Grid item xs={9} md={10} className={classes.itemValue}>{set.approvedCheckListCount} of {set.checkListCount > 0 ? set.checkListCount : 0} Complete</Grid>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Grid item xs={3} md={2} container alignContent="center" className={classes.itemLabel}>Part #:</Grid>
              <Grid item xs={9} md={10} className={classes.itemValue}>{set.propertyBag?.partNumber}</Grid>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Grid item xs={3} md={2} container alignContent="center" className={classes.itemLabel}>Product:</Grid>
              <Grid item xs={9} md={10} className={classes.itemValue}>{set.propertyBag?.productName}</Grid>
            </Grid>
            <Grid item container xs={12} className={classes.notesGrid}>
              <Grid item xs={3} md={1} container alignContent="center" className={classes.itemLabel}>Notes:</Grid>
              <Grid item xs={9} md={11} className={classes.itemValue} style={{maxWidth: "unset"}}>{set.notes}</Grid>
            </Grid>
          </Grid>
        </Grid>
    </IfBlock>
  );
}