import React from "react";
import { makeStyles } from 'tss-react/mui';
import { Card, Grid, Typography } from "@mui/material";
import { parseError } from "utils/error-helpers";
import { IfBlock } from 'features/common';

interface ControlProps {
  // code?: string;
  // title?: string;
  // header?: string;
  message: string;
  error: any;
  // children: ReactNode;
}

const env = process.env.NODE_ENV;
console.log("fail environment: ", env);

//NOTE: This control is almost the same as the ErrorView, but it is intended to be used
// before the app has been initialized, and the Store / API / MSAL is available. So it doesn't
// have all the features of the standard error view.

const InitializeFailure = ({message, error} : ControlProps) => {
  const { classes }   = buildStyles();
  const details   = parseError("", "", "", message, error);
  // const isDev = true; //(env !== "production");
  // const [testUrl, setTestUrl] = useState("");

  // const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   setTestUrl(value);
  // }

  // const onTestUrl = async () => {
  //   console.log("try out url: ", testUrl);
  //   const result = await fetch(testUrl);
  //   const resultJson = await result.json();
  //   console.log("test url json: ", resultJson);
  // }

  return (
    <Grid id="not-found" container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item xs={6}>
        <Card className={classes.errorCard}>
          {/* title */}
          <IfBlock condition={details.title}>
            <Grid item container justifyContent="center" alignItems="center" className={classes.titleGrid}>
              <Typography variant="h2" className={classes.title}>{details.title}</Typography>
            </Grid>
          </IfBlock>
          {/* body */}
          <Grid container justifyContent="center" alignItems="center" className={classes.bodyGrid}>
            <Grid item xs={12} container justifyContent="center">
              <Grid item xs={12} container justifyContent="center">
                <Typography variant="h2" className={classes.header}>{details.header}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.message}>{details.message}</Typography>
              </Grid>

              {/* action */}
              <IfBlock condition={details.action}>
                <Grid item container className={classes.message}>
                  <Typography variant="h2" className={classes.message}>{details.action}</Typography>
                </Grid>
              </IfBlock>

              {/* Debug request */}
              {/* <IfBlock condition={isDev}>
                <Grid item container>
                  <Grid item sm={10}>
                    <Input id="testUrl" fullWidth onChange={onInputChange}></Input>
                  </Grid>
                  <Grid item sm={2} container alignItems="center" justifyContent="flex-end">
                    <Button id="urlButton" onClick={onTestUrl}>Test</Button>
                  </Grid>
                </Grid>
              </IfBlock> */}
            </Grid>

          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default InitializeFailure;


const buildStyles = makeStyles()((theme: any) => ({
  root        : {
    height        : "95vh",
  },
  errorCard   : {
    backgroundColor   : theme.palette.error.light,
    color             : theme.palette.error.contrastText,
    borderColor       : theme.palette.error.main,
  },
  titleGrid   : {
    backgroundColor   : theme.palette.error.main,
    borderBottom      : `1px solid ${theme.palette.error.main}`, //`
    color             : theme.palette.common.white,
    padding           : theme.spacing(2),
  },
  title   : {
    fontSize          : 23,
    fontWeight        : 500,    
  },
  bodyGrid  : {
    padding           : theme.spacing(2),
  },
  header  : {
    fontSize          : 20,
    fontWeight        : 400,
    marginBottom      : theme.spacing(2),
    textAlign: "center",
  },
  message    : {
    fontSize          : 18,
    fontWeight        : 300,
    marginBottom      : theme.spacing(2),
  }
}));