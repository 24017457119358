import _ from 'lodash';
import { createSelector } from 'reselect';


const _getSetData   = state => state.reports.setData;
const _getClData    = state => state.reports.clData;
const _getSettings  = state => state.settings;

export const selectReportEngagement = createSelector(
  [_getSetData, _getSettings],
  (data, settings) => {
    if(!data || !settings) return null;
    
    const engagement    = {
      protocol    : data.protocol,
      project     : data.protocol?.project,
      client      : data.protocol?.project?.client,      
    };

    return engagement;
  }
);

export const selectReportSet = createSelector(
  [_getSetData],
  (data) => {
    if(!data) return null;
    return data.reviewSet;
  }
);

export const selectReportSite = createSelector(
  [_getSetData],
  (data) => {
    if(!data) return null;
    return data.site;
  }
)

export const selectSetDocuments = createSelector(
  [_getSetData],
  (data) => {
    if(!data) return null;   
    
    const docIds  = _.uniq(_.map(data.reviewSet.reviewRecords, rr => rr.documentId));
    const docs    = _.map(docIds, id => _.find(data.documents, d => d.id === id));
    
    const items = docs.map(doc => { 
      const dType =  _.find(data.documentTypes, dt => dt.id === doc.documentTypeId);
      return {
        ...doc,
        documentType: dType,
        checkLists: dType.checkLists,
      };
    });

    const sorted  = sortDocs(items, data.docTypes);

    return sorted;
  }
);

export const selectCheckListReportData = createSelector(
  [_getClData, _getSettings],
  (clData, settings) => {
    
    if(!clData || !settings.isInitialized){
      return null;
    }

    const set         = clData.set;
    const engagement  = _.find(settings.engagements, e => e.id === set.engagementId);
    const protocol    = _.find(settings.protocols, p => p.id === engagement.protocolId);
    const project     = _.find(settings.projects, prj => prj.id === protocol.projectId);
    const site        = _.find(settings.sites, s => s.id === engagement.siteId);
    const client      = _.find(settings.clients, c => c.id === site.clientId);

    const fullEng     = {
      ...engagement,
      protocol  : protocol,
      project   : project,
      client    : client,
      site      : site,
    };

    return {
      isWorking   : false,
      set         : set,
      doc         : clData.doc,
      engagement  : fullEng,
    };
  }
)


//-----------------------------------
// Helper Methods

//-----------
// Sorts the reviewset report documents
function sortDocs(docs, docTypes){
  if(_.every(docs, doc => doc.documentType.propertyBag.printIndex)){

      const oldVersions   = _.filter(docs, doc => Boolean(doc.documentType.propertyBag.nextVersion));
      if(oldVersions && oldVersions.length > 0){
          //If there are any with old versions, need to get the print index for the current version of that doc type
          oldVersions.forEach(doc => {
              let dt  = doc.documentType;
              do{
                  // eslint-disable-next-line no-loop-func
                  dt = docTypes.find(d => d.id === parseInt(dt.propertyBag.nextVersion));
              } while(dt && dt.propertyBag && dt.propertyBag.nextVersion);
              
              //Just assign the new print index
              doc.documentType.propertyBag.printIndex     = dt.propertyBag.printIndex;
          });
      }

      return _.sortBy(docs, [doc => parseInt(doc.documentType.propertyBag.printIndex), doc => doc.documentKey.toLowerCase()]);
  }
  else{
      return _.sortBy(docs, [doc => doc.documentType.name.toLowerCase(), doc => doc.documentKey.toLowerCase()]);  //_.orderBy(docs, ["documentType.name", "documentKey"])
  }
}