import { useMediaQuery } from "@mui/material"

//===
// Wraps the MUI Hidden component in .jsx so it doesn't complain about it
// in the .tsx files.
const CltHidden = ({children, ...hiddenProps}) => {
  const { bp, dir } = mapHidden(hiddenProps);
  const hidden = useMediaQuery(theme => theme.breakpoints[dir](bp));

  return hidden ? null : children;

  // return (
  //   <Hidden {...hiddenProps}>
  //     {children}
  //   </Hidden>
  // );
};

export default CltHidden;

const mapHidden = (hiddenProp) => {
  const hiddenStr = Object.keys(hiddenProp)[0];
  const bp = hiddenStr.substring(0, 2);
  const dir = hiddenStr.substring(2).toLowerCase();
  return {bp, dir};
}