import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { ErrorDisplay, WaitContainer } from 'features/common';
import ReviewSetHeader from './reviewset-header';
import ReviewSetDocuments from './reviewset-documents';
// import { loadSet } from 'store/actions/reviewset-actions';
import { useCurrentReviewSet, useAppParams } from 'hooks';
import { fetchSetThunk } from 'store/thunks';


function ReviewSetView(){
  const dispatch        = useDispatch();
  const set             = useCurrentReviewSet();
  const status          = useSelector(state => state.set.itemStatus);
  const params = useAppParams();
  const { reviewsetId } = params;

  const title           = `${set?.title || "Loading"} ReviewSet`;

  //Load the ReviewSet if necessary
  useEffect(() => {  
    const isError = status.error && (!status.error.id || status.error.id === reviewsetId);
    if(reviewsetId && !isError && !status.isLoading && (status.isDocChanged || !set || (set && set.id !== parseInt(reviewsetId)))){
      dispatch(fetchSetThunk({id: reviewsetId, engagementId: params.engagementId}));
      // dispatch(loadSet(reviewsetId, params.engagementId));
    }
  }, [reviewsetId, status.error, status.isDocChanged, status.isLoading, set, params.engagementId, dispatch]);
  
  if(status.error){
    return (
      <ErrorDisplay error={status.error} />
    );
  }

  return (
    <Grid id="reviewset-view" container spacing={2} justifyContent="center">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <WaitContainer isWaiting={Boolean(reviewsetId && !set)} message="Loading ReviewSet..." variant="circular">  
        <ReviewSetHeader />
        <ReviewSetDocuments />
      </WaitContainer>

    </Grid>
  );

}

export default ReviewSetView;