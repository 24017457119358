import React, { useState } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MessageIcon from '@mui/icons-material/Message';
import { IfBlock } from 'features/common';
import { useHover } from 'hooks';
import { formatDate } from 'utils/general-helpers';
import { CommentDialog } from './';

//NOTE: isReadOnly is applied in the toggleDialog method because if the IconButton is disabled, the hover popover doesn't work (disabled elements don't raise the right events)
function CommentInput({item, onChange, isReadOnly}){  
  const { classes, cx }   = buildStyles();
  const [isOpen, setOpen]   = useState(false);
  const [anchorEl, isHovering, onEnter, onExit, position]   = useHover();
  
  const input   = item.commentInput;
  const response= _.find(item.responses, rsp => rsp.checkListInputId === input.id);

  if(!input) return null;   //should be checked before this, but just in case

  const toggleDialog  = (e) => { if(!isReadOnly) setOpen(!isOpen); };
  const saveComment   = (value) => { onChange(value); toggleDialog(); }

  const props   = input.propertyBag;
  const isReq   = Boolean(props.isRequiredOnReject);
  const color   = isReq ? {color: red[400]} : {color: green[400]};
  const hasRsp  = Boolean(response);
  const icon    = hasRsp ? <MessageIcon fontSize="small"/> : <ChatBubbleOutlineIcon fontSize="small"/>;
  const title   = hasRsp ? response.textResponse : "[no comments]";
  const rspDate = hasRsp ? formatDate(response.responseDate) : "";

  return <>
    <IconButton size="small" aria-label="comments" color="primary" style={color} disabled={item.isWorking} onClick={toggleDialog} onMouseEnter={onEnter} onMouseLeave={onExit}>
      {icon}
    </IconButton>
    <Popover open={isHovering} anchorEl={anchorEl} className={classes.tip} classes={{paper: classes.tipPaper}} {...position} onClose={onExit} disableRestoreFocus>
      <Grid container direction="column">
        <Grid item className={classes.popContent}>
          <Typography className={cx(classes.popContentText, {[classes.popContentEmpty] : !hasRsp})}>{title}</Typography>
        </Grid>
        <IfBlock condition={isReq && !hasRsp}>
          <Grid item className={classes.popRequired}>
            <Typography className={classes.popRequiredText}>Required if this assertion is rejected</Typography>
          </Grid>
        </IfBlock>
        <IfBlock condition={hasRsp}>
          <Grid item className={classes.popFooter}>
            <Typography className={classes.popFooterText}>
              by <span className={classes.popFooterLabel}>{response?.userName}</span> on <span className={classes.popFooterLabel}>{rspDate}</span>
            </Typography>
          </Grid>
        </IfBlock>
      </Grid>
    </Popover>
    <IfBlock condition={isOpen}>
      <CommentDialog item={item} isOpen={isOpen} onSave={saveComment} onCancel={toggleDialog}/>
    </IfBlock>
  </>;

}

export default CommentInput;

const buildStyles   = makeStyles()(theme => ({

  dialogLabel   : {
    fontSize    : "1.5rem",
    fontWeight  : 400,
  },
  tip   : {
    pointerEvents   : "none",
  },
  tipPaper     : {
    // padding         : theme.spacing(1),
  },
  popContent    : {
    padding         : theme.spacing(1),
    maxWidth        : 400,
  },
  popContentText     : {
    fontSize    : 15,
    fontWeight  : 400,
  },
  popContentEmpty     : {
    fontStyle     : "italic",
    color         : theme.palette.grey[500],
  },
  popRequired     : {
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    background    : red[50],
  },
  popRequiredText   : {
    fontSize    : 13,
    fontWeight  : 300,
    color       : theme.palette.grey[700],
  },
  popFooter     : {
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    background    : theme.palette.grey[200],
  },
  popFooterText   : {
    fontSize    : 13,
    fontWeight  : 300,
    color       : theme.palette.grey[700],
  },
  popFooterLabel  : {
    fontWeight  : 400,
    color       : theme.palette.grey[800],
  }
}));

// const popPosition   = {
//   anchorOrigin  : {
//     vertical    : "bottom", 
//     horizontal  : "right"
//   },
//   transformOrigin   : {
//     vertical    : "top",
//     horizontal  : "right",
//   }
// };