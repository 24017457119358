import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ProfileButton from './profile-button';
import EnvironmentAlert from './environment-alert';
import { useIsMobile } from 'hooks/general-hooks';

const Header = () => {
  const isMobile = useIsMobile();

  return (
    <AppBar position="absolute">
      <Toolbar >
        <Grid container alignItems="center" justify={isMobile ? "center" : "space-between"} direction={isMobile ? "column" : "row"}>

          <Typography component="h1" variant="h6" noWrap sx={{"& a": { color: "primary.contrastText" }}}>
            <Link to="/" >
              <AssignmentTurnedInIcon sx={{mr: 1, mb: -0.5}}/>
              Checklist Tracker
            </Link>
          </Typography>

          <Grid item flexGrow={1}>
            <Grid container justifyContent="center" flexWrap="nowrap">
              <EnvironmentAlert />        
            </Grid>
          </Grid>

          <ProfileButton />
        </Grid>

      </Toolbar>
    </AppBar>
  );
};

export default Header;