import _ from 'lodash';

const PREFIX            = "quanta.security.";
const claims     = {
    ADMIN_VIEW          : PREFIX + 'adm_view',
    USER_ASSIGN_ROLE    : PREFIX + 'ro_asg',
    ENG_ASSIGN_USER     : PREFIX + 'en_asg',
    DOC_TYPE_CREATE     : PREFIX + 'dt_add',
    CHECKLIST_CREATE    : PREFIX + 'cl_add',
    // CHECKLIST_APPROVE   : PREFIX + 'cl_app',
    CHECKLIST_UNAPPROVE : PREFIX + 'cl_unapp',
    REVIEWSET_UNAPPROVE : PREFIX + 'rs_unapp',
    // REVIEWSET_APPROVE   : PREFIX + 'rs_app',
    REVIEWSET_VIEW      : PREFIX + 'rs_view'
};

const roleLabels = {
    reader  : "reader",
    user    : "editor",
    approver: "approver",
};

const roles   = {
    engagement  : {
        USER        : 'user',
        APPROVER    : 'approver',
        READER      : 'reader',
    }
};

const actions = {
    checklists : {
        approve : "approve",
        unapprove : "unapprove",
    },
    documents : {
        create : "create",
        remove : "delete",
    },
    reviewsets : {
        create : "create",
        approve : "approve",
        unapprove : "unapprove",
    },
}

const entityTypes   = {
    engagement      : 'eng'
};

function isPermitted(user, claim){    
    let response    = false;
    if(user && claim && user.securityClaims){
        response    = Boolean(user.securityClaims[claim]);
    }

    return response;
}

function getRole(user, entityId, entityType = entityTypes.engagement){
    const assignment   = _.find(user.assignments, {entityType: entityType, entityId: entityId});
    return assignment?.role || null;   
}

const helper = {
    getRole                   : getRole,
    isPermitted               : isPermitted,
    claims                    : claims,
    entityTypes               : entityTypes,
    roles                     : roles,
    actions                   : actions,
    roleLabels                : roleLabels,
};

export default helper;