import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

function ConditionButton({condition, titles, contents, onClicks, isIcon, ...otherProps}){
  const result  = _.isFunction(condition) ? condition() : Boolean(condition);
  const index   = result ? 0 : 1;
  const title   = titles[index];
  const content = contents[index];
  const onClick = onClicks[index];

  return (
    <React.Fragment>
      {isIcon && 
        <IconButton title={title} onClick={onClick} {...otherProps} size="large">
          {content}
        </IconButton>
      }
      {!isIcon && 
        <Button title={title} onClick={onClick} {...otherProps}>
          {content}
        </Button>
    }
    </React.Fragment>
  );
}

export default ConditionButton;