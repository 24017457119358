import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { respondToItemThunk } from 'store/thunks/document-thunks';
import { CltHidden, IfBlock } from 'features/common';
import { InputList, BoolInput, AssertionIcon, CommentInput } from './'
import { useCurrentDocument } from 'hooks';
import { selectCheckListItem, selectCheckListStatus } from 'store/selectors/checklist-selectors';
import { statusBackgrounds } from 'utils/checklist-helpers';
import { useIsMobile } from 'hooks/general-hooks';

export default function AssertionItem({itemId, filter, isReadOnly}){
  const dispatch    = useDispatch();
  const item        = useSelector(state => selectCheckListItem(state, itemId));
  const clStatus    = useSelector(state => selectCheckListStatus(state, item?.checkListId));
  const doc         = useCurrentDocument();
  const { classes, cx }     = buildStyles(item, {
    props: item
  });
  const isMobile = useIsMobile();

  const onRespond   = useCallback(async (response) => {
    const full  = {
      ...response,
      checkListId       : item.checkListId,
      checkListItemId   : item.id,      //this is for the reducer, to track which items are working
      reviewRecordId    : doc.primaryReviewRecord?.id,
    };

    return await dispatch(respondToItemThunk({ response: full }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, doc]);

  const isCollapsed = useMemo(() => {
    //everything is collapsed, or my section is collapsed
    if(!filter.open || filter.collapsed[item?.parentId]) return true;
    if(item.isFinished) {
      //item has an answer, and answered items are closed
      if(!filter.answered) return true;
      //approved or rejected or na
      if((!filter.approve && item.isConfirmed) || (!filter.reject && item.isRejected) || (!filter.na && item.isNa)) return true;
    }

    return false;   //if we got here, it's not collapsed
  }, [filter, item]);

  // console.log("** assertion item rendered");

  if(isCollapsed){
    return (
      <AssertionIcon item={item} withTip={true} showEmpty={true}/>
    );
  }

  return (
    <Grid id={`assertion-${item.id}`} item container direction={isMobile ? "column" : "row"} className={cx(classes.assertion, item.className)}>
      
      <CltHidden smUp>
        <MobileAssertion item={item} clStatus={clStatus} onRespond={onRespond} isReadOnly={isReadOnly} classes={classes} />
      </CltHidden>

      <CltHidden smDown>
        <Grid item className={classes.itemKey}>
          <Typography className={classes.asnKey}>{item.key}</Typography>
        </Grid>
      
        <Grid item className={classes.itemBody}>
          <Grid container direction="column">
            <Grid container justifyContent="space-between">
              <Typography className={classes.asnText}>{item.assertionText}</Typography>
              <Grid item className={classes.itemComments}>
                <IfBlock condition={item.hasComment}>
                  <CommentInput item={item} checkListStatus={clStatus} onChange={onRespond} isReadOnly={isReadOnly}/>
                </IfBlock>
              </Grid>    
            </Grid>
            <InputList item={item} onSave={onRespond} isReadOnly={isReadOnly}/>
          </Grid>
        </Grid>
      
        <Grid item className={classes.itemActions}>
          <BoolInput item={item} checkListStatus={clStatus} onSave={onRespond} isReadOnly={isReadOnly}/>           
        </Grid>      
      </CltHidden>
    </Grid>
  );
}

const buildStyles   = makeStyles()((theme, item) => ({
  assertion   : {
    width       : "calc(100% + 42px)",
    padding     : `${theme.spacing(1)} ${theme.spacing(0.5)}`,
    marginBottom: theme.spacing(1),

    "&:last-child" : {
      borderBottom  : "none",
      marginBottom  : theme.spacing(2),
    },
    ...statusBackgrounds(theme),
  },
  itemKey   : {
    width       : "33px",
    marginRight : theme.spacing(1),
  },
  itemBody  : {
    width       : "calc(100% - 213px)",
  },
  itemComments : ({
    maxWidth    : 30,
  }),
  itemActions   : {
    width         : "170px",
    "& button" : {
      marginLeft  : theme.spacing(0.5),
      padding     : "2px 4px",
      minWidth    : "48px",

      "& span.MuiButton-label" : {
        fontSize    : "11px",
        fontWeight  : 400,
      }
    },
  },
  actionsContainer: {
    "& button" : {
      marginLeft  : theme.spacing(0.5),
      padding     : "2px 4px",
      minWidth    : "48px",

      "& span.MuiButton-label" : {
        fontSize    : "11px",
        fontWeight  : 400,
      }
    },
  },
  asnKey : {
    fontSize    : "1.2rem",
    fontWeight  : 500,
    color       : theme.palette.grey[800],
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
    }
  },
  asnText : {
    fontSize    : "1.3rem",
    fontWeight  : 300,
    maxWidth    : item.hasComment ? "calc(100% - 30px)" : "100%",
  }, 
}));

const MobileAssertion = ({item, clStatus, onRespond, isReadOnly, classes}) => {

  return (
    <Grid id={`assertion-${item.id}-mobile`} container direction="column">
      
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container>
            <Typography className={classes.asnKey}>{item.key}</Typography>
            <IfBlock condition={item.hasComment}>
              <CommentInput item={item} checkListStatus={clStatus} onChange={onRespond} isReadOnly={isReadOnly}/>
            </IfBlock>
          </Grid>
        </Grid>
        <Grid item className={classes.actionsContainer}>
          <BoolInput item={item} checkListStatus={clStatus} onSave={onRespond} isReadOnly={isReadOnly}/> 
        </Grid>
      </Grid>

      <Grid item>
        <Typography className={classes.asnText}>{item.assertionText}</Typography>
      </Grid>

      <Grid item>
        <InputList item={item} onSave={onRespond} isReadOnly={isReadOnly}/>
      </Grid>

    </Grid>
  )
}