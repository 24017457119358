import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Replay';
import auth from 'utils/auth/auth-helper';
import { APPROVE_TYPES } from 'utils/reviewset-helpers';
import ReviewSetApprovalDialog from './reviewset-approval-dialog';
import { selectIsMultiApprove } from 'store/selectors/reviewset-selectors';
import { useCurrentPermissions } from 'hooks/general-hooks';

function ReviewSetApproveButton({set}){
  const user          = useSelector(state => state.app.currentUser);
  const isMulti       = useSelector(selectIsMultiApprove);
  const permissions   = useCurrentPermissions();
  const buttons       = PROPERTIES[set.statusCode](set, isMulti, user);
  const [props, setProps]   = useState({isOpen: false, action: null});

  //don't show the button if not applicable
  if(!set || !user || !permissions.isApprover) return null;

  const onToggle = (action) => { setProps({isOpen: !props.isOpen, action: action}); }

  return (
    <>
      {_.map(buttons, btn => <Button key={btn.action} size="small" variant="outlined" endIcon={btn.icon} disabled={btn.disabled} onClick={() => onToggle(btn.action)}>{btn.content}</Button>) }
      <ReviewSetApprovalDialog set={set} isOpen={props.isOpen} type={props.action} onClose={onToggle} />
    </>
  );
}

export default ReviewSetApproveButton;

ReviewSetApproveButton.propTypes  = {
  set     : PropTypes.object.isRequired,
  // onClick : PropTypes.func.isRequired,
};

const PROPERTIES  = {
  //Pending
  0     : (set, isMulti, user) => {
    return [{
      content     : isMulti ? "PreApprove" : "Approve",
      icon        : <CheckCircleIcon />,
      disabled    : !set.isApprovable,
      action      : isMulti ? APPROVE_TYPES.preApprove : APPROVE_TYPES.approve,
    }];
  },
  //Pre-Approved
  50     : (set, isMulti, user) => {
    const btns = [{
      content     : "Approve",
      icon        : <CheckCircleIcon />,
      disabled    : false,
      action      : APPROVE_TYPES.approve,
    }];

    //make sure this user has the unapprove claim (must be an admin)
    if(auth.isPermitted(user, auth.claims.REVIEWSET_UNAPPROVE)){
      btns.push({
          content     : "UnApprove",
          icon        : <UndoIcon />,
          disabled    : false,
          action      : APPROVE_TYPES.unApprove,
      });
    }

    return btns;
  },
  //Approved
  100    : (set, isMulti, user) => {
    //make sure this user has the unapprove claim (must be an admin)
    if(!auth.isPermitted(user, auth.claims.REVIEWSET_UNAPPROVE)) return [];
    return [{
      content     : "UnApprove",
      icon        : <UndoIcon />,
      disabled    : false,
      action      : APPROVE_TYPES.unApprove,
    }];
  }
}

