import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { menuConfig } from '../reviewsets/reviewsets-config';
import NavMenu from 'features/app/nav-menu';
import { useAppParams } from 'hooks';
import { selectMultiApproveFromEngagement } from 'store/selectors/reviewset-selectors';
import EngagementBreadcrumbs from './parts/engagement-breadcrumbs';
import { CltHidden } from 'features/common';

export default function EngagementHeader(){
  const { classes }   = buildStyles();
  const isWorking = useSelector(state => state.sets.isLoadingItems);
  const { engagementId } = useAppParams();
  const isMulti = useSelector(state => selectMultiApproveFromEngagement(state, engagementId));

  return (
    <Grid id="engagement-header" container className={classes.engagementHeader}>
      <Grid item xs={12} container alignItems="center">
        
        {/* In mobile, show the menu at the front of the Breadcrumbs */}
        <CltHidden mdUp>
          <NavMenu settingsKey="engagement-nav" menu={menuConfig} basePath="" isWorking={isWorking} conditionProps={{ isMulti: isMulti }} />
        </CltHidden>

        <EngagementBreadcrumbs />
      </Grid>      
    </Grid>
  );
}

const buildStyles   = makeStyles()(theme => ({
  engagementHeader    : {
    minHeight   : 30,
    padding     : `${theme.spacing(1)} ${theme.spacing(2)}`, //`
    fontSize    : "14px",
    marginBottom: theme.spacing(1),
    alignContent: "center",
    "& a,span"   : {
      fontSize    : "14px",
      display     : "flex",

      "& svg"   : {
        marginRight : theme.spacing(0.25),
        width       : 20,
        height      : 20,
      },
    },
    zIndex    : 2,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(0.5)} ${theme.spacing(0)}`, //`
    },
  },
}));