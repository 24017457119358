import React, { useMemo } from "react";
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Typography } from "@mui/material";
import { WaitContainer } from "features/common";
import EngagementAreaChart from "./engagement-area-chart";
import { itemColors } from '../engagements-config';
import { muiDialogReason } from "utils/ui-helpers";
import { getNowString } from "utils/general-helpers";

export interface IEngagementChartsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  engagement: any;
  rsData: any;
  docData: any;
  status: any;
}

const EngagementChartsDialog = ({isOpen, onClose, rsData, docData, status, engagement}: IEngagementChartsDialogProps) => {
  const { classes }   = buildStyles({});
  const displayDate = useMemo(() => getNowString(true), []);

  function doClose(event: object, reason: string){
    if(muiDialogReason.isBackdrop(reason)) return;
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={doClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>{engagement?.name ?? 'Engagement Charts'}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item container direction="row">
            <WaitContainer isWaiting={status.isWorking} className={classes.waitContainer} message="Loading dashboard data...">
              <Grid item sm={6} container justifyContent="center">
                <Grid item container direction="column" alignItems="center">
                  <Typography className={classes.chartTitle}><Icon className="material-icons-outlined">book_outlined</Icon>ReviewSets</Typography>
                  <Typography className={classes.chartTime}>as of: {displayDate} UTC</Typography>
                </Grid>
                <EngagementAreaChart chartData={rsData?.chartData} isHighContrast={true}/>
                <ChartLegend itemLabel="ReviewSet" hasPreApproved={rsData?.chartData[0].preApproved >= 0}/>
              </Grid>
              <Grid item sm={6} container justifyContent="center" spacing={2}>
                <Grid item container direction="column" alignItems="center">
                  <Typography className={classes.chartTitle}><Icon className="material-icons-outlined">description_outlined</Icon>Documents</Typography>
                  <Typography className={classes.chartTime}>as of: {displayDate} UTC</Typography>
                </Grid>
                <EngagementAreaChart chartData={docData?.chartData} isHighContrast={true}/>
                <ChartLegend itemLabel="Document" hasPreApproved={docData?.chartData[0].preApproved >= 0}/>
              </Grid>
            </WaitContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EngagementChartsDialog;

export interface IChartLegendProps {
  hasPreApproved: boolean;
  itemLabel: string;
}

const ChartLegend = ({itemLabel, hasPreApproved}: IChartLegendProps) => {
  const cPreApproved  = itemColors.active["A700"];
  const cApproved = itemColors.approved["A700"];
  const cCreated  = itemColors.created[200];
  const { classes } = buildStyles({preApproved: cPreApproved, approved: cApproved, created: cCreated});
  
  return (
    <Grid item xs={12} container className={classes.chartLegend}>
      <Grid item container justifyContent="center" className={classes.legendRow}>
        <Typography className={classes.legendText}>Legend</Typography>
      </Grid>
      <Grid container className={classes.legendRow} alignItems="center">
        <Grid item className={classes.createdBox}/>
        <Typography className={classes.legendText}>Total {itemLabel}s</Typography>
      </Grid>
      <Grid container className={classes.legendRow} alignItems="center">
        <Grid item className={classes.approvedBox}/>
        <Typography className={classes.legendText}>Approved {itemLabel}s</Typography>
      </Grid>
      {hasPreApproved && 
        <Grid container className={classes.legendRow} alignItems="center">
          <Grid item className={classes.preApprovedBox}/>
          <Typography className={classes.legendText}>PreApproved {itemLabel}s</Typography>
        </Grid>
      }
    </Grid>
  )
}

const buildStyles = makeStyles<any>()(
  (theme: any, props: any) => ({
  waitContainer   : {
    width     : "35%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop   : 65,
    color     : theme.palette.grey[200],
  },
  chartTitle  : {
    fontSize    : 20,
    fontWeight  : 400,
    marginLeft  : theme.spacing(1),
    marginTop   : theme.spacing(2),
    marginBottom: theme.spacing(-1),
    color       : theme.palette.grey[800],
    "& svg, .MuiIcon-root"   : {
      fontSize      : 22,
      fontWeight    : 300,
      marginRight   : theme.spacing(1),
      marginBottom  : theme.spacing(-0.5),
      color       : theme.palette.grey[600],
    },
  },
  chartTime: {
    fontSize    : 16,
    fontWeight  : 300,
    marginTop   : theme.spacing(1),
    marginBottom: theme.spacing(-1),
    color       : theme.palette.grey[800],   
  },
  chartLegend : {
    marginTop: theme.spacing(2),
    width: "30%",
    maxWidth: 250,
    border: `1px solid ${theme.palette.grey[300]}`, //`
    borderRadius: 4,
    padding: theme.spacing(1),
  },
  legendRow: {
    marginBottom: theme.spacing(1),
  },
  legendText: {
    fontSize: "1rem",
  },
  preApprovedBox: {
    width: 35,
    height: 20,
    border: `1px solid ${props?.preApproved}`, //`
    background: `${props?.preApproved}EE`,
    marginRight: theme.spacing(2)
  },
  approvedBox: {
    width: 35,
    height: 20,
    border: `1px solid ${props?.approved}`, //`
    background: `${props?.approved}EE`,
    marginRight: theme.spacing(2)
  },
  createdBox: {
    width: 35,
    height: 20,
    border: `1px solid ${props?.created}`, //`
    background: `${props?.created}55`,
    marginRight: theme.spacing(2)
  }
}));
// const buildStyles = makeStyles<any>()((theme: any) => ({
  // waitContainer   : {
  //   width     : "35%",
  //   marginLeft: "auto",
  //   marginRight: "auto",
  //   marginTop   : 65,
  //   color     : theme.palette.grey[200],
  // },
  // chartTitle  : {
  //   fontSize    : 20,
  //   fontWeight  : 400,
  //   marginLeft  : theme.spacing(1),
  //   marginTop   : theme.spacing(2),
  //   marginBottom: theme.spacing(-1),
  //   color       : theme.palette.grey[800],
  //   "& svg, .MuiIcon-root"   : {
  //     fontSize      : 22,
  //     fontWeight    : 300,
  //     marginRight   : theme.spacing(1),
  //     marginBottom  : theme.spacing(-0.5),
  //     color       : theme.palette.grey[600],
  //   },
  // },
  // chartTime: {
  //   fontSize    : 16,
  //   fontWeight  : 300,
  //   marginTop   : theme.spacing(1),
  //   marginBottom: theme.spacing(-1),
  //   color       : theme.palette.grey[800],   
  // },
  // chartLegend : {
  //   marginTop: theme.spacing(2),
  //   width: "30%",
  //   maxWidth: 250,
  //   border: `1px solid ${theme.palette.grey[300]}`, //`
  //   borderRadius: 4,
  //   padding: theme.spacing(1),
  // },
  // legendRow: {
  //   marginBottom: theme.spacing(1),
  // },
  // legendText: {
  //   fontSize: "1rem",
  // },
//   preApprovedBox: (props: any) => ({
//     width: 35,
//     height: 20,
//     border: `1px solid ${props?.preApproved}`, //`
//     background: `${props?.preApproved}EE`,
//     marginRight: theme.spacing(2)
//   }),
//   approvedBox: (props: any) => ({
//     width: 35,
//     height: 20,
//     border: `1px solid ${props?.approved}`, //`
//     background: `${props?.approved}EE`,
//     marginRight: theme.spacing(2)
//   }),
//   createdBox: (props: any) => ({
//     width: 35,
//     height: 20,
//     border: `1px solid ${props?.created}`, //`
//     background: `${props?.created}55`,
//     marginRight: theme.spacing(2)
//   }),
// }));

