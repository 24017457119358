import { ApiResponse } from "apisauce";
import { IPagination } from "app-types";
import { normalizeError } from "utils/general-helpers-ts";
import { ApiResponseAction } from "app-types";

//---Creates the pagination header for a request
export const paginationHeader = (pageNumber: number, pageSize: number) => {
  return { headers: {"Pagination": `{"CurrentPage":${pageNumber}, "ItemsPerPage":${pageSize}}`}};
}

export const getPagination = (result: ApiResponse<any>) : IPagination | undefined => {
  return (result.headers && result.headers.pagination) ? JSON.parse(result.headers.pagination) : undefined;
}

// Converts an ApiResponse (from apisauce) to a QuantaApiResponse<T>
export function apiResponseToAction<T = any>(
    result: ApiResponse<any>, 
    okAction: string, 
    errorAction?: string, 
    otherProps?: Record<string, any>
  ) : ApiResponseAction<T> {
    if(result.ok){
      const output  = {
        type          : okAction, 
        ok            : result.ok,
        data          : result.data,
        response      : result, 
        pagination    : getPagination(result),
        state: otherProps,
      };
      return output;
    }
    else{
      //catch an exception thrown by the reducer
      const err = normalizeError(result, "Action Failed", "Your changes were not saved, please try again.")
      const errOutput = {
        type: errorAction ?? "NO_OP_ERROR",
        ok: false,
        error: err,
        errorInfo: "api error",   //just some info to help with troubleshooting
        response: result,
        state: otherProps,
      };
      return errOutput;
      
    }
}