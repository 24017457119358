import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { responseMap } from 'utils/checklist-helpers';
import { IfBlock } from 'features/common';
import RadioBoxesOption from './input-options-radio';
import CheckBoxesOption from './input-options-check';
import SelectOption from './input-options-select';

function OptionsInput({item, input, onSave, isReadOnly}){
  const { classes }             = buildStyles();
  const response            = _.find(item.responses, rsp => rsp.checkListInputId === input.id);
  const [value, setValue]   = useState(response ? response.textResponse : "");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const choices     = useMemo(() => { return prepareChoices(input); }, [input.propertyBag]);
  
  const handleChange = async (e) => {
    const newValue  = _.isString(e) ? e : e.target.value;
    
    if(newValue !== (response?.textResponse || "") ){
      setValue(newValue);
      let model   = responseMap.base(input, response);
      
      if(!newValue || newValue === ""){
        model   = {...model, ...responseMap.delete };
      }
      else{
        model   = {...model, ...responseMap.text(newValue) };
      }

      await onSave(model); 
    }
  }

  return (
      <Grid container className={classes.inputCard}>
        <IfBlock condition={input.optionType === 0}>
          <RadioBoxesOption item={item} input={input} choices={choices} value={value} response={response} handleChange={handleChange} isReadOnly={isReadOnly}/>
        </IfBlock>
        <IfBlock condition={input.optionType === 1}>
          <CheckBoxesOption item={item} input={input} choices={choices} value={value} response={response} handleChange={handleChange} isReadOnly={isReadOnly}/>
        </IfBlock>
        <IfBlock condition={input.optionType === 2}>
          <SelectOption item={item} input={input} choices={choices} value={value} response={response} handleChange={handleChange} isReadOnly={isReadOnly}/>
        </IfBlock>        
      </Grid>
  );
}

export default OptionsInput;

const buildStyles   = makeStyles()(theme => ({
  inputCard   : {
    padding     : theme.spacing(1),
  },
}));

function prepareChoices(input){
  const choices = JSON.parse(input.propertyBag.choices);
  const options = _.map(choices, itm => { return {value: itm.trim(), label: itm.trim()}});
  return options;
}

