import React, { useState, useEffect } from 'react';
import { pick } from 'lodash';
import { useQuery, useSetting } from 'hooks';
import { ListToolbar, ListFooter, WaitOverlay } from 'features/common';
import { CltTbody, CltThead } from "features/common/table";
import { updateUrlSearch } from 'utils/general-helpers';
import ErrorBoundary from './error-boundary';
import { useNavigate } from 'react-router-dom';
import { Table } from '@mui/material';

const DEFAULT_PROPS  = {
  pageNumber      : 1,
  pageSize        : 25,
  error           : null,
  isInitialized   : false,  
  sort            : null,
  filter          : null,
  search          : null,
};
const NO_CHANGES = {
  sort            : null,
  filter          : null,
  search          : null,
}

const ItemList = ({
    config, 
    items, 
    pagination, 
    isWorking, 
    RowComponent, 
    onRefresh, 
    onSort = null, 
    onAdd = null, 
    addUrl = null, 
    noToolbar, 
    onExport = null, 
    onManualRefresh = (props) => {}}
) => {
  const navigate = useNavigate();
  const query     = useQuery();
  const psizeKey  = config.pageSizeKey || "default-grid-pagesize";
  const [defaultPageSize, setDefaultPageSize]   = useSetting(psizeKey, 25);
  const [props, setProps]         = useState({...DEFAULT_PROPS, pageSize : defaultPageSize});

  useEffect(() => {
    //Check to see if the props changed from last time
    const isChanged   = needsRefresh(props, query, pagination);
    if(isChanged){
      //If something other than the page changed, then we need to reset the page to 1
      const nextProps = (props.pageNumber === pagination?.CurrentPage && props.pageNumber !== 1) ? { ...props, pageNumber: 1 } : props;
      onRefresh(nextProps, query);
      const changes   = {...NO_CHANGES, ...pick(query, ["sort", "filter", "search"])};
      setProps({...props, ...changes, isInitialized: true});
    }
    else if(props.error && !query.search){
      setProps({...props, error: null});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageNumber, props.pageSize, query.sort, query.filter, query.search]);

  function onChangePage(pageNum, pageSize){ 
    
    if(pageSize && pageSize !== props.pageSize){
      setDefaultPageSize(pageSize);
    }

    const changes   = {
      pageNumber  : pageNum || props.pageNumber,
      pageSize    : pageSize || props.pageSize,
    }
    setProps({...props, ...changes});     
  };

  function mySort(sortStr){
    const newUrl  = updateUrlSearch("sort", sortStr);
    navigate(newUrl, { replace: true });
  }

  function doManualRefresh(props){
    const propsWithPage = {
      ...props,
      pageNumber: props.pageNum,
      pageSize: props.pageSize,
    };
    onManualRefresh(propsWithPage);
  }

  onSort  = onSort || mySort;

  return (
    <ErrorBoundary>
      {!noToolbar &&
        <ListToolbar 
          title={config.title} 
          isWorking={isWorking} 
          pagination={pagination} 
          onChangePage={onChangePage} 
          newUrl={addUrl}
          newCallback={onAdd} 
          searchPlaceholder={config.searchPlaceholder} 
          filterOptions={config.filterOptions}
          baseUrl={config.baseUrl}
          onFiltered={config.onFiltered}
          onExport={onExport}
          onManualRefresh={doManualRefresh}
          />
      }

      <WaitOverlay isWaiting={isWorking} message="Working...">
        <Table sx={{
          "& td, th, button, a": { fontSize: "0.9rem", fontWeight: "400", lineHeight: "18px", padding: 1},
          "& a": { display: "inline-block"},
          }}>
          
          <CltThead 
            cols={config.cols} 
            onSort={onSort} 
            isWorking={isWorking}/>

          <CltTbody colSpan={config.cols.length} isWorking={isWorking} noWorkingRow={true}>
            {items?.map(item => <RowComponent key={config.keyFunc(item)} item={item} actions={config.actions}/>)}
          </CltTbody>

        </Table>
      </WaitOverlay>

      {pagination && <ListFooter pagination={pagination} isWorking={isWorking} onChangePage={onChangePage}/>}
    </ErrorBoundary>
  );
}

export default ItemList;

function needsRefresh(props, query, pagination){
  if(!props.isInitialized) return true;

  if(pagination && (pagination.CurrentPage !== props.pageNumber || pagination.ItemsPerPage !== props.pageSize)){
    return true;  //page number or page size changed...
  }
  
  if(query){
    return (
      hasChanged(props.sort, query.sort) ||
      hasChanged(props.filter, query.filter) ||
      hasChanged(props.search, query.search)      
    );
  } 
  
  return true;
}

function hasChanged(v1, v2){
  return !!((!v1 && v2) || (v1 && v1 !== v2));
}