import React from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import Grid from '@mui/material/Grid';
import NavMenu from 'features/app/nav-menu';
import { menuConfig } from './admin-config';
import { selectAdminStatus } from 'store/selectors/admin-selectors';
import { appRoutes } from 'utils/route-helpers';
import UsersView from './users/users-view';
import AdminTasksView from './tasks/admin-tasks-view';
import AdminHome from './admin-home';
import { Route, Routes, useLocation } from 'react-router-dom';
import ChecklistPrintView from './checklists/checklist-print-view';
import AdminCard from './common/admin-card';
import DocTypesList from './checklists/doctypes-list';
import CheckListEditor from './checklists/checklist-editor';
import EngagementsList from './engagements/engagements-list';
import EngagementEditor from './engagements/engagement-editor';
import AdminReportsView from './reports/admin-reports-view';

const AdminView = () => {
  const { pathname }  = useLocation();
  const isPrint   = pathname?.endsWith("/print");
  const status    = useSelector(selectAdminStatus);

  return (
    <Grid id="admin-view" container justifyContent="center" flexWrap="nowrap">
      
      <Helmet>
        <title>Admin - CheckList Tracker</title>
      </Helmet>

      {!isPrint &&
        <Grid id="admin-menu" item>
          <NavMenu settingsKey="admin-nav" menu={menuConfig} basePath="/" isWorking={status.isWorking}/>                                                            
        </Grid>
      }

      <Grid id="admin-content" item container sx={{p: 2}}>
        <Routes>
          <Route path={appRoutes.admin.users} element={<UsersView />} />
          <Route path={appRoutes.admin.engagements} element={ <AdminCard statusKey="engagements"><EngagementsList /></AdminCard> } />
          <Route path={appRoutes.admin.engagement} element={ <AdminCard statusKey="engagements"><EngagementEditor /></AdminCard> } />
          <Route path={appRoutes.admin.tasks} element={<AdminTasksView />} />
          <Route path={appRoutes.admin.reports} element={<AdminCard statusKey="reports"><AdminReportsView /></AdminCard>} />
          <Route path={appRoutes.admin.documentTypes} element={ <AdminCard statusKey="doctypes"><DocTypesList /></AdminCard> } />
          <Route path={appRoutes.admin.checklist} element={ <AdminCard statusKey="checklists"><CheckListEditor /></AdminCard> } />
          <Route path={appRoutes.admin.checklistPrint} element={<ChecklistPrintView />} />
          <Route path="*" element={<AdminHome />} />
        </Routes>
      </Grid>

    </Grid>
  );
}

export default AdminView;