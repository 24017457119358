import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { doInsertItem, doMoveItem, replaceBuilderItem } from "features/admin/checklists/builder-helpers";
import { CheckList, CheckListItem, CheckListSection } from "features/admin/checklists/builder-types";
import { get, orderBy } from "lodash";
import { fetchBuilderChecklistThunk, saveBuilderChecklistThunk } from "store/thunks/builder-thunks";
import { createWorkingChecklist, defaultAssertion, defaultChecklist, defaultSection } from "utils/checklist-helpers";

type BuilderStatus = {
  checklists: {
    isWorking: boolean;
    isInitialized: boolean;
    isDirty: boolean;
    error: any | null;
  };
  builder: {
    isWorking: boolean;
    isInitialized: boolean;
    error: any | null;
  };
}

export interface BuilderSlice {
  original: CheckList | null;
  working: CheckList | null;
  status: BuilderStatus;
}

const INITIAL_STATE: BuilderSlice = {
  original: null,
  working: null,
  status: {
    checklists: {
      isWorking: false,
      isInitialized: false,
      isDirty: false,
      error: null,
    },
    builder: {
      isWorking: false,
      isInitialized: false,
      error: null,
    },
  },
};

//=== Thunk Reducers
const checklistFetched = (builder: ActionReducerMapBuilder<BuilderSlice>) => {
  builder
    .addCase(fetchBuilderChecklistThunk.pending, startWorking("checklists"))
    .addCase(fetchBuilderChecklistThunk.rejected, thunkError("checklists"))
    .addCase(fetchBuilderChecklistThunk.fulfilled, (state, action) => {
      
      const checklist = action.payload.data;
      const items = orderBy(checklist.checkListItems, i => i.ordinal);
      checklist.checkListItems = items;

      initializeChecklist(state, checklist);
    });
};

const checklistSaved = (builder: ActionReducerMapBuilder<BuilderSlice>) => {
  builder
    .addCase(saveBuilderChecklistThunk.pending, startWorking("checklists"))
    .addCase(saveBuilderChecklistThunk.rejected, thunkError("checklists"))
    .addCase(saveBuilderChecklistThunk.fulfilled, (state, action) => {
      if(!state.working) return;

      const checklist = action.payload.data;
      const expandedItems = state.working.checklistItems.filter((i: any) => i.isExpanded);
      const working = createWorkingChecklist(checklist, expandedItems);
      
      state.original = checklist;
      state.working = working;
      state.status.checklists.isWorking = false;
      state.status.checklists.isDirty = false;
      state.status.checklists.isInitialized = true;
      state.status.checklists.error = null;
    });
};

//=== Reducers

//TODO: this is really the same as updateBuilderItem
const updateBuilderSection = (state: BuilderSlice, action: PayloadAction<{id: number, changes: any}>) => {
  if(!state.working) return;
  const items = state.working!.checklistItems;
  const { id, changes } = action.payload;
  const section = getItem<CheckListSection>(items, id);
  const updated = {...section, ...changes};
  
  state.working.checklistItems = replaceBuilderItem(items, section, updated);
};

const toggleBuilderSection = (state: BuilderSlice, action: PayloadAction<{id: number, isExpanded: boolean}>) => {
  if(!state.working) return;
  const items = state.working!.checklistItems;
  const { id, isExpanded } = action.payload;
  const section = getItem<CheckListSection>(items, id);
  if(section.isExpanded === isExpanded) return;   //nothing to do here
  const updated = {...section, isExpanded: isExpanded};

  state.working.checklistItems = replaceBuilderItem(items, section, updated);
};

const updateBuilderItem = (state: BuilderSlice, action: PayloadAction<{id: number, changes: any}>) => {
  if(!state.working) return;
  const items = state.working!.checklistItems;
  const { id: itemId, changes } = action.payload;
  const original = getItem(items, itemId);
  const update    = {...original, ...changes};
  
  state.working.checklistItems = replaceBuilderItem(items, original, update);
};

const moveBuilderItem = (state: BuilderSlice, action: PayloadAction<{id: number, offset: number}>) => {
  if(!state.working) return;
  const items = state.working!.checklistItems;
  const { id: itemId, offset } = action.payload;
  const changes = doMoveItem(items, itemId, offset);
  if(!changes) return;

  state.working.checklistItems = changes;
};

const insertBuilderSection = (state: BuilderSlice, action: PayloadAction<{id?: number, offset?: number}>) => {
  if(!state.working) return;
  const { id: sectionId, offset } = action.payload;
  const items = state.working!.checklistItems;
  const newSec = {...defaultSection(null)};    //id will be set by the doInsert method

  const changes = doInsertItem(items, newSec, sectionId, offset);
  if(!changes) return;

  state.working.checklistItems = changes;
};

const insertBuilderItem = (state: BuilderSlice, action: PayloadAction<{parentId: number, refId: number, offset: number}>) => {
  if(!state.working) return;
  const { parentId, refId, offset } = action.payload;
  const items = state.working!.checklistItems;
  const newItem: CheckListItem = {...defaultAssertion(parentId, null)};    //id will be set by the doInsert method

  const changes = doInsertItem(items, newItem, refId, offset);
  if(!changes) return;

  state.working.checklistItems = changes;
};

//=== Slice
export const builderSlice = createSlice({
  name: "builder",
  initialState: INITIAL_STATE,
  reducers: {
    startBuilderChecklist: (state, action: PayloadAction<number>) => {
      const checklist = { ...defaultChecklist(action.payload) };
      initializeChecklist(state, checklist);
    },
    modifyBuilderChecklist: (state, action: PayloadAction<{id: number, checklist: any}>) => {
      if(action.payload.checklist){
        if(state.working?.id !== action.payload.id) throw new Error("Invalid checklist id: " + action.payload.id);
        //changes to the checklist itself
        state.working = { ...state.working, ...action.payload.checklist };
      }
    },
    resetBuilderChecklist: (state) => {
      //This is called when user tries to save when the only changes are "ghosts" - items that were added and removed in the same 
      // session, and therefore don't need to be sent to the server.  This action will just reset the current checklist so that
      // it appears as if those changes have been committed.
      const checklist = state.original;
      if(state.working) {
        const expandedItems = state.working.checklistItems.filter((i: any) => i.isExpanded);
        const working = createWorkingChecklist(checklist, expandedItems);
        state.working = working;
      }
      state.status.checklists.isWorking = false;
      state.status.checklists.isDirty = false;
      state.status.checklists.isInitialized = true;
      state.status.checklists.error = null;
    },
    clearBuilderStatus: (state, action: PayloadAction<"checklists" | "builder">) => {
      state.status[action.payload].error = null;
      state.status[action.payload].isWorking = false;
      state.status[action.payload].isInitialized = true;
    },
    updateBuilderSection,
    toggleBuilderSection,
    updateBuilderItem,
    moveBuilderItem,
    insertBuilderSection,
    insertBuilderItem,
  },
  extraReducers: (builder) => {
    checklistFetched(builder);
    checklistSaved(builder);
  },
});

export default builderSlice.reducer;
//The Slice Actions are shared in ./index.ts

//#region Helper Methods
const startWorking = (statusKey: "checklists" | "builder") => (state: BuilderSlice) => {
  state.status[statusKey].isWorking = true;
  state.status[statusKey].error = null;
}
const thunkError = (statusKey: "checklists" | "builder") => (state: BuilderSlice, action: any) => {
  state.status[statusKey].isWorking = false;
  state.status[statusKey].error = action.error;
}

const initializeChecklist = (state: BuilderSlice, checklist: any) => {
  state.original = checklist;
  state.working = createWorkingChecklist(checklist);
  state.status.checklists.isWorking = false;
  state.status.checklists.isInitialized = true;
  state.status.checklists.isDirty = false;
}

const getItem = <T extends CheckListItem>(items: CheckListItem[], id: number): T => {
  const item = items.find(i => i.id === id);
  if(!item) throw new Error("Invalid item id: " + id);
  return item as T;
}
//#endregion