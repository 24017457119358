import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { IReviewSet } from "app-types";
import { ItemSlice } from "./slice-types";
import { approveSetThunk, createSetThunk, fetchSetThunk, saveSetThunk } from "../thunks/reviewset-thunks";
import { approveCheckListThunk, createDocThunk, removeDocThunk } from "store/thunks";

export interface ReviewsetSlice extends ItemSlice<IReviewSet> {
  error               : any;
  isLoading           : boolean;
  isWorking           : boolean;
  isSaving            : boolean;  
  
  //TODO: convert to ItemStatus & { isDocChanged: boolean }
  itemStatus          : {
    itemTime      : number | null;
    isLoading     : boolean;
    isWorking     : boolean;
    isSaving      : boolean;
    isDocChanged  : boolean;
    error         : any;
  };

  approving           : { isWorking: boolean; error: any; };
}

const INITIAL_STATE = {
  error               : null,
  isLoading           : false,
  isWorking           : false,
  item                : null,

  itemTime            : null,
  isSaving            : false,  
  
  itemStatus          : {
    itemTime      : null,
    isLoading     : false,
    isWorking     : false,
    isSaving      : false,
    isDocChanged  : false,
    error         : null,
  },

  approving           : { isWorking: false, error: null },
};

//=== Reducer for Fetch ReviewSet
const setFetched = (builder: ActionReducerMapBuilder<ReviewsetSlice>) => {
  builder.addCase(fetchSetThunk.pending, (state, action) => {
    state.isLoading = true;
    state.error = null;
    state.itemStatus = { ...state.itemStatus, error: null, isLoading: true };
  })
  .addCase(fetchSetThunk.fulfilled, (state, action) => {
    const ts = new Date().getTime();
    state.isLoading = false;
    state.error = null;
    state.item = action.payload?.data ?? null;
    state.itemTime = ts;
    state.itemStatus = { ...state.itemStatus, error: null, isLoading: false, itemTime: ts, isDocChanged: false };
  })
  .addCase(fetchSetThunk.rejected, (state, action) => {
    state.isLoading = false;
    state.error = action.error;
    state.itemTime = null;
    state.itemStatus = { ...state.itemStatus, error: action.error, isLoading: false, itemTime: null };
  })
};

//=== Reducer for Create ReviewSet
const setCreated = (builder: ActionReducerMapBuilder<ReviewsetSlice>) => {
  builder.addCase(createSetThunk.pending, (state, action) => {
    state.isSaving = true;
    state.error = null;
    state.itemStatus = { ...state.itemStatus, error: null, isSaving: true };
  })
  .addCase(createSetThunk.fulfilled, (state, action) => {
    state.isSaving = false;
    state.item = null;      //lave empty, it will be loaded
    state.itemTime = null;
    // state.error = null;
    //TODO: old reducer sets isSaving to true in itemStatus... is that correct?
    state.itemStatus = { ...state.itemStatus, isSaving: true, error: null, itemTime: null };
  })
  .addCase(createSetThunk.rejected, (state, action) => {
    state.isSaving = false;
    state.error = action.error;
    state.itemStatus = { ...state.itemStatus, error: action.error, isSaving: false }
  });
};

//=== Reducer for Save ReviewSet
const setSaved = (builder: ActionReducerMapBuilder<ReviewsetSlice>) => {
  builder.addCase(saveSetThunk.pending, (state, action) => {
    state.isSaving = true;
    state.error = null;
    state.itemStatus = { ...state.itemStatus, error: null, isSaving: true };
  })
  .addCase(saveSetThunk.fulfilled, (state, action) => {
    const ts = new Date().getTime();
    state.isSaving = false;
    state.error = null;
    state.item = { ...state.item, ...action.payload.data } as IReviewSet;
    state.itemTime = ts;
    //TODO: old reducer sets isSaving to true in itemStatus... is that correct?
    state.itemStatus = { ...state.itemStatus, isSaving: true, itemTime: ts, };
  })
  .addCase(saveSetThunk.rejected, (state, action) => {
    state.isSaving = false;
    state.error = action.error;
    state.itemStatus = { ...state.itemStatus, error: action.error, isSaving: false }
  });
};

//=== Reducer for approving a reviewset
const setApproved = (builder: ActionReducerMapBuilder<ReviewsetSlice>) => {
  builder.addCase(approveSetThunk.pending, (state) => {
    state.approving.isWorking = true;
    state.approving.error = null;
  })
  .addCase(approveSetThunk.fulfilled, (state, action) => {
    state.approving.isWorking = false;
    state.approving.error = null;
    state.item = action.payload.data as IReviewSet;
  })
  .addCase(approveSetThunk.rejected, (state, action) => {
    state.approving.isWorking = false;
    state.approving.error = action.error;
  });
};

//=== Reducer for when a document in the current set is changed
const docChanged = (builder: ActionReducerMapBuilder<ReviewsetSlice>) => {
  builder.addCase(createDocThunk.fulfilled, (state, action) => {
    state.itemStatus = { ...state.itemStatus, isDocChanged: true };
  })
  .addCase(removeDocThunk.fulfilled, (state, action) => {
    state.itemStatus = { ...state.itemStatus, isDocChanged: true };
  })
  .addCase(approveCheckListThunk.fulfilled, (state, action) => {
    state.itemStatus = { ...state.itemStatus, isDocChanged: true };
  });  
};

//=== Slice
export const reviewsetSlice = createSlice({
  name: "reviewset",
  initialState: INITIAL_STATE,
  reducers: {
    approvalCleared: (state) => {
      state.approving = { isWorking: false, error: null };
    },
  },
  extraReducers: builder => {
    setFetched(builder);
    setCreated(builder);
    setSaved(builder);
    setApproved(builder);
    docChanged(builder);
  },
});

//exported in the index.ts file
// export const { approvalCleared } = reviewsetSlice.actions;
export default reviewsetSlice.reducer;