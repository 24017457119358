import React, { useMemo } from 'react';
import _, { isArray, isObject, isString } from 'lodash';
import { Status } from 'features/common';
import { Versions } from '.';
import { APPROVE_TYPES } from 'utils/checklist-helpers';

export default function DocumentHeader({set, doc}){

    const statusCode  = isApproved(doc) ? 100 : 0;
    const DocHeader = useMemo(() => {
        const reportVersion = Versions.getReportVersion(set);
        return reportVersion === "2" ? DocumentHeaderV2 : DocumentHeaderV1;    
    }, [set]);

    if(!set || !doc){
        console.log("Document Report Header: No set or doc to display.");
        return null;
    } 

    
    return (<DocHeader doc={doc} statusCode={statusCode} />);
}

function isApproved(doc){
  
  let clCount   = doc.documentType.checkListCount;
  if(clCount === 0) clCount = doc.documentType.checkLists?.length ?? 0;
  const pRrec     = _.find(doc.reviewRecords, {primaryReviewRecordId: null});
  const approvals = _.filter(pRrec.checkListStatuses, cls => cls.status === APPROVE_TYPES.approve);

  return Boolean(clCount > 0 && clCount === approvals.length);
}

export const DocumentHeaderV1 = ({doc, statusCode}) => {
    return (
        <div key={doc.id} className="row report-header">
            <div className="col-xs-12">
                <div className="row report-row-dark">
                    <div className="col-xs-6">
                        <h2>Document: {doc.documentKey}</h2>
                    </div>
                    <div className="col-xs-3">
                        <span className="m-t-sm">
                            <span className="pull-left m-t-sm report-header-value">{doc.documentType.name}</span>
                        </span>
                    </div>
                    <div className="col-xs-3">
                        <Status code={statusCode} className="pull-right m-t-xs" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export const DocumentHeaderV2 = ({doc, statusCode}) => {

  const displayProps  = doc?.propertyBag?.displayProperties ?? null;
  const hasMeta       = Boolean(displayProps);

  return (
      <div key={doc.id} className="row report-header">
          <div className="col-xs-12">
              <div className="row report-row-dark">
                  <div className="col-xs-6">
                      <h2>Document: {doc.documentKey}</h2>
                  </div>
                  <div className="col-xs-3">
                      <span className="m-t-sm">
                          <span className="pull-left m-t-sm report-header-value">{doc.documentType.name}</span>
                      </span>
                  </div>
                  <div className="col-xs-3">
                      <Status code={statusCode} className="pull-right m-t-xs" />
                  </div>
              </div>
              {hasMeta && (
                <div className="row report-row-dark">
                  <MetaDataSection data={displayProps} />
                </div>
              )}
          </div>
      </div>
  );
}

const MetaDataSection = ({data}) => {
  
  if(!data) return null;

  let index     = 0;
  let rows      = null;
  if(isString(data)) data = JSON.parse(data);

  if(isArray(data)){
    rows          = data.map(dr => {
      return (
        <tr key={index++} className="data-row">
          <td className="doc-props-label-col"><span className="">{dr.label}</span></td>
          <td className="doc-props-value-col"><span className="">{dr.value}</span></td>
        </tr>
      );
    });
  }
  else if(isObject(data)){
    const keys    = Object.keys(data);
    rows          = keys.map(key => {
      const dr    = data[key];
      return (
        <tr key={index++} className="data-row">
          <td className="doc-props-label-col"><span className="">{key}</span></td>
          <td className="doc-props-value-col"><span className="">{dr}</span></td>
        </tr>
      );
    });
  }
  else{
    rows = (
      <tr className="data-row">
        <td className="doc-props-value-col" colSpan={2}>Unknown Display Properties Format</td>
      </tr>
    );
  }

  return (
    <table className="table table-condensed table-bordered doc-data-table doc-data-table-report">
      <tbody>
        {rows}
      </tbody>
    </table>
  );
};