import React from 'react';
import { sortBy } from 'lodash';
import { CheckListReportPart } from '.';
import DocumentHeader from './document-report-header';

export default function DocumentReportPart({set, doc, engagement}){

  const checklists = sortBy(doc.checkLists, ['name']);  

  return (
    <div className="row report-part">
      <div className="col-xs-12">
        <DocumentHeader doc={doc} set={set}/>
        {checklists.map(cl => <CheckListReportPart key={cl.id} set={set} doc={doc} checklist={cl} protocol={engagement.protocol} />)}
      </div>
    </div>
  );
}