import _ from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { isComment, isCommentRequiredOnReject } from 'utils/checklist-helpers';

const PRE   = "PREAPPROVE";
const APR   = "APPROVE";

// const _getDocument  = state => state.doc.item;
const _getReviewSet   = state => state.set.item;
const _getCheckLists  = state => state.doc.itemCheckLists;
const _getStatuses    = state => state.doc.itemStatuses;
const _getCheckListIndex  = state => state.doc.checkListIndex;
const _inItemId       = (state, itemId) => itemId;
const _getResponses     = state => state.checklist?.responses;
const _getCheckListItem  = (state, itemId) => state.checklist.items[itemId];

//-----------------
// Will prepare an item to be displayed / used in a checklist.
//  used by the AssertionItem component to shape the information as necessary
//-----------------
export const selectCheckListItem = createCachedSelector(
  [_getCheckListItem, _inItemId ],
  (item, itemId) => {

    if(!item) return null
    const bResponse   = item.primaryResponse;
    const comment     = _.find(item.checkListInputs, inp => isComment(inp));
    const hasRequiredComment  = comment ? isCommentRequiredOnReject(comment) : false;
    const inpAdjust   = comment ? 1 : 0;
    const rspAdjust   = comment && _.find(item.responses, r => r.checkListInputId === comment.id) != null ? 1 : 0;
    const isFinished  = Boolean(item.responses.length - rspAdjust === item.checkListInputs.length - inpAdjust);
    const isReady     = !isFinished && Boolean(item.responses.length - rspAdjust === item.checkListInputs.length - inpAdjust - 1); //also subtract out the bool input

    let props   = {
      ...item,
      isReady         : isReady,      //whether or not the item is ready to be approved / rejected (meaning all non-comment inputs have been filled, except the bool)
      isFinished      : isFinished,   //whether or not all the non-comment inputs have been filled, including the bool
      isConfirmed     : Boolean(bResponse && bResponse.boolResponse === true),
      isRejected      : Boolean(bResponse && bResponse.boolResponse === false),
      isNa            : Boolean(bResponse && bResponse.notApplicable === true),
      hasComment      : Boolean(comment), //_.find(item.checkListInputs, inp => inp.discriminator === "Comment")),
      isCommentRequiredOnReject  : hasRequiredComment,
      commentInput    : comment,
    };

    props.className    = props.isConfirmed ? "confirmed" : (props.isRejected ? "rejected" : (props.isNa ? "na" : ""));
    return props;
  }
)(
  (state, itemId) => itemId ?? "-1", //key for re-reselect caching
);

//-----------------
// Gets the specific checklist from the available options
//-----------------
export const selectCheckList = createSelector(
  [_getCheckLists, _getCheckListIndex],
  (checkLists, index) => {
    return checkLists[index];
  }
);

//-----------------
// Gets the status information for a checklist
//-----------------
export const selectCheckListStatus = createCachedSelector(
  [_getStatuses, _getReviewSet, _inItemId],
  (statuses, set, checkListId) => {
    
    if(!checkListId) return null;

    if(statuses && statuses.length > 0){
      const myStatuses = _.filter(statuses, s => s.checkListId === checkListId);  //might have multiple: pre-approve, approve
      if(myStatuses && myStatuses.length > 0){
        const approval      = _.find(myStatuses, s => s.status === APR);
        const preApproval   = _.find(myStatuses, s => s.status === PRE);
        return {
          statusCode      : (approval === null) ? (preApproval === null ? 0 : 50) : 100,
          statuses        : statuses,
          approval        : approval,
          preApproval     : preApproval,
          isApproved      : !!approval,
          isPreApproved   : !!preApproval,
          isPending       : (!approval && !preApproval), 
          isSetLocked     : set.statusCode > 0, //50 or 100 for pre-approved or approved         
        };
      }
    }

    return {
      statusCode      : 0,
      statuses        : [],
      approval        : null,
      preApproval     : null,
      isApproved      : false,
      isPreApproved   : false,
      isPending       : true,
    };
  }
)(
  (state, itemId) => itemId ?? "-1",  //key for re-reselect caching
);

//-----------------
// Gets the responses for a specific checklist
//-----------------
export const selectCheckListResponses = createSelector(
  [_getResponses, _inItemId],
  (responses, checkListId) => {
    return _.filter(responses, r => r.checkListId === checkListId);
  }
);