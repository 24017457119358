import { AuthenticationResult } from "@azure/msal-browser";
import { loggedIn, loggedOut } from "store/actions";
import { createAppAsyncThunk } from "store/slices/slice-helpers";
import { AppAuthResult } from "types";
import { buildMsalResult, msalLogout } from "utils/auth/auth-utils";
import { fetchSettingsThunk } from "./setting-thunks";

export const APP_THUNKS = {
  authChanged: "app/authChanged",
};

//Thunk Parameter Types
export type AuthChangedParams = { account: AuthenticationResult };

//Thunks

export const authChangedThunk = createAppAsyncThunk<any, AuthChangedParams>(APP_THUNKS.authChanged, async (params, thunkAPI) => {
  const { dispatch, getState } = thunkAPI
  const { account } = params;
  const isCurrentlyAuthenticated = getState().app.isAuthenticated;

  if(!isCurrentlyAuthenticated && account){
    //user is logging in
    const response: AppAuthResult = buildMsalResult(account);
    
    if(response.error != null){
      //TODO: handle auth errors
      console.error("authChangedThunk error", response.error);
      return response;
    }

    const { tenantProfiles, ...profile } = response.profile!;    //tenant profiles is not serializable
    response.profile = profile;
    
    dispatch(loggedIn(response));

    if(response.isAuthenticated){
      dispatch(fetchSettingsThunk());
    }
    
    return response;
  }
  else if(isCurrentlyAuthenticated){
    //user is logging out
    dispatch(loggedOut());
  }

  return account;
});

export const logoutThunk = createAppAsyncThunk(APP_THUNKS.authChanged, async (params, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const isLoggedIn = getState().app.isAuthenticated;
  if(!isLoggedIn) return;

  dispatch(loggedOut());
  msalLogout();
});