import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ChevronUp from '@mui/icons-material/KeyboardArrowUp';
import ChevronDown from '@mui/icons-material/KeyboardArrowDown';
import { TableCell } from '@mui/material';

function CltTh({sortKey, query, defaultDir, children, onSort, isWorking, ...otherProps}){
  const { classes }     = buildStyles();
  
  if(!sortKey){
    return (
      <TableCell {...otherProps}>
        {children}
      </TableCell>
    );
  }

  const urp       = query ? new URLSearchParams(query) : (new URL(window.location.href)).searchParams;
  const sort      = urp.get("sort");
  const isSorted  = Boolean(sort && sort.indexOf(sortKey) >= 0) || Boolean(!sort && defaultDir);
  let icon        = null;
  let nextDir     = "asc";
  let onClick     = () => {};   //no-op by default
  let title       = isWorking ? "" : "Click to sort";

  if(isSorted){
    const isAsc   = Boolean(!sort && defaultDir === "asc") || Boolean(sort && sort.indexOf("-asc") > 0);
    icon          = isAsc ? <ChevronUp className={classes.icon} title="Sorted ascending by this column"/> : <ChevronDown className={classes.icon} title="Sorted descending by this column"/>;
    nextDir       = isAsc ? "desc" : "asc";
  }

  const nextSort  = (defaultDir && defaultDir === nextDir ) ? "" : `${sortKey}-${nextDir}`;

  if(!isWorking){
    onClick       = () => onSort(nextSort);
  }

  //Construct the string for the next sort
  
  return (
    <TableCell onClick={onClick} className={isSorted ? classes.sortedCol : classes.col} title={title}>
      {children}
      {icon}
    </TableCell>
  )
}

const buildStyles   = makeStyles()(theme => ({
  col   : {
    cursor    : "pointer",
  },
  icon  : {
    fontSize    : "1.25rem",
    float       : "right",
  },
  sortedCol   : {
    background    : theme.palette.grey[100],
    borderRadius  : "5px 5px 0 0",
    cursor: "pointer",
  }
}));

export default CltTh;