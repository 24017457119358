import { filter, reduce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { tryParseInt } from "utils/general-helpers";

function appendSearchParams(url, search){
  const separator = url.indexOf("?") > 0 ? "&" : "?";
  return `${url}${separator}${search}`;
}


function parseDialogString(value){
  if(!value) return null;
  
  const arr   = value.split(",");
  let items   = reduce(arr, (result, item) => {
    const parts   = item.split(":");
    const val     = parts.length > 0 ? tryParseInt(parts[1], parts[1]) : true;
    return {
      ...result,
      [parts[0]]  : val,
    }
  }, {});

  return items;
}

function parseDialogStoreString(value){
  if(!value) return {};
  
  const arr   = value.split(",");
  let items   = reduce(arr, (result, item) => {
    const parts   = item.split(":");
    let val     = parts.length > 0 ? tryParseInt(parts[1], (parts[1] || true)) : true;
    if(val === "true" || val === "false") val = Boolean(val);
    return {
      ...result,
      [parts[0]]  : val,
    }
  }, {});

  return items;
}

//Will manage the visible dialog boxes based on the URL QueryString
// query string should be in the format:
// ?dialogs={key}:{id},{key2}:{id2}...
// the dialogs structure will return it in the format:
// {key: id, key2: id2}
export function useDialogs(defaults = null){  
  const location      = useLocation();
  const urlp          = new URLSearchParams(location.search);
  const dialogString  = urlp.get("dialogs");
  const navigate = useNavigate();
  const [dialogs, setDialogs]   = useState(defaults || null);

  // function evaluateDialogs(){
  //   if(!dialogString){
  //     if(dialogs !== null) setDialogs(null);    
  //   }
  //   else{
  //     const items   = parseDialogString(dialogString);
  //     setDialogs(items);
  //   }  
  // }
  const evaluateDialogs = useCallback(() => {
    if(!dialogString){
      if(dialogs !== null) setDialogs(null);    
    }
    else{
      const items   = parseDialogString(dialogString);
      setDialogs(items);
    } 
  }, [dialogString, dialogs, setDialogs]);

  useEffect(() => {
    evaluateDialogs();
  }, [dialogString, evaluateDialogs]);

  //Will remove the specified dialog key from the query string, effectively closing the dialog
  function closeDialog(key){
    if(dialogs && dialogs[key]){
      const items   = dialogString.split(",");
      const after   = filter(items, i => i.indexOf(key) !== 0);
      
      //TODO: this can be switched to useSearchParams, I think, which will modify the search, but won't navigate.
      let newSearch   = new URLSearchParams(location.search);
      if(after.length === 0){
        newSearch.delete("dialogs");
        const url = appendSearchParams(location.pathname, newSearch.toString());
        navigate(url, { replace: true });
      }
      else{
        const result  = after.join(",");
        newSearch.set("dialogs", result);
        const url = appendSearchParams(location.pathname, newSearch.toString());
        navigate(url, { replace: true });
      }
    }
  }

  //Will remove the specified dialog key from the query string, effectively closing the dialog
  function openDialog(key, id){
    if(!dialogs || !dialogs[key]){
      const items   = (dialogString || "").split(",");
      const clean   = filter(items, i => !i === "");
      const after   = [...clean, `${key}:${id}`];  //`
      const result  = after.join(",");
      let newSearch   = new URLSearchParams(location.search);
      newSearch.set("dialogs", result);
      const url = appendSearchParams(location.pathname, newSearch.toString());
      navigate(url, { replace: true });
  }
  }
  
  return [dialogs, openDialog, closeDialog];  
}


export function useDialogsWithoutUrl(defaults = null){  
  const [store, setStore]     = useState(null)
  const [dialogs, setDialogs] = useState(defaults || {});

  //Will remove the specified dialog key from the query string, effectively closing the dialog
  function closeDialog(key){
    if(dialogs && dialogs[key]){
      const items   = store.split(",");
      const after   = filter(items, i => i.indexOf(key) !== 0);
      // let newSearch   = store; //new URLSearchParams(location.search);
      if(after.length === 0){
        setStore(null);
        setDialogs({});
      }
      else{
        const result  = after.join(",");
        setStore(result);
        setDialogs(parseDialogStoreString(result));
      }
    }
  }

  //Will remove the specified dialog key from the query string, effectively closing the dialog
  function openDialog(key, id = true){
    if(!dialogs || !dialogs[key]){
      const items   = (store || "").split(",");
      const clean   = filter(items, i => i !== "");
      const after   = [...clean, `${key}:${id}`];  //`
      const result  = after.join(",");
      setStore(result);
      setDialogs(parseDialogStoreString(result));
    }
  }
  
  return [dialogs, openDialog, closeDialog, (key) => () => openDialog(key), (key) => () => closeDialog(key)];  
}



