import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { downloadReportThunk, fetchCheckListForReportThunk, fetchSetForReportThunk } from "store/thunks/report-thunks";

interface ReportsSliceStatus {
  isLoading: boolean;
  error: any | null;
  time: number | null;
}

interface ReportDownloadStatus {
  isWorking: boolean;
  error: any | null;
  report: any | null;
  config: any | null;
}

interface ReportsSlice {
  status: ReportsSliceStatus;
  setData: any | null;
  clData: any | null;
  downloads: ReportDownloadStatus;
}

const INITIAL_STATE: ReportsSlice = {
  status: {
    isLoading: false,
    error: null,
    time: null,
  },
  setData: null,
  clData: null,
  downloads: {
    isWorking: false,
    error: null,
    report: null,
    config: null,
  },
};

//=== Thunk Extra Reducers

const setFetched = (builder: ActionReducerMapBuilder<ReportsSlice>) => {
  builder.addCase(fetchSetForReportThunk.pending, (state, action) => {
    state.status.isLoading = true;
    state.status.error = null;
    state.status.time = null;
  })
  .addCase(fetchSetForReportThunk.fulfilled, (state, action) => {
    state.status.isLoading = false;
    state.status.time = new Date().getTime();
    state.setData = action.payload?.data;
  })
  .addCase(fetchSetForReportThunk.rejected, (state, action) => {
    state.setData = null;
    state.status.isLoading = false;
    state.status.error = action.error;
    state.status.time = null;
  });
};

const checklistFetched = (builder: ActionReducerMapBuilder<ReportsSlice>) => {
  builder.addCase(fetchCheckListForReportThunk.pending, (state, action) => {
    state.status.isLoading = true;
    state.status.error = null;
    state.status.time = null;
  })
  .addCase(fetchCheckListForReportThunk.fulfilled, (state, action) => {
    state.status.isLoading = false;
    state.status.time = new Date().getTime();
    state.clData = action.payload;
  })
  .addCase(fetchCheckListForReportThunk.rejected, (state, action) => {
    state.clData = null;
    state.status.isLoading = false;
    state.status.error = action.error;
    state.status.time = null;
  });
};

const downloaded = (builder: ActionReducerMapBuilder<ReportsSlice>) => {
  builder.addCase(downloadReportThunk.pending, (state, action) => {
    const { url } = action.meta.arg;
    state.downloads = { 
      isWorking: true,
      error: null,
      report: null,
      config: url,
    };
  })
  .addCase(downloadReportThunk.fulfilled, (state, action) => {
    state.downloads.isWorking = false;
    state.downloads.report = action.payload.data;
  })
  .addCase(downloadReportThunk.rejected, (state, action) => {
    state.downloads.isWorking = false;
    state.downloads.error = action.error;
  });
};

//=== Slice
export const reportsSlice = createSlice({
  name: "reports",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    setFetched(builder);
    checklistFetched(builder);
    downloaded(builder);
  },
});

export default reportsSlice.reducer;