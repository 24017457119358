import React from 'react';
import Grid from '@mui/material/Grid';
import EngagementHeader from './engagement-header';
import EngagementDash from './engagement-dashboard';
import EngagementReports from '../reports/engagement-reports-view';
import { appRoutes, appRoutesLegacy } from 'utils/route-helpers';
import { IfBlock, NotFound } from 'features/common';
import { useCurrentPermissions } from 'hooks';
import { Route, Routes, useMatch } from 'react-router-dom';
import ReviewsetsRouter from 'features/reviewsets/reviewsets-router';
import DocumentsView from 'features/documents/documents-view';
import ReviewSetsView from 'features/reviewsets/reviewsets-view';

function EngagementRouter() {

	const perms = useCurrentPermissions();
	const match = useMatch(appRoutesLegacy.engagements.view);
	
	return (
		<Grid id="engagement-view" container direction="column" alignItems="stretch">
			<IfBlock condition={perms.currentAssignment}>
				{!!match ? null : <EngagementHeader />}

				<Routes>
					<Route path={appRoutes.engagements.dashboard} element={ <EngagementDash /> } />

					<Route path="reviewsets" element={<ReviewSetsView />} />
					<Route path="reviewsets/*" element={<ReviewsetsRouter />} />

					<Route path={appRoutes.engagements.documents} element={ <DocumentsView /> } />
					<Route path={appRoutes.engagements.reports} element={ <EngagementReports /> } />
				</Routes>

			</IfBlock>
			<IfBlock condition={!perms.currentAssignment}>
				<NotFound />
			</IfBlock>
		</Grid>
	);
}

export default EngagementRouter;