import _ from 'lodash';

const SETTINGS_KEY  = "clt-settings";

export function writeItem(key: string, value: any){
  const storeVal : string  = (_.isObject(value) || _.isArray(value)) ? JSON.stringify(value) : value;
  localStorage.setItem(key, storeVal);
}

export function readItem<T>(key: string, defaultVal : (T | null) = null){
  const value  = getValueFromStorage(key);
  return (value || defaultVal) as T;
}

export function saveSetting<T>(key: string, value: T){
  const existing  = getValueFromStorage(SETTINGS_KEY) as any;
  let updated     = { [key]: value };

  if(existing){
    updated   = {
      ...existing,
      ...updated,
    };
  }

  writeItem(SETTINGS_KEY, updated);
}

export function readSetting<T>(key: string, defaultValue : (T | null) = null) : T {
  const existing : Record<string, any>  = getValueFromStorage(SETTINGS_KEY);
  return existing && (key in existing) ? existing[key] : defaultValue;
}

function getValueFromStorage<T>(key: string) : T {
  const strValue  = localStorage.getItem(key);
  let readVal     = strValue;
  if(strValue){
      try{
          let objVal  = JSON.parse(strValue);
          readVal     = objVal;
      }
      catch(ex){} //ignore it if it's not an object
  }
  return (readVal as any) as T;
}