import React from 'react';
import { Grid } from "@mui/material";
import Helmet from "react-helmet";
import ReviewSetEditor from "./reviewset-editor";

const ReviewsetAdd = () => {

  return (
    <Grid id="reviewset-add" container spacing={2} justifyContent="center">
      <Helmet>
        <title>New ReviewSet</title>
      </Helmet>

      <ReviewSetEditor set={null} />

    </Grid>
  );
}

export default ReviewsetAdd;
