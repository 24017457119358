import { useState } from 'react';

export function useHover(position){
    const [anchor, setAnchor]   = useState(null);

    const onEnter   = (e) => { setAnchor(e.currentTarget); };
    const onExit    = (e) => { setAnchor(null); };
    const positionProps   = positions[position] || positions["bottomRight"];

    // console.log("** run useHover");

    return [
      anchor,
      Boolean(anchor),
      onEnter,
      onExit,
      positionProps,
    ];
}

//TODO: when converting to Typescript, use the PopoverPlacement / PopoverPositions in hook-types.

const positions   = {
  bottomRight   : {
    anchorOrigin  : {
      vertical    : "bottom", 
      horizontal  : "right"
    },
    transformOrigin   : {
      vertical    : "top",
      horizontal  : "right",
    }
  },
  bottomLeft   : {
    anchorOrigin  : {
      vertical    : "bottom", 
      horizontal  : "left"
    },
    transformOrigin   : {
      vertical    : "top",
      horizontal  : "left",
    }
  },
  bottomCenter   : {
    anchorOrigin  : {
      vertical    : "bottom", 
      horizontal  : "center"
    },
    transformOrigin   : {
      // vertical    : "top",
      horizontal  : "center",
    }
  },
}