import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, InputAdornment, Tooltip, TextField, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { approveSetThunk } from 'store/thunks';
import { approvalCleared } from 'store/actions';
import { IfBlock, WaitButton, ErrorDisplay, StatusCard } from 'features/common';
import { APPROVE_TYPES } from 'utils/reviewset-helpers';
import { getNowString } from 'utils/general-helpers';
import { muiDialogReason } from 'utils/ui-helpers';
import { useCurrentPermissions } from 'hooks';

const ReviewsetApprovalDialog = ({set, isOpen, type, onClose}) => {
  const { classes, cx }   = buildStyles();
  const perms     = useCurrentPermissions();
  const approving = useSelector(state => state.set.approving);
  const dispatch  = useDispatch();
  const [content, setContent]   = useState({name: "", notes: "", nameError: false, adornment: null});

  const isUnapprove   = Boolean(type === APPROVE_TYPES.unApprove);
  const isEmpty       = Boolean((!isUnapprove && content.name === "") || (isUnapprove && content.notes === "") || content.nameError);
  const isPreApprover = getIsPreApprover(type, set, perms);

  const title     = (type === APPROVE_TYPES.approve) ? "Approve" : ((type === APPROVE_TYPES.preApprove) ? "Pre Approve" : "Unapprove");
  const message   = isUnapprove ? "Are you sure you would like to un-approve this ReviewSet?" : "By typing your name in the box below, you declare this ReviewSet complete and approved.";

  //---
  //Capture text changes and update the state
  const onChange = (e) => {
    const key   = e.currentTarget.id;
    const val   = e.currentTarget.value;
    const newContent  = {
      ...content,
      [key]   : val,
    };
    setContent(newContent);
  }

  //---
  //Some validation when the Name textfield loses focus
  const onBlur = () => {
    if(content.name !== perms.user.displayName){
      setContent({
        ...content,
        nameError   : true,
        adornment   : <InputAdornment position="end"><Tooltip title="Your name must match the display name associated with your login (case sensitive)."><WarningIcon color="error" fontSize="small"/></Tooltip></InputAdornment>
      });
    }
    else{
      setContent({...content, nameError: false, adornment: null});
    }
  }

  //---
  //Do the approve / pre-approve / unapprove
  const doSave = async() => {
    const model     = {
      setId           : set.id,
      signature       : isUnapprove ? null : content.name,
      notes           : content.notes,
      statusDate      : getNowString(), //"YYYY-MM-DD HH:mm:ss"), // -- parameter is ignored in getNowString
      status          : type,      
    };

    // const result  = await dispatch(approveReviewSet(model));
    const result = await dispatch(approveSetThunk({ model }));
    if(result.ok){
      setContent({name: "", notes: "", nameError: false, adornment: null})
      onClose();
    }
  }

  function doClose(event, reason){
    if(muiDialogReason.isBackdrop(reason) || (approving.isWorking && muiDialogReason.isEscape(reason))) return;
    // if(approving.error) dispatch(clearReviewSetApproval()); //don't need to wait for it.
    if(approving.error) dispatch(approvalCleared());
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={doClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle className={classes.title}>{title} ReviewSet</DialogTitle>
      <DialogContent>
        <Grid container className={classes.content} spacing={2}>
          <ErrorDisplay error={approving.error} />
          <StatusCard isVisible={isPreApprover} status="info" message="As the pre-approver, you are not permitted to approve this ReviewSet"/>
          <Grid item xs={12}>
            <Typography className={classes.text}>
              {message}
            </Typography>
          </Grid>
          <IfBlock condition={!isUnapprove}>
            <Grid item xs={12}>
              <TextField id="name" fullWidth label="Enter your full name" variant="outlined" disabled={approving.isWorking || isPreApprover} value={content.name} classes={{root: cx({[classes.error]: content.nameError})}} onChange={onChange} onBlur={onBlur} InputProps={{endAdornment: content.adornment}}/>
            </Grid>
          </IfBlock>
          <Grid item xs={12}>
            <TextField id="notes" multiline minRows={4} fullWidth label="Notes" variant="outlined" disabled={approving.isWorking || isPreApprover} value={content.notes} onChange={onChange}/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid item>
        </Grid>
        <Grid item md container justifyContent="flex-end">
          <WaitButton onClick={() => doClose(null, muiDialogReason.isEither)} variant="outlined" color="default" isWaiting={approving.isWorking}>Cancel</WaitButton>
          <WaitButton onClick={doSave} variant="outlined" color="primary" disabled={isEmpty || isPreApprover} isWaiting={approving.isWorking}>{title}</WaitButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ReviewsetApprovalDialog;

const buildStyles   = makeStyles()(theme => ({
  title   : {
    background  : theme.palette.grey[50],
    borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
  },
  text  : {
    fontSize  : 15,
  },
  error : {
    background    : theme.palette.error.light,  
  },
  actions: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`, //`
  }
}));

function getIsPreApprover(type, set, permissions){
  if(type === APPROVE_TYPES.approve && set.statusCode === 50){
    const status  = _.find(set.reviewSetStatuses, s => s.status === APPROVE_TYPES.preApprove);
    return Boolean(status?.userId === permissions.user.id);
  }

  return false;
}