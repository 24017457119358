import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import grey from '@mui/material/colors/grey';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { responseMap, statusBackgrounds } from 'utils/checklist-helpers';
import { formatDate } from 'utils/general-helpers';
import { useCurrentDocument } from 'hooks';

function TextInput({item, input, onSave, isReadOnly}){
  const { classes, cx }             = buildStyles();
  const response            = _.find(item.responses, rsp => rsp.checkListInputId === input.id);
  const doc                 = useCurrentDocument();
  const [value, setValue]   = useState(response ? response.textResponse : "");
  const isChanged           = value !== (response?.textResponse || "");
    
  const adornment   = useMemo(() => {
    const hasRsp              = Boolean(response);
    if(hasRsp){
      const rspDate   = hasRsp ? formatDate(response.responseDate) : "";
      const tip     = `by ${response?.userName} on ${rspDate}`; //`
      return (<InputAdornment title={tip} position="end"><InfoOutlinedIcon fontSize="small" style={{color: grey[600]}}/></InputAdornment>);
    }
    else 
      return null;
  
  }, [response]);

  const props               = useMemo(() => {
    return {
      id        : `input-${input.id}`,  //`
      multiline : input.textMultiLine ? true : null,
      minRows   : input.textMultiLine ? 3 : null,
      maxRows   : input.textMultiLine ? 5 : null,
      placeholder : input.textEmptyMessage,
      fullWidth : true,
      variant   : "outlined",
      size      : "small",
      className : classes.inputTextField,
      disabled  : item.isWorking || item.isFinished || !doc.isPrimary || isReadOnly,
    };
  }, [input.id, input.textMultiLine, input.textEmptyMessage, classes.inputTextField, item.isWorking, item.isFinished, doc.isPrimary, isReadOnly]);

  const handleChange = (e) => {
    setValue(e.currentTarget.value);
  }

  const handleBlur = async (e) => {
    if(isChanged){
      let model   = responseMap.base(input, response);
      
      if(!value || value === ""){
        model   = {...model, ...responseMap.delete };
      }
      else{
        model   = {...model, ...responseMap.text(value) };
      }

      await onSave(model); 
    }
  }

  return (
    <Grid container className={classes.inputCard}>
      <FormControl id={input.id} variant="outlined" className={classes.inputFormControl} fullWidth>
        <InputLabel className={cx(classes.inputLabel, item.className)}>{input.caption}</InputLabel>
        <OutlinedInput type="text" margin="dense" notched={true} className={classes.inputTextField} value={value} title={input.toolTip} onChange={handleChange} onBlur={handleBlur} {...props} fullWidth endAdornment={adornment}/>
      </FormControl>
    </Grid>
  );
}

export default TextInput;

const buildStyles   = makeStyles()(theme => ({
  inputCard   : {
    padding     : theme.spacing(1),
  },
  inputCaption  : {
    fontSize      : 14,
    fontWeight    : 500,
    color         : theme.palette.grey[800],
  },
  inputLabel  : {
    marginTop   : "-8px",
    fontSize    : 15,
    "&.MuiInputLabel-shrink" : {
      padding       : "2px 8px",
      marginTop     : "-3px",
      background    : "white",
      color         : grey[400],
      fontSize      : 18,
      fontWeight    : 400
  },
    ...statusBackgrounds(theme),
  },
  inputTextField  : {
    "&.Mui-disabled"  : {
      // background  : grey[50],
    },
    "& input, textarea"   : {
      fontSize    : 15,

      "&:disabled" : {
        color       : grey[700],
      }
    },
  }
}));