import React, { ReactNode, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Pagination2 from "../pagination";
import FilterButton from './filter-button';
import { IPagination } from 'app-types';
import { useNavigate } from 'react-router-dom';
import { compact, find, isObject } from 'lodash';
import { useQuery } from 'hooks';
import { Button, ButtonGroup, TextField, Typography } from '@mui/material';
import { ClearOutlined, CloudDownload, SearchOutlined, Refresh } from '@mui/icons-material';
import { notMobile, onlyMobile } from 'utils/sx-helpers';
import { IListProps } from './list-types';

export interface IListToolbarProps {
  title: string | ReactNode;
  pagination?: IPagination;
  onChangePage?: VoidFunction;
  filterOptions?: any[];
  onFiltered?: VoidFunction;
  isWorking?: boolean;
  isDisabled?: boolean;
  baseUrl?: string;
  newUrl?: string;
  newCallback?: VoidFunction;
  urlOverride?: any;
  keepSearchOnDisable?: boolean;
  noSearch?: boolean;
  searchPlaceholder?: string;
  onExport?: VoidFunction;
  idUrlModifier?: string;
  onManualRefresh?: (props: IListProps) => void; 
}

const ListToolbar = (props: IListToolbarProps) => {
  const { title, isWorking, isDisabled, pagination, onChangePage, noSearch, filterOptions, searchPlaceholder, urlOverride, baseUrl, idUrlModifier, onExport, onFiltered, newUrl, newCallback, onManualRefresh } = props;
  const { filter, sort } = useQuery();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>("");
  const [lastSearch, setLastSearch] = useState<string>("");
  const isFiltered    = Boolean(filter);
  const filterLabel   = isFiltered ? (find(filterOptions, opt => opt.key === filter)?.subTitle || null) : null;
  const titleContent = useMemo(() => {
    if(isObject(title)){
      return title;
    }
    else{
      return (<Typography variant="h5">{title} {filterLabel && `(${filterLabel})`}</Typography>);
    }
  }, [title, filterLabel]);

  
  
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }

  const onSearch = () => refresh();

  //Refresh due to something changing, like the search term changing
  const refresh = (nextSearchText?: string) => {
    const searchTerm = nextSearchText ?? searchText;

    if(lastSearch !== searchTerm){
      setLastSearch(searchTerm);

      if(urlOverride && urlOverride.onSearch){
          urlOverride.onSearch(searchTerm);
      }
      else{
          const url = buildSearchUrl(baseUrl, {filter, sort}, searchTerm, idUrlModifier);
          if((searchTerm.startsWith("id:"))){
              //we are going to a specific item, so add to history, don't replace
              navigate(url);
          }
          else{
            navigate(url, { replace: true });
          }
      }
    }
  }

  //Refresh based on the user clicking a refresh button
  const doManualRefresh = () => {
    if(!onManualRefresh) return;
    const props : IListProps = {
      search: lastSearch,
      sort,
      filter,
      hasFilter: !!filter,
      hasSort: !!sort,
    };
    onManualRefresh(props);
  }

  const onClearSearch = () => {
    setSearchText("");
    refresh("");
  }

  const onKeyUp = (event: React.KeyboardEvent) => {
    if(event.key === "Enter"){
        refresh();
    }
    else if(event.key === "Escape"){  //escape
        onClearSearch();
    }
  }

  //Only have a new item method if there is a url or a callback function
  const onNewItem = (!newCallback && !newUrl) ? null : () => {
    if(newCallback) newCallback();
    else if(newUrl) navigate(newUrl);
  }

  return (
    <Grid container className="grid-header">
      <Grid item xs={8} sm={4}>
        {/* <Typography variant="h5">{title} {filterLabel && `(${filterLabel})`}</Typography> */}
        {titleContent}
      </Grid>

      <Grid item xs={4} container justifyContent="flex-end" sx={onlyMobile()}>
        { !!onChangePage && <Pagination2 pagination={pagination!} onChangePage={onChangePage!} isDisabled={isWorking} /> }
      </Grid>

      <Grid item xs={12} sm={5} container justifyContent="center" alignItems="center" sx={{mt: 0.5}}>
        {!noSearch && 
          <Grid container justifyContent="center" flexWrap="nowrap">
            <TextField id="search" 
              variant="outlined" size="small"
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "4px 0 0 4px",
                },
                "& .MuiInputBase-input": {
                  fontSize: 14,
                  padding: "6px 12px",
                },
                "& legend": {
                  width: "unset",
                }
              }}
              placeholder={searchPlaceholder ?? "Search..."} disabled={isDisabled || isWorking} 
              value={searchText} onChange={onInputChange} onKeyUp={onKeyUp} onBlur={onSearch} 
            />
            <ButtonGroup variant="outlined" size="small" color="inherit">
              <Button title="Clear search" onClick={onClearSearch} disabled={isDisabled || isWorking || !searchText} sx={{borderRadius: 0, borderColor: "rgba(0, 0, 0, 0.23)", borderLeftColor: "transparent"}}>
                <ClearOutlined sx={{height: 18, width: 18 }}/>
              </Button> 
              <Button title="Perform search" onClick={onSearch} disabled={isDisabled || isWorking || !searchText} sx={{borderColor: "rgba(0, 0, 0, 0.23)", ...mobileGroupCap}}>
                  <SearchOutlined sx={{height: 18, width: 18 }} />
              </Button>
              <FilterButton filterOptions={filterOptions} isDisabled={isDisabled || isWorking} onFiltered={onFiltered} />
              {onExport && 
                <Button title="Export this list" onClick={() => onExport()} disabled={isDisabled || isWorking} sx={{ display: {xs: "none", sm: "flex"}, borderColor: "rgba(0, 0, 0, 0.23)"}}>
                  <CloudDownload sx={{height: 18, width: 18 }} />
                </Button>
              }
              {onNewItem && 
                <Button onClick={onNewItem} disabled={isDisabled || isWorking} sx={{borderColor: "rgba(0, 0, 0, 0.23)", backgroundColor: "primary.main", color: "primary.contrastText", alignItems: "center", "&:hover": { color: "primary.main"}}}>
                  <Typography fontSize="14px" sx={{height: 18, lineHeight: "18px"}}>New</Typography>
                </Button>
              }
              {onManualRefresh && 
                <Button title="Refresh" onClick={doManualRefresh} disabled={isDisabled || isWorking} sx={{ display: {xs: "none", sm: "flex"}, borderColor: "rgba(0, 0, 0, 0.23)"}}>
                  <Refresh sx={{height: 18, width: 18 }} />
                </Button>
              }
            </ButtonGroup>
          </Grid>
        }
      </Grid>     
      
      <Grid item sm={3} container justifyContent="flex-end" sx={notMobile()}>
        { !!pagination && <Pagination2 pagination={pagination!} onChangePage={onChangePage!} isDisabled={isDisabled || isWorking} /> }
      </Grid>
    </Grid>
  );
}

export default ListToolbar;


function buildSearchUrl(baseUrl: string | undefined, query: Record<string, any>, searchTerm: string, modifier: string | undefined){
  const base      = baseUrl ?? "";

  if((base.indexOf("reviewsets") >= 0 || base.indexOf("documents") >= 0 ) && searchTerm.substring(0, 3) === "id:" && searchTerm.length > 3){
      // They are navigating directly to a specific id, just modify the url to include the id they're accessing
      // Note: only do this if we're in the reviewset documents list or document list, otherwise, we don't have the right url for the document
      // const parts = search.split(":");
      return `${base}/${modifier ? `${modifier}/` : ""}${searchTerm.substring(3)}`;
  }

  const sortStr   = query.sort ? `sort=${query.sort}` : null;
  const filterStr = query.filter ? `filter=${query.filter}` : null;
  const searchStr = searchTerm ? `search=${searchTerm}` : null;
  
  const queryStr  = compact([filterStr, sortStr, searchStr]).join('&');
  return `${base}${queryStr ? '?' : ''}${queryStr}`;
}

const mobileGroupCap = {
  borderRightColor: { xs: "inherit !important", sm: "unset" },
  borderTopRightRadius: { xs: "4px !important", sm: "unset" },
  borderBottomRightRadius: { xs: "4px !important", sm: "unset" },
};