export const MUI_DIALOG_REASONS = {
  escape: "escapeKeyDown",
  backdrop: "backdropClick"
};

export const muiDialogReason = {
  isBackdrop: (reason: string) => (reason === MUI_DIALOG_REASONS.backdrop),
  isEscape: (reason: string) => (reason === MUI_DIALOG_REASONS.escape),
  isEither: (reason: string) => (reason === MUI_DIALOG_REASONS.backdrop || reason === MUI_DIALOG_REASONS.escape),
};



// export const muiDialogReason = (reason: string) => {

//   const isBackdrop = (reason === MUI_DIALOG_REASONS.backdrop);
//   const isEscape = (reason === MUI_DIALOG_REASONS.escape);

//   return {
//     isBackdrop,
//     isEscape,
//     isEither: (isBackdrop || isEscape),
//   };  
// }