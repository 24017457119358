import { PublicClientApplication } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { initMsalAuthConfig } from '../../config/auth-config';

const resultTemplate  = {
  ok                : true,
  isAuthenticated   : false,
  profile           : null,
  username          : null,
  token             : null,
  error             : null,
};

let _authApp        = null;
let _authConfig     = null;

export async function initializeMsalAuth(config){
  //initialize the config, and create the UserAgentApplication
  _authConfig     = initMsalAuthConfig(config);
  if(_authConfig.isOk === false){
    return _authConfig;   //unrecoverable, raise it up the chain.
  }

  _authApp = new PublicClientApplication(_authConfig.agentConfig);

  //If we're already logged in, then set the active account
  let token = null;
  const allAccounts = _authApp.getAllAccounts();
  if(allAccounts?.length > 0){
    try{
      token = await _authApp.acquireTokenSilent(_authConfig.apiRequest);      
      _authApp.setActiveAccount(allAccounts[0]);
    }
    catch(error) {
      //Swallow the error, as they're not currently logged in
      console.log("user is not currently logged in.");
    }
  }
  
  return {
    isOk: true,
    authApp: _authApp,
    token: token
  };
}

export async function msalLogout(){
  _authApp.logoutRedirect();
}

export const acquireMsalToken = async () => {
  const request = _authConfig.apiRequest;

  const tokenResult = _authApp
    .acquireTokenSilent(request)
    .catch(async error => {
      // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure due to consent or interaction required ONLY
      if (error instanceof InteractionRequiredAuthError) {
        console.log("acquiring token via popup for request", request);
        const result    = await _authApp.acquireTokenPopup(request)
          .catch(error => {
            console.error("Error acquiring token for request", request, error);
            // _onTokenFailed({request: request, error: error});
          });
          
          return result;
      } 
      else {
        console.error('Non-interactive error:', error.errorCode)
        // _onTokenFailed({request: request, error: error});
      }
  });

  return tokenResult;

};

export const buildMsalResult = (account, error) => {
  if(account){
    return {
      ...resultTemplate, 
      isAuthenticated     : true, 
      profile             : account, 
      username            : account.name,
      token               : account.idTokenClaims,
    };
  }
  else{
    return {
      ...resultTemplate, 
      error               : error
    };
  }
};

///--
// Checks the url search string for an access token. This is used by the report generator
// to automate report generation with puppetter.
export const getTokenFromUrl = () => {
  const search = decodeURIComponent(window.location.search);  
  const sp = new URLSearchParams(search);
  const token = sp.get("token");
  return token;
}