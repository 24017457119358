import DocumentView from 'features/documents/document/document-view';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { appRoutes } from 'utils/route-helpers';
import ReviewsetAdd from './reviewset/reviewset-add';
import ReviewSetEdit from './reviewset/reviewset-edit';
import ReviewSetView from './reviewset/reviewset-view';

const ReviewsetsRouter = () => {

  return (
    <div id="reviewsets-router">
      <Routes>
        <Route path={appRoutes.engagements.reviewset.add} element={ <ReviewsetAdd /> } />
        <Route path={appRoutes.engagements.reviewset.view} >
          <Route path="" element={ <ReviewSetView /> } />
          <Route path="documents/:documentId" element={<DocumentView />} />
        </Route>
        <Route path={appRoutes.engagements.reviewset.edit} element={ <ReviewSetEdit /> } />
      </Routes>
    </div>
  );
};

export default ReviewsetsRouter;
