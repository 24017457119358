import _ from 'lodash';

export function replaceAt(array, index, value){
    const ret = array.slice(0);
    ret[index] = value;
    return ret;
}

export function addOrReplace(array, predicate, value){
  const index   = predicate ? _.findIndex(array, i => predicate(i)) : -1;
  if(index >= 0){
    return replaceAt(array, index, value);
  }
  else{
    return [...array, value];
  }
}

export function addOrReplaceItem(array, item, value){
  const index   = item ? _.findIndex(array, item) : -1;
  if(index >= 0){
    return replaceAt(array, index, value);
  }
  else{
    return [...array, value];
  }
}

export function removeItem(array, itemOrPredicate){
  const isFunc  = _.isFunction(itemOrPredicate);
  const index   = isFunc ? _.findIndex(array, itemOrPredicate) : _.indexOf(array, itemOrPredicate);
  if(index >= 0){    
    return array.length === 1 ? [] : [...array.slice(0,index), ...array.slice(index + 1)];
  }
  else{
    return array;
  }
}