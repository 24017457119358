import { addMinutes, differenceInSeconds, format, formatDistance } from "date-fns";

// const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};
// const dateFormatter = new Intl.DateTimeFormat('en-us', dateOptions);
// const utcDateFormatter = new Intl.DateTimeFormat("en-us", {...dateOptions, timeZone: 'UTC'});

const INVALID_DATE = "Invalid date";
//D => d
//YYYY => yyyy
//h:mm A => p
const DEFAULT_DATE_FORMAT = "M/d/yyyy p";
export const DATE_ONLY_FORMAT = "M/d/yy";
export const DATE_ONLY_REPORT_FORMAT = "MM/d/yyyy";
export const DATE_TIME_REPORT_FORMAT = "MM/d/yyyy p";
export const LONG_DATE_FORMAT = "MMM d, yyyy";

//*** NOTE ABOUT TIMEZONE  ***/
// All dates from the DB are in GMT time. We can add a 
// Z to the end of the time string to tell the Date object
// that it is GMT, not local time, so it loads the object
// correctly. But getting the format of the date in GMT time
// doesn't work - if we try to format it, it converts it to local time
// to format. If we use toUTCString(), there are no format options
// to make the date look like we want.
// So, for now, we're letting the Date object believe that the DB time
// is in local time, so that we have the ability to format it, and display it
// as we wish - so the value displayed is GMT time, but the Date object here
// thinks it is local time.

//===
// Formats a date string (in the form provided by the db: yyyy-mm-ddThh:mm:ss), converts it to a
// date and returns a formatted version of the string for display in the UI.
export function formatDate(dateString: string, customFormat?: string){
  if(!dateString) return INVALID_DATE;

  const theFormat = customFormat ?? DEFAULT_DATE_FORMAT;
  // See note about timezones above. This would correctly create the date
  // in GMT time, but then we can't format it the way we want
  // const theDate = new Date(`${dateString}Z`);

  //If we don't add the 'Z' at the end, the date object is wrong - 
  // it assumes the date is local time. This displays correctly 
  // (as UTC in the UI) but the date object is internally incorrect 
  // because it thinks the time is local, when it's not.
  const theDate = new Date(dateString);

  if (theDate instanceof Date && isFinite(theDate.getTime())){
    return format(theDate, theFormat);
  }
  else{
    return INVALID_DATE;
  }
}

//===
// Gets the current date/time and returns it formatted
// in the default format for the application
export function getNowString(inGMT = false) {
  let now = new Date();

  // See the note above about timezones. To get it to
  // format the current time in GMT format, we need to adjust
  // the time so that we can use the format method.
  if(inGMT){
    const tzOffset = now.getTimezoneOffset();
    now = addMinutes(now, tzOffset);
  }

  return format(now, DEFAULT_DATE_FORMAT);
}

//date comes from the server in UTC, but without the Z to tell the Date constructor it is in UTC:
export function parseServerDate(dateString: string) : Date {
  const suffix = dateString.endsWith("Z") ? "" : "Z";
  const utcDate = new Date(`${dateString}${suffix}`);
  return utcDate;
}

//Gets the age of an item in seconds
export function getAge(serverDateString: string) : number {
  const serverDate = parseServerDate(serverDateString);
  const nowDate = new Date(new Date().toISOString());
  return differenceInSeconds(nowDate, serverDate);
}

//Gets the age of an item in seconds
export function getDateDistance(serverDateString: string) : string {
  const serverDate = parseServerDate(serverDateString);
  const nowDate = new Date(new Date().toISOString());
  return formatDistance(nowDate, serverDate);
}