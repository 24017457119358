import React from 'react';
import AddBoxIcon from '@mui/icons-material/AddBoxOutlined';
import BookIcon from '@mui/icons-material/BookOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AdjustIcon from '@mui/icons-material/Adjust';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const SET_LIST_SIZE_KEY  = "sets-list-page-size";

//ReviewSet Menu
export const menuConfig  = [
  { 
    id        : 0, 
    type      : "button",
    content   : 'New ReviewSet', 
    path      : "add",
    style     : { marginBottom: "20px" },
    icon      : <AddBoxIcon fontSize="small" />,
  },
  { 
    id        : 1, 
    content   : 'All ReviewSets', 
    path      : "",
    icon      : <CollectionsBookmarkIcon fontSize="small"/>,
  },
  { 
    id        : 2, 
    content   : 'My ReviewSets',
    path      : "?filter=owner:me",
    icon      : <BookIcon fontSize="small" />,
  },
  { 
    id        : 3, 
    content   : 'Pending ReviewSets',
    path      : "?filter=status:pending",
    icon      : <RadioButtonUncheckedIcon fontSize="small" />,
  },
  { 
    id        : 4, 
    content   : 'PreApproved ReviewSets',
    path      : "?filter=status:preapproved",
    condition : (props) => props.isMulti,
    icon      : <AdjustIcon fontSize="small" />,
  },
  { 
    id        : 5, 
    content   : 'Complete ReviewSets',
    path      : "?filter=status:approved",
    icon      : <CheckCircleOutlineIcon fontSize="small" />,
  },
  { 
    id          : 99, 
    type        : "separator", 
    content     : "Other Links" 
  },
  { 
    id          : 6, 
    content     : "Documents",
    path        : "../documents",
    icon      : <DescriptionIcon fontSize="small" />,
  },
  { 
    id          : 7, 
    content     : 'Reports',
    path        : "../reports",
    icon      : <AssignmentIcon fontSize="small" />,
  },
];