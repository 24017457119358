import React, { ReactNode } from "react";
import { Button, ButtonProps, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { usePopover } from "hooks/use-popover";

export type MenuOption = {
  icon?: ReactNode;
  iconPosition?: "start" | "end";
  title: string;
  onClick: (() => Promise<void>) | (() => void);
}

export interface IMenuButtonProps extends Omit<ButtonProps, "onClick"> {
  options: MenuOption[];
}

const MenuButton = ({children, options, ...buttonProps}: IMenuButtonProps) => {

  const {openPopover, closePopover, popoverProps} = usePopover();

  const itemClicked = (option: MenuOption) => async () => {
    await option.onClick();
    closePopover();
  }

  return (
    <>
      <Button onClick={openPopover} {...buttonProps}>
          {children}
      </Button>
      <Menu {...popoverProps}>
        <MenuList>
          {options.map(opt => 
            <MenuItem key={opt.title} onClick={itemClicked(opt)}>
              {opt.icon && (!opt.iconPosition || opt.iconPosition === "start") && <ListItemIcon>{opt.icon}</ListItemIcon>}
              <ListItemText>{opt.title}</ListItemText>
              {opt.icon && opt.iconPosition === "end" && <ListItemIcon>{opt.icon}</ListItemIcon>}
            </MenuItem>)
          }
        </MenuList>
      </Menu>
    </>
  );
};

export default MenuButton;