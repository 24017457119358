import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { keys, map, omit } from "lodash";
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { selectEngagement } from "store/selectors/setting-selectors";
import EngagementBreadcrumb from "./engagement-breadcrumb";
import { useIsMobile } from "hooks/general-hooks";
import { useHover } from "hooks";

// export interface IBreadcrumbsProps {
//   match: any;
// }

const EngagementBreadcrumbs = () => {
  const { classes } = buildStyles();
  const isMobile = useIsMobile();
  const [anchor, isOpen, onOpen, onClose]  = useHover("bottomCenter");
  const location = useLocation();
  const { pathname } = location;
  const params = useMemo(() => prepareParameters(pathname), [pathname]);
  const engagement  = useSelector(state => selectEngagement(state, params.engagementId))
  const set         = useSelector(state => state.set.item);
  const doc         = useSelector(state => state.doc.item);
  const isAdd       = useMemo(() => (params.reviewsetId === "add"), [params.reviewsetId]);
  const extras      = useMemo(() => {
    return {
      docName : doc?.documentKey,
      rsName  : getSetTitle(params, set),
      engName : engagement?.name,
      isAdd   : isAdd,
    };
  }, [doc, params, set, engagement, isAdd]);

  //Need to close the menu if they navigate
  useEffect(() => {
    if(isOpen) onClose();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      {isMobile && 
        <>
          <Button size="small" endIcon={<NavigateNextIcon />} startIcon={<HomeIcon />} onClick={onOpen}>Go To</Button>
          <Menu id="nav-menu-menu" anchorEl={anchor} open={isOpen} onClose={onClose}>
            <MenuItem>
              <Link color="inherit" to="/" className={classes.link} onClick={onClose}>
                Home
              </Link>
            </MenuItem>
            { map(keys(params), key => (
              <MenuItem key={key}>
                <EngagementBreadcrumb type={key} params={params} extras={extras} onClick={onClose}/>
              </MenuItem>
              )
            )}
          </Menu> 
        </>
      }
      {!isMobile && 
        <Breadcrumbs separator={<NavigateNextIcon className={classes.separator} fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" to="/" className={classes.link}>
            <HomeIcon/> Home
          </Link>
          { map(keys(params), key => <EngagementBreadcrumb key={key} type={key} params={params} extras={extras}/>) }
        </Breadcrumbs>
      }
    </>
  );
}

export default EngagementBreadcrumbs;


const buildStyles   = makeStyles()(theme => ({
  link: {
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      alignItems: "center",
      fontSize: "1rem",
      textWrap: "pre-wrap",
      "& svg": {
        marginRight: theme.spacing(0.5),
      },
    }
  },
  separator   : {
    marginLeft    : theme.spacing(-1),
    marginRight   : theme.spacing(-1),
  },
}));


function prepareParameters(pathname){
  const match = MATCH_PATHS.map(mp => matchPath(mp, pathname)).find(match => !!match);
  let params = match?.params;

  if(params.reviewsetId){
    //Inject a "reviewsets" param into the list
    params  = {
      engagementId  : params.engagementId,
      reviewsets    : true,
      ...omit(params, "engagementId"),
    };
  }
  else{
    const suffixes  = ["documents", "reports", "reviewsets"];
    const parts   = pathname.split("/");
    const idx     = suffixes.indexOf(parts[parts.length - 1]);
    if(idx >= 0){
      //Add a Documents or Reports param into the list  
      params  = {
        ...params,
        [suffixes[idx]]   : true,
      };
    }    
  }

  return params;
}

function getSetTitle(params, set){
  if(params.reviewsetId === "add") return "New ReviewSet";
  else if(params.reviewsetId){
    if(set && set.id === parseInt(params.reviewsetId)){
      return set.title;
    }
    else{
      return "ReviewSet " + params.reviewsetId;
    }
  }
}

const MATCH_PATHS = [
  "/engagements/:engagementId/reviewsets/:reviewsetId/documents/:documentId",
  "/engagements/:engagementId/reviewsets/:reviewsetId/edit",
  "/engagements/:engagementId/reviewsets/:reviewsetId",
  "/engagements/:engagementId/reviewsets",
  "/engagements/:engagementId/documents",
  "/engagements/:engagementId/reports",
  "/engagements/:engagementId",
];