import React from "react";
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import CltThead from "./clt-thead";
import CltTbody from "./clt-tbody";
import { Table } from "@mui/material";
import { onlyMobile } from "utils/sx-helpers";
import CltHidden from "../clt-hidden";

const buildStyles = makeStyles()((theme: any) => ({
  errorContainer: {
    width: "100%",
    display: "flex",

  },
  errorCard: {
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
    borderRadius: 5,
    background: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

export interface ITableColumn {
  id: number;
  sortKey?: string;
  label: string;
  style?: React.CSSProperties;
  noSort?: boolean;
  minSize?: string;
  maxSize?: string;
  defaultDir?: string;
}

export interface ICltTableProps extends React.ComponentProps<"table"> {
  cols: ITableColumn[];
  onSort?: (key: string) => void;
  isWorking?: boolean;
  isEmpty?: boolean;
  emptyText?: string;
  noWorkingRow?: boolean;
}

const noSort = (key: string) => {};

const CltTable = ({cols, onSort = noSort, isWorking = false, isEmpty = false, emptyText = "No items were found", noWorkingRow = false, children}: ICltTableProps) => {
  const { classes }   = buildStyles();
  // const isMobile = useIsMobile();

  if(!cols) {
    return (
      <Grid justifyContent="center" className={classes.errorContainer}>
        <div className={classes.errorCard}>
          <span className={classes.errorText}>
            Error: The table has no columns
          </span>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <CltHidden smDown>
        <Table sx={{"& td, th": { fontSize: "0.9rem", lineHeight: "18px", padding: 1}}}>
          <CltThead cols={cols} onSort={onSort} isWorking={isWorking} />
          <CltTbody colSpan={cols.length} isWorking={isWorking} isEmpty={isEmpty} emptyText={emptyText} noWorkingRow={noWorkingRow}>
            {children}
          </CltTbody>        
        </Table>
      </CltHidden>

      <CltHidden mdUp>
        <Grid container sx={onlyMobile()}>
          <CltThead cols={cols} onSort={onSort} isWorking={isWorking} />
          <CltTbody colSpan={cols.length} isWorking={isWorking} isEmpty={isEmpty} emptyText={emptyText} noWorkingRow={noWorkingRow}>
            {children}
          </CltTbody>
        </Grid>
      </CltHidden>
    </>    
  );
}

export default CltTable;