import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAppDispatch } from 'store';
import { appInitialized } from 'store/actions';
import { authChangedThunk } from 'store/thunks/app-thunks';
import { appRoutes } from 'utils/route-helpers';
import ErrorView from 'features/common/error-view';
import ReportsView from 'features/reports/reports-view';
import ProtectedRoute from 'features/common/protected-route';
import Initializing from './initializing';
import Login from './login';
import HomeView from 'features/home/home-view';
import AppRouter from './app-router';

const AppContainer = ({serverConfig}) => {
  const { isInitialized, isAuthenticated, isLoadingSettings, error, tokenError, initializeError } = useSelector(rootState => rootState.app);
  const dispatch = useAppDispatch();
  
  const { instance } = useMsal();
  const isMsalAuthed = useIsAuthenticated();

  const [isAuthInitialized, setAuthInitialized] = useState(false);
  const isError = useMemo(() => Boolean(tokenError || error || initializeError), [tokenError, error, initializeError]);
  const isBusy = useMemo(() => !isError && (isLoadingSettings || !isInitialized || !isAuthInitialized), [isError, isLoadingSettings, isInitialized, isAuthInitialized]);

  useEffect(() => {
    async function doEffect(){
      const { tokenFactoryAsync, ...config } = serverConfig;
      await dispatch(appInitialized(config));
    }
  
    if(!isInitialized){
      doEffect()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function doEffect(){
      if(!isAuthenticated && isMsalAuthed){ //If the store doesn't know yet
        const activeAccount = instance.getActiveAccount();
        await dispatch(authChangedThunk({ account: activeAccount }));
      }
      setAuthInitialized(true); 
    }  
    doEffect();
  }, [instance, isAuthenticated, isMsalAuthed, dispatch]);

  return (
      <div id="app-container">
        { isBusy && <Initializing/>} 
        { isError && <ErrorView error={initializeError || error} message="Oops, something went wrong!" />}
        { (!isBusy && !isError) && 
          <Routes>
            <Route path={appRoutes.reports.list} >
              <Route path="*" element={
                <ProtectedRoute>
                  <ReportsView />
                </ProtectedRoute>
              } />
            </Route>

            <Route path={appRoutes.login} element={<Login />} />
            
            <Route path="/" element={
              <ProtectedRoute>
                <HomeView />
              </ProtectedRoute>
            } />

            <Route path="/*" element={
              <ProtectedRoute>
                <AppRouter />
              </ProtectedRoute>
            } />

          </Routes>            
        }           
      </div>        
  );
}

export default AppContainer;