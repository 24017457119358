import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { IReviewSet } from "app-types";
import { PaginatedSlice } from "./slice-types";
import { exportSetsThunk, fetchPendingSetsThunk, fetchSetsThunk } from "store/thunks/reviewsets-thunks";
import { approveSetThunk, createSetThunk, saveSetThunk } from "store/thunks";
import { checkReportStatusThunk } from "store/thunks/report-thunks";
import { deleteReviewSetThunk } from "store/thunks/admin-thunks";
import { removeItem, replaceItem } from "./slice-helpers";

interface ReviewsetsSlice extends PaginatedSlice<IReviewSet>{
  isInitialized: boolean;
  isLoadingItems: boolean;
  error: any;
  isItemChanged: boolean;
  pendingItems: IReviewSet[];
}

const INITIAL_STATE: ReviewsetsSlice = {
  isInitialized       : false,
  error               : null,
  
  isLoadingItems      : false,
  pagination          : null,
  items               : [],

  loadTime            : null,   //the time the last load occurred
  lastFilter          : null,   //the filter from the last load
  isItemChanged       : false,  //means there's a change to the item, so need to refresh the list

  pendingItems         : [], //Used by the Move Document function to choose a reviewset to move the document to
};

//=== Async Thunk Reducers

const fetchSetsReducer = (builder: ActionReducerMapBuilder<ReviewsetsSlice>) => {
  builder.addCase(fetchSetsThunk.pending, (state, action) => {
    state.isLoadingItems = true;
    state.error = null;
  })
  .addCase(fetchSetsThunk.fulfilled, (state, action) => {
    
    state.isLoadingItems = false;
    state.error = null;
    state.isItemChanged = false;
    if(!action.payload) return; //No data to update with (probably a forced refresh)

    state.items = action.payload?.data ?? [];
    state.loadTime = new Date().getTime();
    state.pagination = action.payload?.pagination ?? null;
    state.lastFilter = action.payload?.filter;
  })
  .addCase(fetchSetsThunk.rejected, (state, action) => {
    state.isLoadingItems = false;
    state.error = action.error;
  })
};


const fetchPendingSetsReducer = (builder: ActionReducerMapBuilder<ReviewsetsSlice>) => {
  builder.addCase(fetchPendingSetsThunk.pending, (state, action) => {
    state.isLoadingItems = true;
    state.error = null;
  })
  .addCase(fetchPendingSetsThunk.fulfilled, (state, action) => {
    state.isLoadingItems = false;
    state.error = null;
    state.pendingItems = action.payload?.data ?? [];
  })
  .addCase(fetchPendingSetsThunk.rejected, (state, action) => {
    state.isLoadingItems = false;
    state.error = action.error;
  })
};


const exportSetsReducer = (builder: ActionReducerMapBuilder<ReviewsetsSlice>) => {
  builder.addCase(exportSetsThunk.pending, (state, action) => {
    state.isLoadingItems = true;
    state.error = null;
  })
  .addCase(exportSetsThunk.fulfilled, (state, action) => {
    state.isLoadingItems = false;
    state.error = null;
  })
  .addCase(exportSetsThunk.rejected, (state, action) => {
    state.isLoadingItems = false;
    state.error = action.error;
  })
};

//This handles actions that from the Set reducer
const externalReducers = (builder: ActionReducerMapBuilder<ReviewsetsSlice>) => {
  builder
    .addCase(approveSetThunk.fulfilled, (state, action) => {
      state.isItemChanged = true;
    })
    .addCase(saveSetThunk.fulfilled, (state, action) => {
      state.isItemChanged = true;
    })
    .addCase(createSetThunk.fulfilled, (state, action) => {
      state.isItemChanged = true;
    })
    //TODO: TEST when admin slice and thunks are online
    .addCase(deleteReviewSetThunk.fulfilled, (state, action) => {
      const { id, validateOnly } = action.payload;
      if(validateOnly) return;
      state.items = removeItem(state.items, item => item.id === id);
    })
    //TODO: TEST when the reports slice and thunks are online
    .addCase(checkReportStatusThunk.fulfilled, (state, action) => {
      const data = action.payload?.data as any;
      if(data?.status === "available" && state.items){
        const setId = action.payload?.setId;
        const original = state.items.find(rs => rs.id === setId);
        if(original){
          const replacement = {...original, reportLocation: data.reportPath };
          const updatedList = replaceItem(state.items, original, replacement);
          state.items = updatedList;
        }
      }
    });
};


//=== Slice
export const reviewsetsSlice = createSlice({
  name: "reviewsets",
  initialState: INITIAL_STATE,
  reducers: {
    setChanged: (state) => {
      state.isItemChanged = true;
    },
    clearChanged: (state) => {
      state.isItemChanged = false;
    },
  },
  extraReducers: builder => {
    fetchSetsReducer(builder);
    fetchPendingSetsReducer(builder);
    exportSetsReducer(builder);
    externalReducers(builder);
  },
});

export default reviewsetsSlice.reducer;