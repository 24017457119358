import { paginationHeader } from "api";
import { ApiResponse } from "apisauce";
import Apis, { downloadFile } from "store/api";
import { createAppAsyncThunk, parseResponse } from "store/slices/slice-helpers";
import { getSearchProps } from "utils/action-helpers";
import { getNowString } from 'utils/date-helpers';
import { EmptyParams, IdParams, ListParams, SaveModelParams } from "./thunk-types";

export const ADMIN_THUNKS = {
  fetchUsers: "admin/fetch-users",
  exportUsers: "admin/export-users",
  fetchUserAssignments: "admin/fetch-user-assignments",
  saveUser: "admin/save-user",

  fetchEngagements: "admin/fetch-engagements",
  fetchAllEngagements: "admin/fetch-all-engagements",
  fetchEngagementAssignments: "admin/fetch-engagement-assignments",

  saveAssignment: "admin/save-assignment",
  deleteAssignment: "admin/delete-assignment",

  fetchDocumentTypes: "admin/fetch-document-types",
  fetchDocumentType: "admin/load-document-type",
  fetchChecklists: "admin/load-checklists",
  addDocumentTypeVersion: "admin/add-document-type-version",
  copyDocumentType: "admin/copy-document-type",
  toggleDocumentTypeCirculation: "admin/toggle-document-type-circulation",

  unapproveMultiSets: "admin/unapprove-multi-sets",
  deleteReviewSet: "admin/delete-review-set",
  queueReviewSetReports: "admin/queue-review-set-reports",
};

//#region User Thunks

export const fetchUsersThunk = createAppAsyncThunk(ADMIN_THUNKS.fetchUsers, async (params: ListParams, thunkAPI) => {
  const { filter, pageNumber = 1, pageSize = 25 } = params;
  
  const requestParams = getSearchProps(filter);
  const headers = paginationHeader(pageNumber, pageSize);

  const result = await Apis.Users.get<any, any>("", requestParams, headers);

  return parseResponse(ADMIN_THUNKS.fetchUsers, result, { filter: requestParams });
});

export const exportUsersThunk = createAppAsyncThunk(ADMIN_THUNKS.exportUsers, async (params: ListParams, thunkAPI) => {
  const { filter } = params;
  
  const requestParams = getSearchProps(filter);
  const headers = paginationHeader(1, 10000);

  const result = await Apis.Users.get<any, any>("/export", { ...requestParams, responseType: "arrayBuffer" }, headers);
  if(result.ok){
    const isOk = await downloadFile(result, "text/csv");
    if(!isOk){
      console.log("Download Users failed.");
      throw new Error("Export Users failed.");
    }

    return result;
  }
  else {
    console.error("failed to export users", result);
    throw new Error("Failed to export users.");
  }
});

export const fetchUserAssignmentsThunk = createAppAsyncThunk(ADMIN_THUNKS.fetchUserAssignments, async (params: IdParams, thunkAPI) => {
  const { id: userId } = params;
  const filter = { filter: `user: ${userId}` };

  const result = await Apis.Assignments.get<any, any>("", filter);
  return parseResponse(ADMIN_THUNKS.fetchUserAssignments, result, { userId });
});

export const saveUserThunk = createAppAsyncThunk(ADMIN_THUNKS.saveUser, async (params: SaveModelParams, thunkAPI) => {
  const { id, model } = params;

  if(!id || !model) {
    console.error("Invalid parameters for saveUserThunk", params);
    throw new Error("Internal Error, unable to save user. Please contact the administrator.");
  }

  const api = Apis.Users; 

  let apiResult: ApiResponse<any, any>;

  if(id === -1){
    apiResult  = await api.post<any, any>("", model);
  }
  else{
    apiResult  = await api.put<any, any>(`/${id}`, model);
  }

  return parseResponse(ADMIN_THUNKS.saveUser, apiResult, { userId: id });
});

//#endregion

//#region Engagements

export const fetchAllEngagementsThunk = createAppAsyncThunk(ADMIN_THUNKS.fetchAllEngagements, async (params: EmptyParams, thunkAPI) => {
  const existing = thunkAPI.getState().admin.allEngagements;
  if(!!existing) {
    console.debug("fetchAllEngagementsThunk: already have allEngagements, skipping.");
    return;
  }
  
  const requestParams = { filter: "all" };
  const headers = paginationHeader(1, 9999);

  const result = await Apis.Engagements.get<any, any>("", requestParams, headers);

  return parseResponse(ADMIN_THUNKS.fetchAllEngagements, result);
});

export const fetchEngagementsThunk = createAppAsyncThunk(ADMIN_THUNKS.fetchEngagements, async (params: ListParams, thunkAPI) => {
  const { filter, pageNumber = 1, pageSize = 25 } = params;
  
  const requestParams = getSearchProps(filter);
  const headers = paginationHeader(pageNumber, pageSize);

  const result = await Apis.Engagements.get<any, any>("", requestParams, headers);

  return parseResponse(ADMIN_THUNKS.fetchEngagements, result, { filter: requestParams });
});

export const fetchEngagementAssignmentsThunk = createAppAsyncThunk(ADMIN_THUNKS.fetchEngagementAssignments, async (params: IdParams, thunkAPI) => {
  const { id: engagementId } = params;
  const filter = { filter: `eng:${engagementId}` };
  const result = await Apis.Assignments.get<any, any>("", filter);
  return parseResponse(ADMIN_THUNKS.fetchEngagementAssignments, result, { engagementId });
});

//#endregion

//#region Assignments

export const saveAssignmentThunk = createAppAsyncThunk(ADMIN_THUNKS.saveAssignment, async (params: SaveModelParams, thunkAPI) => {
  const { id, model } = params;

  if(!id || !model) {
    console.error("Invalid parameters for saveAssignmentThunk", params);
    throw new Error("Internal Error, unable to save engagement. Please contact the administrator.");
  }
  const currentUid = thunkAPI.getState().app.currentUser.id;
  const isMe = model.assignedUserId === currentUid;

  const api = Apis.Assignments; 

  let apiResult: ApiResponse<any, any>;

  if(id === -1) apiResult  = await api.post<any, any>("", model);
  else apiResult  = await api.put<any, any>(`/${id}`, model);

  return parseResponse(ADMIN_THUNKS.saveAssignment, apiResult, { id, isMe, model });
});

export const deleteAssignmentThunk = createAppAsyncThunk(ADMIN_THUNKS.deleteAssignment, async (params: any, thunkAPI) => {
  const { id, engagementId } = params;
  if(!id) throw new Error("Invalid parameters for deleteAssignmentThunk");

  const apiResult = await Apis.Assignments.delete<any, any>(`/${id}`);
  return parseResponse(ADMIN_THUNKS.deleteAssignment, apiResult, { id, engagementId });
});

//#endregion

//#region Document Types & Checklists

export const fetchDocumentTypesThunk = createAppAsyncThunk(ADMIN_THUNKS.fetchDocumentTypes, async (params: ListParams, thunkAPI) => {
  const { filter, pageNumber = 1, pageSize = 25 } = params;
  const headers = paginationHeader(pageNumber, pageSize);
  const apiResult = await Apis.DocumentTypes.get<any, any>("", filter, headers);
  return parseResponse(ADMIN_THUNKS.fetchDocumentTypes, apiResult);
});

export const fetchDocumentTypeThunk = createAppAsyncThunk(ADMIN_THUNKS.fetchDocumentType, async (params: IdParams, thunkAPI) => {
  const { id } = params;
  const apiResult = await Apis.DocumentTypes.get<any, any>(`/${id}`);
  return parseResponse(ADMIN_THUNKS.fetchDocumentType, apiResult, { id });
});

export const fetchChecklistsThunk = createAppAsyncThunk(ADMIN_THUNKS.fetchChecklists, async (params: ListParams, thunkAPI) => {
  const { filter, pageNumber = 1, pageSize = 25 } = params;
  const headers = paginationHeader(pageNumber, pageSize);
  const apiResult = await Apis.Checklists.get<any, any>("", filter, headers);
  return parseResponse(ADMIN_THUNKS.fetchChecklists, apiResult);
});

export const addDocumentTypeVersionThunk = createAppAsyncThunk(ADMIN_THUNKS.addDocumentTypeVersion, async (params: IdParams & { newName: string }, thunkAPI) => {
  const { id, newName } = params;
  const url   = newName ? `/${id}/addversion?newName=${newName}` : `/${id}/addversion`; //`
    const apiResult = await Apis.DocumentTypes.put<any, any>(url);
  return parseResponse(ADMIN_THUNKS.addDocumentTypeVersion, apiResult, { id }); //id will be id of the original version, not new version
});

export const copyDocumentTypeThunk = createAppAsyncThunk(ADMIN_THUNKS.copyDocumentType, async (params: IdParams & { protocolId: number }, thunkAPI) => {
  const { id, protocolId } = params;
  const apiResult = await Apis.DocumentTypes.put<any, any>(`/${id}/copyto?protocolId=${protocolId}`);
  return parseResponse(ADMIN_THUNKS.copyDocumentType, apiResult, { id });
});

export const toggleDocumentTypeCirculationThunk = createAppAsyncThunk(ADMIN_THUNKS.toggleDocumentTypeCirculation, async (params: IdParams & { inCirculation: boolean }, thunkAPI) => {
  const { id, inCirculation } = params;
  const apiResult = await Apis.DocumentTypes.put<any, any>(`/${id}/circulation?target=${inCirculation ? "add" : "remove"}`);
  return parseResponse(ADMIN_THUNKS.toggleDocumentTypeCirculation, apiResult, { id });
});

//#endregion

//#region Admin Tasks

export const unapproveMultiSetsThunk = createAppAsyncThunk(ADMIN_THUNKS.unapproveMultiSets, async (params: any, thunkAPI) => {
  const { engagementId, ids, signature, notes } = params;
  const model = {
    engagementId,
    ids,
    signature,
    statusDate: getNowString(),
    notes
  };

  const apiResult = await Apis.Reviewsets.put<any, any>("/unapprovemultiple", model);
  return parseResponse(ADMIN_THUNKS.unapproveMultiSets, apiResult, { engagementId, ids });
});

export const deleteReviewSetThunk = createAppAsyncThunk(ADMIN_THUNKS.deleteReviewSet, async (params: IdParams & { validateOnly?: boolean }, thunkAPI) => {
  const { id, validateOnly } = params;
  const url = validateOnly ? `/${id}/validate_delete` : `/${id}/delete`;
  const apiResult = await Apis.Reviewsets.post<any, any>(url);

  return parseResponse(ADMIN_THUNKS.deleteReviewSet, apiResult, { id, validateOnly });
});

export const queueReviewSetReportsThunk = createAppAsyncThunk(ADMIN_THUNKS.queueReviewSetReports, async (params: IdParams, thunkAPI) => {
  const { id: engagementId } = params;
  const apiResult = await Apis.Reviewsets.post<any, any>(`/push-engagement/${engagementId}`);
  return parseResponse(ADMIN_THUNKS.queueReviewSetReports, apiResult, { engagementId });
});

//#endregion