import React from 'react';
import _ from 'lodash';
import { Status } from 'features/common';
import { getCheckListStatus, getCheckListStatusRecord, addCheckListItemKeys } from 'utils/checklist-helpers';
import { Versions, StatusRow } from '.';

export default function CheckListPart({checklist, doc, set, protocol}){

  //Get the primary review record of the document for this checklist
  const rrec          = _.find(doc.reviewRecords, rr => rr.primaryReviewRecordId === null);
  const statusCode    = getCheckListStatus(checklist, rrec);
  const reportVer     = Versions.getReportVersion(set);

  return (
    <div className="row report-part checklist-part">
        <div className="col-xs-12">
            <CheckListReportHeader list={checklist} statusCode={statusCode} />
            {statusCode !== 100 && <div className="watermark">DRAFT</div>}
            <div className="row">
                <div className="col-xs-12">
                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th className="id-header">Item Id</th>
                                <th className="question-header">Question</th>
                                <th className="response-header">Response</th>
                                <th className="by-header">Completed By</th>
                                <th className="date-header">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                          <CheckListReportItems rrec={rrec} list={checklist} protocol={protocol} rptVersion={reportVer} />
                        </tbody>
                    </table>
                </div>
            </div>
            <CheckListReportFooter list={checklist} rrec={rrec} />
        </div>
    </div>
  );
}

function CheckListReportHeader({list, statusCode}){

  return (
      <div className="row report-header report-row-semidark checklist-header">
          <div className="col-xs-12">
              <div className="row">
                  <div className="col-xs-6">
                      <h3>CheckList: {list.name}</h3>                            
                  </div>
                  <div className="col-xs-6">
                      <Status code={statusCode} className="pull-right m-t-xs" />
                  </div>
              </div>
              {list.description && 
                <div className="row">
                  <div className="col-sm-12">
                      <p>{list.description}</p>
                  </div>
                </div>
              }
          </div>
      </div>
  );
}

function CheckListReportItems({rrec, list, protocol, rptVersion}){

  const renderers     = Versions.getRenderers(rptVersion);  
  const responses     = rrec.responses || rrec.checkListResponses;
  const checkItems    = addCheckListItemKeys(list.checkListItems);

  return (
    <React.Fragment>
      {_.map(checkItems, item => renderers.item(item, responses, protocol))}
    </React.Fragment>
  );
}


function CheckListReportFooter({list, rrec}){
  const statuses  = _.orderBy(getCheckListStatusRecord(rrec, list.id, ["PREAPPROVE", "APPROVE"]), s => new Date(s.statusDate), ["asc"]);
  const isPending = Boolean(!statuses || statuses.length === 0);

  return (
    <div className="row report-header report-row-semidark checklist-header">
      <div className="col-xs-12">
        { isPending && 
          <StatusRow statusRec={null} />
        }
        { !isPending && 
          _.map(statuses, s => <StatusRow key={s.id} statusRec={s} /> )
        }        
      </div>
    </div>
  );
}