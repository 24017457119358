import React, { useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { CltTable, TabPanel } from 'features/common';
import reports from "./admin-reports.json";
import { ITableColumn } from 'features/common/table/clt-table';
import { IAdminReport } from './admin-report-types';
import AdminReportRow from './admin-report-row';
import AdminReportsQueue from './admin-reports-queue';
import { useAppDispatch } from 'store';
import { downloadReportThunk } from 'store/thunks/report-thunks';

const AdminReportsView = () => {
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex]   = useState(0);
  
  const theReports = reports as IAdminReport[];

  async function onRun(report: IAdminReport, paramValue?: string){
    console.log("run report", report);

    let reportUrl = report.path;
    if(report.parameter && paramValue){
      reportUrl = reportUrl.replace(`{${report.parameter.pathProp}}`, paramValue);
    }

    //TODO: Test with redux migration
    // await dispatch(downloadReport({url: reportUrl}));
    await dispatch(downloadReportThunk({ url: reportUrl }));
  }

  return (
    <Grid id="admin-reports-card" container direction="column" rowGap={2}>  
      <Tabs  value={tabIndex} onChange={(e, i) => { setTabIndex(i); }}>
        <Tab label="Administrative Reports" />
        <Tab label="Report Persistence Queue" />
      </Tabs>

      {/* <Typography variant="h5">Administrative Reports</Typography> */}

      <TabPanel id="admin-reports-panel" value={tabIndex} index={0}>
        <CltTable cols={REPORT_COLS}>
          {theReports?.map(item => (<AdminReportRow key={item.id} item={item} onRun={onRun} /> ))}
        </CltTable>
      </TabPanel>
      <TabPanel id="persistence-queue-panel" value={tabIndex} index={1}>
        <AdminReportsQueue />
      </TabPanel>
    </Grid>
  );
}

export default AdminReportsView;

const REPORT_COLS : ITableColumn[] = [
  {
    id: 0,
    label: "Name",
  },
  {
    id: 1,
    label: "Description",
  },
  {
    id: 2,
    label: "Parameter(s)",
    noSort: true,
  },
  {
    id: 3,
    label: "Actions",
    noSort: true,
  }
];
