import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { styled } from "@mui/system";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import WaitContainer from 'features/common/wait-container';
import { addSearchParam, getSearchParam, appPathnames, appRoutes } from 'utils/route-helpers';
import background from '../../x-styles/login-background.jpg';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { searchStringKeys } from 'utils/route-helpers-ts';

//Styled component for the background of the login page.
const LoginBackground = styled(Grid)({
  position: "absolute",
  height: "100vh",  
  width: "100vw",
  backgroundImage: `url('${background}')`, //url("./login-background.jpg"),
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "50% 0",
  zIndex: -1,
  opacity: 0.6,
});

function LoginView(){
  const isAuthenticated = useIsAuthenticated();
  const { pathname, search } = useLocation();
  const redirPath = getSearchParam(search, searchStringKeys.redirect);

  const {instance, accounts, inProgress} = useMsal();
  const isAuthenticating = useMemo(() => inProgress === "login", [inProgress])

  //set the active account when the user has logged in
  useEffect(() => {
    if(accounts?.length > 0 && !instance.getActiveAccount()){
      instance.setActiveAccount(accounts[0]);
    }
  }, [instance, accounts]);

  if(isAuthenticated){
    //We're authenticated, send the user back to the main app
    if(pathname === appPathnames.login){
      console.log("REDIRECT: login complete.  redirecting back to the app.");
      const path  = redirPath || appRoutes.home;
      return <Navigate to={path} replace />;
    }
    else{
      console.log("REDIRECT: logged in. redirecting to the desired path.");
      return <Navigate to={`${pathname}${search}`} />;
    }
  }
  else if(!isAuthenticated && pathname !== appPathnames.login){
    //Need to redirect so we're at the login route (for purposes of auth)...
    const query   = pathname !== appPathnames.home ? `?${addSearchParam("", searchStringKeys.redirect, pathname + search)}` : ""; //`
    const url     = `${appRoutes.login.login}${query}`;
    console.log("REDIRECT: not logged in. redirecting to ", url, " from ", window.location.href);
    return <Navigate to={url} replace/>;
  }
  
  function onLogin(){
    instance.loginPopup();
  }

  return (
    <Grid id="login-view" container alignItems="flex-start" justifyContent="center">
      <LoginBackground />
      <Grid item md={5} container justifyContent="center">
        <Card sx={{zIndex: 2, mt: "25%", width: {xs: "80%", sm: "100%"}, maxWidth: "450px", p: 2}}>
          <CardContent sx={{textAlign: "center"}}>
            <WaitContainer isWaiting={isAuthenticating} message="Logging In (see pop-up window)..." variant="circular">
              <Grid container direction="column" alignItems="center">
                <Typography variant="h5" sx={{mb: 3}}>Welcome to Checklist Tracker</Typography>
                <Typography variant="body1" sx={{mb: 2}}>Checklist Tracker is only available to authorized users.  Please click the button below to sign in.</Typography>
                <Button id="signin-button" onClick={onLogin} color="primary" variant="contained" sx={{width: "70%", mt: 2}}>Sign In</Button>
              </Grid>
            </WaitContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default LoginView;