import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/SettingsApplications';
import ExitIcon from '@mui/icons-material/ExitToApp';
import Authorize from 'utils/auth/auth-helper';
import { useNavigate } from 'react-router-dom';

const anchor  = { vertical: 'bottom',  horizontal: 'left', };
const txform  = { vertical: 'top',  horizontal: 'left', };

function ProfileButton(){
  const { classes }   = buildStyles();
  const [anchorEl, setAnchorEl]   = useState(null);
  const isAuthenticated   = useIsAuthenticated();
  const { instance } = useMsal();
  const profile           = useSelector(state => state.app.profile);
  const user              = useSelector(state => state.app.currentUser);
  const navigate = useNavigate();

  const onOpen  = event => setAnchorEl(event.currentTarget);
  const onClose = event => setAnchorEl(null);
  const isOpen  = Boolean(anchorEl);

  //If we're not authenticated, don't display anything
  if(!isAuthenticated) return null;

  const isAdmin   = Authorize.isPermitted(user, Authorize.claims.ADMIN_VIEW);

  const onLogout = () => {
    instance.logoutRedirect();
  }

  return (
    <div>
      <IconButton
        color="inherit"
        onClick={onOpen}
        title={"You are logged in as " + profile?.name}
        size="large">
        <AccountCircleIcon />
      </IconButton>
      
      <Popover open={isOpen} anchorEl={anchorEl} anchorOrigin={anchor} transformOrigin={txform} onClose={onClose} >
        <Grid container justifyContent="center" className={classes.popoverContent}>
          
          <Grid item sm={12} container direction="column" alignItems="center" className={classes.profilePanel}>
            <AccountCircleIcon fontSize="large" color="primary"/>
            <Typography>{user?.displayName}</Typography>
            <Typography>{user?.email}</Typography>
            <Typography>{user?.role}</Typography>
          </Grid>

          <Grid item sm={8}>
            <Divider />
          </Grid>
          
          <Grid item sm={12} container className={classes.menuContent}>
            <MenuList className={classes.menuList}>
              {isAdmin && 
                <MenuItem onClick={() => { navigate("/admin"); setAnchorEl(null); }}>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" color="secondary"/>
                  </ListItemIcon>
                  <Typography color="secondary">Administration</Typography>
                </MenuItem>
              }
              <MenuItem onClick={onLogout}>
                <ListItemIcon>
                  <ExitIcon fontSize="small" color="secondary"/>
                </ListItemIcon>
                <Typography color="secondary">Log Out</Typography>
              </MenuItem>              
            </MenuList>
          </Grid>
          
        </Grid>              
      </Popover>
    </div>
  );
}

export default ProfileButton;

const buildStyles = makeStyles()(theme => ({
  popoverContent  : {
    maxWidth    : 300,
  },
  profilePanel: {
    padding         : `${theme.spacing(2)} 0`,
    "& svg"     : {
      marginBottom  : theme.spacing(1),
    },
    "& p"       : {
      fontSize        : "16px",
      color           : theme.palette.grey[600],
      fontWeight      : 300,
    }
  },
  menuContent   : {
    padding     : `0 5px`,
  },
  menuList: {
    width       : "100%",
  }
}));
