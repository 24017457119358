import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import { apiThunkMiddleware } from "./api-middleware/api-middleware";
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    const middleware = getDefaultMiddleware();
    middleware.push(apiThunkMiddleware);
    return middleware;
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = <T = any>(selector: (state: RootState) => T) => useSelector(selector);