import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import MenuIcon from '@mui/icons-material/Menu';
import { useHover } from 'hooks';
import { PropertiesGrid } from 'features/common';
import { formatOptionChoices, optionTypeNames } from 'utils/general-helpers';

export const TextInputIcon = ({input, onClick, disabled, isEditable}) => {
  const { classes, cx }   = buildStyles();
  const [anchor, isHovering, onEnter, onExit, position]   = useHover("bottomRight");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fillClass = useMemo(() => !!input ? classes.full : classes.empty, [input]);
  const clickCaption  = useMemo(() => !input ? "(click to add)" : "(click to edit)", [input]);

  const properties  = !!input ? [
    { label: "Caption:", value: input.caption },
    { label: "Placeholder:", value: input.textEmptyMessage },
    { label: "Tooltip:", value: input.toolTip },
    { label: "Multiline:", value: input.textMultiLine?.toString() },
  ] : [
    { label: "Text:", value: "Click to add a Text input" },    
  ];

  function doClick(){
    if(!disabled){
      if(isEditable || input?.id > 0) onClick();
    }
  }

  return <>
    <Grid item className={cx(classes.iconCircle, fillClass)} container alignItems="center" justifyContent="center" onClick={doClick}>
      <TextFieldsIcon fontSize="small" className={classes.iconRoot} onMouseEnter={onEnter} onMouseLeave={onExit} onClick={doClick}/>
    </Grid>
    <Popover anchorEl={anchor} open={isHovering} onClose={onExit} {...position} className={classes.tip} classes={{paper: classes.tipPaper}} disableRestoreFocus>
      <Grid container direction="column">
        <Grid item container className={classes.popHeader} justifyContent="space-between">
            <Grid item xs={8} container>
              <TextFieldsIcon className={classes.tipIcon}/>
              <Typography className={classes.popHeaderText}>Text Input</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.popHeaderText}>{clickCaption}</Typography>
            </Grid>
        </Grid>
        <Grid item container className={classes.popContent}>
          <PropertiesGrid props={properties} />
        </Grid>
      </Grid>
    </Popover>
  </>;
}

export const OptionInputIcon = ({input, onClick, disabled, isEditable}) => {
  const { classes, cx }   = buildStyles();
  const [anchor, isHovering, onEnter, onExit, position]   = useHover("bottomLeft");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fillClass = useMemo(() => !!input ? classes.full : classes.empty, [input]);
  const clickCaption  = useMemo(() => !input ? "(click to add)" : "(click to edit)", [input]);
  

  const properties  = !!input ? [
    { label: "Type:", value : optionTypeNames(input.optionType)},
    { label: "Caption:", value: input.caption },
    { label: "Choices:", value: formatOptionChoices(input.propertyBag?.choices) },
  ] : [
    { label: "Options:", value: "Click to add an Options input" },    
  ];

  function doClick(){
    if(!disabled){
      if(isEditable || input?.id > 0) onClick();
    }
  }

  return <>
    <Grid item className={cx(classes.iconCircle, fillClass)} container alignItems="center" justifyContent="center" onClick={doClick}>
      <MenuIcon fontSize="small" className={classes.iconRoot} onMouseEnter={onEnter} onMouseLeave={onExit} onClick={doClick}/>
    </Grid>
    <Popover anchorEl={anchor} open={isHovering} onClose={onExit} {...position} className={classes.tip} classes={{paper: classes.tipPaper}} disableRestoreFocus>
      <Grid container direction="column">
        <Grid item container className={classes.popHeader} justifyContent="space-between">
          <Grid item xs={8} container>
            <MenuIcon className={classes.tipIcon}/>
            <Typography className={classes.popHeaderText}>Option Input</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.popHeaderText}>{clickCaption}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.popContent}>
          <PropertiesGrid props={properties} />
        </Grid>
      </Grid>
    </Popover>
  </>;
}

const buildStyles   = makeStyles()(theme => ({
  empty     : {
    border        : `1px solid ${theme.palette.grey[400]}`,
    color         : theme.palette.grey[400],
  },
  full      : {
    border        : `1px solid ${theme.palette.primary.light}`,
    background    : `${theme.palette.primary.light}cc`,
    color         : theme.palette.primary.contrastText,
  },
  iconCircle  : {
    marginLeft    : theme.spacing(0.5),
    borderRadius  : "50%",
    width         : 24,
    height        : 24,
    "& svg"   : {
      fontSize    : 18,
    }    
  },
  iconRoot  : {
    marginLeft  : theme.spacing(0.5),
    marginRight : theme.spacing(0.5),    
  },
  tip   : {
    pointerEvents   : "none",
  },
  tipPaper     : {
    minWidth        : 300,
  },
  tipIcon   : {
    marginRight   : theme.spacing(1),
    marginTop     : theme.spacing(-0.5),
  },
  popHeader     : {
    flexWrap    : "nowrap",
    padding     : theme.spacing(1),
    background      : theme.palette.primary.main,
    color           : theme.palette.common.white,
    borderBottom    : `1px solid ${theme.palette.grey[200]}`,
    "&.training"  : {
      background    : theme.statuses.training.color[200],
      color         : theme.palette.grey[700],
    },
    "&.archived"  : {
      background    : theme.statuses.archived.color[200],
    },
  },
  popHeaderText   : {
    fontSize    : 15,
    fontWeight  : 600,
  },
  popContent    : {
    padding         : theme.spacing(1),
  },
}));