import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSettingsThunk } from "store/thunks";
import { AppAccountInfo, AppAuthResult } from "types";

export interface AppSlice {
  isInitializing: boolean;
  isInitialized: boolean;
  initializeError: string | null;

  serverConfig: any;    //TODO: serverConfig type

  isAuthenticating: boolean;
  isAuthenticated: boolean;
  authenticationError: string | null;
  authStatus: string | null;
  tokenError: string | null;

  profile: AppAccountInfo | null;
  username: string | null;
  token: any | null;
  
  isLoadingSettings: boolean;
  // settings: any | null;
  currentUser: any | null;
  environment: any | null;

  isWorking: boolean;
  error: any | null;

  lastHeartbeat: number | null;
}

const INITIAL_STATE: AppSlice   = {
  isInitializing      : false,
  isInitialized       : false,
  initializeError     : null,
  serverConfig        : null,

  isAuthenticating    : false,
  isAuthenticated     : false,
  authenticationError : null,
  authStatus          : null,
  tokenError          : null,

  profile             : null,
  username            : null,
  token               : null,
  
  isLoadingSettings   : false,
  // settings            : null,
  currentUser         : null,
  environment         : null,

  isWorking           : false,
  error               : null,

  lastHeartbeat       : null,  
};

const settingsReducer = (builder: ActionReducerMapBuilder<AppSlice>) => {
  builder
    .addCase(fetchSettingsThunk.pending, (state, action) => {
      state.isLoadingSettings = true;
      // state.environment = null;
      // state.currentUser = null;
    })
    .addCase(fetchSettingsThunk.fulfilled, (state, action) => {
      const { data } = action.payload;
      const { settings, environment } = data || {};

      state.isLoadingSettings = false;
      state.error = null;
      state.environment = environment;
      state.currentUser = settings?.user;
    });
};

//=== Slice
export const appSlice = createSlice({
  name: "app",
  initialState: INITIAL_STATE,
  reducers: {
    appInitialized: (state, action) => {
      state.isInitializing = false;
      state.isInitialized = true;
      state.initializeError = null;
      state.serverConfig = action.payload;
    },
    authChanging: (state, action: PayloadAction<string>) => {
      state.isAuthenticating = true;
      state.authenticationError = null;
      state.authStatus = action.payload;
    },
    loggedIn: (state, action: PayloadAction<AppAuthResult>) => {
      const authResponse = action.payload;
      state.isAuthenticating = false;
      state.authenticationError = null;
      state.authStatus = null;
      state.isAuthenticated = authResponse.isAuthenticated;
      state.error = authResponse.error;
      state.profile = authResponse.profile;
      state.username = authResponse.username;
      state.token = authResponse.token;
    },
    loggedOut: (state) => {
      state.isAuthenticating = false;
      state.isAuthenticated = false;
      state.authenticationError = null;
      state.profile = null;
      state.username = null;
      state.token = null;
      state.currentUser = null;
    }
  },
  extraReducers: (builder) => {
    settingsReducer(builder);
  },
});

export default appSlice.reducer;