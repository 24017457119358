import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MessageIcon from '@mui/icons-material/Message';
import { StatusCard, IfBlock } from 'features/common';
import { ApprovalDialog } from './';
import { formatDate } from 'utils/general-helpers';
import { ITEM_TYPES, INP_TYPES, APPROVE_TYPES } from 'utils/checklist-helpers';
import { selectCheckListStatus, selectCheckListResponses } from 'store/selectors/checklist-selectors';
import { selectProtocol } from 'store/selectors/setting-selectors';
import { useAppParams, useCurrentPermissions } from 'hooks';
import authHelper from 'utils/auth/auth-helper';

function ApprovalCard({checkList, doc}){
  const { classes, cx }     = buildStyles();
  const [dialog, setDialog]   = useState({isOpen: false, type: ""});
  const status      = useSelector(state => selectCheckListStatus(state, checkList.id));
  const perms       = useCurrentPermissions();
  const { engagementId } = useAppParams();
  const protocol    = useSelector(state => selectProtocol(state, engagementId));
  
  const role        = perms.currentAssignment?.role;

  const onShowDialog = async( approveType ) => {
    const val   = { isOpen: true, type: approveType };
    setDialog(val);
  }

  if(!status){
    return null;
  }

  const props   = {
    classes     : classes,
    cx          : cx,
    doc         : doc,
    checkList   : checkList,
    protocol    : protocol,
    status      : status,
    role        : role,
    perms       : perms,
    onChange    : onShowDialog,
  }
  
  return (
    <Grid container sx={{pb: 2}}>
      {status.isPending && <Pending {...props} />}
      {(status.isPreApproved && !status.isApproved) && <PreApproved {...props} />}
      {status.isApproved && <Approved {...props} />}      
      
      <ApprovalDialog isOpen={dialog.isOpen} type={dialog.type} checkList={checkList} onClose={() => setDialog({isOpen: false, type: ""})} />
    </Grid>    
  )

}

export default ApprovalCard;

const buildStyles   = makeStyles()(theme => ({
  approvalRoot  : {
    width     : "100%",
    padding   : theme.spacing(1),    
  },
  approveBtn : {
    marginRight   : theme.spacing(1),
  },
  unApproveBtn  : {
    background  : theme.palette.grey[50],
    "&:hover"   : {
      background  : theme.palette.grey[200],
    }
  },
  info  : {
    fontSize  : "15px",
    display   : "inline-block",
  },
  notesIcon   : {
    marginLeft    : theme.spacing(1),
    color         : theme.palette.grey[600],
    marginBottom  : "-5px",
  },
  approved  : {
    background  : `${theme.palette.success.light}33`,
  },
}));

function Approved({classes, cx, doc, checkList, role, status, perms, onChange}){
  const preApproval     = status.preApproval;
  const approval        = status.approval;
  const preApproveDate  = preApproval ? formatDate(preApproval.statusDate) : null;
  const approveDate     = formatDate(approval.statusDate);

  //doc.isPrimary - can only change if this is the primary doc
  //status.isSetLocked - can only change if the ReviewSet this belongs to is not pre-approved or approved
  //hasClaim - make sure they have the proper claim to unapprove (must be an admin)
  const hasClaim        = authHelper.isPermitted(perms.user, authHelper.claims.CHECKLIST_UNAPPROVE)
  const canUnapprove    = Boolean(role === "approver") && hasClaim && doc.isPrimary && !status.isSetLocked;

  return (
    <Card id={"approval-" + checkList.id} elevation={0} variant="outlined" className={cx(classes.approvalRoot, classes.approved)}>
      <Grid container alignItems="center" spacing={2} justifyContent="space-between">
        <Grid item>
          <Grid item>
            <IfBlock condition={preApproval}>
            <Grid item>
              <Typography className={classes.info}>
                This checklist was pre-approved by <strong>{preApproval?.userName}</strong> on <strong>{preApproveDate}</strong>
              </Typography>
              <Tooltip title={preApproval?.notes ?? ""}>
                <MessageIcon fontSize="small" className={classes.notesIcon}/>
              </Tooltip>
            </Grid>
            </IfBlock>
            <Grid item>
              <Typography className={classes.info}>
                This checklist was electronically signed by <strong>{approval.userName}</strong> on <strong>{approveDate}</strong>
              </Typography>
              <Tooltip title={approval.notes}>
                <MessageIcon fontSize="small" className={classes.notesIcon}/>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.unApproveGrid}>
          {canUnapprove && <Button
            variant="outlined"
            size="small"
            className={classes.unApproveBtn}
            onClick={() => onChange(APPROVE_TYPES.unApprove)}
            title="Click to unapprove this checklist">Unapprove Checklist</Button>}
        </Grid>
      </Grid>
    </Card>
  );
}

function PreApproved({classes, cx, doc, checkList, role, perms, status, onChange}){
  const existing  = status.preApproval;
  const exDate    = formatDate(existing.statusDate);

  if(role !== "approver") return null;  //doesn't have approval permissions
  const isPreApprover   = Boolean(existing.userId === perms.user.id);
  const isPrimary       = doc.isPrimary;

  return (
    <Card id={"approval-" + checkList.id} elevation={0} variant="outlined" className={cx(classes.approvalRoot, classes.preApproved)}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item container>
          <StatusCard status="success" isVisible={true}>
            <Typography className={classes.info}>
              This checklist was pre-approved by <strong>{existing.userName}</strong> on <strong>{exDate}</strong>
            </Typography>
            <Tooltip title={existing.notes}>
              <MessageIcon fontSize="small" className={classes.notesIcon}/>
            </Tooltip>
          </StatusCard>
        </Grid>
        <Grid item>
          <IfBlock condition={isPrimary}>
            <Button variant="contained" color="primary" size="small" className={classes.approveBtn} onClick={() => onChange(APPROVE_TYPES.approve)} disabled={isPreApprover}>Approve Checklist</Button>
            <Button
              variant="contained"
              size="small"
              className={classes.unApproveBtn}
              onClick={() => onChange(APPROVE_TYPES.unApprove)}>Unapprove Checklist</Button>
          </IfBlock>
        </Grid>
        <IfBlock condition={isPreApprover}>
          <Grid item>
            <Typography className={classes.info}>As the pre-approver, you are not permitted to approve this CheckList</Typography>
          </Grid>
        </IfBlock>
      </Grid>
    </Card>
  );
}

function Pending({classes, cx, doc, checkList, protocol, role, onChange}){
  const responses = useSelector(state => selectCheckListResponses(state, checkList.id));

  if(role !== "approver") return null;  //doesn't have approval permissions
  
  //determine if all assertions have been answered
  const assertions  = _.filter(checkList.checkListItems, i => i.discriminator === ITEM_TYPES.assertion)
  const bResponses  = _.filter(responses, r => r.discriminator === INP_TYPES.bool);
  const isFinished  = Boolean(assertions.length === bResponses.length);
  const isMulti     = protocol.isMultiCheckList;
  const approveType = isMulti ? APPROVE_TYPES.preApprove : APPROVE_TYPES.approve;
  const isPrimary   = doc.isPrimary;

  return (
    <Card id={"approval-" + checkList.id} elevation={0} variant="outlined" className={cx(classes.approvalRoot, classes.pending)}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <IfBlock condition={isPrimary}>
            <Button variant="contained" color="primary" size="small" className={classes.approveBtn} disabled={!isFinished} onClick={() => onChange(approveType)}>{approveType} CheckList</Button>
          </IfBlock>
        </Grid>
        <Grid item>
          {!isFinished && <Typography className={classes.info}>All items must be completed before approving the CheckList</Typography>}
          {(isFinished && isMulti) && <Typography className={classes.info}>This CheckList requires 2 approvals</Typography>}
        </Grid>
      </Grid>
    </Card>
  );
}