import React, { useMemo } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { blue, red, yellow, grey } from '@mui/material/colors';
// import red from '@mui/material/colors/red';
// import yellow from '@mui/material/colors/yellow';
// import grey from '@mui/material/colors/grey';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HelpIcon from '@mui/icons-material/Help';
import Popover from '@mui/material/Popover';
import { useHover } from 'hooks';
import { IfBlock } from 'features/common';
import { INP_TYPES } from 'utils/checklist-helpers';
import { formatDate } from 'utils/general-helpers';

function AssertionIcon({item, withTip, showEmpty}){
  const { classes, cx }   = buildStyles();
  const response  = item.primaryResponse;
  const hasRsp    = Boolean(response);
  const rspDate   = hasRsp ? formatDate(response.responseDate) : "";
  
  const subItems  = useMemo(() => {
    const otherResponses  = _.filter(item.responses, r => [INP_TYPES.text, INP_TYPES.comment].indexOf(r.discriminator) >= 0);
    const prepared        = _.map(otherResponses, rsp => prepareResponse(rsp, item.checkListInputs));
    return prepared;
  }, [item.checkListInputs, item.responses])

  
  const [anchor, isHovering, onEnter, onExit, position]   = useHover();

  if(item.className || (!item.className && showEmpty)){
    const itemKey = item.className || "empty";

    return <>
      <IfBlock condition={withTip}>
        <Chip icon={chipIcons[itemKey]} label={withTip ? item.key : null} variant="outlined" className={cx(classes.chip, itemKey)} onMouseEnter={onEnter} onMouseLeave={onExit} />
      </IfBlock>
      <IfBlock condition={!withTip}>
        {icons[itemKey](onEnter, onExit)}          
      </IfBlock>
      <Popover open={isHovering} anchorEl={anchor} className={classes.tip} classes={{paper: classes.tipPaper}} {...position} onClose={onExit} disableRestoreFocus>
        <Grid container direction="column">
          <Grid item className={cx(classes.popHeader, itemKey)}>
            <Typography className={classes.popHeaderText}>{item.key} {tooltips[itemKey].toUpperCase()}</Typography>
          </Grid>
          <IfBlock condition={withTip}>
            <Grid item className={classes.popContent}>
              <Typography className={classes.popContentText}>{item.assertionText}</Typography>
            </Grid>
            {_.map(subItems, itm => {
              return(
                <Grid key={itm.id} item className={classes.popResponse} container>
                  <Grid item className={classes.popResponseLabelGrid}>
                    <Typography className={classes.popResponseLabel}>{itm.label}:</Typography>
                  </Grid>
                  <Grid item className={classes.popResponseBodyGrid}>
                    <Typography className={classes.popResponseText}>{itm.value}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </IfBlock>
          <IfBlock condition={hasRsp}>
            <Grid item className={classes.popFooter}>
              <Typography className={classes.popFooterText}>
                by <span className={classes.popFooterLabel}>{response?.userName}</span>
              </Typography>
              <Typography className={classes.popFooterText}>
                on <span className={classes.popFooterLabel}>{rspDate}</span>
              </Typography>
            </Grid>
          </IfBlock>
        </Grid>
      </Popover>
    </>;
  }

  return null;
}

const icons   = {
  confirmed     : (onEnter, onExit) => <CheckCircleIcon fontSize="small" style={{color: blue[700]}} onMouseEnter={onEnter} onMouseLeave={onExit}/>,
  rejected      : (onEnter, onExit) => <CancelIcon  fontSize="small" style={{color: red[500]}} onMouseEnter={onEnter} onMouseLeave={onExit}/>,
  na            : (onEnter, onExit) => <RemoveCircleIcon fontSize="small" style={{color: yellow[800]}} onMouseEnter={onEnter} onMouseLeave={onExit}/>,
  empty         : (onEnter, onExit) => <HelpIcon fontSize="small" style={{color: grey[500]}} onMouseEnter={onEnter} onMouseLeave={onExit}/>,
};

const chipIcons   = {
  confirmed     : <CheckCircleIcon fontSize="small" style={{color: blue[700]}} />,
  rejected      : <CancelIcon  fontSize="small" style={{color: red[500]}} />,
  na            : <RemoveCircleIcon fontSize="small" style={{color: yellow[800]}} />,
  empty         : <HelpIcon fontSize="small" style={{color: grey[500]}} />,
};

const tooltips  = {
  confirmed     : "Confirmed",
  rejected      : "Rejected",
  na            : "Not Applicable",
  empty         : "Unanswered",
};

export default AssertionIcon;

const buildStyles   = makeStyles()(theme => ({
  chip  : {
    marginRight   : theme.spacing(0.5),
    marginBottom  : theme.spacing(0.5),
    "&.confirmed"   : {
      background      : blue[50],
      color           : blue[700],
      borderColor     : blue[700],
    },
    "&.rejected"   : {
      background      : red[50],
      color           : red[700],
      borderColor     : red[700],
    },
    "&.na"   : {
      background      : yellow[50],
      color           : yellow[900],
      borderColor     : yellow[900],
    },    
  },
  // icon: {
  //   marginRight: theme.spacing(0.5),
  // },
  tip   : {
    pointerEvents   : "none",
  },
  tipPaper     : {
    // padding         : theme.spacing(1),
  },
  popHeader     : {
    padding     : theme.spacing(1),
    "&.confirmed"   : {
      background      : blue[50],
      color           : blue[700],
      borderBottom    : `1px solid ${blue[200]}`, //`
    },
    "&.rejected"   : {
      background      : red[50],
      color           : red[700],
      borderBottom    : `1px solid ${red[200]}`, //`
    },
    "&.na"   : {
      background      : yellow[50],
      color           : yellow[900],
      borderBottom    : `1px solid ${yellow[200]}`, //`
    },"&.empty"   : {
      background      : grey[200],
      color           : grey[700],
      borderBottom    : `1px solid ${grey[200]}`, //`
    },
  },
  popHeaderText   : {
    fontSize    : 15,
    fontWeight  : 600,
  },
  popContent    : {
    padding         : theme.spacing(1),
    maxWidth        : 600,
  },
  popContentText     : {
    fontSize    : 15,
    fontWeight  : 600,
  },
  popRequiredText   : {
    fontSize    : 13,
    fontWeight  : 300,
    color       : theme.palette.grey[700],
  },
  popFooter     : {
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    background    : theme.palette.grey[200],
    borderTop     : `1px solid ${theme.palette.grey[300]}`, //`
  },
  popFooterText   : {
    fontSize    : 13,
    fontWeight  : 300,
    color       : theme.palette.grey[700],
    display     : "block",
  },
  popFooterLabel  : {
    fontWeight  : 400,
    color       : theme.palette.grey[800],
  },
  popResponse   : {
    padding         : `0 ${theme.spacing(1.5)}`, //`
    marginTop       : theme.spacing(-1), //`
    marginBottom    : theme.spacing(1),
    maxWidth        : 600,
  },
  popResponseLabelGrid : {
    width   : "75px",
  },
  popResponseBodyGrid : {
    width   : "Calc(100% - 75px)",
  },
  popResponseLabel  : {
    fontSize    : 13,
    fontWeight  : 400,
    color       : theme.palette.grey[600],
    display     : "inline-block",
    marginRight : theme.spacing(0.5),
  },
  popResponseText   : {
    fontSize    : 14,
    fontWeight  : 400,
    color       : theme.palette.grey[800],
    display     : "inline-block",
  },
}));

function prepareResponse(response, inputs){
  const inp   = _.find(inputs, i => i.id === response.checkListInputId);
  return {
    id        : inp.id,
    label     : inp.discriminator,
    value     : response.textResponse,
  };
}