import _ from 'lodash';
import { createSelector } from 'reselect';
import { getHost } from '../../utils/general-helpers';

const _getEngagements   = state => state.settings.engagements;
const _getProtocols     = state => state.settings.protocols;
const _getSettings      = state => state.settings;
const _getEnvironment   = state => state.app.environment;

///-----
/// Gets the full Engagement from the state, based on the engagement id
///-----
export const selectEngagements = createSelector(
  _getSettings,
  (settings) => {

    if(!settings) return null;

    const { engagements, protocols, projects, sites, clients }  = settings;

    const items   = _.map(engagements, eng => {
      const protocol  = _.find(protocols, p => p.id === eng.protocolId);
      const site      = _.find(sites, s => s.id === eng.siteId);
      const client    = _.find(clients, c => c.id === site.clientId);
      const project   = _.find(projects, p => p.id === protocol.projectId);

      const otherProps  = {
        name        : eng.name || `Engagement ${eng.id}`, //`
        isTraining  : Boolean(eng.propertyBag?.isTraining),
        protocol    : protocol,
        project     : project,
        site        : site,
        client      : client,
      };
        
      return { ...eng, ...otherProps };
    });
    return items;
  }
);

///-----
/// Gets the Engagement from the state, based on the engagement id
///-----
export const selectEngagement = createSelector(
  _getEngagements,
  (_, id) => id,
  (engagements, id) => {
    
    const engId   = id ? parseInt(id) : -1;
    if(engagements && engId >= 0){
      let eng   = _.find(engagements, e => e.id === engId);
      const props   = {
        name        : eng.name ? eng.name : `Engagement ${eng.id}`,
        isTraining  : Boolean(eng.propertyBag?.isTraining),        
      };

      eng   = {
        ...eng,
        ...props,
      }

      return eng;
    }

    return null;
  }
);

///-----
/// Gets the full Engagement from the state, based on the engagement id
///-----
export const selectFullEngagement = createSelector(
  _getSettings,
  _getEngagements,
  (_, id) => id,
  (settings, engagements, id) => {
    
    const { protocols, projects, sites, clients }  = settings;
    const engId   = id ? parseInt(id) : -1;

    if(engagements && engId >= 0){
      const eng       = _.find(engagements, e => e.id === engId);
      const protocol  = _.find(protocols, p => p.id === eng.protocolId);
      const site      = _.find(sites, s => s.id === eng.siteId);
      const client    = _.find(clients, c => c.id === site.clientId);
      const project   = _.find(projects, p => p.id === protocol.projectId);

      const otherProps  = {
        name        : eng.name || `Engagement ${eng.id}`, //`
        isTraining  : Boolean(eng.propertyBag?.isTraining),
        protocol    : protocol,
        project     : project,
        site        : site,
        client      : client,
      };
        
      return { ...eng, ...otherProps };
    }

    return null;
  }
);

///-----
/// Gets the full Engagement from the state, based on the engagement id
///-----
export const selectProtocol = createSelector(
  _getEngagements,
  _getProtocols,
  (_, id) => id,
  (engagements, protocols, engagementId) => {

    // console.log("**selectProtocol called");
    if(engagementId && protocols){
      const engagement = _.find(engagements, e => e.id === parseInt(engagementId));
      const protocol   = _.find(protocols, p => p.id === engagement.protocolId);

      return {
        ...protocol,
        engagement        : engagement,
        isMultiCheckList  : Boolean(protocol?.propertyBag?.qCheckListMulti === "true"),
        isMultiReviewSet  : Boolean(protocol?.propertyBag?.qReviewSetMulti === "true"),
      };
    }

    return null;
  }
);


export const selectEnvironment = createSelector(
  _getEnvironment,
  (environment) => {
    if(!environment) return null;

    const host = getHost();
    const isProdDb = Boolean(environment.databaseName.length === 0);
    
    const env = (host === "local" || host.toLowerCase() === environment.databaseName.toLowerCase() || (host === "dev" && environment.databaseName.toLowerCase() === "dev2")) ? host : "Mixed";

    let output          = {
      ...environment,
      env,
      location     : window.location.hostname.toLowerCase(),
      host,
      isProdDb,
      isProdEnv    : Boolean(host === "prod"),
      isConcerning : host !== "prod" && isProdDb,
    };

    return output;
  }
)