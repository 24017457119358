import React from "react";
import { Launch as OpenIcon, Download as DownloadIcon, Summarize as SummaryIcon } from "@mui/icons-material";
import { MenuOption } from "features/common";
import { openReviewSetReport } from "utils/reviewset-helpers";
import { IReviewSet } from "../reviewset-types";


  //---
  // Opens or downloads the report, or opens a tab with the report summary
  // depending on what menu option the user chooses.
  export const fetchReport = (set: IReviewSet, reportPath: string) => (target: "open" | "download" | "summary") => async () => {
    if(!!set){
      console.log(`Fetch the report for set ${set.id} with target: ${target}`);
      if(target === "summary"){
        window.open(reportPath, "_blank");
      }
      else{
        await openReviewSetReport(set.id, target === "open");
      }
    }
  }

  //Items in the report button menu. Different ways to view the report
  export const reportMenuOptions = (set: IReviewSet, reportPath: string): MenuOption[] => {
    
    const fetch = fetchReport(set, reportPath);

    return [
      {title: "Open Report", onClick: fetch("open"), icon: <OpenIcon /> },
      {title: "Download Report", onClick: fetch("download"), icon: <DownloadIcon /> },
      {title: "View Summary", onClick: fetch("summary"), icon: <SummaryIcon /> },
    ];
  };  