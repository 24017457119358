import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = ({open, title, prompt, onYes, onNo, onCancel, yesLabel, noLabel, cancelLabel, isBusy}) => {
  const yesText   = yesLabel || "Yes";
  const noText    = noLabel || "No";
  const cancelText = cancelLabel || "Cancel";

  if(!open) return null;

  return (
    <Dialog open={open} onClose={onNo}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {prompt}
      </DialogContent>
      <DialogActions>
        <Button onClick={onYes} color="primary" disabled={isBusy}>{yesText}</Button>
        <Button onClick={onNo} color="primary" disabled={isBusy}>{noText}</Button>
        {Boolean(onCancel) && <Button onClick={onCancel} disabled={isBusy}>{cancelText}</Button>}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;