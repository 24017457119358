import React from 'react';
import { useSelector, } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { isString, isArray, isObject } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DocIcon from '@mui/icons-material/Description';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronUp from '@mui/icons-material/KeyboardArrowUp';
import ChevronDown from '@mui/icons-material/KeyboardArrowDown';
import { selectDocumentProgress } from 'store/selectors/document-selectors';
import { Status, StatusCard } from 'features/common';
import { CheckListFilter } from './checklists';
import { appRoutesLegacy } from 'utils/route-helpers';
import { useBoolState, useIsMobile } from '../../../hooks/general-hooks';
import { useNavigate } from 'react-router-dom';
import { useAppParams } from 'hooks';

function DocumentHeader({doc}){
  const { classes, cx }         = buildStyles();
  const progress        = useSelector(selectDocumentProgress);
  const checkListId     = useSelector(state => state.doc.checkListId);
  const { pathname } = useLocation();
  const params = useAppParams();
  const navigate = useNavigate();
  const altUrls         = doc.isPrimary ? [] : buildAltUrls(pathname, params, doc.primaryReviewRecord);
  const displayProps  = doc?.propertyBag?.displayProperties ?? null;
  const hasMeta       = Boolean(displayProps);
  const isMobile = useIsMobile();
  
  console.log("isMobile: ", isMobile);

  const goBack  = () => {
    const backUrl   = pathname.replace(`/documents/${params.documentId}`, "");
    navigate(backUrl);
  }

  return (
    <Grid id="document-header" container spacing={2} className={classes.root}>
      
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={12} sm={9} container alignItems="center" className={classes.titleGrid}>
          <Tooltip title={"Document " + doc.id}>
            <DocIcon color="primary"/>
          </Tooltip>
          <Typography className={classes.title}>{doc.documentKey}</Typography>
          <Status code={doc.statusCode}/>
        </Grid>
        <Grid item xs={12} sm={3} container justifyContent="flex-end" alignItems="center">
          <Tooltip title="Print current checklist">
            <IconButton variant="outlined" size="small" color="primary" className={classes.action} component={Link} to={appRoutesLegacy.reports.checkListItemReport(params.reviewsetId, params.documentId, checkListId)} target="_blank">
              <PrintIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Go back">
            <IconButton variant="outlined" size="small" className={classes.action} onClick={goBack}>
              <ArrowBackIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid item xs={12} container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} sm={5} md={4} container alignItems={isMobile ? "center" : "flex-start"} direction="column">
          <Typography className={classes.value} title="Document Type">{doc.documentType?.name}</Typography>
          <Typography className={classes.value} title="Progress">{`${progress[0]} of ${progress[1]} Questions Answered`}</Typography>          
        </Grid>
        <Grid item xs={12} sm={7} md={8} container alignItems="center" sx={{justifyContent: {xs: "center", sm: "flex-end"}}}>
          <CheckListFilter/>
        </Grid>

        {hasMeta && <MetaDataSection data={displayProps} classes={classes} cx={cx}/>}
        
        <StatusCard status="warning" isVisible={!doc.isPrimary} sx={{marginTop: 1}}>
          <Grid container justifyContent="space-between" alignItems="center" sx={{px: 2}}>
            <Grid item>
              <Typography className={classes.info}>This document is read only because it belongs to a different ReviewSet.</Typography>
            </Grid>
            <Grid item>
              <Button size="small" color="default" variant="outlined" title="Navigate to the ReviewSet" onClick={() => navigate(altUrls[0])} endIcon={<ArrowForwardIcon fontSize="small"/>} className={classes.nonPrimaryButton}>ReviewSet</Button>
              <Button size="small" color="default" variant="outlined" title="Navigate to the Document" onClick={() => navigate(altUrls[1])} endIcon={<ArrowForwardIcon fontSize="small"/>} className={classes.nonPrimaryButton}>Document</Button>
            </Grid>
          </Grid>
        </StatusCard> 
      
      </Grid>

    </Grid>
  );
}

export default DocumentHeader;

const buildStyles   = makeStyles()(theme => ({
  root  : {
    margin      : theme.spacing(2),
  },
  titleGrid   : {
    marginBottom  : theme.spacing(0.5),
    "& p"  : {
      fontSize    : 24,
      fontWeight  : 300,
      color       : theme.palette.grey[800],
      display     : "inline-block",
      marginRight : theme.spacing(1),
    },
    "& svg"   : {
      marginTop     : "-2px",
      marginRight   : theme.spacing(0.5),
      marginBottom  : theme.spacing(-0.5),
    }
  },
  label   : {
    fontSize    : ".9rem",
    fontWeight  : 400,
    display     : "inline-block",
    color       : theme.palette.grey[700],
    marginRight : theme.spacing(1),
  },
  value   : {
    fontSize    : "1.05rem",
    fontWeight  : 400,
    display     : "inline-block",
  },  
  info      : {
    fontSize    : 16,
    fontWeight  : 400,
  },
  action  : {
    marginLeft  : theme.spacing(1),
    marginBottom : theme.spacing(1),
  },
  nonPrimaryButton  : {
    marginLeft    : theme.spacing(0.5),
  },
  propertiesTable: {
    marginBottom: 0,
    border: `1px solid ${theme.palette.secondary.main}66`, //`
  },
  headerRow: {
    "& td": {
      cursor: "pointer",
      textTransform: "uppercase",
      color: theme.palette.secondary.main,
      backgroundColor: `${theme.palette.secondary.main}22`,
      borderBottom: `1px solid ${theme.palette.secondary.main} !important`,
    }
  },
  headerRowSubtle: {
    "& td": {
      cursor: "pointer",
      textTransform: "uppercase",
      color: theme.palette.secondary.main,
      backgroundColor: `${theme.palette.secondary.main}11`,
    }
  },
  propertyKeyCol: {
    textAlign   : "right",
    background  : theme.palette.grey[100], //`${}22`,
    width       : "15%",
    color: theme.palette.primary.main
  }
}));

function buildAltUrls(url, params, primaryRr){
  if(!primaryRr) return [];
  return [
    url.replace(`/reviewsets/${params.reviewsetId}/documents/${params.documentId}`, `/reviewsets/${primaryRr?.reviewSetId}`),
    url.replace(`/reviewsets/${params.reviewsetId}/documents/${params.documentId}`, `/reviewsets/${primaryRr?.reviewSetId}/documents/${primaryRr?.documentId}`),
  ];
}

const MetaDataSection = ({data, classes, cx}) => {
  const [isExpanded, toggleExpanded] = useBoolState(false);
  
  if(!data) return null;

  let index     = 0;
  let rows      = null;
  if(isExpanded){
    if(isString(data)) data = JSON.parse(data);

    if(isArray(data)){
      rows          = data.map(dr => {
        return (
          <tr key={index++} className="data-row">
            <td className={classes.propertyKeyCol}><span className="">{dr.label}</span></td>
            <td className="data-val"><span className="">{dr.value}</span></td>
          </tr>
        );
      });
    }
    else if(isObject(data)){
      const keys    = Object.keys(data);
      rows          = keys.map(key => {
        const dr    = data[key];
        return (
          <tr key={index++} className="data-row">
            <td className={classes.propertyKeyCol}><span className="">{key}</span></td>
            <td className="data-val"><span className="">{dr}</span></td>
          </tr>
        );
      });
    }
    else{
      rows = (<tr className="data-row"><td className="data-val" colSpan={2}>Unknown Display Properties Format</td></tr>)
    }
  }

  return (
    <Grid item container>
        <table className={cx("table table-condensed table-bordered doc-data-table", classes.propertiesTable)}>
          <tbody>
            <tr className={isExpanded ? classes.headerRow : classes.headerRowSubtle}>
              <td colSpan={2} onClick={() => toggleExpanded()}>
                <Grid container alignItems="center" spacing={2} wrap="nowrap">
                  <Grid item>
                    <Tooltip title="Toggle Document Properties">
                      <IconButton variant="outlined" size="small" onClick={() => toggleExpanded()}>
                        {isExpanded ? <ChevronUp /> : <ChevronDown />}
                      </IconButton>
                      </Tooltip>
                  </Grid>
                  <Grid item>{isExpanded ? "Document Properties" : "Show Document Properties"}</Grid>
                </Grid>
              </td>
            </tr>
            {rows}
          </tbody>
        </table>
    </Grid>
  );
};