import React from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const PropertiesGrid = ({props, maxLabelWidth, maxValueWidth, labelWidth, classes}) => {
  const { classes: myClasses }   = buildStyles({maxValueWidth: maxValueWidth, maxLabelWidth: maxLabelWidth, labelWidth: labelWidth});
  const allClasses  = {...myClasses, ...classes};
  let i = 0;
  
  return (
    <table className={allClasses.contentTable}>
      <tbody>
        { _.map(props, prop => <PropertiesTableRow key={i++} prop={prop} classes={allClasses} />) }      
      </tbody>
  </table>
  );
}

export default PropertiesGrid;

const buildStyles   = makeStyles()((theme, props) => ({
  contentGrid   : {
    padding   : theme.spacing(1),
  },
  contentTable  : {
    width   : "100%",
  },
  labelCol  : {
    maxWidth    : props.maxLabelWidth || "unset",
    width       : props.labelWidth || "unset",
  },
  valueCol      : {
    maxWidth    : props.maxValueWidth || 300,
  },
  itemLabel   : {
    fontSize      : 13,
    fontWeight    : 400,
    color         : theme.palette.grey[500],
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  itemValue  : {
    fontSize      : 15,
    fontWeight    : 400,
    color         : theme.palette.grey[800],
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`,    
  },  
}));

// function PropertiesGridRow({prop, classes}){
//   return (
//     <>
//       <Grid item xs={12} container alignItems="center">

//         <Grid item className={classes.labelCol}>
//           <Typography className={classes.itemLabel}>{prop.label}</Typography>
//         </Grid>

//         <Grid item className={classes.valueCol}>
//           <Typography className={classes.itemValue}>{prop.value}</Typography>
//         </Grid>

//       </Grid>
//     </>
//   );
// }

function PropertiesTableRow({prop, classes}){
  return (
    <tr>
      <td className={classes.labelCol}>
        <Typography className={classes.itemLabel}>{prop.label}</Typography>
      </td>        
      <td className={classes.valueCol}>
        <Typography className={classes.itemValue}>{prop.value}</Typography>
      </td>
    </tr>
  );
}