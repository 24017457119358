import { createAsyncThunk } from "@reduxjs/toolkit";
// import { REPORT_ACTIONS } from "./report-actions";
import Apis from "store/api";
import { downloadFile } from "store/api/quanta-api";
import { createAppAsyncThunk, parseResponse } from "store/slices/slice-helpers";
import { REPORT_API_PATHS } from "utils/report-helpers";
import { CheckReportStatusParams, DownloadThunkParams } from "./thunk-types";

export const REPORT_THUNKS = {
  download: "report/download",
  fetchSet: "report/fetch-set",
  fetchCheckList: "report/fetch-checklist",
  checkStatus: "report/check-status",
}



//TODO: switch use to this method from the report-actions method
export const downloadReportThunk = createAsyncThunk<any, DownloadThunkParams>(REPORT_THUNKS.download, async (params, thunkAPI) => {
  
  const { url } = params;
  const result  = await Apis.Reports.get(url, { responseType: "arraybuffer"});

  if(result.ok){
    const isOk  = await downloadFile(result, "text/csv");
    if(!isOk){
      console.log("Download Report failed.");
    }
    return parseResponse(REPORT_THUNKS.download, result, { report: url });
  }
  else{
    console.error("failed to download report", result);
    throw result;
  }
});

//===
// Fetch the set for the report
//===
export const fetchSetForReportThunk = createAppAsyncThunk(REPORT_THUNKS.fetchSet, async (params: any, thunkAPI) => {
  const { id } = params;
  
  const state = thunkAPI.getState().reports;
  if(state.setData && state.setData.id === id) return state.setData;  //return existing item

  const result = await Apis.Reports.get(`reviewsets/${id}`);
  const response = parseResponse(REPORT_THUNKS.fetchSet, result, { id });
  
  return response;
});

//===
// Fetch the data for a Checklist Report
//===
export const fetchCheckListForReportThunk = createAppAsyncThunk(REPORT_THUNKS.fetchCheckList, async (params: any, thunkAPI) => {
  const { setId, docId } = params;

  const setPromise = Apis.Reviewsets.get(`/${setId}`);
  const docPromise = Apis.Documents.get(`/${docId}`);
  const [setResult, docResult] = await Promise.all([setPromise, docPromise]);

  if(setResult?.ok && docResult?.ok){
    const payload = {
      id: setId,
      set: setResult.data,
      doc: docResult.data,
    };

    return payload;
  }
  else {
    const failure = !setResult?.ok ? setResult : docResult;
    throw failure;
  }
});

//===
// Check the status of a reviewset report
//===
export const checkReportStatusThunk = createAppAsyncThunk<any, CheckReportStatusParams>(REPORT_THUNKS.checkStatus, async (params, thunkAPI) => {
  const { setId } = params;
  const path = REPORT_API_PATHS.reviewSetStatus(setId);
  const result = await Apis.Reports.get(path);
  const response = parseResponse(REPORT_THUNKS.checkStatus, result, { setId });
  
  return response;
});