import { createApiThunk, createAppAsyncThunk, parseResponse } from "store/slices/slice-helpers";
import { getQuantaApi, getSearchProps } from "utils/action-helpers";
import { ListParams } from "./thunk-types";
import { paginationHeader } from "api";

export const ADMIN_REPORT_THUNKS = {
  FETCH_REPORT_QUEUE: "ADMIN_FETCH_REPORT_QUEUE",
  RETRY_REPORT: "ADMIN_RETRY_REPORT",
  DELETE_REPORT: "ADMIN_DELETE_REPORT",
};

const Apis = getQuantaApi();

export const fetchReportQueueThunk = createAppAsyncThunk(ADMIN_REPORT_THUNKS.FETCH_REPORT_QUEUE, async (params: ListParams, thunkAPI) => {
  const { filter, pageNumber = 1, pageSize = 25 } = params;
  
  const requestParams = getSearchProps(filter);
  const headers = paginationHeader(pageNumber, pageSize);

  const result = await Apis.ReportQueue.get<any, any>("/list", requestParams, headers);

  return parseResponse(ADMIN_REPORT_THUNKS.FETCH_REPORT_QUEUE, result, { filter: requestParams });
});

export const retryReportThunk = createAppAsyncThunk(ADMIN_REPORT_THUNKS.RETRY_REPORT, async (params: { rowId: number }, thunkAPI) => {
  const { rowId } = params;
  const result = await Apis.ReportQueue.put<any, any>(`${rowId}/retry`);
  return parseResponse(ADMIN_REPORT_THUNKS.RETRY_REPORT, result, { rowId });
});

export const deleteReportQueueThunk = createAppAsyncThunk(ADMIN_REPORT_THUNKS.DELETE_REPORT, async (params: { rowId: number }, thunkAPI) => {
  const { rowId } = params;
  const result = await Apis.ReportQueue.delete<any, any>(`${rowId}`);
  return parseResponse(ADMIN_REPORT_THUNKS.DELETE_REPORT, result, { rowId });
});

// export const fetchReportQueueThunk = createApiThunk<ListParams>(
//   ADMIN_REPORT_THUNKS.FETCH_REPORT_QUEUE,
//   Apis.ReportQueue,
//   "get",
//   "/list",
//   (params) => params.filter,
//   (params) => paginationHeader(params.pageNumber ?? 1, params.pageSize ?? 999)
// );

// export const retryReportThunk = createApiThunk<{ rowId: number }>(
//   ADMIN_REPORT_THUNKS.RETRY_REPORT,
//   Apis.ReportQueue,
//   "put",
//   (params) => `${params.rowId}/retry`,
// );

// export const deleteReportQueueThunk = createApiThunk<{ rowId: number }>(
//   ADMIN_REPORT_THUNKS.DELETE_REPORT,
//   Apis.ReportQueue,
//   "delete",
//   (params) => `${params.rowId}`,
// );