import { useState } from 'react';
import _ from 'lodash';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDocument } from 'store/selectors/document-selectors';
import { selectSet } from 'store/selectors/reviewset-selectors';
import { selectFullEngagement } from 'store/selectors/setting-selectors';
import { readSetting, saveSetting } from 'utils/localstorage-helpers';
import Authorize from 'utils/auth/auth-helper';
import { useMediaQuery, useTheme } from '@mui/material';
import { useAppParams } from './general-hooks-ts';

export function useSetting(key, defaultValue){
  const [value, setValue]  = useState(readSetting(key, defaultValue));

  const updateFunc    = (value) => {
    saveSetting(key, value);
    setValue(value);
  };

  return [value, updateFunc];
}

///==
// a useState wrapper specifically to hold bool values
export function useBoolState(defaultValue){
  const [value, setValue]   = useState(defaultValue);
  
  function toggle() { setValue(!value); }
  function onFalse() { setValue(false); };
  function onTrue() { setValue(true); }

  return [
    value,
    toggle,
    onFalse,
    onTrue,
    setValue,
  ];
}

//-----------------
// Gets the current engagement 
//-----------------
export function useEngagement(){
  const { engagementId }    = useAppParams();
  const eng   = useSelector(state => selectFullEngagement(state, engagementId));
  return eng;
}

//-----------------
// Gets the current document making use of the current reviewset as specified in the url
//  this includes the relevant reviewrecord
//-----------------
export function useCurrentDocument(){
  const { reviewsetId }   = useAppParams();
  const doc         = useSelector(state => selectDocument(state, reviewsetId));
  return doc;
}

//-----------------
// Gets the current reviewset making use of the url to determine the id of the current set
//-----------------
export function useCurrentReviewSet(){
  const set     = useSelector(selectSet);
  const { reviewsetId, engagementId } = useAppParams();

  if(!set || (!reviewsetId)) return null;
  return set && (set.id === reviewsetId && set.engagementId === engagementId) ? set : null;   //don't return if the set doesn't match the url
}

//-----------------
// Gets permission information for the current user, and the current engagements
//-----------------
export function useCurrentPermissions(){
  const user          = useSelector(state => state.settings.user);
  const { engagementId } = useParams();
  // const { engagementId } = useAppParams();
  const assignment    = engagementId ? _.find(user?.assignments, a => a.entityType === "eng" && a.entityId === parseInt(engagementId)) : null;
  const isAdmin       = Authorize.isPermitted(user, Authorize.claims.ADMIN_VIEW);

  return {
    user    : user,
    role    : user?.role,
    isAdmin,
    currentAssignment   : assignment,   //The assignment for the current engagement
    isReadOnly : Boolean(assignment?.role.toLowerCase() === "reader"),
    isApprover : Boolean(assignment?.role.toLowerCase() === "approver"),
  };

}


///==
// Used by reports, where props are stored in the query string.
export function useQueryString(){
  const queryString = useLocation().search;
  const urlp  = new URLSearchParams(queryString);
  const rsId  = urlp.get("reviewsetid");
  const docId = urlp.get("documentid");
  const clId  = urlp.get("checklistid");
  const sort  = urlp.get("sort");
  const filter= urlp.get("filter");
  const search  = urlp.get("search");

  let result = {};

  if(rsId) result.reviewsetId   = parseInt(rsId);
  if(docId) result.documentId   = parseInt(docId);
  if(clId) result.checkListId   = parseInt(clId);
  if(sort) result.sort          = sort;
  if(filter) result.filter      = filter;
  if(search) result.search        = search;
  result.queryString = queryString;

  return result;
}

export function useInputHandler(defaultValues, handlerOverride, onValidate){
 const [values, setValues]	= useState(defaultValues); 

  function onInputChange(e, selected){
    const type      = e.target.type;
    let key         = e.target.name || e.target.id; // || e.currentTarget.name || e.currentTarget.id;
    let value       = e.target[valMap(type)];
    let otherProps  = null;

    if(handlerOverride){
      const result  = handlerOverride(e, key, value);
      key   = result.key || key;
      value = result.value || value;
      otherProps  = result.otherProps;
    }
  
    const updated   = {...values, [key]: value, ...otherProps};
    setValues(updated);
    if(onValidate) onValidate(updated);
  }

  const onSelectChange = (key) => async (e) => {
    let value   = e.target.value || e.currentTarget.value;
    let otherProps  = null;

    if(handlerOverride){
      const result  = handlerOverride(e, key, value);
      key   = result.key || key;
      value = result.value || value;
      otherProps  = result.otherProps;
    }
  
    const updated   = {...values, [key]: value, ...otherProps};
    setValues(updated);
    if(onValidate) onValidate(updated);
  }

  //kick things off with a validation check
  // if(onValidate) onValidate(defaultValues);

  return [values, onInputChange, setValues, onSelectChange];
}

function valMap(targetType){
  switch(targetType){
    case "checkbox"   : return "checked";
    case "radio"      : return "checked";
    default           : return "value";
  }
}

//Gets the list of fields as typed params
// export function useTypedParams(fields){
//   const params   = useParams();
//   const fieldKeys= _.keys(fields);
//   const untyped  = _.pick(params, fieldKeys);

//   const output   = _.reduce(_.fieldKeys, (prev, key) => {
//     const fieldType   = fields[key];
//     const rawValue    = untyped[key];
//     let typedValue    = rawValue;
//     switch(fieldType){
//       case "int"  : typedValue  = tryParseInt(rawValue, NaN); break;
//       case "bool" : typedValue  = Boolean(rawValue === "true"); break;
//       default     : typedValue  = rawValue; break;
//     }

//     if(isNaN(typedValue)) typedValue = rawValue;
//     return {
//       ...prev,
//       [key]   : typedValue
//     };

//   }, {});

//   return output;

// }


export function useIsMobile(){
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return isMobile;
}

export function useIsBreakpointDown(breakpoint){
  const theme = useTheme();
  const isBpDown = useMediaQuery(theme.breakpoints.down(breakpoint));
  return isBpDown;
}
