import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const InputEditorBool = ({input, onChange, disabled}) => {
  const { classes, cx }   = buildStyles();
  if(!input) return null;

  return (
    <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.rootGrid}>
      <Grid item className={cx(classes.buttonGrid, input.allowNA && classes.naGrid)}>
        <Tooltip title="Select to include N/A choice for this assertion">
          <FormControlLabel disabled={disabled} control={<Checkbox name="hasNa" onChange={onChange} checked={input.allowNA} style={{display: "none"}} />} label="n/a" className={classes.checkLabel} />
        </Tooltip>          
      </Grid>
    </Grid>
  );
}

export default InputEditorBool;

const buildStyles   = makeStyles()(theme => ({
  rootGrid    : {
  },
  buttonGrid  : {
    borderRadius  : "50%",
    borderWidth   : "1px",
    width         : "24px",
    height        : "24px",
    margin      : `${theme.spacing(0)} ${theme.spacing(0.25)}`,
    border      : `1px solid ${theme.palette.grey[400]}`,

    "& .MuiFormControlLabel-root"   : {
      marginLeft  : 0,
      marginBottom  : 0,      
    },
    "& .MuiFormControlLabel-label"  : {
      fontSize        : "10px",
      fontWeight      : "800",
      textTransform   : "uppercase",
      color           : theme.palette.grey[400],
      marginTop       : "-5px",
      marginLeft      : "2px",
    },
    "& .MuiIconButton-root"   : {
      padding     : `${theme.spacing(0)} ${theme.spacing(0.25)}`,
    },
    "& .MuiSvgIcon-root"  : {
      height    : "0.75em",
      width    : "0.75em",
    },
  },
  confirmGrid   : {
    background    : theme.statuses.confirmed.background,
    borderColor   : theme.statuses.confirmed.border,
    "& span"   : {
      color     : theme.statuses.confirmed.text,
      marginBottom  : "-5px",
    }
  },
  rejectGrid  : {
    background    : theme.statuses.rejected.background,
    borderColor        : theme.statuses.rejected.border,
    "& span"   : {
      color     : theme.statuses.rejected.text,
      marginBottom  : "-5px",
    }
  },
  naGrid  : {
    background    : theme.statuses.na.border,
    borderColor        : theme.statuses.na.border,
    "& .MuiFormControlLabel-label"   : {
      color     : "white", 
    },
    "& .MuiIconButton-label"   : {
      color    : "white",
    }
  },
  checkLabel  : {
    marginRight   : theme.spacing(1),
  }
}));