import React, { useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CloseIcon from '@mui/icons-material/Close';
import { useInputHandler } from 'hooks/general-hooks';
import { adminDialogClasses } from 'features/common/dialog-classes';
import { WaitButton } from 'features/common';
import { swapNulls } from 'utils/general-helpers';
import { defaultInputs } from 'utils/checklist-helpers';

const AddTextDialog = ({isOpen, input, onClose, onSave, onDelete, isEditable}) => {
  const { classes }   = buildStyles();
  const isAdd     = !input;
  const [original, setOriginal]   = useState(null);
  const [values, onChange, setValues]   = useInputHandler({}, null, null);
  
  //Need to respond to changes to the input
  useEffect(() => {
    const prepared  = !!input ? swapNulls(input) : {...defaultInputs.text};
    setOriginal(prepared);
    setValues(prepared);
  }, [input, setValues]);

  if(!isOpen) return null;
  
  function onSaveChanges(){
    //Has anything changed?
    if(original.id === -1 || !shallowEqual(original, values)){
      onSave(values);
    }
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className={classes.dialog} maxWidth="sm">
      
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between">
          <Typography className={classes.titleText}>
            <Tooltip title="Text Input">
              <TextFieldsIcon fontSize="small" className={classes.titleIcon}/>
            </Tooltip>
            {isAdd ? "New " : ""}Text Input
          </Typography>
          <CloseIcon fontSize="small" onClick={onClose} className={classes.closeIcon}/>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container>

          <Grid item container className={classes.formRow}>
            <TextField name="caption" value={values.caption} onChange={onChange} fullWidth label="Caption" className={classes.field} autoComplete="off" disabled={!isEditable}/>
          </Grid>

          <Grid item container className={classes.formRow}>
            <TextField name="textEmptyMessage" value={values.textEmptyMessage} onChange={onChange} fullWidth label="Placeholder" className={classes.field} autoComplete="off" disabled={!isEditable}/>
          </Grid>

          <Grid item container className={classes.formRow}>
            <TextField name="toolTip" value={values.toolTip} onChange={onChange} fullWidth label="Tooltip" className={classes.field} autoComplete="off" disabled={!isEditable}/>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={7}>
              <Tooltip title="Should this texbox be multiple lines?">
                <Typography className={classes.checkLabel}>Multi-Line</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={5}>
              <FormControlLabel name="textMultiLine" checked={values.textMultiLine} onChange={onChange} className={classes.check} control={<Checkbox />}  disabled={!isEditable}/>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button size="small" onClick={onClose}>Cancel</Button>
        {(isEditable && !isAdd) && <Button size="small" onClick={onDelete}>Delete</Button>}
        {isEditable && <WaitButton onClick={onSaveChanges} color="secondary" size="small">Save</WaitButton>}
      </DialogActions>

    </Dialog>
  );
}

export default AddTextDialog;

const buildStyles   = makeStyles()(theme => ({
  ...adminDialogClasses(theme),
  root  : {
    padding     : theme.spacing(1),
  },  
}));