import _ from 'lodash';
import { createSelector } from 'reselect';
import { DATE_ONLY_FORMAT, LONG_DATE_FORMAT } from 'utils/date-helpers';
import { formatDate } from 'utils/general-helpers';

const _getStatusByKey   = (state, key) => state.metrics.status[key];
// const _getEngagementData  = state => state.metrics.engagement;
const _getRsCounts        = state => state.metrics.rsCounts;
const _getDocCounts       = state => state.metrics.docCounts;

export const selectStatusByKey = createSelector(
  [_getStatusByKey],
  (status) => {
    return status || {};
  }
);

export const selectEngagementReviewSetData = createSelector(
  [_getRsCounts],
  (data) => {
    if(!data || data.length === 0) return null;

    // const format      = "M/D/YY";
    // const ttFormat    = "MMM D, YYYY";
    let newCount      = 0, apprCount = 0, preApprCount = 0;
    const hasPre      = Boolean(_.find(data, i => i.preApprCount > 0));   //any pre-approvals?
    const dataPoints  = _.map(data, item => {
      
      const dateStr   = formatDate(item.itemDate, DATE_ONLY_FORMAT);   
      const ttStr     = formatDate(item.itemDate, LONG_DATE_FORMAT);   
      newCount  = newCount + item.createdCount - item.deletedCount;
      apprCount  = apprCount + item.approvedCount - item.unApprovedCount;
      preApprCount  = preApprCount + item.preApprovedCount;

      let point   = {
        label     : dateStr,
        ttTitle   : ttStr,
        created   : newCount,
        approved  : apprCount,        
        details   : item,
      };

      if(hasPre){
        point.preApproved   = preApprCount;
      }

      return point;
    });

    return {
      chartData   : dataPoints,
      total       : newCount,
      approved    : apprCount,
      preApproved : preApprCount,
      hasPreApprove : hasPre,
    };
  }
);

export const selectEngagementDocumentData = createSelector(
  [_getDocCounts],
  (data) => {
    if(!data || data.length === 0) return null;

    // const format      = "M/D/YY";
    // const ttFormat    = "MMM D, YYYY";
    let newCount      = 0, apprCount = 0, preApprCount = 0;
    const hasPre      = Boolean(_.find(data, i => i.preApprCount > 0));   //any pre-approvals?
    const dataPoints  = _.map(data, item => {
      
      const dateStr   = formatDate(item.itemDate, DATE_ONLY_FORMAT);   
      const ttStr     = formatDate(item.itemDate, LONG_DATE_FORMAT);   
      newCount  = newCount + item.createdCount;
      apprCount  = apprCount + item.approvedCount - item.unApprovedCount;
      preApprCount  = preApprCount + item.preApprovedCount;

      let point   = {
        label     : dateStr,
        ttTitle   : ttStr,
        created   : newCount,
        approved  : apprCount,        
        details   : item,
      };

      if(hasPre){
        point.preApproved   = preApprCount;
      }

      return point;
    });

    return {
      chartData   : dataPoints,
      total       : newCount,
      approved    : apprCount,
      preApproved : preApprCount,
      hasPreApprove : hasPre,
    };
  }
);