export const adminDialogClasses = (theme) => ({
  title   : {
    background  : theme.palette.primary.dark,
    padding     : theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleText   : {
    fontSize    : 22,
    fontWeight  : 500,
    color       : theme.palette.primary.contrastText,
  },
  titleIcon   : {
    marginRight   : theme.spacing(1),
    marginBottom  : theme.spacing(-0.5),
  },
  content   : {
    marginBottom  : theme.spacing(2),
  },
  formGrid  : {
    paddingRight  : theme.spacing(4),
  },
  formRow   : {
    marginBottom  : theme.spacing(2),
  },
  field     : {
    minWidth    : 150,
  },
  formSelectRow   : {
    marginTop     : theme.spacing(1.5),
    marginBottom  : theme.spacing(2),
  },
  closeIcon   : {
    marginLeft  : theme.spacing(2),
    color       : theme.palette.primary.contrastText,
    cursor      : "pointer",
  },
  formCompactRow   : {
    marginBottom    : theme.spacing(1.5),
  }
});

export const listItemClasses = (theme) => ({
  listItemRow   : {

  },
  listItemCol   : {
    verticalAlign   : "middle !important",
    "& svg"   : {
      marginRight   : theme.spacing(1),
      marginBottom  : theme.spacing(-0.5),
      fontSize      : 18,
      color         : theme.palette.grey[600],
    }
  },
  listItemActionButton  : {
    padding     : 2,
    "& svg"     : {
      fontSize    : 18,
    }
  },
  listItemEditButton  : {
    textTransform   : "none",
    fontWeight      : "700",
    padding         : 0,
    color           : "#337ab7",
    "& span"        : {
      textAlign       : "left",
      justifyContent  : "flex-start",
    },
  },
  endIcon  : {
    marginLeft  : theme.spacing(1),
  }
});