import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { selectExistingDocs } from 'store/selectors/document-selectors';
import { selectSet } from 'store/selectors/reviewset-selectors';
import { fetchExistingDocsThunk } from 'store/thunks';
import { WaitContainer } from 'features/common';
import { useAppDispatch, useAppSelector } from 'store';

function AddExistingPanel({doc, onDocChanged}){
  const { classes }         = buildStyles();
  const dispatch        = useAppDispatch();
  const lastFilter      = useAppSelector(state => state.docs.existingFilter);
  const set             = useAppSelector(selectSet);
  const existingDocs    = useAppSelector(selectExistingDocs);
  const isLoading       = useAppSelector(state => state.docs.isExistingWorking);  
  const isSaving        = useAppSelector(state => state.docs.isCreating);
  const noExisting      = existingDocs && existingDocs.length === 0;
  
  const options = useMemo(() => {
    if(!existingDocs) return [];
  //Don't include documents already associated with this ReviewSet
  const items = existingDocs?.filter(doc => !_.find(doc.reviewRecords, rr => rr.reviewSetId === set.id));
    items.sort((a, b) => a.documentKey.localeCompare(b.documentKey));
    return items;
  }, [existingDocs, set]);

  useEffect(() => {
    if(!isLoading && isNeeded(lastFilter, set, existingDocs)){
      const filterParams  = createFilter(set, ""); 
      dispatch(fetchExistingDocsThunk({ filter: filterParams }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingDocs]);

  const handleChange = (e, value) => {
    const update   = {
      docId       : value?.id,
      doc         : value,
    };

    onDocChanged(update);
  }

  const filterOptions   = createFilterOptions({
    stringify: LABEL_FUNC,
  });

  return (
    <WaitContainer isWaiting={isLoading} message="Loading..." isEmpty={noExisting} emptyMessage="There are no documents available to add to this ReviewSet.">
      <Typography className={classes.instructions}>Choose from the documents below and click Save</Typography>
      <Grid container spacing={2}> 
        <Grid item xs={11} container>
            <Autocomplete id="docId" 
              value={doc.doc} onChange={handleChange} disabled={isSaving} style={{width: "100%"}}
              options={options} 
              filterOptions={filterOptions}
              getOptionLabel={LABEL_FUNC}
              renderInput={params => <TextField {...params} label="Choose a Document" />}
              />
        </Grid>
      </Grid>
    </WaitContainer>
  );
}

export default AddExistingPanel;

const buildStyles   = makeStyles()(theme => ({
  instructions  : {
    color       : theme.palette.grey[800],
    marginBottom: theme.spacing(2),
  },
}));

///---- whether or not the existing doc list needs to be refreshed
function isNeeded(lastFilter, set, existingDocs){
  return (set && (!existingDocs || (lastFilter.siteId !== set.siteId || lastFilter.protocolId !== set.protocolId)));
}

///---- creates the filter params necessary to get existing docs
function createFilter(set, filter){
  let filterObj   = {
    siteId      : set?.siteId,
    protocolId  : set?.protocolId,
  };
  if(filter) filterObj.search   = filter;
  return filterObj;
}

///---- the display label for the document options
const LABEL_FUNC    = opt => `${opt.documentKey} (${opt.documentType?.name})`;