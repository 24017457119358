import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
import { downloadReportThunk, deleteAssignmentThunk, saveAssignmentThunk, saveUserThunk } from 'store/thunks';
import { selectStatusByKey, selectUser } from 'store/selectors/admin-selectors';
import { IfBlock, WaitContainer, ErrorDisplay } from 'features/common';
import UserEngagements from './user-engagements';
import Validator from 'utils/validation-helper';
import { muiDialogReason } from 'utils/ui-helpers';

const DEFAULT_PROPS   = {displayName: "", role: "", email: ""};

const UserDialog = ({isOpen, onClose, userId}) => {
  const { classes }   = buildStyles();
  const dispatch  = useAppDispatch();
  const currentUser = useSelector(state => state.app.currentUser);
  const user      = useSelector(state => selectUser(state, userId));
  const status    = useSelector(state => selectStatusByKey(state, "users"));
  const engStatus = useSelector(state => selectStatusByKey(state, "engagements"));
  const [props, setProps]   = useState(DEFAULT_PROPS);
  const [original, setOriginal]   = useState(null);
  const [validation, setValidation]   = useState(null);
  const [assignChanges, setAssignChanges] = useState([]);
  const isAdd     = Boolean(userId === -1);
  const colWidth  = isAdd ? 12 : 5;
  const hasChanges = useMemo(() => {
    return (assignChanges.length > 0 || !shallowEqual(original, props));
  }, [original, props, assignChanges]);

  useEffect(() => {
    if(user){
      const original = {displayName: user.displayName, role: user.role, email: user.email};
      setProps(original);
      setOriginal(original);
    }
    else{
      setProps(DEFAULT_PROPS);
      setOriginal(original);
    }
    return undefined;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function onInputChanged(e){
    const key   = e.target.name;
    const value = e.target.value;
    setProps({...props, [key]: value});
  }

  function onEngagementsChanged(permissions){
    //TODO: implement support of changing engagements permissions in this dialog.
    console.log("change in engagement permissions", permissions);
    setAssignChanges(permissions);
  }
  
  async function onSave(){
    const isSame = shallowEqual(original, props);
    if(isSame && assignChanges.length === 0){
      //nothing changed, just close down
      onClose();
    }
    else{
      if(!isSame){
        const errors  = validateSave(props, currentUser.email);
        if(errors){
          //Found something wrong...
          setValidation(errors);
          return;
        }
        else{
          setValidation(null);
        }

        const result  = await dispatch(saveUserThunk({ id: user.id, model: props }));
        if(!result.ok){
          //TODO: error should show from status??
          return;
        }
      }

      //TODO: need to test this out...
      if(assignChanges.length > 0){
        for(let i = 0; i < assignChanges.length; i++){
          const ac = assignChanges[i];
          if(ac.role === null){
            //delete this role
            await dispatch(deleteAssignmentThunk({ id: ac.id }));
          }
          else{
            //Update this one
            await dispatch(saveAssignmentThunk({ id: ac.id, model: {entityType: "eng", entityId: ac.engagementId, role: ac.role, assignedUserId: userId} }));
          }
        };
      }

      onClose();
    }
  }

  function doClose(event, reason){
    if(muiDialogReason.isBackdrop(reason) || (status.isWorking && muiDialogReason.isEscape(reason))) return;
    onClose();
  }

  async function generateAssignmentReport(){
    const reportUrl = `admin/assignmentsbyuser?userId=${userId}`;
    // await dispatch(downloadReport({url: reportUrl}));
    await dispatch(downloadReportThunk({ url: reportUrl }));
  }

  return (
    <Dialog open={isOpen} onClose={doClose} maxWidth={isAdd ? "sm" : "md"}>
      <DialogTitle className={classes.title}>
        <IfBlock condition={!isAdd}>
          <Grid container justifyContent="space-between">
            <Typography className={classes.titleText}>{props.displayName}</Typography>
            <Typography className={classes.titleText}>ID: {user?.id}</Typography>
            <CloseIcon fontSize="small" onClick={onClose} className={classes.closeIcon}/>
          </Grid>
        </IfBlock>
        <IfBlock condition={isAdd}>
          <Typography className={classes.titleText}>New User</Typography>
        </IfBlock>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Grid container>
          {status.error && <ErrorDisplay error={status.error} />}
          
          <Grid item sm={colWidth} container alignContent="flex-start" className={classes.formGrid}>
            
            <Grid item container sx={{mb: 2}}>
              <TextField name="displayName" value={props.displayName} onChange={onInputChanged} 
                disabled={status.isWorking} fullWidth label="Name" className={classes.field}
                error={validation?.displayName.hasError} helperText={validation?.displayName.message}/>
            </Grid>

            <Grid item container sx={{mb: 2}}>
              <TextField name="email" value={props.email} onChange={onInputChanged} 
                disabled={!isAdd || status.isWorking} label="Email" fullWidth className={classes.field}
                error={validation?.email.hasError} helperText={validation?.email.message}/>
            </Grid>

            <Grid item container sx={{mb: 2}}>
              <FormControl error={Boolean(validation?.role.hasError)} variant="standard">
                <InputLabel id="role-label">Role</InputLabel>
                <Select name="role" label="Role" value={props.role} onChange={onInputChanged} disabled={status.isWorking} className={classes.field}>
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
                <FormHelperText>{validation?.role.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid container sx={{mt: 6}}>
              <Button onClick={generateAssignmentReport} variant="outlined">Download Assignments Report</Button>
            </Grid>

          </Grid>
          
          <IfBlock condition={!isAdd}>
            <Grid item sm={7} container>
              <WaitContainer isWaiting={engStatus.isWorking} message="Loading engagements...">
                <UserEngagements user={user} onChange={onEngagementsChanged} />
              </WaitContainer>
            </Grid>
          </IfBlock>

        </Grid>
      </DialogContent>

      <DialogActions>
        <Button size="small" disabled={status.isWorking} onClick={onClose}>Cancel</Button>
        <Button color="secondary" size="small" disabled={status.isWorking || !hasChanges} onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserDialog;

const buildStyles   = makeStyles()(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  title   : {
    background  : theme.palette.primary.dark,
    padding     : theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleText   : {
    fontSize    : 22,
    fontWeight  : 500,
    color       : theme.palette.primary.contrastText,
  },
  content   : {
    marginBottom  : theme.spacing(2),
  },
  formGrid  : {
    paddingRight  : theme.spacing(4),
  },
  formRow   : {
    marginBottom  : theme.spacing(2),
  },
  field     : {
    minWidth    : 150,
  },
  formSelectRow   : {
    marginTop     : theme.spacing(1.5),
    marginBottom  : theme.spacing(2),
  },
  closeIcon   : {
    marginLeft  : theme.spacing(2),
    color       : theme.palette.primary.contrastText,
    cursor      : "pointer",
  }
}));

const VALID_STATE       = {count: 0, displayName: {className: null, message: null}, email: {className: null, message: null}, role: {className: null, message: null}};

function validateSave(user, myEmail){
  let output  = Validator.exists(user);
  if(!output ||output.count === 0){
      //Need to validate email address matches the domain of current user
      if(!Validator.doesEmailDomainMatch(user.email, myEmail)){
          output = _.merge({}, VALID_STATE, {email: {hasError: true, className: 'has-error', message: 'Email domain must match your email domain.'}});
      }
      else{
          output = null;
      }
  }

  return output;
}