import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Archive as ArchiveIcon, History as HistoryIcon, EditOutlined as EditIcon } from '@mui/icons-material';
import { Button, Grid, Tooltip } from '@mui/material';
import DocTypeIcon from '@mui/icons-material/InsertDriveFile';
import { selectStatusByKey, selectDocTypes } from 'store/selectors/admin-selectors';
import DocTypeDialog from '../dialogs/doctype-dialog';
import { useQuery } from 'hooks';
import { ItemList } from 'features/common';
import { isTrue } from 'utils/general-helpers';

const EngagementDocTypes = () => {
  const { engagementId }  = useParams();
  const { sort }      = useQuery();
  const engId         = parseInt(engagementId);
  const engagements   = useSelector(state => state.admin.allEngagements);
  const docTypes      = useSelector(state => selectDocTypes(state, engId));
  const status        = useSelector(state => selectStatusByKey(state, "doctypes"));
  const [showId, setShowId]   = useState(null);
  
  const sorted        = useMemo(() => {
    if(docTypes?.length > 0){
      const parts   = (sort || "name-asc").split("-");
      return _.orderBy(docTypes, [parts[0]], [parts[1]]);
    }
    else{
      return [];
    }
  }, [docTypes, sort]);

  function onShow(id){
    setShowId(id);
  }

  function onOpenDialog(id){
    //TODO: open the doc type editor
    console.log("Open document type " + id);
  }

  const config  = listConfig({onShow: onShow, onOpen: onOpenDialog});
  const engagement  = _.find(engagements.items, eng => eng.id === engId);
  if(!engagement){
    console.error("Engagement not found while trying to display Engagement DocTypes.");
    return null;
  }
  
  return (
    <Grid id="data-view" container>
      
      <Grid item xs={12} container alignItems="center" justifyContent="flex-end" sx={{pb: 0.5}}>
        <Tooltip title="Add document type">
          <Button color="primary" onClick={() => setShowId(-1)}>
            <DocTypeIcon fontSize="small" style={{marginRight: "8px"}}/>
            Add Document Type  
          </Button>
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <ItemList config={config} items={sorted} isWorking={status.isWorking || false} onRefresh={() => {}} RowComponent={DocTypeListItem} noToolbar={true} />
      </Grid>

      {/* TODO: Need to provide the protocol id so it's limited to this engagement's protocol */}
      <DocTypeDialog isOpen={Boolean(showId)} onClose={() => setShowId(null)} docTypeId={showId} props={{protocolId: engagement.protocolId}} />
    </Grid>
  );
}

export default EngagementDocTypes;

const LIST_COLS   = [
  {
    id        : 0,
    sortKey   : "id",
    label     : "Id",
  },
  {
    id: 5,
    noSort: true,
    label: "",
  },
  {
    id        : 10,
    sortKey   : "name",
    label     : "Name",
    defaultDir: "asc",
  },
  {
    id        : 20,
    sortKey   : "validFrom",
    label     : "Last Modified",
  }, 
];

const listConfig = (actions) => ({
  keyFunc : item => item.id,
  cols    : LIST_COLS,
  actions : {
    onShow    : actions.onShow,
    onOpen    : actions.onOpen,
  }
});

function DocTypeListItem({item, actions}){
  return (
    <tr>
      <td>
        {item.id}
      </td>
      <td>
        {item.isReplaced && 
          <Tooltip title="This document type has been replaced by a newer version">
            <HistoryIcon fontSize="small" sx={{color: "text.secondary" }}/>
          </Tooltip>
        }
        {isTrue(item.propertyBag?.isDraft) && 
          <Tooltip title="This document type is a Draft">
            <EditIcon fontSize="small" sx={{color: "text.secondary" }}/>
          </Tooltip>
        }
        {isTrue(item.propertyBag?.isOutOfCirculation) && 
          <Tooltip title="This document has been removed from circulation">
            <ArchiveIcon fontSize="small" sx={{color: "text.secondary" }}/>
          </Tooltip>
        }        
      </td> 
      <td>
        <Button onClick={() => actions.onShow(item.id)} color="secondary"
          sx={{textTransform: "none", textAlign: "left", color: "#337ab7"}} title="Click to view document type">
          {item.name}
        </Button>
      </td>
      <td>
        {item.lastModified}
      </td>      
    </tr>
  );
}