import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import NavMenu from 'features/app/nav-menu';
import { CltHidden, ErrorDisplay } from 'features/common';
import { useAppParams } from 'hooks';
import { menuConfig } from './documents-config';
import DocumentsList from './documents-list';

function DocumentsView(){
  const { pathname } = useLocation();
  const { engagementId }    = useAppParams();
  const status          = useState({isWorking: false, error: null});
  const title         = `Eng ${engagementId} Documents`;  //`

  return (
    <Grid id="documents-view" container spacing={2} flexWrap="nowrap" sx={{flexDirection: {xs: "column", md: "row"}}}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <CltHidden smDown>
        <Grid id="documents-menu" item>
          <NavMenu settingsKey="documents-nav" menu={menuConfig} basePath={pathname} isWorking={status.isWorking} />  
        </Grid>
      </CltHidden>

      <Grid id="documents-content" item sx={{width: "100%", pb: 2}}>
        <ErrorDisplay error={status.error}></ErrorDisplay>
        <DocumentsList />
      </Grid>

    </Grid>
  );
}

export default DocumentsView;