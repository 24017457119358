import { ApiResponse } from "apisauce";
import { ApiResponseAction, IReviewSet } from "app-types";
import Apis from "store/api";
import { createAppAsyncThunk, haveItem, parseResponse } from "store/slices/slice-helpers";
import { APPROVE_TYPES } from "utils/reviewset-helpers";
import { ApproveSetParams } from "./thunk-types";

export const SET_THUNKS = {
  fetch: "reviewset/fetchSet",
  create: "reviewset/createSet",
  save: "reviewset/saveSet",
  approve: "reviewset/approveSet",
};

//=== Fetches a ReviewSet by the engagement and id
export const fetchSetThunk = createAppAsyncThunk(SET_THUNKS.fetch, async (params: any, thunkAPI) => {
  const { id, engagementId, isForced = false } = params;
  const state = thunkAPI.getState().set;

  let item: IReviewSet | null = null
  let itemEngagementId = -1;
  let response: ApiResponseAction<IReviewSet> | null = null;

  if(!isForced && !state.itemStatus.isDocChanged && haveItem(id, state)){
    item = state.item;
    itemEngagementId = item!.engagementId;
    response = { type: SET_THUNKS.fetch, ok: true, data: item!, params };
  }
  else {
    const getResult = await Apis.Reviewsets.get<IReviewSet, any>(`/${id}`);
    response = parseResponse(SET_THUNKS.fetch, getResult, { id });
    itemEngagementId = response.data?.engagementId ?? -1;
  }

  if(itemEngagementId && itemEngagementId !== engagementId){
    //trying to access the reviewset with an invalid engagement id
    console.error("ReviewSet requested with invalid engagement id");
    const error = {
      ok: false,
      message: "404: ReviewSet not found",
      id,
    };
    throw error;
  }

  return response;
});

//=== Creates a new ReviewSet
export const createSetThunk = createAppAsyncThunk(SET_THUNKS.create, async (params: any, thunkAPI) => { 
  const { data } = params;
  const postResult = await Apis.Reviewsets.post<IReviewSet, any>("", data);
  if(postResult.ok === false) throw new Error(postResult.problem);

  const changes = addLocalProps(data, postResult, thunkAPI.getState().app.currentUser);
  const response = parseResponse(SET_THUNKS.create, postResult, { data: changes, id: postResult.data?.id });

  return response;
});

//=== Saves a ReviewSet
export const saveSetThunk = createAppAsyncThunk(SET_THUNKS.save, async (params: any, thunkAPI) => {
  const { id, data } = params;
  const putResult = await Apis.Reviewsets.put<IReviewSet, any>(`/${id}`, data);
  if(putResult.ok === false) throw new Error(putResult.problem);
  
  //Add some info to the set for the slice
  const changes = addLocalProps(data, putResult, thunkAPI.getState().app.currentUser);
  const response = parseResponse(SET_THUNKS.save, putResult, { id, data: changes });

  return response;
});

//=== Approves a ReviewSet
export const approveSetThunk = createAppAsyncThunk(SET_THUNKS.approve, async (params: ApproveSetParams, thunkAPI) => {
  const  { model } = params;
  const endpoint = model.status === APPROVE_TYPES.unApprove ? "unapprove" : "approve";
  const url = `/${model.setId}/${endpoint}`;

  const result = await Apis.Reviewsets.post<IReviewSet, any>(url, model);
  if(result.ok === false) throw new Error(result.problem);

  const response = parseResponse(SET_THUNKS.approve, result, { id: model.setId, data: model });
  return response;
});


//===============================
// Helper functions
const addLocalProps = (data: any, result: ApiResponse<IReviewSet, any>, user: any): IReviewSet => {
  const changes = {
    ...data,
    lastModifiedDate      : result.data?.validFrom,
    lastModifiedUserName  : user.displayName,
    lastModifiedUserId    : user.id,
  };
  return changes;
}