import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { StatusCard } from './';

function NotFound(){

  const { classes }   = buildStyles();

  return (
    <Grid id="not-found" container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item xs={6}>
        <StatusCard status="danger" isVisible={true} className={classes.errorCard}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h2" className={classes.header}>We couldn't find this item</Typography>
            <Typography className={classes.body}>This could happen if you entered an invalid url, the item was removed, or you don't have permissions to view the item.</Typography>
            <Button variant="contained" color="secondary" component={Link} to="/" className={classes.btn}>Return Home</Button>
          </Grid>
        </StatusCard>
      </Grid>
    </Grid>
  );
}

export default NotFound;

const buildStyles   = makeStyles()(theme => ({
  root        : {
    height        : "calc(100vh - 64px)",
  },
  errorCard   : {
    padding           : theme.spacing(2),
  },
  header  : {
    fontSize          : 23,
    fontWeight        : 600,
    marginBottom      : theme.spacing(2),
  },
  body    : {
    fontSize          : 18,
    fontWeight        : 300,
    marginBottom      : theme.spacing(2),
  }
}));
