import _ from 'lodash';
import { shallowEquals } from './general-helpers';

const validateField = (errors, props, message = 'This field is required.') => {
    // if(props.id === NEW_ID){
        if(!props.name || props.name.trim().length === 0){
            let output          = _.merge({}, errors, {count: errors.count + 1});
            output[props.key]   = {className: 'has-error', message: message };
            return output;                    
        } 
    // }

    return errors;
};

const validateRequiredFields = (item, validState) => {
    let errors  = _.reduce(_.values(item), (state, f) => {return validateField(state, f);}, validState);
    return errors;
};

export const createValidationError = (key, isError, message, otherErrors = {}) => {
    const thisError     = {
        hasError    : isError,
        className   : isError ? "has-error" : null,
        message     : isError ? message : null,
    };

    let errCount    = otherErrors?.count ? otherErrors.count + 1 : 1;

    return {...otherErrors, [key] : thisError, count: errCount };
}

//---
// Validates that a specific item property is present and has a value
export const validateProperty = (state, key, item, message) => {
    if(_.has(item, key)){
        const value = item[key];
        if(!value || (_.isString(value) && value.trim().length === 0)){
            let output  = _.merge({}, state, {count: (state?.count || 0) + 1});
            output[key] = {hasError: true, className: 'has-error', message: message, fieldProps: {error: true, helperText: message} };
            return output;
        }
    }

    let output = _.merge({}, state);
    output[key] = {hasError: false, className: null, message: null};
    return output;
}

//---
// Validates that all fields have values
export const validateExists = (item, errorMessage = 'This field is required.') => {
    const keys = _.keys(item);
    const errors = _.reduce(keys, (state, key) => { return validateProperty(state, key, item, errorMessage); }, { count: 0 });
    return errors.count > 0 ? errors : null;
}

const doesEmailDomainMatch = (email1, email2) =>{
    let domain1 = email1.split('@')[1];
    let domain2 = email2.split('@')[1];
    return domain1.toLowerCase() === domain2.toLowerCase();
}

export const validatePropertyCondition = (state, key, item, condition, message = "Value is not valid.") => {
    if(_.has(item, key)){
        const value = item[key];
        const isOk  = condition(value);
        if(!isOk){
            let output  = _.merge({}, state, {count: (state?.count || 0) + 1});
            output[key] = {hasError: true, className: 'has-error', message: message, fieldProps: {error: true, helperText: message} };
            return output;
        }
    }

    let output = _.merge({}, state);
    output[key] = {hasError: false, className: null, message: null};
    return output;
}

const validatePropertyConditions = (obj, keys, condition, message) => {
    const errors    = _.reduce(keys, 
        (state, key) => {
            return validatePropertyCondition(state, key, obj, condition, message);
        }, { count: 0});

    return errors.count > 0 ? errors : null;
}

const helper = {
    exists                  : validateExists,
    requiredItems           : validateRequiredFields,
    doesEmailDomainMatch    : doesEmailDomainMatch,
    validatePropertyCondition  : validatePropertyCondition,
    validatePropertyConditions : validatePropertyConditions,
    hasChanges              : (original, updates) => !shallowEquals(original, updates),
};

export default helper;
