import React, { ReactNode } from "react";
import Tooltip from '@mui/material/Tooltip';

export interface ITipIfProps {
  tooltip?: string | null;
  children: ReactNode;
}

const TipIf = ({tooltip, children}: ITipIfProps)  => {

  return (
    <>
      {tooltip && <Tooltip title={tooltip}>
        <>
          {children}
        </>
      </Tooltip>
      }

      {!tooltip && 
        <>{children}</>
      }
    </>
  )
};

export default TipIf;