import _ from 'lodash';
import { createSelector } from 'reselect';
import { buildPath } from 'utils/route-helpers';
import { formatDate, isTrue } from '../../utils/general-helpers';

const _getSet           = state => state.set.item;
const _getSettings      = state => state.settings;
const _getDocTypes      = state => state.settings?.documentTypes;
const _getEngagementId  = (state, engId) => engId; //props && props.params && props.params.engagementId ? props.params.engagementId : null;

export const selectSet = createSelector(
  [_getSet, _getSettings],
  (set, settings) => {
        if(!set || !settings) return set;        //need to make sure the set exists first
        
        const approvalInfo  = calcApproval(set);
        const engagement    = _.find(settings.engagements, e => e.id === set.engagementId);
        const site          = _.find(settings.sites, s => s.id === engagement?.siteId);
        const output        = _.merge({}, set, { siteId : engagement?.siteId, clientId : site?.clientId }, approvalInfo); //, {isMultiApprove: isMulti});
        return output;
  }
);

///------------------
/// Determines whether or not the current engagement requires multi-approve for ReviewSets
///---
export const selectMultiApproveFromEngagement = createSelector(
    [_getEngagementId, _getSettings],
    (engagementId, settings) => {
        let requires2       = false;

        if(engagementId){
            const engId     = parseInt(engagementId);
            const eng       = _.find(settings.engagements, e => e.id === engId);
            const prot      = _.find(settings.protocols, p => p.id === eng.protocolId);
            requires2       = prot?.propertyBag?.qReviewSetMulti === "true"; //Boolean(prot && prot.propertyBag?.qReviewSetMulti);
        }

        return requires2;
    }
);

///------------------
/// Get the Engagement Id out of the properties
///---
const _getEngIdFromProps = (props) => props && props.params && props.params.engagementId ? props.params.engagementId : null;
export const getEngagementId = createSelector(
    [_getEngIdFromProps],
    (engId) => {
        return engId ?  parseInt(engId) : null;
    }
);

//********************* HELPERS ********************/

const clCount           = state => state.checkListCount;
const approvedClCount   = state => state.approvedCheckListCount;
const statusCode        = state => state.statusCode;
const calcApproval = createSelector(
    [clCount, approvedClCount, statusCode],
    (clCount, approvedClCount, statusCode) => {
        
        // Can also add the Status to the DTO very easily from the new view on the server
        if(clCount > 0){
            return {
                    checkListCount          : clCount, 
                    approvedCheckListCount  : approvedClCount, 
                    isApprovable            : (statusCode !== 100 && clCount === approvedClCount),
                    isApproved              : (statusCode === 100),
                    isPreApproved           : (statusCode === 50),
                    isPending               : (statusCode === 0),
            };
        }

        //default if we can't determine, error on the side of caution
        return {
                checkListCount          : -1, 
                approvedCheckListCount  : 0, 
                isApprovable            : false,
                isApproved              : (statusCode === 100),
                isPreApproved           : (statusCode === 50),
                isPending               : (statusCode === 0),
        };
    }
);

const getSetFromProps       = props => props.set;
const getTargetFromProps    = props => props.target;
export const getSetSummary = createSelector(
    [getSetFromProps, getTargetFromProps],
    (set, target) => {

        return {
            id                  : set.id,
            statusCode          : set.statusCode,
            url                 : buildPath([target, set.id]),
            title               : set.title ? set.title.trim() : set.title,
            lastModifiedDate    : formatDate(set.lastModifiedDate),
            lastModifiedUser    : set.lastModifiedUserName,
            description         : set.description,
            notes               : set.notes,
            reportLocation      : set.reportLocation,
            reportUrl           : set.reportUrl
        };
    }
);

///------------------
/// Determines if the current ReviewSet is multi-approve or not
///---
export const selectIsMultiApprove = createSelector(
    [_getSet, _getSettings],
    (set, settings) => {
        let isMulti         = false;

        if(set?.engagementId && settings){
            const eId   = parseInt(set.engagementId);
            const eng   = settings.engagements.find(e => e.id === eId);
            const prot  = settings.protocols.find(p => p.id === eng?.protocolId);
            isMulti     = prot?.propertyBag?.qReviewSetMulti === "true"; //Boolean(prot && prot.propertyBag?.qReviewSetMulti);
        }

        return isMulti;
    }
);

///----------------------
/// Gets the list of Document Types that are available for the current ReviewSet (based on protocol)
///----
export const selectSetDocTypes = createSelector(
    [_getSet, _getDocTypes],
    (set, docTypes) => {
        if(!set || !docTypes) return null;
        //Filter on Protocol ID, Has Checklists, and not Old Version
        const items     = _.filter(docTypes, d => 
            d.protocolId === set.protocolId && 
            (d.checkListCount > 0 || d.checkLists?.length > 0)  && 
            !d.propertyBag?.nextVersion     &&
            !isTrue(d.propertyBag?.isDraft) &&
            !isTrue(d.propertyBag?.isOutOfCirculation)
        );

        const ordered   = _.orderBy(items, ["name"], ["asc"]);
        return ordered;
    }
);