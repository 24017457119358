import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button, Tooltip } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/EditOutlined';
import { useAppDispatch } from 'store';
import { fetchDocumentTypesThunk } from 'store/thunks';
import { useDialogs } from 'hooks';
import { useAllEngagements } from 'hooks/admin-hooks';
import { ItemList } from 'features/common';
import { selectStatusByKey, selectAdminDocTypes } from 'store/selectors/admin-selectors';
import ProtocolDialog from '../dialogs/protocol-dialog';
import DocTypeDialog from '../dialogs/doctype-dialog';
import { isTrue } from '../../../utils/general-helpers';

const DocTypesList = () => {
  const status      = useSelector(state => selectStatusByKey(state, "doctypes"));
  const doctypes    = useSelector(selectAdminDocTypes);
  // eslint-disable-next-line no-unused-vars
  const engagements = useAllEngagements();  //Note: Needed for doc types to load, even though engagements aren't used directly
  const [dialog, openDialog, closeDialog]   = useDialogs();
  const dispatch    = useAppDispatch();

  let items     = doctypes?.items;

  function onRefresh(props, query){
    dispatch(fetchDocumentTypesThunk({ filter: query, pageNumber: props.pageNumber, pageSize: props.pageSize }));
  }

  const gridConfig  = {
    title     : "Document Types",
    baseUrl   : "/admin/documenttypes",
    searchPlaceholder   : "Search by name...",
    pageSizeKey : "admin-dt-list",
    keyFunc   : item => item.id,
    filterOptions   : [
      { key: "current",   label: "Current"},
      { key: "all",   label: "All", },
      { key: "historic",   label: "Historic", }
    ],
    actions   : {
      onEditProtocol  : id => openDialog("protocol", id),
      onEditDocType   : id => openDialog("doctype", id),
    },
    cols  : GRID_HEADER,
  }

  return (
    <>
      <ItemList 
        config={gridConfig} 
        items={items} 
        pagination={doctypes?.pagination} 
        isWorking={status.isWorking}
        onRefresh={onRefresh}
        addUrl={"/admin/documenttypes?dialogs=doctype:-1"}
        RowComponent={DocTypeListItem}
      />

      <ProtocolDialog protocolId={dialog?.protocol} onClose={() => closeDialog("protocol")}/>
      <DocTypeDialog docTypeId={dialog?.doctype} onClose={() => closeDialog("doctype")}/>
    </>
  );
}

export default DocTypesList;

const buildStyles   = makeStyles()(theme => ({
  listCard  : {
    width       : "100%",
  },
  editButton  : {
    textTransform   : "none",
    fontWeight      : "700",
    textAlign : "left",
    color           : "#337ab7",
    "& span"        : {
      textAlign       : "left",
      justifyContent  : "flex-start",
    },
  },
  itemCol   : {
    verticalAlign   : "middle !important",
    "& svg"   : {
      color         : theme.palette.grey[500],
    }
  },
  checklistLink   : {
    display     : "block",
    fontSize    : "1rem",
    fontWeight  : "700",
  },
  addChecklistLink : {
    display     : "block",
    fontSize    : "1rem",
    fontWeight  : "400",
    fontStyle   : "italic",
  }
}));

function DocTypeListItem({item, actions}){
  const { classes }   = buildStyles();
  return (
    <tr className={classes.userRow}>
      <td className={classes.itemCol}>{item.id}</td>
      <td className={classes.itemCol}>
        {item.isReplaced && 
          <Tooltip title="This document type has been replaced by a newer version">
            <HistoryIcon fontSize="small"/>
          </Tooltip>
        }
        {isTrue(item.propertyBag?.isDraft) && 
          <Tooltip title="This document type is a Draft">
            <EditIcon fontSize="small"/>
          </Tooltip>
        }
        {isTrue(item.propertyBag?.isOutOfCirculation) && 
          <Tooltip title="This document has been removed from circulation">
            <ArchiveIcon fontSize="small"/>
          </Tooltip>
        }        
      </td>      
      <td className={classes.itemCol}>
        <Button onClick={() => actions.onEditDocType(item.id)} className={classes.editButton} title="Click to view / edit document type">
          {item.name}
        </Button>
      </td>
      <td className={classes.itemCol}>
        <ChecklistColumnContent item={item} classes={classes} />        
      </td>
      <td className={classes.itemCol}>{item.engagement ? `${item.engagement.name ? item.engagement.id : ""}${item.engagement.name ? ": " : ""}${item.engagementName}`: ""}</td>
      <td className={classes.itemCol}>
        {item.protocol && 
          <Button onClick={() => actions.onEditProtocol(item.protocol.id)} className={classes.editButton} title="Click to view / edit protocol">
            {item.protocol.name}
          </Button>
        }
        {!item.protocol && "[unknown protocol]"}        
      </td>
      <td className={classes.itemCol}>{item.lastModified}</td>
    </tr>
  );
}

function ChecklistColumnContent({item, classes}){
  const hasLists  = item.checkLists?.length > 0;

  return (
    <>
    {hasLists &&
      _.map(item.checkLists, cl => {
        return (
          <Link key={cl.id} to={`/admin/checklists/${cl.id}/edit`} className={classes.checklistLink} title="Click to view / edit checklist">
            {cl.name}
          </Link>
        );
      })
    }
    {!hasLists && 
      <Link to={`/admin/checklists/-1/edit?doctype=${item.id}`} className={classes.addChecklistLink} title="Click to create a checklist">
      Add a checklist
    </Link>
    }
    </>
  )
}

const GRID_HEADER   = [
  {
    id        : 0,
    sortKey   : "id",
    label     : "Id",
    style     : {width: "45px"},
  },
  {
    id        : 5,
    style     : { width: "40px" },
  },
  {
    id        : 10,
    sortKey   : "name",
    label     : "Name",
    style     : {width: "200px"},    
  },
  {
    id        : 20,
    label     : "Checklists",
    style     : {width: "200px"},    
  },
  {
    id        : 30,
    sortKey   : "engagement",
    label     : "Engagement",
  },
  {
    id        : 40,
    sortKey   : "protocol",
    label     : "Protocol",
  },
  {
    id        : 50,
    sortKey   : "date",
    label     : "Last Modified",
    defaultDir: "desc",
  },
];