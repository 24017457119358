import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;
let appInsights = null;

function getAppInsightsEnvironment() {
    const hostName = window.location.hostname.toLowerCase();
    let env = "";
    
    if (hostName.indexOf("-qa") >= 0) {
        env = "QA";
    } else if (hostName.indexOf("-dev") >= 0) {
        env = "Development";
    } else if (hostName.indexOf("localhost") >= 0) {
        env = "Local";
    } else if (hostName.indexOf("-upgrade") >= 0) {
        env = "Development";
    } else if (hostName.indexOf("-test") >= 0) {
        env = "QA";
    }  else if (hostName.indexOf("-stage") >= 0) {
        env = "Stage";
    } else {
        env = "Production";
    }
    
    return env;
};

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (instrumentationKey, browserHistory) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx')
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                autoTrackPageVisitTime: true,
                enableAutoRouteTracking: true,      //for use with react-router v6, where history isn't exposed
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        const environment = getAppInsightsEnvironment();
        if(environment !== "Local"){        //localhost puts logs of exceptions into console
            appInsights.loadAppInsights();
            appInsights.addTelemetryInitializer(envelope => envelope.data.EnvironmentName = environment);
            appInsights.trackPageView();
        }
    };

    return {reactPlugin, appInsights, initialize};
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;