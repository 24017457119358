import _, { pick } from 'lodash';
import { shallowEquals } from './general-helpers-ts';

//Starting to migrate helpers to typescript
export { formatDate, getNowString } from "./date-helpers";
export { normalizeError, shallowEquals } from "./general-helpers-ts";

export function engagementName(engagement){
  if(!engagement) return null;
  return engagement?.name || `Engagement ${engagement.id}`;   //`
}

//----
// Will return a url with an added / removed / modified search parameter
export function updateUrlSearch(key, value){
  const url   = new URL(window.location.href);
  if(url.searchParams.has(key) && !value) 
      url.searchParams.delete(key);
  else 
      url.searchParams.set(key, value);
  
  const relative  = url.href.replace(window.location.origin, ""); //need to remove the origin for sake of the history
  return decodeURIComponent(relative);  //don't want the encoding at this point
}

export function inputChangeHandler(e){
  const key   = e.currentTarget.id || e.currentTarget.attributes["data-id"]?.value;
  const val   = e.target.value;
  return {
    [key]     : val,
  };
}

export function tryParseInt(value, fallback){
  try{
    const val = parseInt(value);
    return _.isNaN(val) ? fallback : val;
  }
  catch(ex){
    return fallback === undefined ? -1 : fallback;
  }
}

export function findById(items, id, idProp = "id"){
  const key  = idProp || "id";
  const idInt   = tryParseInt(id, null);
  if(items.length === 0 || id === null) return null;
  return _.find(items, item => item[key] === idInt);

}

//===
// prepares the model for use in the UI
export function flatten(item, modelFields, propBagFields, boolFields){
  let model   = {...item, ..._.pick(item, modelFields), ..._.pick(item?.propertyBag, propBagFields)};
  
  if(boolFields && _.isArray(boolFields) && boolFields.length > 0){
    model   = _.reduce(boolFields, (output, field) => {
      if(output[field]) output[field]   = Boolean(output[field] === "true");
      return output;
    }, model);
  }
  
  return model; 
}

//===
// prepares the model to be sent to the server
export function unFlatten(item, original, modelFields, propBagFields){
  let model     = pick(item, modelFields);
  const pb      = pick(item, propBagFields);
  const oPb     = pick(original, propBagFields);

  if(!shallowEquals(oPb, pb)){
    model.propertyBag   = pb;
  }

  return model;
}



export function swapNulls(obj){
  const result  = _.mapValues(obj, v => (v === null ? "" : v));
  return result;
}

export function formatOptionChoices(choices, maxDisplay = 5){
  let items   = !!choices ? JSON.parse(choices) : [];
  let extra   = 0;
  if(items.length > maxDisplay){
    extra   = items.length - maxDisplay;
    items   = items.slice(0, maxDisplay);
  }
  
  const extraStr  = extra > 0 ? `, + ${extra} others...` : "";   //`
  return `${items.join(", ")}${extraStr}`;  //`
}

export function optionTypeNames(type){
  const iVal  = parseInt(type);
  switch(iVal){
    case 0  : return "Radio boxes"; 
    case 1  : return "Checkboxes (multi-select)";
    case 2  : return "Combo box";
    default: return "[unknown]";
  };
}

export function keyDownCommand(e){
  const match   = _.find(keyMap, m => m.func(e));
  return match?.label || null;  
}

const keyMap  = [
  {label: "moveUp",                func : e => !!(e.shiftKey && e.keyCode === 38), }, //up arrow,
  {label: "moveDown",              func : e => !!(e.shiftKey && e.keyCode === 40), }, //down arrow,
  {label: "insertAssertionBefore", func : e => !!(e.altKey && e.shiftKey && e.keyCode === 65), },  //a,
  {label: "insertAssertionAfter",  func : e => !!(e.altKey && e.keyCode === 65), },  //a,
  {label: "insertSectionBefore",   func : e => !!(e.altKey && e.shiftKey && e.keyCode === 83), },  //s,
  {label: "insertSectionAfter",    func : e => !!(e.altKey && e.keyCode === 83), },  //s,
  {label: "openTextInput",         func : e => !!(e.altKey && e.keyCode === 84),   },  //i,
  {label: "openOptionsInput",      func : e => !!(e.altKey && e.keyCode === 79),   },  //o,
  {label: "deleteItem",            func : e => !!(e.altKey && e.keyCode === 88),   },  //x,
]

export const getHost = () => {
  const hostName = window.location.hostname.toLowerCase();
  let env     = "";

  if(hostName === "clt.quanticgroup.com" || hostName === "agreeable-sky-06e9d6310.1.azurestaticapps.net"){ // hostName === "quanta-clt.azurewebsites.net" || hostName === "quantaclient.azurewebsites.net" || hostName === "quantawebapi.azurewebsites.net"){
      env = "prod";
  }
  else if(hostName.indexOf("localhost") >= 0){
      env     = "local";
  }
  else if(hostName.indexOf("-development") >= 0 || hostName.indexOf("-dev") >= 0){  //redundant (-dev will capture -development), but just want to be explicit
      env     = "dev";
  }
  else if(hostName.indexOf("-stage") >= 0 || hostName.indexOf("-staging") >= 0){
      env     = "stage";
  }
  else if(hostName.indexOf("-test") >= 0){
      env     = "test";
  }
  else if(hostName.indexOf("-qa") >= 0){
      env     = "qa";
  }
  else if(hostName.indexOf("-getinge") >= 0){
      env     = "getinge";
  }
  else{
      env     = "dev";
  }

  return env;
};

//---
// Compares two strings, and returns true if they're the same, ignoring all whitespace
export const isSameWithoutWhitespace = (left, right) => {
  const leftNoW = left.replace(/\s+/g, '');
  const rightNoW = right.replace(/\s+/g, '');
  return leftNoW === rightNoW;      
}

export const isTrue = (val) => {
  return val ? Boolean((val.toString().toLowerCase() === "true" || val === true)) : false;
}