import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { StatusCard } from 'features/common';
// import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import blue from '@mui/material/colors/blue';

function NotImplemented({viewName}){

  const { classes }   = buildStyles();

  return (
    <Grid id="not-found" container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item xs={6}>
        <StatusCard status="info" isVisible={true} className={classes.card}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h2" className={classes.header}>{viewName}</Typography>
            <Typography className={classes.body}>This feature hasn't been implemented yet.  We're sorry for the inconvenience, but we're working on it!</Typography>
            <Button variant="contained" color="secondary" component={Link} to="/" className={classes.btn}>Return Home</Button>
          </Grid>
        </StatusCard>
      </Grid>
    </Grid>
  );
}

export default NotImplemented;

const buildStyles   = makeStyles()(theme => ({
  root        : {
    margin        : theme.spacing(3),
    padding       : theme.spacing(1),
  },
  card  : {
    padding       : theme.spacing(2),
  },
  header  : {
    fontSize          : 18,
    fontWeight        : 600,
    marginBottom      : theme.spacing(2),
  },
  body    : {
    fontSize          : 16,
    fontWeight        : 300,
    marginBottom      : theme.spacing(2),
    textAlign         : "center",
  }
}));
