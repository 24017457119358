import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Alert from '@mui/material/Alert';
import { selectEnvironment } from '../../store/selectors/setting-selectors';
import { Grid } from '@mui/material';

const EnvironmentAlert = () => {
  const { classes }   = buildStyles();
  const env = useSelector(selectEnvironment);
  
  if(!env) return null;
  const [severity, message] = getAlert(env);
  if(!severity) return null;

  return (
    <Alert severity={severity} classes={{root: classes.alert}}>
      <Grid container alignItems="center" sx={{fontSize: "1rem"}}>
        {message}
      </Grid>
    </Alert>    
  );
}

export default EnvironmentAlert;

const buildStyles   = makeStyles()(theme => ({
  alert: {
    padding: `${theme.spacing(0)} ${theme.spacing(2)}`, //`
    width: "50%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: theme.spacing(0.5),
      "& .MuiAlert-icon, .MuiAlert-message": {
        padding: "2px 0",
      }
    }
  }
}));

const getAlert = (env) => {
  if(env.isConcerning){
    return ["error", `PROD DB WARNING (Server:  ${env.host})`];
  }
  else if(env.isProdEnv){
    return [null, null];
  }
  else if(env.host === "local"){
    return ["info", `${env.databaseName} database`];
  }
  else {
    return ["warning", `${env.env} environment ${env.env === "Mixed" ? ` (Server:  ${env.host}, Database: ${env.databaseName})` : ""}`];
  }
}