import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import EngagementsList from './engagements-list';
import RecentsTable from './parts/recents-table';
import { useMemo } from 'react';
import AppLayout from 'features/app/app-layout';

function HomeView(){
    const { classes }   = buildStyles();
    const user      = useSelector(state => state.app.currentUser);
    const displayName = user?.displayName;

    const name      = useMemo(() => {
      if(displayName){
        const parts = displayName.trim().split(" ");
        if(parts[0].endsWith(",") && parts.length > 1){
          return parts[1];
        }
        return parts[0];
      }
      return "";
    }, [displayName]);

    return (
      <AppLayout>
        <Grid id="home-view" container spacing={2} className={classes.homeRoot}>
          <Helmet>
            <title>Checklist Tracker Home</title>
          </Helmet>

          <Grid container direction="column" sx={{backgroundColor: "primary.main", minHeight: "400px", p: 2, pb: 9}}>
            <Grid item container justifyContent={{xs: "center", sm: "flex-start"}} sx={{mt: 1}}>
              <Typography variant="h5" color="white">Welcome back {name}</Typography>
            </Grid>

            <Grid item sm={8} container>
              <RecentsTable />
            </Grid>
          </Grid>

          <Grid container sx={{p: 2, mt: "-67px"}}>
            <Card id="home-eng-list-card" sx={{width: "100%", m: 0, mx: {xs: 0, md: 4}}}>
              <EngagementsList />
            </Card>
          </Grid>

        </Grid>
      </AppLayout>
    )
}

export default HomeView;

const buildStyles = makeStyles()(theme => ({
  homeRoot    : {
    marginLeft      : theme.spacing(-2),
    width           : `calc(100% + ${theme.spacing(4)})`, //`
    background      : theme.palette.grey[50],
  },    
}));


